import { combineReducers } from 'redux';
import { companyClientsReducer } from './clients/reducer';
import { scheduleReducer } from './schedule/reducer';
import { expertReducer } from './experts';
import { companyServicesReducer } from './services/reducer';
export var companyReducer = combineReducers({
    clients: companyClientsReducer,
    schedule: scheduleReducer,
    experts: expertReducer,
    services: companyServicesReducer,
});
