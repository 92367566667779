export var ProfileBookingsActionType;
(function (ProfileBookingsActionType) {
    ProfileBookingsActionType["GET_BOOKINGS_PENDING"] = "GET_BOOKINGS_PENDING";
    ProfileBookingsActionType["GET_BOOKINGS_SUCCESS"] = "GET_BOOKINGS_SUCCESS";
    ProfileBookingsActionType["GET_BOOKINGS_ERROR"] = "GET_BOOKINGS_ERROR";
    ProfileBookingsActionType["GET_BOOKINGS_PENDING_PENDING"] = "GET_BOOKINGS_PENDING_PENDING";
    ProfileBookingsActionType["GET_BOOKINGS_PENDING_SUCCESS"] = "GET_BOOKINGS_PENDING_SUCCESS";
    ProfileBookingsActionType["GET_BOOKINGS_PENDING_ERROR"] = "GET_BOOKINGS_PENDING_ERROR";
    ProfileBookingsActionType["ADD_BOOKING_PENDING"] = "ADD_BOOKING_PENDING";
    ProfileBookingsActionType["ADD_BOOKING_SUCCESS"] = "ADD_BOOKING_SUCCESS";
    ProfileBookingsActionType["ADD_BOOKING_ERROR"] = "ADD_BOOKING_ERROR";
    ProfileBookingsActionType["DELETE_BOOKING_PENDING"] = "DELETE_BOOKING_PENDING";
    ProfileBookingsActionType["DELETE_BOOKING_SUCCESS"] = "DELETE_BOOKING_SUCCESS";
    ProfileBookingsActionType["DELETE_BOOKING_ERROR"] = "DELETE_BOOKING_ERROR";
    ProfileBookingsActionType["EDIT_BOOKING_PENDING"] = "EDIT_BOOKING_PENDING";
    ProfileBookingsActionType["EDIT_BOOKING_SUCCESS"] = "EDIT_BOOKING_SUCCESS";
    ProfileBookingsActionType["EDIT_BOOKING_ERROR"] = "EDIT_BOOKING_ERROR";
    ProfileBookingsActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(ProfileBookingsActionType || (ProfileBookingsActionType = {}));
