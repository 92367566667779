var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import { useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/uk';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
export var useCurrentLocaleTranslation = function (translations) {
    var locale = useParams().locale;
    useEffect(function () {
        var e_1, _a;
        try {
            for (var _b = __values(Object.keys(translations)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var locale_1 = _c.value;
                i18next.addResourceBundle(locale_1, 'translation', translations[locale_1], true, true);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        i18next.changeLanguage(locale);
        moment.locale(locale);
    }, []);
    return locale;
};
