import { combineReducers } from 'redux';
import { bookingReducer } from './booking';
import { chatReducer } from './chat';
import { clientsReducer } from './clients';
import { companyReducer } from './company';
import { consultantRequestReducer } from './consultant-request/reducer';
import { clientConsultantRequestReducer } from './client-consultant-request/reducer';
import { tagReducer } from './tag';
import { expertReducer } from './company/experts';
import { diseaseReducer } from './disease';
import { referenceReducer } from './reference';
import { wardsReducer } from './wards';
import { dataArchiveReducer } from './data-archive';
import { expertScheduleOfAppointmentsReducer } from './expert-schedule-of-appointments';
import { serviceReducer } from './service';
import { registerReducer } from './register';
import { passwordRestoreReducer } from './password-restore';
import { userReducer } from './user';
import { cabinetReducer } from './cabinet';
import { referralsReducer } from './referrals';
import { notificationsReducer } from './notifications';
import { categoriesReducer } from './categories';
export var moiStore = combineReducers({
    booking: bookingReducer,
    chat: chatReducer,
    clients: clientsReducer,
    consultantRequest: consultantRequestReducer,
    clientConsultantRequest: clientConsultantRequestReducer,
    company: companyReducer,
    tag: tagReducer,
    experts: expertReducer,
    disease: diseaseReducer,
    reference: referenceReducer,
    wards: wardsReducer,
    dataArchive: dataArchiveReducer,
    expertScheduleOfAppointments: expertScheduleOfAppointmentsReducer,
    service: serviceReducer,
    register: registerReducer,
    passwordRestore: passwordRestoreReducer,
    user: userReducer,
    cabinet: cabinetReducer,
    referrals: referralsReducer,
    notifications: notificationsReducer,
    categories: categoriesReducer,
});
