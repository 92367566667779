export var PARAM_MOI_BOOKING_MEETING_URL = 'PARAM_MOI_BOOKING_MEETING_URL';
export var PARAM_MOI_WARDS_LIST_URL = 'PARAM_MOI_WARDS_LIST_URL';
export var PARAM_MOI_WARDS_REGISTER_URL = 'PARAM_MOI_WARDS_REGISTER_URL';
export var PARAM_MOI_WARDS_REQUESTS_LIST_URL = 'PARAM_MOI_WARDS_REQUEST_LIST_URL';
export var PARAM_MOI_WARDS_ACCEPT_REQUEST_URL = 'PARAM_MOI_WARDS_ACCEPT_REQUEST_URL';
export var PARAM_MOI_WARDS_DECLINE_REQUEST_URL = 'PARAM_MOI_WARDS_DECLINE_REQUEST_URL';
export var PARAM_MOI_WARDS_SEND_REQUEST_URL = 'PARAM_MOI_WARDS_SEND_REQUEST_URL';
// moi bookings
export var PARAM_MOI_BOOKINGS_LIST_URL = 'PARAM_MOI_BOOKINGS_LIST_URL';
export var PARAM_MOI_BOOKINGS_LIST_SUMMARY_URL = 'PARAM_MOI_BOOKINGS_LIST_SUMMARY_URL';
export var PARAM_MOI_BOOKINGS_CREATE_URL = 'PARAM_MOI_BOOKINGS_CREATE_URL';
export var PARAM_MOI_BOOKINGS_CANCEL_URL = 'PARAM_MOI_BOOKINGS_CANCEL_URL';
export var PARAM_MOI_BOOKING_LEAVE_REDIRECT_URL = 'PARAM_MOI_BOOKING_LEAVE_REDIRECT_URL';
// Data archive
// Data archive - my records
export var PARAM_MOI_DATA_ARCHIVE_RECORDS_CATEGORIES_LIST_URL = 'PARAM_MOI_DATA_ARCHIVE_ALL_CATEGORIES_LIST_URL';
export var PARAM_MOI_DATA_ARCHIVE_CREATE_RECORD_URL = 'PARAM_MOI_DATA_ARCHIVE_CREATE_RECORD_URL';
export var PARAM_MOI_DATA_ARCHIVE_GET_ONE_RECORD_URL = 'PARAM_MOI_DATA_ARCHIVE_GET_ONE_RECORD_URL';
export var PARAM_MOI_DATA_ARCHIVE_GET_ALL_RECORDS_URL = 'PARAM_MOI_DATA_ARCHIVE_GET_ALL_RECORDS_URL';
export var PARAM_MOI_DATA_ARCHIVE_DELETE_RECORD_URL = 'PARAM_MOI_DATA_ARCHIVE_DELETE_RECORD_URL';
// Data archive - history of changes
export var PARAM_MOI_DATA_ARCHIVE_GET_ALL_CHANGES_HISTORY_URL = 'PARAM_MOI_DATA_ARCHIVE_GET_ALL_CHANGES_HISTORY_URL';
// Data archive - access
// Data archive - local storage
export var PARAM_MOI_CHAT_MESSAGE_TEMPLATE_LIST_URL = 'PARAM_MOI_CHAT_MESSAGE_TEMPLATE_LIST_URL';
export var PARAM_MOI_CHAT_MESSAGE_TEMPLATE_CREATE_URL = 'PARAM_MOI_CHAT_MESSAGE_TEMPLATE_CREATE_URL';
export var PARAM_MOI_CHAT_MESSAGE_TEMPLATE_REMOVE_URL = 'PARAM_MOI_CHAT_MESSAGE_TEMPLATE_REMOVE_URL';
export var PARAM_MOI_CHAT_MESSAGE_TEMPLATE_EDIT_URL = 'PARAM_MOI_CHAT_MESSAGE_TEMPLATE_EDIT_URL';
export var PARAM_MOI_CHAT_MESSAGE_TEMPLATE_SINGLE_URL = 'PARAM_MOI_CHAT_MESSAGE_TEMPLATE_SINGLE_URL';
export var PARAM_MOI_CHAT_MESSAGE_TEMPLATE_SEND_URL = 'PARAM_MOI_CHAT_MESSAGE_TEMPLATE_SEND_URL';
export var PARAM_MOI_CLIENTS_LIST_URL = 'PARAM_MOI_CLIENTS_LIST_URL';
export var PARAM_MOI_COMPANY_SIMPLE_EXPERTS_ALL_URL = 'PARAM_MOI_COMPANY_SIMPLE_EXPERTS_ALL_URL';
export var PARAM_MOI_COMPANY_SCHEDULES_URL = 'PARAM_MOI_COMPANY_SCHEDULES_URL';
export var PARAM_MOI_COMPANY_DATA_URL = 'PARAM_MOI_COMPANY_DATA_URL';
export var PARAM_MOI_COMPANY_SAVE_DATA_URL = 'PARAM_MOI_COMPANY_SAVE_DATA_URL';
export var PARAM_MOI_COMPANY_BOOKINGS_URL = 'PARAM_MOI_COMPANY_BOOKINGS_URL';
export var PARAM_MOI_COMPANY_BOOKING_CREATE_URL = 'PARAM_MOI_COMPANY_BOOKING_CREATE_URL';
export var PARAM_MOI_COMPANY_BOOKING_CONFIRM_URL = 'PARAM_MOI_COMPANY_BOOKING_CONFIRM_URL';
export var PARAM_MOI_COMPANY_BOOKING_DECLINE_URL = 'PARAM_MOI_COMPANY_BOOKING_DECLINE_URL';
export var PARAM_MOI_COMPANY_BOOKING_EDIT_URL = 'PARAM_MOI_COMPANY_BOOKING_EDIT_URL';
export var PARAM_MOI_COMPANY_BOOKING_SUMMARY_URL = 'PARAM_MOI_COMPANY_BOOKING_SUMMARY_URL';
export var PARAM_MOI_CONSULTANT_REQUEST_URL = 'PARAM_MOI_CONSULTANT_REQUEST_URL';
export var PARAM_MOI_DECLINE_CLIENT_URL = 'PARAM_MOI_DECLINE_CLIENT_URL';
export var PARAM_MOI_CLIENTS_EMPTY_CARD_PHOTO_URL = 'PARAM_MOI_CLIENTS_EMPTY_CARD_PHOTO_URL';
export var PARAM_MOI_COMPANY_E_HEALTH_EMPTY_CARD_PHOTO_URL = 'PARAM_MOI_COMPANY_E_HEALTH_EMPTY_CARD_PHOTO_URL';
export var PARAM_MOI_CLIENTS_CONSULTANTS_LIST_URL = 'PARAM_MOI_CLIENTS_CONSULTANTS_LIST_URL';
export var PARAM_MOI_CLIENTS_ALL_LIST_URL = 'PARAM_MOI_CLIENTS_ALL_LIST_URL';
export var PARAM_MOI_CONSULTANT_REQUEST_LIST_URL = 'PARAM_MOI_CONSULTANT_REQUEST_LIST_URL';
export var PARAM_MOI_CLIENT_CONSULTANT_REQUEST_LIST_URL = 'PARAM_MOI_CLIENT_CONSULTANT_REQUEST_LIST_URL';
export var PARAM_MOI_CLIENT_CONSULTANT_REQUEST_CONFIRM_URL = 'PARAM_MOI_CLIENT_CONSULTANT_REQUEST_CONFIRM_URL';
export var PARAM_MOI_CLIENT_CONSULTANT_REQUEST_DECLINE_URL = 'PARAM_MOI_CLIENT_CONSULTANT_REQUEST_DECLINE_URL';
export var PARAM_MOI_COMPANY_CLIENT_LIST_URL = 'PARAM_MOI_COMPANY_CLIENT_LIST_URL';
export var PARAM_MOI_COMPANY_REGISTER_CLIENT_URL = 'PARAM_MOI_COMPANY_REGISTER_CLIENT_URL';
export var PARAM_MOI_COMPANY_DELETE_CLIENT_URL = 'PARAM_MOI_COMPANY_DELETE_CLIENT_URL';
export var PARAM_MOI_COMPANY_SERVICES_URL = 'PARAM_MOI_COMPANY_SERVICES_URL';
export var PARAM_MOI_CLIENT_TAGS_LIST_URL = 'PARAM_MOI_CLIENT_TAGS_LIST_URL';
export var PARAM_MOI_CLIENT_TAGS_CREATE_URL = 'PARAM_MOI_CLIENT_TAGS_CREATE_URL';
export var PARAM_MOI_CLIENT_TAGS_DELETE_URL = 'PARAM_MOI_CLIENT_TAGS_DELETE_URL';
export var PARAM_MOI_CLIENT_TAGS_EDIT_URL = 'PARAM_MOI_CLIENT_TAGS_EDIT_URL';
export var PARAM_MOI_CLIENT_TAGS_ATTACH_URL = 'PARAM_MOI_CLIENT_TAGS_ATTACH_URL';
export var PARAM_MOI_CLIENT_TAGS_DETACH_URL = 'PARAM_MOI_CLIENT_TAGS_DETACH_URL';
export var PARAM_MOI_CLIENT_TAGS_MASS_APPLY_URL = 'PARAM_MOI_CLIENT_TAGS_MASS_APPLY_URL';
export var PARAM_MOI_COMPANY_EXPERTS_LIST_URL = 'PARAM_MOI_COMPANY_EXPERTS_LIST_URL';
export var PARAM_MOI_COMPANY_EXPERT_REQUEST_URL = 'PARAM_MOI_COMPANY_EXPERT_REQUEST_URL';
export var PARAM_MOI_COMPANY_EXPERT_REQUEST_DECLINE_URL = 'PARAM_MOI_COMPANY_EXPERT_REQUEST_DECLINE_URL';
export var PARAM_MOI_COMPANY_EXPERT_REQUEST_ACCEPT_URL = 'PARAM_MOI_COMPANY_EXPERT_REQUEST_ACCEPT_URL';
export var PARAM_MOI_COMPANY_REGISTER_EXPERT_URL = 'PARAM_MOI_COMPANY_REGISTER_EXPERT_URL';
export var PARAM_MOI_COMPANY_MAKE_REPORT_URL = 'PARAM_MOI_COMPANY_MAKE_REPORT_URL';
export var PARAM_MOI_COMPANY_EXPERT_SERVICES_URL = 'PARAM_MOI_COMPANY_EXPERT_SERVICES_URL';
export var PARAM_MOI_COMPANY_EXPERT_SCHEDULE_URL = 'PARAM_MOI_COMPANY_EXPERT_SCHEDULE_URL';
export var PARAM_MOI_COMPANY_EXPERT_SCHEDULE_LIST_URL = 'PARAM_MOI_COMPANY_EXPERT_SCHEDULE_LIST_URL';
export var PARAM_MOI_COMPANY_EXPERT_SCHEDULE_CREATE_URL = 'PARAM_MOI_COMPANY_EXPERT_SCHEDULE_CREATE_URL';
export var PARAM_MOI_COMPANY_EXPERT_SCHEDULE_EDIT_URL = 'PARAM_MOI_COMPANY_EXPERT_SCHEDULE_EDIT_URL';
export var PARAM_MOI_COMPANY_EXPERT_SCHEDULE_DELETE_URL = 'PARAM_MOI_COMPANY_EXPERT_SCHEDULE_DELETE_URL';
export var PARAM_MOI_COMPANY_SERVICES_LIST_URL = 'PARAM_MOI_COMPANY_EXPERT_SERVICES_LIST_URL';
export var PARAM_MOI_COMPANY_SERVICES_TEMPLATES_LIST_URL = 'PARAM_MOI_COMPANY_SERVICES_TEMPLATES_LIST_URL';
export var PARAM_MOI_COMPANY_SERVICES_TEMPLATES_LIST_BY_USER_URL = 'PARAM_MOI_COMPANY_SERVICES_TEMPLATES_LIST_BY_USER_URL';
export var PARAM_MOI_COMPANY_SERVICES_CREATE_URL = 'PARAM_MOI_COMPANY_EXPERT_SERVICES_CREATE_URL';
export var PARAM_MOI_COMPANY_SERVICES_EDIT_URL = 'PARAM_MOI_COMPANY_EXPERT_SERVICES_EDIT_URL';
export var PARAM_MOI_COMPANY_SERVICES_ASSIGN_URL = 'PARAM_MOI_COMPANY_EXPERT_SERVICES_ASSIGN_URL';
export var PARAM_MOI_COMPANY_SERVICES_UNASSIGN_URL = 'PARAM_MOI_COMPANY_EXPERT_SERVICES_UNASSIGN_URL';
export var PARAM_MOI_COMPANY_SERVICES_CATEGORIES_LIST_URL = 'PARAM_MOI_COMPANY_SERVICES_CATEGORIES_LIST_URL';
export var PARAM_MOI_COMPANY_GET_SERVICE_BY_ID_URL = 'PARAM_MOI_COMPANY_GET_SERVICE_BY_ID_URL';
export var PARAM_MOI_COMPANY_SERVICE_TO_ARCHIVE_URL = 'PARAM_MOI_COMPANY_SERVICE_TO_ARCHIVE_URL';
export var PARAM_MOI_EXPERTS_SCHEDULE_LIST_URL = 'PARAM_MOI_EXPERTS_SCHEDULE_LIST_URL';
export var PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_LIST_URL = 'PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_LIST_URL';
export var PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_LIST_ALL_URL = 'PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_LIST_ALL_URL';
export var PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_REMOVE_URL = 'PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_REMOVE_URL';
export var PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_EDIT_URL = 'PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_EDIT_URL';
export var PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_CREATE_URL = 'PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_CREATE_URL';
export var PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_LIST_URL = 'PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_LIST_URL';
export var PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_REMOVE_URL = 'PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_REMOVE_URL';
export var PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_CREATE_URL = 'PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_CREATE_URL';
export var PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_EDIT_URL = 'PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_EDIT_URL';
export var PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_CHANGE_STATUS_URL = 'PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_CHANGE_STATUS_URL';
export var PARAM_MOI_CLIENT_DISEASE_LIST_URL = 'PARAM_MOI_CLIENT_DISEASE_LIST_URL';
export var PARAM_MOI_CLIENT_DISEASE_ATTACH_URL = 'PARAM_MOI_CLIENT_DISEASE_ATTACH_URL';
export var PARAM_MOI_CLIENT_DISEASE_DETACH_URL = 'PARAM_MOI_CLIENT_DISEASE_DETACH_URL';
export var PARAM_MOI_CHAT_MESSAGE_TEMPLATE_SEND_LIST_URL = 'PARAM_MOI_CHAT_MESSAGE_TEMPLATE_SEND_LIST_URL';
export var PARAM_MOI_CLIENTS_MEDICAL_INFORMATION_URL = 'PARAM_MOI_CLIENTS_MEDICAL_INFORMATION_URL';
export var PARAM_MOI_GET_REFERENCES_URL = 'PARAM_MOI_GET_REFERENCES_URL';
export var PARAM_MOI_REFERENCE_DELETE_URL = 'PARAM_MOI_REFERENCE_DELETE_URL';
export var PARAM_MOI_GET_USER_DATA_FOR_REFERENCE_URL = 'PARAM_MOI_GET_USER_DATA_FOR_REFERENCE_URL';
export var PARAM_MOI_REFERENCE_CREATE_URL = 'PARAM_MOI_REFERENCE_CREATE_URL';
export var PARAM_MOI_COMPANY_LEGAL_ENTITY_SAVE_URL = 'PARAM_MOI_COMPANY_LEGAL_ENTITY_SAVE_URL';
export var PARAM_MOI_COMPANY_LEGAL_ENTITY_GET_URL = 'PARAM_MOI_COMPANY_LEGAL_ENTITY_GET_URL';
export var PARAM_MOI_PICTURE_MENU_ARCHIVE = 'PARAM_MOI_PICTURE_MENU_ARCHIVE';
export var PARAM_MOI_COMPANY_REGISTER_URL = 'PARAM_MOI_COMPANY_REGISTER_URL';
// Expert bookings - booked services
export var PARAM_MOI_EXPERT_BOOKINGS_GET_BOOKED_SERVICES_URL = 'PARAM_MOI_EXPERT_BOOKINGS_GET_BOOKED_SERVICES_URL';
export var PARAM_MOI_EXPERT_BOOKINGS_CREATE_BOOKED_SERVICE_URL = 'PARAM_MOI_EXPERT_BOOKINGS_CREATE_BOOKED_SERVICE_URL';
// Expert bookings - services
export var PARAM_MOI_EXPERT_BOOKINGS_GET_ALL_SERVICES_URL = 'PARAM_MOI_EXPERT_BOOKINGS_GET_ALL_SERVICES_URL';
export var PARAM_MOI_EXPERT_BOOKINGS_CREATE_UPDATE_SERVICE_URL = 'PARAM_MOI_EXPERT_BOOKINGS_CREATE_UPDATE_SERVICE_URL';
export var PARAM_MOI_EXPERT_BOOKINGS_DELETE_SERVICE_URL = 'PARAM_MOI_EXPERT_BOOKINGS_DELETE_SERVICE_URL';
export var PARAM_MOI_EXPERT_BOOKINGS_GET_FREE_HOURS_URL = 'PARAM_MOI_EXPERT_BOOKINGS_GET_FREE_HOURS_URL';
