var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { clearObject } from '../../../../../../../assets/js/utils/etc';
import { ResponseError } from '../../../../../shared/error/response-error';
import cloneDeep from 'lodash/cloneDeep';
var LegalEntity = /** @class */ (function () {
    function LegalEntity(configure) {
        this.signService = configure.signService;
        this.saveLegalEntityDataUrl = configure.saveLegalEntityDataUrl;
        this.getLegalEntityDataUrl = configure.getLegalEntityDataUrl;
    }
    LegalEntity.prototype.getLegalEntityData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(this.getLegalEntityDataUrl, {
                            credentials: 'include',
                        })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LegalEntity.prototype.saveLegalEntityData = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.prepareSignLegalEntityData(data)];
                    case 1:
                        data = _a.sent();
                        return [4 /*yield*/, fetch("".concat(this.saveLegalEntityDataUrl), {
                                method: 'POST',
                                credentials: 'include',
                                headers: {
                                    'Content-Type': 'application/json; charset=utf-8',
                                },
                                body: JSON.stringify(data),
                            })];
                    case 2:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    LegalEntity.prototype.prepareSignLegalEntityData = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var clonedData, signParams, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        data.phones = data.phones.filter(function (item) { return item.number != ''; });
                        data.owner.phones = data.owner.phones.filter(function (item) { return item.number != ''; });
                        clonedData = cloneDeep(data);
                        signParams = clonedData.sign_params;
                        return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _b.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        // @ts-ignore
                        delete clonedData.sign_params;
                        // @ts-ignore
                        delete clonedData.processing_status;
                        // @ts-ignore
                        delete clonedData.status;
                        // @ts-ignore
                        delete data.signed_data;
                        delete clonedData.signed_data;
                        delete data.edr_response;
                        delete clonedData.edr_response;
                        delete data.error_response;
                        delete clonedData.error_response;
                        delete data.processing_status;
                        delete clonedData.processing_status;
                        delete data.status;
                        delete clonedData.status;
                        _a = data;
                        return [4 /*yield*/, this.signService.sign(JSON.stringify(clearObject(clonedData)), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        _a.signed_data = _b.sent();
                        return [2 /*return*/, data];
                }
            });
        });
    };
    return LegalEntity;
}());
export { LegalEntity };
