export var PARAM_ITEMS_PER_PAGE = 'PARAM_ITEMS_PER_PAGE';
export var PARAM_ITEMS_IN_AUTOCOMPLETE = 'PARAM_ITEMS_IN_AUTOCOMPLETE';
export var PARAM_WORKER_SRC = 'PARAM_WORKER_SRC';
export var PARAM_CITEMS_PER_PAGE = 'PARAM_CITEMS_PER_PAGE';
export var PARAM_CITEMS_IN_AUTOCOMPLETE = 'PARAM_CITEMS_IN_AUTOCOMPLETE';
export var PARAM_MQ_NAME = 'PARAM_MQ_NAME';
export var PARAM_MQ_VERSION = 'PARAM_MQ_VERSION';
export var PARAM_NAV_CONFIG = 'PARAM_NAV_CONFIG';
export var PARAM_APP_ORIGIN = 'PARAM_APP_ORIGIN';
export var PARAM_APP_ROOT_PATH = 'PARAM_APP_ROOT_PATH';
export var PARAM_SPECIALIST_MODE = 'PARAM_SPECIALIST_MODE';
export var PARAM_APP_IMAGE_DEFAULT_PATH = 'PARAM_APP_IMAGE_DEFAULT_PATH';
export var PARAM_APP_IMAGE_ALLOWED_FORMATS = 'PARAM_APP_IMAGE_ALLOWED_FORMATS';
export var PARAM_APP_IMAGE_MAXIMUM_SIZE = 'PARAM_APP_IMAGE_MAXIMUM_SIZE';
export var PARAM_LOCALIZE_LOCALE = 'PARAM_LOCALIZE_LOCALE';
export var PARAM_LOCALIZE_DATE_PREVIEW_FORMAT = 'PARAM_LOCALIZE_DATE_PREVIEW_FORMAT';
export var PARAM_LOCALIZE_DATE_VALUE_FORMAT = 'PARAM_LOCALIZE_DATE_VALUE_FORMAT';
export var PARAM_LOCALIZE_TIME_FORMAT = 'PARAM_LOCALIZE_TIME_FORMAT';
export var PARAM_COMPANY_CREATE_PAGE = 'PARAM_COMPANY_CREATE_PAGE';
export var PARAM_USER_ROLES = 'PARAM_USER_ROLES';
export var PARAM_USER_ID = 'PARAM_USER_ID';
export var E_HEALTH_REDIRECT_URL = 'E_HEALTH_REDIRECT_URL';
