export var initialState = {
    expertBookings: [],
    isBookingCreated: false,
    isBookingDeleted: false,
    isBookingEdited: false,
    isBookingConfirmed: false,
    total_count: 0,
    loading: false,
    error: null,
};
