import { combineReducers } from 'redux';
import { personalReducer } from './personalInfo';
import { medicalReducer } from './medicalInfo';
import { addressReducer } from './addressInfo';
import { workReducer } from './workInfo';
import { additionalReducer } from './additionalInfo';
export var userReducer = combineReducers({
    personal: personalReducer,
    medical: medicalReducer,
    address: addressReducer,
    work: workReducer,
    additional: additionalReducer,
});
