export var ExpertReviewsInitialState = {
    id: 0,
    reviewTitle: null,
    reviewComment: null,
    reviewDate: null,
    userFullName: null,
    clientFullName: null,
    avatar: null,
    userShortName: null,
};
export var initialState = {
    expertReviews: [ExpertReviewsInitialState],
    isLoading: false,
    error: null,
};
