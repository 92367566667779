export var EhealthJobType;
(function (EhealthJobType) {
    EhealthJobType["CREATE"] = "create";
    EhealthJobType["CLOSE"] = "close";
    EhealthJobType["CANCEL"] = "cancel";
    EhealthJobType["RECALL"] = "recall";
    EhealthJobType["ERLN"] = "erln";
    EhealthJobType["SIGN"] = "sign";
    EhealthJobType["RELEASE"] = "release";
})(EhealthJobType || (EhealthJobType = {}));
