export var defaultState = {
    items: new Map(),
    totalCount: 0,
    page: 1,
    totalPages: 0,
    isLoading: false,
    filter: {},
    editable: undefined,
    canSendMailings: false,
    lastMailing: undefined,
    nextAvailableDate: undefined,
};
