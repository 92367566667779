import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './NotificationMenuStyle';
export var CountMessage = function (_a) {
    var count = _a.count;
    var classes = useStyles();
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: classes.modalCountMessageCircle },
            React.createElement(Typography, { className: classes.modalCountMessageText }, count))));
};
