export var EhealthJobEntity;
(function (EhealthJobEntity) {
    EhealthJobEntity["EPISODES"] = "episodes";
    EhealthJobEntity["ENCOUNTER"] = "encounter";
    EhealthJobEntity["CONDITIONS"] = "conditions";
    EhealthJobEntity["OBSERVATIONS"] = "observations";
    EhealthJobEntity["ALLERGY_INTOLERANCES"] = "allergy_intolerances";
    EhealthJobEntity["IMMUNIZATIONS"] = "immunizations";
    EhealthJobEntity["RISK_ASSESSMENTS"] = "risk_assessments";
    EhealthJobEntity["DEVICES"] = "devices";
    EhealthJobEntity["MEDICATION_STATEMENTS"] = "medication_statements";
    EhealthJobEntity["MEDICATION_ADMINISTRATIONS"] = "medication_administrations";
    EhealthJobEntity["DIAGNOSTIC_REPORTS"] = "diagnostic_reports";
    EhealthJobEntity["PROCEDURES"] = "procedures";
    EhealthJobEntity["APPROVALS"] = "approvals";
    EhealthJobEntity["SERVICE_REQUEST"] = "serviceRequest";
    EhealthJobEntity["COMPOSITION"] = "composition";
    EhealthJobEntity["CARE_PLANS"] = "care_plans";
    EhealthJobEntity["CARE_PLAN_ACTIVITY"] = "carePlanActivity";
    EhealthJobEntity["DEVICE_REQUEST"] = "device_request";
})(EhealthJobEntity || (EhealthJobEntity = {}));
