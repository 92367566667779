import { ClinicScheduleActionType } from './action';
export var fetchGetClinicScheduleBegin = function () { return ({
    type: ClinicScheduleActionType.GET_SHEDULES_PENDING,
}); };
export var fetchGetClinicScheduleSuccess = function (shedule) { return ({
    type: ClinicScheduleActionType.GET_SHEDULES_SUCCESS,
    payload: shedule,
}); };
export var fetchGetClinicScheduleFailure = function (error) { return ({
    type: ClinicScheduleActionType.GET_SHEDULES_ERROR,
    payload: { error: error },
}); };
//export const fetchGetAnalysesBegin = () => ({
//  type: ClinicScheduleActionType.GET_ANALYSES_PENDING,
//});
//export const fetchGetAnalysesSuccess = (analyses: IAnalyse[]) => ({
//  type: ClinicScheduleActionType.GET_ANALYSES_SUCCESS,
//  payload: analyses,
//});
//export const fetchGetAnalysesFailure = (error: any) => ({
//  type: ClinicScheduleActionType.GET_ANALYSES_ERROR,
//  payload: { error },
//});
//export const fetchGetClientsBegin = () => ({
//  type: ClinicScheduleActionType.GET_CLIENTS_PENDING,
//});
//export const fetchGetClientsSuccess = (clients: IPatient[]) => ({
//  type: ClinicScheduleActionType.GET_CLIENTS_SUCCESS,
//  payload: clients,
//});
//export const fetchGetClientsFailure = (error: any) => ({
//  type: ClinicScheduleActionType.GET_CLIENTS_ERROR,
//  payload: { error },
//});
//export const fetchAddDoctorReferralBegin = () => ({
//  type: ClinicScheduleActionType.ADD_REFERRAL_PENDING,
//});
//export const fetchAddDoctorReferralSuccess = (addedReferral: addedReferralType) => ({
//  type: ClinicScheduleActionType.ADD_REFERRAL_SUCCESS,
//  payload: addedReferral,
//});
//export const fetchAddDoctorReferralFailure = (error: any) => ({
//  type: ClinicScheduleActionType.ADD_REFERRAL_ERROR,
//  payload: { error },
//});
export var setDoctorReferralDataChangedFalse = function () { return ({
    type: ClinicScheduleActionType.SET_DATA_FALSE,
}); };
