import { createStyles, makeStyles } from '@mui/styles';
export var useStyles = makeStyles(function (theme) {
    return createStyles({
        customTypography: {
            fontFamily: 'GraphikLCG',
            color: '#1E1E1E',
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '1px',
            letterSpacing: '-0.03em',
        },
        customTypographyNow: {
            fontFamily: 'GraphikLCG',
            color: '#90A4BE',
            lineHeight: '1px',
            fontWeight: '500',
            fontSize: '12px',
            letterSpacing: '-0.03em',
        },
    });
});
