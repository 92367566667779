import React from 'react';
import classNames from 'classnames';
import { useStyles } from './Icon.jss';
import { Size } from '../../../enum/size';
export var Icon = function (props) {
    var _a, _b;
    var icon = props.icon, _c = props.outlined, outlined = _c === void 0 ? false : _c, _d = props.className, className = _d === void 0 ? '' : _d, _e = props.size, size = _e === void 0 ? Size.MEDIUM : _e, onClick = props.onClick;
    var _f = useStyles(), materialIcons = _f.materialIcons, pointer = _f.pointer;
    var material = "material-icons".concat(outlined ? '-outlined ' : '');
    return (React.createElement("i", { onClick: onClick, className: classNames((_a = {}, _a[materialIcons] = !props.materialIcons, _a), size, material, className, (_b = {}, _b[pointer] = props.pointer, _b)) }, icon));
};
