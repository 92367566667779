export var EhealthRoutes;
(function (EhealthRoutes) {
    EhealthRoutes["ROOT"] = "root";
    EhealthRoutes["LOGIN"] = "login";
    EhealthRoutes["LOGOUT"] = "logout";
    EhealthRoutes["PROTOTYPE"] = "prototype";
    // legal entity info
    EhealthRoutes["LEGAL_ENTITY_SHOW"] = "legalEntityShow";
    // divisions
    EhealthRoutes["DIVISION_LIST"] = "divisionList";
    EhealthRoutes["DIVISION_CREATE"] = "divisionCreate";
    EhealthRoutes["DIVISION_EDIT"] = "divisionEdit";
    // emloyees
    EhealthRoutes["EMPLOYEE_LIST"] = "employeeList";
    EhealthRoutes["EMPLOYEE_REQUEST_LIST"] = "employeeRequestList";
    EhealthRoutes["EMPLOYEE_CREATE_REQUEST"] = "employeeCreateRequest";
    EhealthRoutes["EMPLOYEE_UPDATE_REQUEST"] = "employeeUpdateRequest";
    EhealthRoutes["EMPLOYEE_SHOW_REQUEST"] = "employeeShowRequest";
    // capitation contract requests
    EhealthRoutes["CAPITATION_CONTRACT_REQUEST_LIST"] = "capitationContractRequestList";
    EhealthRoutes["CAPITATION_CONTRACT_REQUEST_CREATE"] = "capitationContractRequestCreate";
    // capitation contracts
    EhealthRoutes["CAPITATION_CONTRACT_LIST"] = "capitationContractList";
    EhealthRoutes["CAPITATION_CONTRACT_SHOW"] = "capitationContractShow";
    // declaration requests
    EhealthRoutes["DECLARATION_REQUEST_LIST"] = "declarationRequestList";
    EhealthRoutes["DECLARATION_REQUEST_CREATE"] = "declarationRequestCreate";
    // declarations
    EhealthRoutes["DECLARATION_LIST"] = "declarationList";
    // healthcare services
    EhealthRoutes["HEALTHCARE_SERVICE_LIST"] = "healthcareServiceList";
    EhealthRoutes["HEALTHCARE_SERVICE_EDIT"] = "healthcareServiceEdit";
    EhealthRoutes["HEALTHCARE_SERVICE_CREATE"] = "healthcareServiceCreate";
    // person requests
    EhealthRoutes["PERSON_REQUEST_LIST"] = "personRequestList";
    EhealthRoutes["PERSON_REQUEST_EDIT"] = "personRequestEdit";
    EhealthRoutes["PERSON_REQUEST_CREATE"] = "personRequestCreate";
    // persons
    EhealthRoutes["PERSON_LIST"] = "personList";
    // preperson
    EhealthRoutes["PREPERSON_LIST"] = "prepersonList";
    EhealthRoutes["PREPERSON_CREATE"] = "prepersonCreate";
    // episodes
    EhealthRoutes["EPISODE_LIST_SEARCH"] = "episodeListSearch";
    EhealthRoutes["EPISODE_LIST_APPROVED"] = "episodeListApproved";
    EhealthRoutes["EPISODE_CREATE"] = "episodeCreate";
    EhealthRoutes["EPISODE_EDIT"] = "episodeEdit";
    // encounter
    EhealthRoutes["ENCOUNTER_LIST"] = "encounterList";
    EhealthRoutes["ENCOUNTER_CREATE"] = "encounterCreate";
    EhealthRoutes["ENCOUNTER_CANCEL"] = "encounterCancel";
    // encounter package
    EhealthRoutes["CONDITION_LIST"] = "conditionList";
    EhealthRoutes["OBSERVATION_LIST"] = "observationList";
    EhealthRoutes["ALLERGY_INTOLERANCE_LIST"] = "allergyIntoleranceList";
    EhealthRoutes["IMMUNIZATION_LIST"] = "immunizationList";
    EhealthRoutes["RISK_ASSESSMENT_LIST"] = "riskAssessmentList";
    EhealthRoutes["DEVICE_LIST"] = "deviceList";
    EhealthRoutes["MEDICATION_STATEMENT_LIST"] = "medicationStatementList";
    EhealthRoutes["DIAGNOSTIC_REPORT_LIST"] = "diagnosticReportList";
    EhealthRoutes["DIAGNOSTIC_REPORT_CREATE"] = "diagnosticReportCreate";
    EhealthRoutes["DIAGNOSTIC_REPORT_APPROVED"] = "diagnosticReportApproved";
    EhealthRoutes["PROCEDURE_LIST"] = "procedureList";
    EhealthRoutes["PROCEDURE_CREATE"] = "procedureCreate";
    EhealthRoutes["MEDICATION_ADMINISTRATION_LIST"] = "medicationAdministrationList";
    // approval
    EhealthRoutes["APPROVAL_LIST"] = "approvalList";
    EhealthRoutes["APPROVAL_CREATE"] = "approvalCreate";
    // medications
    EhealthRoutes["MEDICATION_REQUEST_REQUEST_LIST"] = "medicationRequestRequestList";
    EhealthRoutes["MEDICATION_REQUEST_REQUEST_CREATE"] = "medicationRequestRequestCreate";
    EhealthRoutes["MEDICATION_REQUEST_REQUEST_EDIT"] = "medicationRequestRequestEdit";
    EhealthRoutes["MEDICATION_REQUEST_EXPIRED_LIST"] = "medicationRequestExpiredList";
    // device request
    EhealthRoutes["DEVICE_REQUEST_LIST"] = "deviceRequestList";
    EhealthRoutes["DEVICE_REQUEST_CREATE"] = "deviceRequestCreate";
    // service request
    EhealthRoutes["SERVICE_REQUEST_LIST"] = "serviceRequestList";
    EhealthRoutes["SERVICE_REQUEST_CREATE"] = "serviceRequestCreate";
    EhealthRoutes["SERVICE_REQUEST_EDIT"] = "serviceRequestEdit";
    //patient summary
    EhealthRoutes["PATIENT_SUMMARY"] = "patientSummary";
    EhealthRoutes["SUMMURY_EPISODES_LIST"] = "summaryEpisodeList";
    EhealthRoutes["SUMMARY_IMMUNIZATION_LIST"] = "summaryImmunizationList";
    EhealthRoutes["SUMMARY_ALLERGY_INTOLERANCE_LIST"] = "summaryAllergyIntoleranceList";
    EhealthRoutes["SUMMARY_OBSERVATION_LIST"] = "summaryObservationList";
    EhealthRoutes["SUMMARY_CONDITION_LIST"] = "summaryConditionList";
    EhealthRoutes["SUMMARY_ACTIVE_DIAGNOSES_LIST"] = "summaryActiveDiagnosesList";
    EhealthRoutes["SUMMARY_RISK_ASSESMENT_LIST"] = "summaryRiskAssesmentList";
    EhealthRoutes["SUMMARY_DEVICE_LIST"] = "summaryDeviceList";
    EhealthRoutes["SUMMARY_MEDICATION_STATEMENT_LIST"] = "summaryMedicationStatementsList";
    EhealthRoutes["SUMMARY_DIAGNOSTIC_REPORT_LIST"] = "summaryDiagnosticReportList";
    EhealthRoutes["SUMMARY_PROCEDURE_LIST"] = "summaryProcedureList";
    // composition
    EhealthRoutes["COMPOSITION_LIST"] = "compositionList";
    EhealthRoutes["COMPOSITION_CREATE"] = "compositionCreate";
    EhealthRoutes["COMPOSITION_EDIT"] = "compositionEdit";
    // care plan
    EhealthRoutes["CARE_PLAN_LIST"] = "carePlanList";
    EhealthRoutes["CARE_PLAN_CREATE"] = "carePlanCreate";
    // care plan activity
    EhealthRoutes["CARE_PLAN_ACTIVITY_LIST"] = "carePlanActivityList";
    EhealthRoutes["CARE_PLAN_ACTIVITY_CREATE"] = "carePlanActivityCreate";
    // healthcare services
    EhealthRoutes["LICENSE_LIST"] = "licenseList";
    EhealthRoutes["LICENSE_EDIT"] = "licenseEdit";
    EhealthRoutes["LICENSE_CREATE"] = "licenseCreate";
    EhealthRoutes["REORGANIZE_MIS_LIST"] = "reorganizeMISList";
    EhealthRoutes["REORGANIZE_ACTIVE_EMPLOYEE_DECLARATION_LIST"] = "reorganizeActiveEmployeeDeclarationList";
})(EhealthRoutes || (EhealthRoutes = {}));
