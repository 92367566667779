export var ClinicBookingsActionType;
(function (ClinicBookingsActionType) {
    ClinicBookingsActionType["GET_CLINIC_BOOKINGS_PENDING"] = "GET_CLINIC_BOOKINGS_PENDING";
    ClinicBookingsActionType["GET_CLINIC_BOOKINGS_SUCCESS"] = "GET_CLINIC_BOOKINGS_SUCCESS";
    ClinicBookingsActionType["GET_CLINIC_BOOKINGS_ERROR"] = "GET_CLINIC_BOOKINGS_ERROR";
    ClinicBookingsActionType["GET_CLINIC_BOOKINGS_COMPLETED_PENDING"] = "GET_CLINIC_BOOKINGS_COMPLETED_PENDING";
    ClinicBookingsActionType["GET_CLINIC_BOOKINGS_COMPLETED_SUCCESS"] = "GET_CLINIC_BOOKINGS_COMPLETED_SUCCESS";
    ClinicBookingsActionType["GET_CLINIC_BOOKINGS_COMPLETED_ERROR"] = "GET_CLINIC_BOOKINGS_COMPLETED_ERROR";
    ClinicBookingsActionType["CHANGE_CLINIC_BOOKING_PENDING"] = "CHANGE_CLINIC_BOOKING_PENDING";
    ClinicBookingsActionType["CHANGE_CLINIC_BOOKING_SUCCESS"] = "CHANGE_CLINIC_BOOKING_SUCCESS";
    ClinicBookingsActionType["CHANGE_CLINIC_BOOKING_ERROR"] = "CHANGE_CLINIC_BOOKING_ERROR";
    ClinicBookingsActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(ClinicBookingsActionType || (ClinicBookingsActionType = {}));
