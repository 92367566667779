var NavConfigurator = /** @class */ (function () {
    function NavConfigurator(config) {
        this.config = config;
    }
    NavConfigurator.prototype.getNavigation = function (key) {
        return this.config[key];
    };
    NavConfigurator.prototype.getAllNavigations = function () {
        return this.config;
    };
    return NavConfigurator;
}());
export { NavConfigurator };
