/// <reference path='./app/moi/app.d.ts'/>
import React, { lazy, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { LazyNotFoundPage } from './app/moi/screen/NotFound/lazy';
import { registerMoiParams } from './app/moi/config/di/params';
import { registerMoiServices } from './app/moi/config/di/services';
import { useContainer } from './shared/service/di-container/react/useContainer';
import { useCurrentLocaleTranslation } from './shared/hook/locale/useCurrentLocaleTranslation';
import { registerEhealthParams } from './app/ehealth/config/di/params';
import { registerEhealthServices } from './app/ehealth/config/di/services';
// apps
var EhealthApp = lazy(function () { return import(/* webpackChunkName: "app-ehealth" */ './app/ehealth/App'); });
var MoiApp = lazy(function () { return import(/* webpackChunkName: "app-moi" */ './app/moi/App'); });
var BaseApp = lazy(function () { return import(/* webpackChunkName: "app-base" */ './app/moi/AppBase'); });
var HomeApp = lazy(function () { return import(/* webpackChunkName: "app-home" */ './app/moi/Home'); });
export var RoutesSwitch = function () {
    useCurrentLocaleTranslation({});
    var container = useContainer();
    var dispatch = useDispatch();
    var t = useTranslation().t;
    useLayoutEffect(function () {
        // Register Moi params
        registerMoiParams(container);
        registerMoiServices(container);
        // Register E-Health params
        registerEhealthParams(container);
        registerEhealthServices(container);
        //const nav = container.get(PARAM_NAV_CONFIG);
        //batch(() => {
        //  // @ts-ignore
        //  dispatch(createSetMenuAction(NavPlace.DESKTOP, nav[NavPlace.DESKTOP]));
        //  // @ts-ignore
        //  dispatch(createSetMenuAction(NavPlace.MOBILE, nav[NavPlace.MOBILE]));
        //});
    }, []);
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/", component: HomeApp }),
        React.createElement(Route, { path: "/?showModal=login", component: HomeApp }),
        React.createElement(Route, { path: "/?showModal=register", component: HomeApp }),
        React.createElement(Route, { path: "/:locale/cabinet", component: MoiApp }),
        React.createElement(Route, { path: "/:locale", component: BaseApp }),
        React.createElement(Route, { path: "/:locale/e-health/app", component: EhealthApp }),
        React.createElement(Route, { path: "*", component: LazyNotFoundPage })));
};
