var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PatientsTemplateAction } from './enum/action';
import { defaultState } from './state';
export function patientPageReducer(state, action) {
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case PatientsTemplateAction.REMOVE:
            delete state.items[action.payload.id];
            return __assign(__assign({}, state), { items: __assign({}, state.items) });
        case PatientsTemplateAction.ADD_ITEMS:
            return __assign(__assign({}, state), { items: __assign(__assign({}, state.items), action.payload.items), totalCount: action.payload.totalCount, page: action.payload.page, totalPages: action.payload.totalPages });
        case PatientsTemplateAction.SET_IS_LOADING:
            return __assign(__assign({}, state), { isLoading: action.payload.isLoading });
        case PatientsTemplateAction.SET_FILTER:
            return __assign(__assign({}, state), { items: {}, page: 1, totalCount: 0, totalPages: 0, isLoading: true, filter: action.payload.filter });
        case PatientsTemplateAction.ATTACH_TAG:
            return __assign(__assign({}, state), { items: __assign(__assign({}, state.items), action.payload.items) });
        case PatientsTemplateAction.DETACH_TAG:
            return __assign(__assign({}, state), { items: __assign({}, state.items) });
        case PatientsTemplateAction.UPDATE_TAG:
            var items = __assign({}, state.items);
            for (var key in items) {
                var item = items[key];
                var index = item.tags.findIndex(function (tag) { return tag.id === action.payload.tag.id; });
                if (index !== -1) {
                    item.tags[index] = action.payload.tag;
                }
            }
            return __assign(__assign({}, state), { items: __assign({}, items) });
    }
    return state;
}
