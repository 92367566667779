import { Resolution } from "./resolution.enum";
var Device = /** @class */ (function () {
    function Device() {
    }
    Device.prototype.getResolution = function () {
        return window.innerWidth;
    };
    Device.prototype.checkExactResolution = function (res) {
        var width = this.getResolution();
        switch (res) {
            case Resolution.DESKTOP:
                return width >= Resolution.DESKTOP;
            case Resolution.LAPTOP_M:
                return width >= Resolution.LAPTOP_M && width < Resolution.DESKTOP;
            case Resolution.LAPTOP:
                return width >= Resolution.LAPTOP && width < Resolution.LAPTOP_M;
            case Resolution.TABLET:
                return width >= Resolution.TABLET && width < Resolution.LAPTOP;
            case Resolution.MOBILE:
                return width >= Resolution.MOBILE && width < Resolution.TABLET;
            case Resolution.MOBILE_L:
                return width >= Resolution.MOBILE_L && width < Resolution.MOBILE;
            case Resolution.MOBILE_M:
                return width < Resolution.MOBILE_L;
        }
    };
    Device.prototype.checkResolutionTreashold = function (res) {
        var width = this.getResolution();
        return width >= res;
    };
    return Device;
}());
export { Device };
