import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useStyles } from './MessageMenuStyle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import RocketImage from '../assets/rocket.svg';
export var MessageMenu = function (_a) {
    var onCloseVisibleMessage = _a.onCloseVisibleMessage;
    var classes = useStyles();
    return (React.createElement(Box, { className: classes.MainWrapper },
        React.createElement(Stack, { className: classes.header },
            React.createElement("img", { src: RocketImage }),
            React.createElement(IconButton, { onClick: onCloseVisibleMessage, sx: {
                    padding: 0,
                } },
                React.createElement(CloseIcon, { sx: { color: '#A5BDDB' } }))),
        React.createElement(Typography, { variant: "tiny", fontWeight: "600", className: classes.TextMain }, "\u041F\u0440\u043E\u0433\u0440\u0430\u043C\u0430 \u0421\u043A\u0440\u0438\u043D\u0456\u043D\u0433\u0443 - \u0446\u0435 \u043F\u0435\u0440\u0432\u0438\u043D\u043D\u0430 \u043F\u0440\u043E\u0433\u0440\u0430\u043C\u0430 \u0434\u043B\u044F \u0432\u0441\u0442\u0430\u043D\u043E\u0432\u043B\u0435\u043D\u043D\u044F \u0440\u0435\u0430\u043B\u044C\u043D\u043E\u0433\u043E \u043F\u043E\u0442\u043E\u0447\u043D\u043E\u0433\u043E \u0441\u0442\u0430\u043D\u0443 \u0437\u0434\u043E\u0440\u043E\u0432\u02BC\u044F \u043B\u044E\u0434\u0438\u043D\u0438. \u0412\u0438\u043A\u043E\u043D\u0443\u0454\u0442\u044C\u0441\u044F \u043E\u0434\u043D\u043E\u0440\u0430\u0437\u043E\u0432\u043E \u043F\u0435\u0440\u0435\u0434 \u043F\u0435\u0440\u0435\u0445\u043E\u0434\u043E\u043C \u0434\u043E \u0432\u0438\u043A\u043E\u043D\u0430\u043D\u043D\u044F \u043F\u0440\u043E\u0433\u0440\u0430\u043C\u0438 \u043F\u043E\u0441\u0442\u0456\u0439\u043D\u043E\u0433\u043E \u043C\u043E\u043D\u0456\u0442\u043E\u0440\u0438\u043D\u0433\u0443 \u0441\u0442\u0430\u043D\u0443 \u0437\u0434\u043E\u0440\u043E\u0432\u02BC\u044F. \u0417\u0430 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u0430\u043C \u0432\u0438\u043A\u043E\u043D\u0430\u043D\u043D\u044F \u043F\u0440\u043E\u0433\u0440\u0430\u043C\u0438 \u0441\u043A\u0440\u0438\u0456\u043D\u0456\u043D\u0433\u0443 \u0431\u0443\u0434\u0435 \u0432\u0441\u0442\u0430\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u0439 \u0440\u0435\u0430\u043B\u044C\u043D\u0438\u0439 \u0441\u0442\u0430\u043D \u0412\u0430\u0448\u043E\u0433\u043E \u0437\u0434\u043E\u0440\u043E\u0432\u02BC\u044F"),
        React.createElement(Stack, { marginTop: "14px", flexDirection: "row", alignItems: "center", minHeight: "6px", gap: "6px" },
            React.createElement(Box, { width: "20px", className: classes.paginationIndicatorActive }),
            React.createElement(Box, { width: "6px", className: classes.paginationIndicator }),
            React.createElement(Box, { width: "6px", className: classes.paginationIndicator }))));
};
