import { createStyles, makeStyles } from '@mui/styles';
export var useStyles = makeStyles(function (theme) {
    return createStyles({
        NotificationWrapper: {
            border: '1px solid #E5EEFF',
            minHeight: '60px',
            borderRadius: '20px',
            padding: '10px',
            maxWidth: '100%',
            backgroundColor: '#FFFFFF',
        },
        TimeText: {
            color: '#90A4BE',
            fontFamily: 'GraphikLCG',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '12px',
            letterSpacing: '-0.03em',
        },
        ButtonLight: {
            color: '#173236',
            height: '28px',
            fontWeight: '500',
            fontSize: '12px',
            border: '1px solid #E5EEFF',
        },
        ButtonDark: {
            color: 'white',
            height: '28px',
            fontWeight: '500',
            backgroundColor: '#173236',
            fontSize: '12px',
            '&:hover': {
                backgroundColor: '#173236',
            },
        },
        FileIconWrapper: {
            border: '1px solid #E5EEFF',
            width: '36px',
            height: '36px',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '10px',
        },
        FileIconText: {
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'GraphikLCG',
            color: '#1E1E1E',
        },
        FileIconTextSize: {
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'GraphikLCG',
            color: '#E5EEFF',
        },
        WrapperComments: {
            height: '100%',
            borderRadius: '10px',
            padding: '10px',
            backgroundColor: '#F1F6FA',
            maxWidth: '836px',
        },
        TextComments: {
            color: '#173236',
            fontSize: '12px',
            fontFamily: 'GraphikLCG',
            fontWeight: '500',
            lineHeight: '14px',
            letterSpacing: '-0.03em',
        },
    });
});
