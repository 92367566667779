export var CabinetMenuModalActionTypes;
(function (CabinetMenuModalActionTypes) {
    CabinetMenuModalActionTypes[CabinetMenuModalActionTypes["ShowCabinetMenuModal"] = 0] = "ShowCabinetMenuModal";
    CabinetMenuModalActionTypes[CabinetMenuModalActionTypes["HideCabinetMenuModal"] = 1] = "HideCabinetMenuModal";
    CabinetMenuModalActionTypes[CabinetMenuModalActionTypes["ShowCreateChatModal"] = 2] = "ShowCreateChatModal";
    CabinetMenuModalActionTypes[CabinetMenuModalActionTypes["HideCreateChatModal"] = 3] = "HideCreateChatModal";
})(CabinetMenuModalActionTypes || (CabinetMenuModalActionTypes = {}));
export function showCabinetMenuModal() {
    return {
        type: CabinetMenuModalActionTypes.ShowCabinetMenuModal,
    };
}
export function hideCabinetMenuModal() {
    return {
        type: CabinetMenuModalActionTypes.HideCabinetMenuModal,
    };
}
export function showCreateChatModal() {
    return {
        type: CabinetMenuModalActionTypes.ShowCreateChatModal,
    };
}
export function hideCreateChatModal() {
    return {
        type: CabinetMenuModalActionTypes.HideCreateChatModal,
    };
}
