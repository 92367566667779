var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useStyles } from './NotificationMenuStyle';
import { List, Stack, Typography } from '@mui/material';
import { CountMessage } from './CountMessage';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { AntSwitchGreen } from '../../../../../app/moi/shared/AntSwitchGreen';
import { Notification } from '../../../../../app/moi/screen/Notifications/components/Notification';
import { useSelector } from 'react-redux';
import { NotificationsDataSelector } from '../../../../../app/moi/store/notifications/selector';
export var NotificationMenu = function (props) {
    var open = props.open, setOpen = props.setOpen;
    var classes = useStyles();
    var handleClose = function () { return setOpen(false); };
    var notificationsList = useSelector(NotificationsDataSelector);
    var _a = __read(useState(false), 2), hideViewed = _a[0], setHideViewed = _a[1];
    var _b = __read(useState([]), 2), filteredNotificationsList = _b[0], setFilteredNotificationsList = _b[1];
    useEffect(function () {
        setFilteredNotificationsList(notificationsList.filter(function (item) { return item.seen === !hideViewed; }));
    }, [hideViewed, notificationsList]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { open: open, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", onClose: handleClose },
            React.createElement(Box, { className: classes.modalContainer },
                React.createElement(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", spacing: 2, sx: { padding: '15px 20px 0px 20px' } },
                    React.createElement(Stack, { direction: "row", justifyContent: "center", alignItems: "center", spacing: 1 },
                        React.createElement(Typography, { className: classes.modalTextHeader }, "\u0421\u043F\u043E\u0432\u0456\u0449\u0435\u043D\u043D\u044F "),
                        React.createElement(CountMessage, { count: filteredNotificationsList.length.toString() })),
                    React.createElement(Stack, { direction: "row", justifyContent: "center", alignItems: "center", spacing: 1 },
                        React.createElement(Typography, { className: classes.modalTextNotRead }, "\u041F\u043E\u043A\u0430\u0437\u0443\u0432\u0430\u0442\u0438 \u043B\u0438\u0448\u0435 \u043D\u0435\u043F\u0440\u043E\u0447\u0438\u0442\u0430\u043D\u0456"),
                        React.createElement(AntSwitchGreen, { value: hideViewed, onChange: function () { return setHideViewed(!hideViewed); } }),
                        React.createElement(MoreVertOutlinedIcon, { sx: { color: '#1E1E1E' } }))),
                React.createElement(Box, { className: classes.ListWrapper },
                    React.createElement(List, { className: classes.ListModal },
                        React.createElement(Typography, { className: classes.modalDayText }, "\u0421\u044C\u043E\u0433\u043E\u0434\u043D\u0456"),
                        React.createElement(Box, { className: classes.HeaderNotificationWrapper }, filteredNotificationsList.map(function (item) { return (React.createElement(Notification, { key: item.id, item: item })); }))))))));
};
