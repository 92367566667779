import { PARAM_MOI_CONSULTANT_REQUEST_LIST_URL } from '../../config/di/params/keys';
import { ConsultantRequest } from './consultant-request';
import { PARAM_ITEMS_PER_PAGE } from '../../../../config/di/params/keys';
export var SERVICE_MOI_CONSULTANT_REQUEST = 'SERVICE_MOI_CONSULTANT_REQUEST';
export function consultantRequestFactory(container) {
    var listUrl = "".concat(container.get(PARAM_MOI_CONSULTANT_REQUEST_LIST_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    return new ConsultantRequest({
        urls: {
            listUrl: listUrl,
        },
        itemsPerPage: itemsPerPage,
    });
}
