var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Box, Fab } from '@mui/material';
import { useStyles } from './ThemeToggleStyle';
import { DayToggle } from '../assets/DayToggle';
import { NightToggle } from '../assets/NightToggle';
export var ThemeToggle = function () {
    var classes = useStyles();
    var _a = __read(useState(false), 2), isNight = _a[0], setIsNight = _a[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: classes.ToggleWrapper },
            React.createElement(Fab, { className: "".concat(classes.ButtonToggle, " ").concat(isNight && classes.ButtonToggleActive), onClick: function () {
                    setIsNight(true);
                } },
                React.createElement(NightToggle, null)),
            React.createElement(Fab, { className: "".concat(classes.ButtonToggle, " ").concat(!isNight && classes.ButtonToggleActive), onClick: function () {
                    setIsNight(false);
                } },
                React.createElement(DayToggle, null)))));
};
