import { makeStyles } from '@mui/styles';
export var useStyles = makeStyles(function (theme) { return ({
    // Parent menu item
    listItemContainer: {
        width: '220px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    /* --- Accordion --- */
    // Accordion summary
    accordion: {
        border: 'none',
        borderBottom: 'none',
        margin: '0',
        '&.Mui-expanded': {
            minHeight: 0,
            margin: '0',
        },
    },
    accordionSummary: {
        padding: 0,
        margin: '0',
        minHeight: 0,
        '&.Mui-expanded': {
            minHeight: 0,
            margin: '0',
        },
        '& .MuiAccordionSummary-content': {
            margin: 0,
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
            position: 'absolute',
            right: '10px',
            zIndex: 3,
        },
    },
    accordionDetails: {
        padding: 0,
    },
    /* ---  --- */
    linkButtonContainer: {
        height: '52px',
        padding: '14px 10px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&.Mui-selected': {
            background: 'linear-gradient(90deg, #EAFBF1 0%, #E7EFFC 59.52%, #E8F9FA 102.95%)',
            '&:hover': {
                background: 'linear-gradient(90deg, #EAFBF1 0%, #E7EFFC 59.52%, #E8F9FA 102.95%)',
                opacity: 1,
            },
        },
        '&:hover': {
            background: 'rgba(0,0,0,0.03)',
            opacity: 1,
        },
    },
    listItemMainContentContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    listItemIconContainer: {
        marginRight: '10px',
    },
    linkButtonText: {
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '-0.03em',
        color: '#A5BDDB',
        lineHeight: 1.25,
        padding: 0,
        margin: 0,
        '&.active': {
            color: theme.palette.primary.main,
        },
    },
    // Submenu
    subMenuDropdownIconContainer: {
        padding: 0,
        margin: 0,
        minWidth: '20px',
    },
    // Submenu items
    subMenuContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: '10px',
        background: theme.palette.background.paper,
        zIndex: 1,
        position: 'relative',
        '&:before': {
            content: "''",
            position: 'absolute',
            zIndex: 1,
            borderRadius: '10px',
            width: '2px',
            background: '#F1F6FA',
            top: '28px',
            left: ' 21px',
            height: 'calc(100% - 50px)',
        },
        '&.one': {
            '&:before': {
                display: 'none',
            },
        },
    },
    subMenuLinkButtonContainer: {
        minHeight: '38px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0',
        paddingLeft: '18px',
        position: 'relative',
        zIndex: 2,
        background: 'transparent',
        '&.Mui-selected': {
            background: 'transparent',
            '&:hover': {
                background: 'transparent',
                opacity: 1,
            },
        },
        '&:hover': {
            background: 'transparent',
            opacity: 1,
        },
    },
    subMenuListItemIconContainer: {
        marginRight: '8px',
    },
    subMenuListItemLabelContainer: {
        minHeight: '38px',
        padding: '12px 10px',
        borderRadius: '10px',
        flex: 1,
        '&.active': {
            background: theme.palette.buttonLighten.main,
            '&:hover': {
                background: theme.palette.buttonLighten.main,
                opacity: 1,
            },
        },
        '&:hover': {
            background: 'rgba(0,0,0,0.04)',
        },
    },
    subMenuLinkButtonText: {
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '-0.03em',
        color: '#A5BDDB',
        lineHeight: 1.25,
        padding: 0,
        margin: 0,
        '&.active': {
            color: theme.palette.primary.main,
        },
    },
    // ---
    // Submenu items of submenu items
    secondaryAccordion: {
        '&.Mui-expanded': {
        // marginBottom: '20px',
        },
    },
    secondarySubMenuContainer: {
        paddingTop: '0px',
        paddingLeft: '20px',
        position: 'relative',
        '&:before': {
            content: "''",
            position: 'absolute',
            zIndex: 1,
            borderRadius: '10px',
            width: '2px',
            background: '#F1F6FA',
            top: '18px',
            left: ' 41px',
            height: 'calc(100% - 50%)',
        },
        '&.one': {
            '&:before': {
                display: 'none',
            },
        },
    },
    secondarySubMenuListItemLabelContainer: {
        '&.active': {
            background: 'transparent',
            '&:hover': {
                background: 'transparent',
                opacity: 1,
            },
        },
    },
}); });
