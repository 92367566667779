export var initialState = {
    referrals: [],
    totalCount: 0,
    isReferralAdded: false,
    addedReferral: null,
    analyses: [],
    clients: [],
    loading: false,
    error: null,
};
