import { createUseStyles } from 'react-jss';
export var useStyles = createUseStyles({
    buttonActionTitle: {
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '16px',
        letterSpacing: '-0.03em',
        marginLeft: '8px!important',
        textTransform: 'none',
    },
});
