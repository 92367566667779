import { EhealthRoutes } from './keys.enum';
export var configureAppRouting = function (router) {
    router
        .setRoute(EhealthRoutes.ROOT, '')
        .setRoute(EhealthRoutes.LOGIN, '/e-health/app/sign-in')
        .setRoute(EhealthRoutes.LOGOUT, '/e-health/app/sign-out')
        .setRoute(EhealthRoutes.PROTOTYPE, '/e-health/app/prototype')
        // Legal Entity
        .setRoute(EhealthRoutes.LEGAL_ENTITY_SHOW, '/e-health/app/legal-entity/show')
        // Divisions
        .setRoute(EhealthRoutes.DIVISION_LIST, '/e-health/app/divisions')
        .setRoute(EhealthRoutes.DIVISION_CREATE, '/e-health/app/division/create')
        .setRoute(EhealthRoutes.DIVISION_EDIT, '/e-health/app/division/:id/edit')
        // Employees
        .setRoute(EhealthRoutes.EMPLOYEE_CREATE_REQUEST, '/e-health/app/employee/request/create')
        .setRoute(EhealthRoutes.EMPLOYEE_UPDATE_REQUEST, '/e-health/app/employee/request/:id/edit')
        .setRoute(EhealthRoutes.EMPLOYEE_REQUEST_LIST, '/e-health/app/employee/requests')
        .setRoute(EhealthRoutes.EMPLOYEE_LIST, '/e-health/app/employees')
        // Capitation contracts
        .setRoute(EhealthRoutes.CAPITATION_CONTRACT_LIST, '/e-health/app/capitation/contracts')
        // Capitation contract requests
        .setRoute(EhealthRoutes.CAPITATION_CONTRACT_REQUEST_LIST, '/e-health/app/capitation/contract/requests')
        .setRoute(EhealthRoutes.CAPITATION_CONTRACT_REQUEST_CREATE, '/e-health/app/capitation/contract/request/create')
        // healthcare service
        .setRoute(EhealthRoutes.HEALTHCARE_SERVICE_LIST, '/e-health/app/healthcare-services')
        .setRoute(EhealthRoutes.HEALTHCARE_SERVICE_CREATE, '/e-health/app/healthcare-service/create')
        .setRoute(EhealthRoutes.HEALTHCARE_SERVICE_EDIT, '/e-health/app/healthcare-service/:id/edit')
        // licenses
        .setRoute(EhealthRoutes.LICENSE_LIST, '/e-health/app/licenses')
        .setRoute(EhealthRoutes.LICENSE_CREATE, '/e-health/app/license/create')
        .setRoute(EhealthRoutes.LICENSE_EDIT, '/e-health/app/license/:id/edit')
        // person request
        .setRoute(EhealthRoutes.PERSON_REQUEST_LIST, '/e-health/app/person-requests')
        .setRoute(EhealthRoutes.PERSON_REQUEST_CREATE, '/e-health/app/person-request/create')
        .setRoute(EhealthRoutes.PERSON_REQUEST_EDIT, '/e-health/app/person-request/:id/edit')
        // declaration requests
        .setRoute(EhealthRoutes.DECLARATION_REQUEST_LIST, '/e-health/app/declaration-requests')
        .setRoute(EhealthRoutes.DECLARATION_REQUEST_CREATE, '/e-health/app/declaration-request/:personId/create')
        // declarations
        .setRoute(EhealthRoutes.DECLARATION_LIST, '/e-health/app/declarations')
        // episodes
        .setRoute(EhealthRoutes.EPISODE_LIST_SEARCH, '/e-health/app/persons/:personId/episodes/search')
        .setRoute(EhealthRoutes.EPISODE_LIST_APPROVED, '/e-health/app/persons/:personId/episodes/approved')
        .setRoute(EhealthRoutes.EPISODE_CREATE, '/e-health/app/persons/:personId/episodes/create/:jobId?')
        .setRoute(EhealthRoutes.EPISODE_EDIT, '/e-health/app/persons/:personId/episodes/:episodeId/edit')
        // encounter
        .setRoute(EhealthRoutes.ENCOUNTER_LIST, '/e-health/app/encounter/:personId/:episodeId/list')
        .setRoute(EhealthRoutes.ENCOUNTER_CREATE, '/e-health/app/encounter/:personId/:episodeId/create/:jobId?')
        .setRoute(EhealthRoutes.ENCOUNTER_CANCEL, '/e-health/app/encounter/:personId/:episodeId/cancel/:encounterId?')
        // encounter package
        .setRoute(EhealthRoutes.CONDITION_LIST, '/e-health/app/condition/:personId/:episodeId/list')
        .setRoute(EhealthRoutes.OBSERVATION_LIST, '/e-health/app/observation/:personId/:episodeId/list')
        .setRoute(EhealthRoutes.ALLERGY_INTOLERANCE_LIST, '/e-health/app/allergy-intolerance/:personId/:episodeId/list')
        .setRoute(EhealthRoutes.IMMUNIZATION_LIST, '/e-health/app/immunization/:personId/:episodeId/list')
        .setRoute(EhealthRoutes.RISK_ASSESSMENT_LIST, '/e-health/app/risk-assessment/:personId/:episodeId/list')
        .setRoute(EhealthRoutes.DEVICE_LIST, '/e-health/app/device/:personId/:episodeId/list')
        .setRoute(EhealthRoutes.MEDICATION_ADMINISTRATION_LIST, '/e-health/app/medication-administration/:personId/:episodeId/list')
        .setRoute(EhealthRoutes.DIAGNOSTIC_REPORT_LIST, '/e-health/app/diagnostic-report/:personId/:episodeId/list')
        .setRoute(EhealthRoutes.DIAGNOSTIC_REPORT_CREATE, '/e-health/app/diagnostic-report/:personId/:episodeId/create/:jobId?')
        .setRoute(EhealthRoutes.DIAGNOSTIC_REPORT_APPROVED, '/diagnostic-report/:personId/:episodeId/diagnostic-reports/approved')
        .setRoute(EhealthRoutes.PROCEDURE_LIST, '/e-health/app/procedure/:personId/:episodeId/list')
        .setRoute(EhealthRoutes.PROCEDURE_CREATE, '/e-health/app/procedure/:personId/:episodeId/create:jobId?')
        // approval
        .setRoute(EhealthRoutes.APPROVAL_LIST, '/e-health/app/approval/:personId/list')
        .setRoute(EhealthRoutes.APPROVAL_CREATE, '/e-health/app/approval/:personId/create/:jobId?')
        // composition
        .setRoute(EhealthRoutes.COMPOSITION_LIST, '/e-health/app/composition/:personId/list')
        .setRoute(EhealthRoutes.COMPOSITION_CREATE, '/e-health/app/composition/:personId/:encounterId/create/:jobId?')
        .setRoute(EhealthRoutes.COMPOSITION_EDIT, '/e-health/app/composition/:personId/:episodeId/:encounterId/edit/:compositionId')
        // persons
        .setRoute(EhealthRoutes.PERSON_LIST, '/e-health/app/persons')
        // prepersons
        .setRoute(EhealthRoutes.PREPERSON_LIST, '/e-health/app/prepersons')
        .setRoute(EhealthRoutes.PREPERSON_CREATE, '/e-health/app/preperson/create')
        // medication
        .setRoute(EhealthRoutes.MEDICATION_REQUEST_REQUEST_LIST, '/e-health/app/medication-request-request/:personId/list')
        .setRoute(EhealthRoutes.MEDICATION_REQUEST_REQUEST_CREATE, '/e-health/app/medication-request-request/:personId/create')
        .setRoute(EhealthRoutes.MEDICATION_REQUEST_REQUEST_EDIT, '/e-health/app/medication-request-request/:personId/edit/:payloadId?')
        .setRoute(EhealthRoutes.MEDICATION_REQUEST_EXPIRED_LIST, '/e-health/app/medication-request/expired/:personId/list')
        //medication device
        .setRoute(EhealthRoutes.DEVICE_REQUEST_LIST, '/e-health/app/device-request/:personId/list')
        .setRoute(EhealthRoutes.DEVICE_REQUEST_CREATE, '/e-health/app/device-request/:personId/create/:jobId?')
        // service request
        .setRoute(EhealthRoutes.SERVICE_REQUEST_LIST, '/e-health/app/service-request/:personId/list')
        .setRoute(EhealthRoutes.SERVICE_REQUEST_CREATE, '/e-health/app/service-request/:personId/create/:jobId?')
        .setRoute(EhealthRoutes.SERVICE_REQUEST_EDIT, '/e-health/app/service-request/:personId/edit/:srId?')
        //patient-summary
        .setRoute(EhealthRoutes.PATIENT_SUMMARY, '/e-health/app/patient/:personId/summary')
        .setRoute(EhealthRoutes.SUMMARY_RISK_ASSESMENT_LIST, '/e-health/app/patient-summary/:personId/risk-assessments')
        .setRoute(EhealthRoutes.SUMMURY_EPISODES_LIST, '/e-health/app/patient-summary/:personId/episodes')
        .setRoute(EhealthRoutes.SUMMARY_IMMUNIZATION_LIST, '/e-health/app/patient-summary/:personId/immunizations')
        .setRoute(EhealthRoutes.SUMMARY_ALLERGY_INTOLERANCE_LIST, '/e-health/app/patient-summary/:personId/allergy-intolerances')
        .setRoute(EhealthRoutes.SUMMARY_OBSERVATION_LIST, '/e-health/app/patient-summary/:personId/observations')
        .setRoute(EhealthRoutes.SUMMARY_CONDITION_LIST, '/e-health/app/patient-summary/:personId/conditions')
        .setRoute(EhealthRoutes.SUMMARY_ACTIVE_DIAGNOSES_LIST, '/e-health/app/patient-summary/:personId/active_diagnoses')
        .setRoute(EhealthRoutes.SUMMARY_DEVICE_LIST, '/e-health/app/patient-summary/:personId/devices')
        .setRoute(EhealthRoutes.SUMMARY_MEDICATION_STATEMENT_LIST, '/e-health/app/patient-summary/:personId/medication_statements')
        .setRoute(EhealthRoutes.SUMMARY_DIAGNOSTIC_REPORT_LIST, '/e-health/app/patient-summary/:personId/diagnostic_reports')
        .setRoute(EhealthRoutes.SUMMARY_PROCEDURE_LIST, '/e-health/app/patient-summary/:personId/procedures')
        // care plan
        .setRoute(EhealthRoutes.CARE_PLAN_LIST, '/e-health/app/care-plan/:personId/list')
        .setRoute(EhealthRoutes.CARE_PLAN_CREATE, '/e-health/app/care-plan/:personId/create/:jobId?')
        // care plan activity
        .setRoute(EhealthRoutes.CARE_PLAN_ACTIVITY_LIST, '/e-health/app/care-plan-activity/:personId/care-plan/:carePlanId/list')
        .setRoute(EhealthRoutes.CARE_PLAN_ACTIVITY_CREATE, '/care-plan-activity/:personId/care-plan/:carePlanId/create/:jobId?')
        // Reorganize
        .setRoute(EhealthRoutes.REORGANIZE_MIS_LIST, '/e-health/app/reorganize/mis-list')
        .setRoute(EhealthRoutes.REORGANIZE_ACTIVE_EMPLOYEE_DECLARATION_LIST, '/e-health/app/reorganize/declaration-list');
};
