import { PARAM_MOI_DATA_ARCHIVE_RECORDS_CATEGORIES_LIST_URL, PARAM_MOI_DATA_ARCHIVE_CREATE_RECORD_URL, PARAM_MOI_DATA_ARCHIVE_DELETE_RECORD_URL, PARAM_MOI_DATA_ARCHIVE_GET_ALL_RECORDS_URL, PARAM_MOI_DATA_ARCHIVE_GET_ONE_RECORD_URL, PARAM_MOI_DATA_ARCHIVE_GET_ALL_CHANGES_HISTORY_URL } from '../../config/di/params/keys';
import { DataArchive } from './data-archive';
export var SERVICE_MOI_DATA_ARCHIVE = 'SERVICE_MOI_DATA_ARCHIVE';
export function dataArchiveFactory(container) {
    /* My records */
    var getRecordsCategoriesListUrl = "".concat(container.get(PARAM_MOI_DATA_ARCHIVE_RECORDS_CATEGORIES_LIST_URL));
    var createRecordUrl = "".concat(container.get(PARAM_MOI_DATA_ARCHIVE_CREATE_RECORD_URL));
    var getOneRecordUrl = "".concat(container.get(PARAM_MOI_DATA_ARCHIVE_GET_ONE_RECORD_URL));
    var getAllRecordsUrl = "".concat(container.get(PARAM_MOI_DATA_ARCHIVE_GET_ALL_RECORDS_URL));
    var deleteRecordUrl = "".concat(container.get(PARAM_MOI_DATA_ARCHIVE_DELETE_RECORD_URL));
    /* History of changes */
    var getAllChangesHistoryUrl = "".concat(container.get(PARAM_MOI_DATA_ARCHIVE_GET_ALL_CHANGES_HISTORY_URL));
    /* Access */
    /* Local storage */
    return new DataArchive({
        getRecordsCategoriesListUrl: getRecordsCategoriesListUrl,
        createRecordUrl: createRecordUrl,
        getOneRecordUrl: getOneRecordUrl,
        getAllRecordsUrl: getAllRecordsUrl,
        deleteRecordUrl: deleteRecordUrl,
        getAllChangesHistoryUrl: getAllChangesHistoryUrl
    });
}
