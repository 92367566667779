var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultState } from './state';
import { NavAction } from './action.enum';
export var navReducer = function (state, action) {
    var _a, _b, _c;
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case NavAction.SET_ALL_MENUS:
            return __assign(__assign({}, state), { menus: action.payload.menus });
        case NavAction.UPDATE_MENU_ITEM:
            var key = action.payload.itemKey;
            var menu = state.menus[action.payload.navPlace];
            if (!menu || !menu.has(key)) {
                return state;
            }
            menu.set(key, __assign(__assign({}, menu.get(key)), action.payload.item));
            return __assign(__assign({}, state), { menus: __assign(__assign({}, state.menus), (_a = {}, _a[action.payload.navPlace] = menu, _a)) });
        case NavAction.SET_MENU:
            return __assign(__assign({}, state), { menus: __assign(__assign({}, state.menus), (_b = {}, _b[action.payload.place] = action.payload.items, _b)) });
        case NavAction.SET_SUB_MENU:
            return __assign(__assign({}, state), { subMenus: __assign(__assign({}, state.subMenus), (_c = {}, _c[action.payload.place] = action.payload.items, _c)) });
    }
    return state;
};
