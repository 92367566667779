import { PARAM_ITEMS_PER_PAGE } from '../../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_STORAGE_PROXY_URL, PARAM_EHEALTH_SINGLE_STORED_PERSON_URL, PARAM_EHEALTH_GET_MONI_HEAL_PERSON, PARAM_EHEALTH_ASSIGN_MONI_HEAL_PERSON_URL, } from '../../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../../request';
import { SERVICE_EHEALTH_SIGN } from '../../signature';
import { PersonService } from './person';
export var SERVICE_EHEALTH_PERSON = 'SERVICE_EHEALTH_PERSON';
export var ehealthPersonFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var storageUrl = "".concat(container.get(PARAM_EHEALTH_STORAGE_PROXY_URL));
    var getSingleStoredPersonUrl = "".concat(container.get(PARAM_EHEALTH_SINGLE_STORED_PERSON_URL));
    var getMoniHealPerson = "".concat(container.get(PARAM_EHEALTH_GET_MONI_HEAL_PERSON));
    var assignMoniHealPersonUrl = "".concat(container.get(PARAM_EHEALTH_ASSIGN_MONI_HEAL_PERSON_URL));
    var signService = container.require(SERVICE_EHEALTH_SIGN);
    return new PersonService({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        storageUrl: storageUrl,
        getSingleStoredPersonUrl: getSingleStoredPersonUrl,
        getMoniHealPerson: getMoniHealPerson,
        assignMoniHealPersonUrl: assignMoniHealPersonUrl,
        signService: signService,
    });
};
