export var initialState = {
    clinicNews: [],
    singleNews: null,
    tags: [],
    totalCount: 0,
    isNewsAdded: false,
    isNewsEdited: false,
    isNewsDeleted: false,
    isLoading: false,
    error: null,
};
