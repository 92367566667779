var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { defaultState } from './state';
import { CompanyScheduleAction } from './enum/action';
export function scheduleReducer(state, action) {
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case CompanyScheduleAction.SET_EXPERTS:
            return __assign(__assign({}, state), { experts: {
                    data: action.payload.experts,
                    isLoading: false,
                } });
        case CompanyScheduleAction.SET_EXPERTS_LOADING:
            return __assign(__assign({}, state), { experts: __assign(__assign({}, state.experts), { isLoading: action.payload.isLoading }) });
        case CompanyScheduleAction.TOGGLE_SELECTION_EXPERT:
            if (state.selectedExperts[action.payload.id]) {
                delete state.selectedExperts[action.payload.id];
            }
            else {
                state.selectedExperts[action.payload.id] = true;
            }
            return __assign(__assign({}, state), { selectedExperts: __assign({}, state.selectedExperts) });
        case CompanyScheduleAction.SET_SCHEDULES:
            return __assign(__assign({}, state), { schedules: action.payload.schedules });
        case CompanyScheduleAction.SET_SERVICES:
            return __assign(__assign({}, state), { services: action.payload.services });
        case CompanyScheduleAction.SET_BOOKINGS:
            return __assign(__assign({}, state), { bookings: {
                    data: action.payload.bookings,
                    isLoading: false,
                } });
        case CompanyScheduleAction.ADD_BOOKINGS:
            return __assign(__assign({}, state), { bookings: {
                    data: __spreadArray(__spreadArray([], __read(action.payload.bookings), false), __read(state.bookings.data), false),
                    isLoading: false,
                } });
        case CompanyScheduleAction.EDIT_BOOKING:
            var index = state.bookings.data.findIndex(function (booking) {
                return booking.id === action.payload.booking.id;
            });
            if (index < 0) {
                return state;
            }
            state.bookings.data[index] = action.payload.booking;
            return __assign(__assign({}, state), { bookings: {
                    data: __spreadArray([], __read(state.bookings.data), false),
                    isLoading: false,
                } });
        case CompanyScheduleAction.SET_BOOKINGS_LOADING:
            return __assign(__assign({}, state), { bookings: __assign(__assign({}, state.bookings), { isLoading: action.payload.isLoading }) });
    }
    return state;
}
