import { SERVICE_EHEALTH_AUTH } from '../../../auth';
import { PARAM_ITEMS_PER_PAGE } from '../../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_STORAGE_PROXY_URL } from '../../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../../request';
import { CapitationContractRequestService } from './capitation-contract-request';
import { SERVICE_EHEALTH_SIGN } from '../../signature';
export var SERVICE_EHEALTH_CAPITATION_CONTRACT_REQUEST = 'SERVICE_EHEALTH_CAPITATION_CONTRACT_REQUEST';
export var ehealthCapitationContractRequestFactory = function (container) {
    var authService = container.require(SERVICE_EHEALTH_AUTH);
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var storageUrl = "".concat(container.get(PARAM_EHEALTH_STORAGE_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var signService = container.require(SERVICE_EHEALTH_SIGN);
    return new CapitationContractRequestService({
        authService: authService,
        baseUrl: baseUrl,
        storageUrl: storageUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        signService: signService,
    });
};
