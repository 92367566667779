var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ResponseError } from '../../../../../shared/error/response-error';
import { clearAndSerialize } from '../../../../../shared/util/normalizer';
import { PayloadStoreType } from '../medical-events/enum/payload-store-type';
import { clearFilter } from './utill';
import { clearObject } from '../../../../../../../assets/js/utils/etc';
var DeviceRequestService = /** @class */ (function () {
    function DeviceRequestService(params) {
        this.baseUrl = params.baseUrl;
        this.storageUrl = params.storageUrl;
        this.itemsPerPage = params.itemsPerPage;
        this.requestService = params.requestService;
        this.signService = params.signService;
        this.payloadFindStoredUrl = params.payloadFindStoredUrl;
        this.localize = params.localizeService;
    }
    DeviceRequestService.prototype.create = function (data, personId, signParams) {
        return __awaiter(this, void 0, void 0, function () {
            var preparedData, signedData, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        preparedData = clearObject(this.prepareData(__assign({}, data)));
                        console.log('preparedData', preparedData);
                        return [4 /*yield*/, this.signService.sign(JSON.stringify(preparedData), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/patients/").concat(personId, "/device_requests"), undefined, JSON.stringify({
                                signed_data: signedData,
                            }))];
                    case 4:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 5:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, json];
                }
            });
        });
    };
    DeviceRequestService.prototype.prepareData = function (data) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        (_a = data === null || data === void 0 ? void 0 : data.supporting_info) === null || _a === void 0 ? void 0 : _a.forEach(function (supporting_info, index) {
            delete supporting_info.display_value;
        });
        (_b = data === null || data === void 0 ? void 0 : data.reason) === null || _b === void 0 ? void 0 : _b.forEach(function (value, i) {
            var _a, _b;
            if (((_a = value === null || value === void 0 ? void 0 : value.identifier) === null || _a === void 0 ? void 0 : _a.value) == '') {
                (_b = data === null || data === void 0 ? void 0 : data.reason) === null || _b === void 0 ? true : delete _b[i];
            }
        });
        if (((_d = (_c = data === null || data === void 0 ? void 0 : data.program) === null || _c === void 0 ? void 0 : _c.identifier) === null || _d === void 0 ? void 0 : _d.value) == '') {
            data === null || data === void 0 ? true : delete data.program;
        }
        (_e = data === null || data === void 0 ? void 0 : data.based_on) === null || _e === void 0 ? void 0 : _e.forEach(function (value, i) {
            var _a, _b;
            if (((_a = value === null || value === void 0 ? void 0 : value.identifier) === null || _a === void 0 ? void 0 : _a.value) == '') {
                (_b = data === null || data === void 0 ? void 0 : data.based_on) === null || _b === void 0 ? true : delete _b[i];
            }
        });
        (_f = data === null || data === void 0 ? void 0 : data.supporting_info) === null || _f === void 0 ? void 0 : _f.forEach(function (value, i) {
            var _a, _b;
            if (((_a = value === null || value === void 0 ? void 0 : value.identifier) === null || _a === void 0 ? void 0 : _a.value) == '') {
                (_b = data === null || data === void 0 ? void 0 : data.supporting_info) === null || _b === void 0 ? true : delete _b[i];
            }
        });
        if (((_h = (_g = data === null || data === void 0 ? void 0 : data.performer) === null || _g === void 0 ? void 0 : _g.identifier) === null || _h === void 0 ? void 0 : _h.value) == '') {
            data === null || data === void 0 ? true : delete data.performer;
        }
        if ((data === null || data === void 0 ? void 0 : data.note) == '') {
            data === null || data === void 0 ? true : delete data.note;
        }
        // if (data?.code_reference?.identifier?.type?.coding?.[0]?.code == '') {
        // delete data?.code;
        // }
        if (((_j = data === null || data === void 0 ? void 0 : data.quantity) === null || _j === void 0 ? void 0 : _j.value) == null) {
            data === null || data === void 0 ? true : delete data.quantity;
        }
        return data;
    };
    DeviceRequestService.prototype.getList = function (personId, page, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var args, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        args = __assign({ page: "".concat(page), page_size: "".concat(this.itemsPerPage) }, clearFilter(filter));
                        return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(personId, "/device_requests"), args)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    DeviceRequestService.prototype.getSingle = function (patientId, deviceRequestId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/device_requests/").concat(deviceRequestId))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __assign({}, json.data)];
                }
            });
        });
    };
    DeviceRequestService.prototype.getStoredSigned = function (medicationRequestId) {
        return __awaiter(this, void 0, void 0, function () {
            var query, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = new URLSearchParams({
                            id: medicationRequestId,
                            type: PayloadStoreType.SIGNED_MEDICATION_REQUEST,
                        });
                        return [4 /*yield*/, fetch("".concat(this.payloadFindStoredUrl, "?").concat(query), {
                                credentials: 'include',
                            })];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __assign({}, json)];
                }
            });
        });
    };
    DeviceRequestService.prototype.qualify = function (id, query) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/medication_requests/").concat(id, "/actions/qualify"), undefined, clearAndSerialize(query))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __spreadArray([], __read(json.data), false)];
                }
            });
        });
    };
    DeviceRequestService.prototype.reject = function (id, signParams, data) {
        return __awaiter(this, void 0, void 0, function () {
            var signedData, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        return [4 /*yield*/, this.signService.sign(JSON.stringify(data), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/medication_requests/").concat(id, "/actions/reject"), undefined, JSON.stringify({
                                signed_medication_reject: signedData,
                                signed_content_encoding: 'base64',
                            }))];
                    case 4:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 5:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __assign({}, json.data)];
                }
            });
        });
    };
    DeviceRequestService.prototype.resend = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/medication_requests/").concat(id, "/actions/resend"))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __assign({}, json.data)];
                }
            });
        });
    };
    return DeviceRequestService;
}());
export { DeviceRequestService };
