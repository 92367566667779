import { connect } from 'react-redux';
import { NavPlace } from '../../../../service/nav/NavPlace.enum';
import { MobileMenu } from './MobileMenu';
function mapStateToProps(state) {
    var items = state.nav.menus[NavPlace.MOBILE];
    var shortcuts = state.nav.menus[NavPlace.SHORTCUT];
    return {
        // don`t use spread operator here
        items: Array.from((items === null || items === void 0 ? void 0 : items.values()) || []),
        shortcuts: Array.from((shortcuts === null || shortcuts === void 0 ? void 0 : shortcuts.values()) || []),
    };
}
export var ConnectedMobileMenu = connect(mapStateToProps)(MobileMenu);
