import { combineReducers } from 'redux';
import { companyExpertsReducer } from './experts/reducer';
import { incomingExpertRequestReducer } from './incomingExpertRequest/reducer';
import { outgoingExpertRequestReducer } from './outgoingExpertRequest/reducer';
import { companyExpertScheduleReducer } from './expert-schedule/reducer';
import { companyExpertServicesReducer } from './expert-services/reducer';
export var expertReducer = combineReducers({
    companyExperts: companyExpertsReducer,
    incomingExpertRequest: incomingExpertRequestReducer,
    outgoingExpertRequest: outgoingExpertRequestReducer,
    companyExpertSchedule: companyExpertScheduleReducer,
    companyExpertServices: companyExpertServicesReducer,
});
