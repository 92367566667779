export var ClinicInfoActionType;
(function (ClinicInfoActionType) {
    ClinicInfoActionType["GET_CLINIC_INFO_PENDING"] = "GET_CLINIC_INFO_PENDING";
    ClinicInfoActionType["GET_CLINIC_INFO_SUCCESS"] = "GET_CLINIC_INFO_SUCCESS";
    ClinicInfoActionType["GET_CLINIC_INFO_ERROR"] = "GET_CLINIC_INFO_ERROR";
    ClinicInfoActionType["SET_CLINIC_INFO_PENDING"] = "SET_CLINIC_INFO_PENDING";
    ClinicInfoActionType["SET_CLINIC_INFO_SUCCESS"] = "SET_CLINIC_INFO_SUCCESS";
    ClinicInfoActionType["SET_CLINIC_INFO_ERROR"] = "SET_CLINIC_INFO_ERROR";
    ClinicInfoActionType["GET_CLINIC_TYPES_PENDING"] = "GET_CLINIC_TYPES_PENDING";
    ClinicInfoActionType["GET_CLINIC_TYPES_SUCCESS"] = "GET_CLINIC_TYPES_SUCCESS";
    ClinicInfoActionType["GET_CLINIC_TYPES_ERROR"] = "GET_CLINIC_TYPES_ERROR";
    ClinicInfoActionType["GET_CLINIC_ADDRESS_PENDING"] = "GET_CLINIC_ADDRESS_PENDING";
    ClinicInfoActionType["GET_CLINIC_ADDRESS_SUCCESS"] = "GET_CLINIC_ADDRESS_SUCCESS";
    ClinicInfoActionType["GET_CLINIC_ADDRESS_ERROR"] = "GET_CLINIC_ADDRESS_ERROR";
    ClinicInfoActionType["SET_CLINIC_ADDRESS_PENDING"] = "SET_CLINIC_ADDRESS_PENDING";
    ClinicInfoActionType["SET_CLINIC_ADDRESS_SUCCESS"] = "SET_CLINIC_ADDRESS_SUCCESS";
    ClinicInfoActionType["SET_CLINIC_ADDRESS_ERROR"] = "SET_CLINIC_ADDRESS_ERROR";
    ClinicInfoActionType["SET_CLINIC_ADDITIONAL_PENDING"] = "SET_CLINIC_ADDITIONAL_PENDING";
    ClinicInfoActionType["SET_CLINIC_ADDITIONAL_SUCCESS"] = "SET_CLINIC_ADDITIONAL_SUCCESS";
    ClinicInfoActionType["SET_CLINIC_ADDITIONAL_ERROR"] = "SET_CLINIC_ADDITIONAL_ERROR";
    ClinicInfoActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(ClinicInfoActionType || (ClinicInfoActionType = {}));
