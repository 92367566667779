var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultState } from './state';
import { AppAction } from './action.enum';
export var appReducer = function (state, action) {
    var _a;
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case AppAction.SET_IS_LOADING:
            return __assign(__assign({}, state), { isLoading: !!action.payload.isLoading });
        case AppAction.POPULATE_ERROR:
            return __assign(__assign({}, state), { errors: (_a = {},
                    _a[action.payload.channel] = action.payload.error,
                    _a) });
        case AppAction.CLEAR_ERROR_CHANNEL:
            if (state.errors[action.payload.channel]) {
                delete state.errors[action.payload.channel];
            }
            return __assign(__assign({}, state), { errors: __assign({}, state.errors) });
        case AppAction.SET_VIEWPORT_WIDTH:
            return __assign(__assign({}, state), { viewportWidth: action.payload.viewportWidth });
    }
    return state;
};
