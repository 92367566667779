export var EhealthResource;
(function (EhealthResource) {
    EhealthResource["employee"] = "employee";
    EhealthResource["encounter"] = "encounter";
    EhealthResource["episode_of_care"] = "episode_of_care";
    EhealthResource["legal_entity"] = "legal_entity";
    EhealthResource["medical_program"] = "medical_program";
    EhealthResource["medication_request"] = "medication_request";
    EhealthResource["medication_administration"] = "medication_administration";
    EhealthResource["medication_statement"] = "medication_statement";
    EhealthResource["procedure"] = "procedure";
    EhealthResource["service_group"] = "service_group";
    EhealthResource["allergy_intolerance"] = "allergy_intolerance";
    EhealthResource["condition"] = "condition";
    EhealthResource["device"] = "device";
    EhealthResource["diagnostic_report"] = "diagnostic_report";
    EhealthResource["immunization"] = "immunization";
    EhealthResource["observation"] = "observation";
    EhealthResource["patient"] = "patient";
    EhealthResource["program_service"] = "program_service";
    EhealthResource["risk_assessment"] = "risk_assessment";
    EhealthResource["service"] = "service";
    EhealthResource["service_request"] = "service_request";
    EhealthResource["visit"] = "visit";
    EhealthResource["episode"] = "episode";
    EhealthResource["division"] = "division";
    EhealthResource["medication"] = "medication";
    EhealthResource["care_plan"] = "care_plan";
    EhealthResource["activity"] = "activity";
    EhealthResource["forbidden_group"] = "forbidden_group";
    EhealthResource["person"] = "person";
    EhealthResource["device_definition"] = "device_definition";
    EhealthResource["diagnoses_group"] = "diagnoses_group";
    EhealthResource["medication_dispense"] = "medication_dispense";
    EhealthResource["device_dispense"] = "device_dispense";
})(EhealthResource || (EhealthResource = {}));
