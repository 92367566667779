export var palette = {
    primary: {
        light: '#D2E3F8',
        main: '#173236',
        dark: '#90A4BE',
    },
    secondary: {
        light: '#D3E2EE',
        main: '#809FB8',
        dark: '#425C76',
    },
    divider: '#D8D8D8',
    background: {
        paper: '#fff',
    },
    text: {
        primary: '#000000',
        secondary: '#333333',
        disabled: '#777E90',
        muted: '#1E1E1E'
    },
    success: {
        light: '#65f6ad',
        main: '#29F499',
        dark: '#1bb36f',
    },
    buttonLight: {
        light: '#dae8f8',
        main: '#D2E3F8',
        dark: '#425C76',
    },
    buttonLighten: {
        light: '#f2f9ff',
        main: '#F1F6FA',
        dark: '#d5dadf',
    },
    error: {
        main: '#FF0000'
    }
};
