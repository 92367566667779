import { createUseStyles } from 'react-jss';
export var useStyles = createUseStyles({
    navList: {},
    __active: {},
    navListWrapper: {
        '.cabinet-mobile-nav &.cabinet-sidebar-block': {
            overflowY: 'auto',
        },
        '& $navList.cabinet-sidebar-nav': {
            overflowY: 'auto',
        },
    },
    item_icon_wrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    arrow_rewert: {
        transform: 'rotate(180deg)'
    }
});
