export var EhealthMEDiagnosticReportAction;
(function (EhealthMEDiagnosticReportAction) {
    EhealthMEDiagnosticReportAction["ADD_ITEMS"] = "EHEALTH_ME_DIAGNOSTIC_REPORT_ADD_ITEMS";
    EhealthMEDiagnosticReportAction["CLEAR"] = "EHEALTH_ME_DIAGNOSTIC_REPORT_CLEAR";
    EhealthMEDiagnosticReportAction["UPDATE"] = "EHEALTH_ME_DIAGNOSTIC_REPORT_UPDATE";
    EhealthMEDiagnosticReportAction["SET_IS_LOADING"] = "EHEALTH_ME_DIAGNOSTIC_REPORT_SET_IS_LOADING";
    EhealthMEDiagnosticReportAction["SETUP_JOBS"] = "EHEALTH_ME_DIAGNOSTIC_REPORT_SETUP_JOBS";
    EhealthMEDiagnosticReportAction["REMOVE_JOB"] = "EHEALTH_ME_DIAGNOSTIC_REPORT_REMOVE_JOB";
    EhealthMEDiagnosticReportAction["SET_FILTER"] = "EHEALTH_ME_DIAGNOSTIC_REPORT_SET_FILTER";
})(EhealthMEDiagnosticReportAction || (EhealthMEDiagnosticReportAction = {}));
