export var clinicInitialState = {
    full_name: null,
    short_name: null,
    type: null,
    webAddress: null,
    city: null,
    company_logo: null,
    createdAt: null,
    description: null,
    eHealthRegister: false,
    email: null,
    legalEntity: null,
    lunch: null,
    phones: null,
    schedule: null,
};
export var initialState = {
    clinic: clinicInitialState,
    loading: false,
    error: null,
};
