import { JobService } from './job';
import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_CREATE_JOB_URL, PARAM_EHEALTH_LIST_JOB_URL, PARAM_EHEALTH_DELETE_JOB_URL, PARAM_EHEALTH_UPDATE_JOB_URL, PARAM_EHEALTH_SINGLE_JOB_URL, PARAM_EHEALTH_ENTITIES_JOB_URL, } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
export var SERVICE_EHEALTH_JOB = 'SERVICE_EHEALTH_JOB';
export var ehealthJobFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var createJobUrl = "".concat(container.get(PARAM_EHEALTH_CREATE_JOB_URL));
    var deleteJobUrl = "".concat(container.get(PARAM_EHEALTH_DELETE_JOB_URL));
    var updateJobUrl = "".concat(container.get(PARAM_EHEALTH_UPDATE_JOB_URL));
    var listJobUrl = "".concat(container.get(PARAM_EHEALTH_LIST_JOB_URL));
    var singleJobUrl = "".concat(container.get(PARAM_EHEALTH_SINGLE_JOB_URL));
    var jobsEntitiesUrl = "".concat(container.get(PARAM_EHEALTH_ENTITIES_JOB_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    return new JobService({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        createJobUrl: createJobUrl,
        listJobUrl: listJobUrl,
        deleteJobUrl: deleteJobUrl,
        updateJobUrl: updateJobUrl,
        singleJobUrl: singleJobUrl,
        jobsEntitiesUrl: jobsEntitiesUrl,
    });
};
