import { PARAM_MOI_BOOKING_MEETING_URL, PARAM_MOI_COMPANY_BOOKINGS_URL, PARAM_MOI_COMPANY_BOOKING_CREATE_URL, PARAM_MOI_COMPANY_BOOKING_CONFIRM_URL, PARAM_MOI_COMPANY_BOOKING_DECLINE_URL, PARAM_MOI_COMPANY_BOOKING_EDIT_URL, PARAM_MOI_COMPANY_BOOKING_SUMMARY_URL, } from '../../config/di/params/keys';
import { Booking } from './booking';
export var SERVICE_MOI_BOOKING = 'SERVICE_MOI_BOOKING';
export function bookingFactory(container) {
    var getMeetingUrl = "".concat(container.get(PARAM_MOI_BOOKING_MEETING_URL));
    var getBookingsUrl = "".concat(container.get(PARAM_MOI_COMPANY_BOOKINGS_URL));
    var createBookingUrl = "".concat(container.get(PARAM_MOI_COMPANY_BOOKING_CREATE_URL));
    var confirmBookingUrl = "".concat(container.get(PARAM_MOI_COMPANY_BOOKING_CONFIRM_URL));
    var declineBookingUrl = "".concat(container.get(PARAM_MOI_COMPANY_BOOKING_DECLINE_URL));
    var editBookingUrl = "".concat(container.get(PARAM_MOI_COMPANY_BOOKING_EDIT_URL));
    var summaryUrl = "".concat(container.get(PARAM_MOI_COMPANY_BOOKING_SUMMARY_URL));
    return new Booking({
        getMeetingUrl: getMeetingUrl,
        getBookingsUrl: getBookingsUrl,
        createBookingUrl: createBookingUrl,
        confirmBookingUrl: confirmBookingUrl,
        declineBookingUrl: declineBookingUrl,
        editBookingUrl: editBookingUrl,
        summaryUrl: summaryUrl,
    });
}
