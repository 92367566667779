import React from 'react';
export var CollapseMenuMedicalArchiveIcon = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { clipPath: "url(#clip0_1526_1268)" },
                React.createElement("path", { d: "M1.33398 10L3.00065 2H13.0007L14.6673 10", stroke: "#1E1E1E", strokeWidth: "1.4", strokeLinecap: "round", strokeLinejoin: "round" }),
                React.createElement("path", { d: "M1.33398 10H4.97035L5.57641 12H10.4249L11.031 10H14.6673V14.3333H1.33398V10Z", stroke: "#1E1E1E", strokeWidth: "1.4", strokeLinejoin: "round" }),
                React.createElement("path", { d: "M6.33398 4.66699H9.66732", stroke: "#1E1E1E", strokeWidth: "1.4", strokeLinecap: "round", strokeLinejoin: "round" }),
                React.createElement("path", { d: "M5.33398 7.33301H10.6673", stroke: "#1E1E1E", strokeWidth: "1.4", strokeLinecap: "round", strokeLinejoin: "round" })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip0_1526_1268" },
                    React.createElement("rect", { width: "16", height: "16", fill: "white" }))))));
};
