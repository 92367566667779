import { PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_CREATE_URL, PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_EDIT_URL, PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_LIST_ALL_URL, PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_LIST_URL, PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_REMOVE_URL, PARAM_MOI_CHAT_MESSAGE_TEMPLATE_SEND_URL, PARAM_MOI_CHAT_MESSAGE_TEMPLATE_SINGLE_URL, } from '../../../config/di/params/keys';
import { EmailMessageTemplateService } from './email-message-template';
import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
export var SERVICE_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE = 'SERVICE_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE';
export function emailMessageTemplateFactory(container) {
    var listUrl = "".concat(container.get(PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_LIST_URL));
    var listAllUrl = "".concat(container.get(PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_LIST_ALL_URL));
    var deleteUrl = "".concat(container.get(PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_REMOVE_URL));
    var updateUrl = "".concat(container.get(PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_EDIT_URL));
    var createUrl = "".concat(container.get(PARAM_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE_CREATE_URL));
    var singleUrl = "".concat(container.get(PARAM_MOI_CHAT_MESSAGE_TEMPLATE_SINGLE_URL));
    var sendUrl = "".concat(container.get(PARAM_MOI_CHAT_MESSAGE_TEMPLATE_SEND_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    return new EmailMessageTemplateService({
        urls: {
            singleUrl: singleUrl,
            listUrl: listUrl,
            updateUrl: updateUrl,
            createUrl: createUrl,
            deleteUrl: deleteUrl,
        },
        sendUrl: sendUrl,
        listAllUrl: listAllUrl,
        itemsPerPage: itemsPerPage,
    });
}
