var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { ObservationCodeKeys } from '../../observation/enum/observation-code-keys';
export function prepareValueFields(observation) {
    var e_1, _a;
    var _b, _c;
    switch ((_c = (_b = observation.code) === null || _b === void 0 ? void 0 : _b.coding) === null || _c === void 0 ? void 0 : _c[0].code) {
        case ObservationCodeKeys.beard:
        case ObservationCodeKeys.mustache:
        case ObservationCodeKeys['57722-1']:
        case ObservationCodeKeys['82810-3']:
            {
                // value_boolean
                delete observation.value_quantity;
                delete observation.value_codeable_concept;
                delete observation.value_string;
            }
            break;
        case ObservationCodeKeys.stature:
        case ObservationCodeKeys.eye_colour:
        case ObservationCodeKeys.hair_color:
        case ObservationCodeKeys.hair_length:
        case ObservationCodeKeys.sex:
        case ObservationCodeKeys['75859-9']:
            {
                // value_codeable_concept
                delete observation.value_quantity;
                delete observation.value_boolean;
                delete observation.value_string;
            }
            break;
        case ObservationCodeKeys['29463-7']:
        case ObservationCodeKeys['8302-2']:
        case ObservationCodeKeys.APGAR_1:
        case ObservationCodeKeys.APGAR_5:
        case ObservationCodeKeys['11884-4']:
        case ObservationCodeKeys['8339-4']:
        case ObservationCodeKeys['73773-4']:
        case ObservationCodeKeys['73771-8']:
        case ObservationCodeKeys['11638-4']:
        case ObservationCodeKeys['68496-9']:
        case ObservationCodeKeys.weight_under_1_year:
            {
                // value_quantity
                delete observation.value_codeable_concept;
                delete observation.value_boolean;
                delete observation.value_string;
            }
            break;
        default: {
            // value_string
            delete observation.value_boolean;
            delete observation.value_quantity;
            delete observation.value_codeable_concept;
        }
    }
    delete observation.value_sampled_data;
    delete observation.value_range;
    delete observation.value_ratio;
    delete observation.value_time;
    delete observation.value_date_time;
    delete observation.value_period;
    numberDataType(observation);
    try {
        for (var _d = __values(observation.components || []), _e = _d.next(); !_e.done; _e = _d.next()) {
            var obs = _e.value;
            numberDataType(obs);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return observation;
}
export function numberDataType(obj) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23;
    // @ts-ignore
    (_a = obj === null || obj === void 0 ? void 0 : obj.value_quantity) === null || _a === void 0 ? void 0 : _a.value = +(((_b = obj === null || obj === void 0 ? void 0 : obj.value_quantity) === null || _b === void 0 ? void 0 : _b.value) || 0);
    // @ts-ignore
    (_c = obj === null || obj === void 0 ? void 0 : obj.value_sampled_data) === null || _c === void 0 ? void 0 : _c.origin = +(((_d = obj === null || obj === void 0 ? void 0 : obj.value_sampled_data) === null || _d === void 0 ? void 0 : _d.origin) || 0);
    // @ts-ignore
    (_e = obj === null || obj === void 0 ? void 0 : obj.value_sampled_data) === null || _e === void 0 ? void 0 : _e.period = +(((_f = obj === null || obj === void 0 ? void 0 : obj.value_sampled_data) === null || _f === void 0 ? void 0 : _f.period) || 0);
    // @ts-ignore
    (_g = obj === null || obj === void 0 ? void 0 : obj.value_sampled_data) === null || _g === void 0 ? void 0 : _g.factor = +(((_h = obj === null || obj === void 0 ? void 0 : obj.value_sampled_data) === null || _h === void 0 ? void 0 : _h.factor) || 0);
    // @ts-ignore
    (_j = obj === null || obj === void 0 ? void 0 : obj.value_sampled_data) === null || _j === void 0 ? void 0 : _j.lower_limit = +(((_k = obj === null || obj === void 0 ? void 0 : obj.value_sampled_data) === null || _k === void 0 ? void 0 : _k.lower_limit) || 0);
    // @ts-ignore
    (_l = obj === null || obj === void 0 ? void 0 : obj.value_sampled_data) === null || _l === void 0 ? void 0 : _l.upper_limit = +(((_m = obj === null || obj === void 0 ? void 0 : obj.value_sampled_data) === null || _m === void 0 ? void 0 : _m.upper_limit) || 0);
    // @ts-ignore
    (_p = (_o = obj === null || obj === void 0 ? void 0 : obj.value_range) === null || _o === void 0 ? void 0 : _o.high) === null || _p === void 0 ? void 0 : _p.value = +(((_r = (_q = obj === null || obj === void 0 ? void 0 : obj.value_range) === null || _q === void 0 ? void 0 : _q.high) === null || _r === void 0 ? void 0 : _r.value) || 0);
    // @ts-ignore
    (_t = (_s = obj === null || obj === void 0 ? void 0 : obj.value_range) === null || _s === void 0 ? void 0 : _s.low) === null || _t === void 0 ? void 0 : _t.value = +(((_v = (_u = obj === null || obj === void 0 ? void 0 : obj.value_range) === null || _u === void 0 ? void 0 : _u.low) === null || _v === void 0 ? void 0 : _v.value) || 0);
    // @ts-ignore
    (_x = (_w = obj === null || obj === void 0 ? void 0 : obj.value_ratio) === null || _w === void 0 ? void 0 : _w.numerator) === null || _x === void 0 ? void 0 : _x.value = +(((_z = (_y = obj === null || obj === void 0 ? void 0 : obj.value_ratio) === null || _y === void 0 ? void 0 : _y.numerator) === null || _z === void 0 ? void 0 : _z.value) || 0);
    // @ts-ignore
    (_1 = (_0 = obj === null || obj === void 0 ? void 0 : obj.value_ratio) === null || _0 === void 0 ? void 0 : _0.denominator) === null || _1 === void 0 ? void 0 : _1.value = +(((_3 = (_2 = obj === null || obj === void 0 ? void 0 : obj.value_ratio) === null || _2 === void 0 ? void 0 : _2.denominator) === null || _3 === void 0 ? void 0 : _3.value) || 0);
    // @ts-ignore
    (_5 = (_4 = obj === null || obj === void 0 ? void 0 : obj.reference_ranges) === null || _4 === void 0 ? void 0 : _4.high) === null || _5 === void 0 ? void 0 : _5.value = +(((_7 = (_6 = obj === null || obj === void 0 ? void 0 : obj.reference_ranges) === null || _6 === void 0 ? void 0 : _6.high) === null || _7 === void 0 ? void 0 : _7.value) || 0);
    // @ts-ignore
    (_9 = (_8 = obj === null || obj === void 0 ? void 0 : obj.reference_ranges) === null || _8 === void 0 ? void 0 : _8.low) === null || _9 === void 0 ? void 0 : _9.value = +(((_11 = (_10 = obj === null || obj === void 0 ? void 0 : obj.reference_ranges) === null || _10 === void 0 ? void 0 : _10.low) === null || _11 === void 0 ? void 0 : _11.value) || 0);
    // @ts-ignore
    (_14 = (_13 = (_12 = obj === null || obj === void 0 ? void 0 : obj.reference_ranges) === null || _12 === void 0 ? void 0 : _12.age) === null || _13 === void 0 ? void 0 : _13.high) === null || _14 === void 0 ? void 0 : _14.value = +(((_17 = (_16 = (_15 = obj === null || obj === void 0 ? void 0 : obj.reference_ranges) === null || _15 === void 0 ? void 0 : _15.age) === null || _16 === void 0 ? void 0 : _16.high) === null || _17 === void 0 ? void 0 : _17.value) || 0);
    // @ts-ignore
    (_20 = (_19 = (_18 = obj === null || obj === void 0 ? void 0 : obj.reference_ranges) === null || _18 === void 0 ? void 0 : _18.age) === null || _19 === void 0 ? void 0 : _19.low) === null || _20 === void 0 ? void 0 : _20.value = +(((_23 = (_22 = (_21 = obj === null || obj === void 0 ? void 0 : obj.reference_ranges) === null || _21 === void 0 ? void 0 : _21.age) === null || _22 === void 0 ? void 0 : _22.low) === null || _23 === void 0 ? void 0 : _23.value) || 0);
}
