var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { useStyles } from './SideBarMenuStyle';
import { Box, Drawer, Stack } from '@mui/material';
import { Logo } from '../../../../../app/moi/shared/SideBarMenu/components/Logo';
import List from '@mui/material/List';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MessageMenu } from './message/MessageMenu';
import { MenuItemDesktop } from './MenuItem';
import { PARAM_USER_ROLES } from '../../../../../config/di/params/keys';
import { useContainer } from '../../../../service/di-container/react/useContainer';
import { UserRoles } from '../../../../../app/moi/service/user/enum/user-roles';
var secondaryList = [
    {
        link: 'settings',
        icon: 'settingsMenu',
        target: 'menu.profile.settings',
        title: 'menu.profile.settings',
    },
    {
        link: 'security_logout',
        icon: 'exitMenu',
        target: 'menu.main.logout',
        title: 'menu.main.logout',
    },
];
export var SideBarMenuDesktop = function (props) {
    var classes = useStyles();
    var t = useTranslation().t;
    var container = useContainer();
    var userRoles = (container.get(PARAM_USER_ROLES) || []);
    var isExpert = userRoles.includes(UserRoles.ROLE_EXPERT) ||
        userRoles.includes(UserRoles.ROLE_CONSULTANT) ||
        userRoles.includes(UserRoles.ROLE_MANAGER);
    var _a = __read(useState(true), 2), isVisibleMessage = _a[0], setIsVisibleMessage = _a[1];
    var onCloseVisibleMessage = function () { return setIsVisibleMessage(false); };
    var pathname = useLocation().pathname;
    var MainListItems = (React.createElement(List, { className: classes.topMenuItemsWrapper }, props.items.map(function (item, index) {
        var _a;
        var isActive = pathname.includes(item.link);
        var subMenuItems = props.subMenusItems
            ? (_a = props.subMenusItems.find(function (i) { return i.parentMenuItem.link === item.link; })) === null || _a === void 0 ? void 0 : _a.subMenuItems
            : undefined;
        // console.log('props.items ', props.items);
        // console.log('subMenuItems', subMenuItems);
        return (React.createElement(MenuItemDesktop, { key: index, isActive: isActive, title: item.title, icon: item.icon, link: item.link, subMenuItems: subMenuItems }));
    })));
    var SecondaryListItems = (React.createElement(List, { className: classes.bottomMenuItemsWrapper }, secondaryList.map(function (item, index) {
        var isActive = item.link === pathname;
        return (React.createElement(MenuItemDesktop, { key: index, isActive: isActive, title: t(item.title || ''), icon: item.icon, link: item.link }));
    })));
    if (!props.items.length) {
        return null;
    }
    return (React.createElement(Drawer, { variant: "permanent", sx: {
            width: '240px',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: '240px',
                boxSizing: 'border-box',
                overflow: 'hidden',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    width: '0px',
                },
            },
        }, anchor: "left" },
        React.createElement(Stack, { role: "presentation", sx: { height: '100%' } },
            React.createElement(Stack, { flexGrow: 1 },
                React.createElement(Box, { className: classes.LogoWrapper },
                    React.createElement(Logo, { fill: isExpert ? '#07D9F3' : '#29F499' })),
                MainListItems),
            isVisibleMessage && React.createElement(MessageMenu, { onCloseVisibleMessage: onCloseVisibleMessage }),
            SecondaryListItems)));
};
