import { RecordsCategoriesActionType } from './action';
export var fetchGetRecordsCategoriesBegin = function () { return ({
    type: RecordsCategoriesActionType.GET_RECORDS_CATEGORIES_PENDING,
}); };
export var fetchGetRecordsCategoriesSuccess = function (categories) { return ({
    type: RecordsCategoriesActionType.GET_RECORDS_CATEGORIES_SUCCESS,
    payload: categories,
}); };
export var fetchGetRecordsCategoriesFailure = function (error) { return ({
    type: RecordsCategoriesActionType.GET_RECORDS_CATEGORIES_ERROR,
    payload: { error: error },
}); };
