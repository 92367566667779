import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
import { PARAM_EHEALTH_GET_STORED_PREPERSON_LIST_URL, PARAM_EHEALTH_PROXY_URL } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
import { PrepersonService } from './preperson';
import { SERVICE_EHEALTH_AUTH } from '../../auth';
export var SERVICE_EHEALTH_PREPERSON = 'SERVICE_EHEALTH_PREPERSON';
export var ehealthPrepersonFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var authService = container.require(SERVICE_EHEALTH_AUTH);
    var getStoredPrepersonListUrl = "".concat(container.get(PARAM_EHEALTH_GET_STORED_PREPERSON_LIST_URL));
    return new PrepersonService({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        authService: authService,
        getStoredPrepersonListUrl: getStoredPrepersonListUrl,
    });
};
