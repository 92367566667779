var _a;
import { EmployeeType } from '../enum/employee.type';
export var EMPLOYEE_TYPE_TO_SPECIALITY_LEVEL = (_a = {},
    _a[EmployeeType.DOCTOR] = {
        BASIC: true,
        SECOND: true,
        FIRST: true,
        HIGHEST: true,
        NOT_APPLICABLE: true,
    },
    _a[EmployeeType.SPECIALIST] = {
        SECOND: true,
        FIRST: true,
        HIGHEST: true,
        BASIC: true,
        NOT_APPLICABLE: true,
    },
    _a[EmployeeType.ASSISTANT] = {
        SECOND: true,
        FIRST: true,
        HIGHEST: true,
        BASIC: true,
        NOT_APPLICABLE: true,
    },
    _a[EmployeeType.PHARMASIST] = {
        SECOND: true,
        FIRST: true,
        HIGHEST: true,
        NOT_APPLICABLE: true,
    },
    _a[EmployeeType.MED_COORDINATOR] = {
        SECOND: true,
        FIRST: true,
        HIGHEST: true,
        NOT_APPLICABLE: true,
    },
    _a[EmployeeType.LABORANT] = {
        FIRST: true,
        SECOND: true,
        SPECIALIST: true,
        HIGHEST: true,
        NOT_APPLICABLE: true,
    },
    _a);
