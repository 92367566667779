var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { defaultState } from './state';
import { ChoiceListAction } from './enum/action';
export function choiceListReducer(state, action) {
    var _a, _b, e_1, _c, _d;
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case ChoiceListAction.SET_LIST:
            return __assign(__assign({}, state), (_a = {}, _a["".concat(action.payload.key)] = action.payload.choices, _a));
        case ChoiceListAction.ADD_TO_LIST:
            return __assign(__assign({}, state), (_b = {}, _b["".concat(action.payload.key)] = __assign(__assign({}, (state["".concat(action.payload.key)] || {})), action.payload.choices), _b));
        case ChoiceListAction.REMOVE_ITEMS:
            var key = "".concat(action.payload.key);
            try {
                for (var _e = __values(action.payload.ids), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var id = _f.value;
                    delete state[key][id];
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_c = _e.return)) _c.call(_e);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return __assign(__assign({}, state), (_d = {}, _d[key] = __assign({}, state[key]), _d));
    }
    return state;
}
