export var UserRoles;
(function (UserRoles) {
    UserRoles["ROLE_CONTENT_MANAGER"] = "ROLE_CONTENT_MANAGER";
    UserRoles["ROLE_ADMIN_MANAGER"] = "ROLE_ADMIN_MANAGER";
    UserRoles["ROLE_FINANCIAL_MANAGER"] = "ROLE_FINANCIAL_MANAGER";
    UserRoles["ROLE_SUPER_ADMIN"] = "ROLE_SUPER_ADMIN";
    UserRoles["ROLE_SEO"] = "ROLE_SEO";
    UserRoles["ROLE_EDITOR"] = "ROLE_EDITOR";
    UserRoles["ROLE_USER"] = "ROLE_USER";
    UserRoles["ROLE_CLIENT"] = "ROLE_CLIENT";
    UserRoles["ROLE_EHEALTH_MEMBER"] = "ROLE_EHEALTH_MEMBER";
    UserRoles["ROLE_PRE_EHEALTH_MEMBER"] = "ROLE_PRE_EHEALTH_MEMBER";
    UserRoles["ROLE_EXPERT"] = "ROLE_EXPERT";
    UserRoles["ROLE_MANAGER"] = "ROLE_MANAGER";
    UserRoles["ROLE_CONSULTANT"] = "ROLE_CONSULTANT";
    UserRoles["ROLE_ADMIN"] = "ROLE_ADMIN";
    UserRoles["ROLE_INSURER"] = "ROLE_INSURER";
    UserRoles["ROLE_PRE_USER"] = "ROLE_PRE_USER";
    UserRoles["ROLE_PRE_EXPERT"] = "ROLE_PRE_EXPERT";
    UserRoles["ROLE_PRE_CONSULTANT"] = "ROLE_PRE_CONSULTANT";
    UserRoles["ROLE_SEARCH_EXPERT"] = "ROLE_SEARCH_EXPERT";
    UserRoles["ROLE_SEARCH_MANAGER"] = "ROLE_SEARCH_MANAGER";
    UserRoles["ROLE_SEARCH_FAMILY_DOCTOR"] = "ROLE_SEARCH_FAMILY_DOCTOR";
    UserRoles["ROLE_SEARCH_INSURER"] = "ROLE_SEARCH_INSURER";
    UserRoles["OWNER"] = "OWNER";
    UserRoles["ROLE_COMPANY_ADMIN"] = "ROLE_COMPANY_ADMIN";
})(UserRoles || (UserRoles = {}));
