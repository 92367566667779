import { PARAM_ITEMS_PER_PAGE } from '../../../../config/di/params/keys';
import { PARAM_MOI_COMPANY_SERVICES_URL } from '../../config/di/params/keys';
import { Service } from './service';
export var SERVICE_MOI_COMPANY_SERVICE = 'SERVICE_MOI_COMPANY_SERVICE';
export function companyServiceFactory(container) {
    var companyServicesUrl = "".concat(container.get(PARAM_MOI_COMPANY_SERVICES_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    return new Service({
        companyServicesUrl: companyServicesUrl,
        itemsPerPage: itemsPerPage,
    });
}
