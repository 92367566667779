import { PARAM_ITEMS_PER_PAGE } from '../../../../config/di/params/keys';
import { PARAM_MOI_GET_USER_DATA_FOR_REFERENCE_URL, PARAM_MOI_GET_REFERENCES_URL, PARAM_MOI_REFERENCE_DELETE_URL, PARAM_MOI_REFERENCE_CREATE_URL, } from '../../config/di/params/keys';
import { Reference } from './reference';
export var SERVICE_MOI_REFERENCE = 'SERVICE_MOI_REFERENCE';
export function referenceFactory(container) {
    var getReferencesUrl = "".concat(container.get(PARAM_MOI_GET_REFERENCES_URL));
    var getReferenceDeleteUrl = "".concat(container.get(PARAM_MOI_REFERENCE_DELETE_URL));
    var getUserDataForReferenceUrl = "".concat(container.get(PARAM_MOI_GET_USER_DATA_FOR_REFERENCE_URL));
    var referenceCreateUrl = "".concat(container.get(PARAM_MOI_REFERENCE_CREATE_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    return new Reference({
        getReferencesUrl: getReferencesUrl,
        getReferenceDeleteUrl: getReferenceDeleteUrl,
        getUserDataForReferenceUrl: getUserDataForReferenceUrl,
        referenceCreateUrl: referenceCreateUrl,
        itemsPerPage: itemsPerPage,
    });
}
