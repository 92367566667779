export var EhealthMECarePlanAction;
(function (EhealthMECarePlanAction) {
    EhealthMECarePlanAction["ADD_ITEMS"] = "EHEALTH_ME_CARE_PLAN_ADD_ITEMS";
    EhealthMECarePlanAction["CLEAR"] = "EHEALTH_ME_CARE_PLAN_CLEAR";
    EhealthMECarePlanAction["UPDATE"] = "EHEALTH_ME_CARE_PLAN_UPDATE";
    EhealthMECarePlanAction["SET_IS_LOADING"] = "EHEALTH_ME_CARE_PLAN_SET_IS_LOADING";
    EhealthMECarePlanAction["SETUP_JOBS"] = "EHEALTH_ME_CARE_PLAN_SETUP_JOBS";
    EhealthMECarePlanAction["REMOVE_JOB"] = "EHEALTH_ME_CARE_PLAN_REMOVE_JOB";
    EhealthMECarePlanAction["SET_FILTER"] = "EHEALTH_ME_CARE_PLAN_SET_FILTER";
})(EhealthMECarePlanAction || (EhealthMECarePlanAction = {}));
