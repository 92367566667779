var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EhealthError } from '../../app/ehealth/enum/ehealth-error';
var SignError = /** @class */ (function (_super) {
    __extends(SignError, _super);
    function SignError(exception) {
        var _this = _super.call(this, EhealthError.SIGN) || this;
        _this.exception = exception;
        return _this;
    }
    Object.defineProperty(SignError.prototype, "message", {
        get: function () {
            return this.exception.getMessage();
        },
        enumerable: false,
        configurable: true
    });
    return SignError;
}(Error));
export { SignError };
