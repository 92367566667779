var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ChatMessageTemplateSendAction } from './enum/action';
import { defaultState } from './state';
export function messageTemplateSendReducer(state, action) {
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case ChatMessageTemplateSendAction.ADD_ITEMS:
            return __assign(__assign({}, state), { items: new Map(__spreadArray(__spreadArray([], __read(action.payload.items), false), __read(state.items), false)), totalCount: action.payload.totalCount, page: action.payload.page, totalPages: action.payload.totalPages });
        case ChatMessageTemplateSendAction.ADD:
            var items = new Map();
            items.set(action.payload.item.id, action.payload.item);
            return __assign(__assign({}, state), { items: new Map(__spreadArray(__spreadArray([], __read(items), false), __read(state.items), false)), totalCount: state.totalCount + 1 });
    }
    return state;
}
