import { combineReducers } from 'redux';
import { episodeReducer } from './episode/reducer';
import { encounterReducer } from './encounter/reducer';
import { conditionReducer } from './condition/reducer';
import { allergyIntoleranceReducer } from './allergy-intolerance/reducer';
import { deviceReducer } from './device/reducer';
import { diagnosticReportReducer } from './diagnostic-report/reducer';
import { immunizationReducer } from './immunization/reducer';
import { medicationAdministrationReducer } from './medication-administration/reducer';
import { medicationStatementReducer } from './medication-statement/reducer';
import { procedureReducer } from './procedure/reducer';
import { riskAssessmentReducer } from './risk-assessment/reducer';
import { observationReducer } from './observation/reducer';
import { approvalReducer } from './approval/reducer';
import { carePlanReducer } from './care-plan/reducer';
import { carePlanActivityReducer } from './care-plan-activity/reducer';
export var medicalEventReducer = combineReducers({
    episode: episodeReducer,
    encounter: encounterReducer,
    condition: conditionReducer,
    allergy_intolerance: allergyIntoleranceReducer,
    device: deviceReducer,
    diagnostic_report: diagnosticReportReducer,
    immunization: immunizationReducer,
    medication_administration: medicationAdministrationReducer,
    medication_statement: medicationStatementReducer,
    procedure: procedureReducer,
    risk_assessment: riskAssessmentReducer,
    observation: observationReducer,
    approval: approvalReducer,
    care_plan: carePlanReducer,
    carePlanActivity: carePlanActivityReducer,
});
