var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ResponseError } from '../../../../../../shared/error/response-error';
import { clearAndSerialize } from '../../../../../../shared/util/normalizer';
import { PayloadStoreType } from '../../medical-events/enum/payload-store-type';
import { clearFilter } from '../utill';
import moment from 'moment';
import { MedicationDeviceRequestStatus } from './enum/medication-request-status';
var MedicationDeviceRequestService = /** @class */ (function () {
    function MedicationDeviceRequestService(params) {
        this.baseUrl = params.baseUrl;
        this.storageUrl = params.storageUrl;
        this.itemsPerPage = params.itemsPerPage;
        this.requestService = params.requestService;
        this.signService = params.signService;
        this.payloadFindStoredUrl = params.payloadFindStoredUrl;
        this.localize = params.localizeService;
    }
    MedicationDeviceRequestService.prototype.getList = function (personId, page, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var args, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        args = __assign({ page: "".concat(page), page_size: "".concat(this.itemsPerPage) }, clearFilter(filter));
                        return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(personId, "/device_requests"), args)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    MedicationDeviceRequestService.prototype.getExpired = function (personId, page, employeeId) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var createdFrom, createdTo, singleListMaxItems, _e, activeMrs, expiredMrs;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        createdFrom = moment()
                            .set({
                            dayOfYear: 1,
                            month: 0,
                        })
                            .format(this.localize.getDateValueFormat());
                        createdTo = moment()
                            .subtract({
                            days: 30,
                        })
                            .format(this.localize.getDateValueFormat());
                        singleListMaxItems = Math.floor(this.itemsPerPage / 2);
                        return [4 /*yield*/, Promise.all([
                                this.getList(personId, page, {
                                    // @ts-ignore
                                    page_size: "".concat(singleListMaxItems),
                                    status: MedicationDeviceRequestStatus.ACTIVE,
                                    created_from: createdFrom,
                                    created_to: createdTo,
                                }),
                                this.getList(personId, page, {
                                    // @ts-ignore
                                    page_size: "".concat(singleListMaxItems),
                                    status: MedicationDeviceRequestStatus.EXPIRED,
                                    created_from: createdFrom,
                                    created_to: createdTo,
                                }),
                            ])];
                    case 1:
                        _e = __read.apply(void 0, [_f.sent(), 2]), activeMrs = _e[0], expiredMrs = _e[1];
                        return [2 /*return*/, {
                                data: __spreadArray(__spreadArray([], __read(activeMrs.data), false), __read(expiredMrs.data), false),
                                paging: {
                                    page_number: page,
                                    total_entries: ((_a = activeMrs.paging) === null || _a === void 0 ? void 0 : _a.total_entries) + ((_b = expiredMrs.paging) === null || _b === void 0 ? void 0 : _b.total_entries),
                                    total_pages: ((_c = activeMrs.paging) === null || _c === void 0 ? void 0 : _c.total_pages) + ((_d = expiredMrs.paging) === null || _d === void 0 ? void 0 : _d.total_pages),
                                },
                            }];
                }
            });
        });
    };
    MedicationDeviceRequestService.prototype.getListInCarePlanContext = function (personId, page, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var carePlanId, args, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        carePlanId = filter === null || filter === void 0 ? void 0 : filter.care_plan_id;
                        args = __assign(__assign({}, clearFilter(filter)), { page: "".concat(page), person_id: "".concat(personId), page_size: "".concat(this.itemsPerPage) });
                        return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/care_plans/").concat(carePlanId, "/medication_requests"), args)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    MedicationDeviceRequestService.prototype.getSingle = function (patientId, medicationRequestId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/persons/").concat(patientId, "/medication_requests/").concat(medicationRequestId))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __assign({}, json.data)];
                }
            });
        });
    };
    MedicationDeviceRequestService.prototype.getStoredSigned = function (medicationRequestId) {
        return __awaiter(this, void 0, void 0, function () {
            var query, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = new URLSearchParams({
                            id: medicationRequestId,
                            type: PayloadStoreType.SIGNED_MEDICATION_REQUEST,
                        });
                        return [4 /*yield*/, fetch("".concat(this.payloadFindStoredUrl, "?").concat(query), {
                                credentials: 'include',
                            })];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __assign({}, json)];
                }
            });
        });
    };
    MedicationDeviceRequestService.prototype.qualify = function (id, query) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/medication_requests/").concat(id, "/actions/qualify"), undefined, clearAndSerialize(query))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __spreadArray([], __read(json.data), false)];
                }
            });
        });
    };
    MedicationDeviceRequestService.prototype.reject = function (id, signParams, data) {
        return __awaiter(this, void 0, void 0, function () {
            var signedData, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        return [4 /*yield*/, this.signService.sign(JSON.stringify(data), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/medication_requests/").concat(id, "/actions/reject"), undefined, JSON.stringify({
                                signed_medication_reject: signedData,
                                signed_content_encoding: 'base64',
                            }))];
                    case 4:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 5:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __assign({}, json.data)];
                }
            });
        });
    };
    MedicationDeviceRequestService.prototype.resend = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/medication_requests/").concat(id, "/actions/resend"))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __assign({}, json.data)];
                }
            });
        });
    };
    return MedicationDeviceRequestService;
}());
export { MedicationDeviceRequestService };
