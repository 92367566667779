var _a;
import { EmployeeType } from '../enum/employee.type';
export var EMPLOYEE_TYPE_TO_SPECIALITY_TYPE = (_a = {},
    _a[EmployeeType.DOCTOR] = {
        FAMILY_DOCTOR: true,
        PEDIATRICIAN: true,
        THERAPIST: true,
    },
    _a[EmployeeType.SPECIALIST] = {
        PHYSICAL_THERAPEUTICS: true,
        ERGOTHERAPEUTICS: true,
        IMMUNOLOGY: true,
        INFECTIOUS_DISEASES: true,
        CARDIOLOGY: true,
        CLINICAL_BIOCHEMISTRY: true,
        CLINICAL_IMMUNOLOGY: true,
        CLINICAL_DIAGNOSTIC: true,
        COMBUSTIOLOGY: true,
        COMMUNAL_HYGIENE: true,
        LABORATORY_IMMUNOLOGY: true,
        LABORATORY_RESEARCH_OF_ENVIRONMENTAL_FACTORS: true,
        LABORATORY_RESEARCH_OF_ENVIRONMENT_PHYSICAL_FACTORS: true,
        LABORATORY_RESEARCH_OF_ENVIRONMENT_CHEMICAL_FACTORS: true,
        PHYSICAL_THERAPY: true,
        PHYSICAL_THERAPY_AND_SPORTS_MEDICINE: true,
        EMERGENCY_MEDICINE: true,
        MEDICAL_PSYCHOLOGY: true,
        MICROBIOLOGY: true,
        NARCOLOGY: true,
        TRADITIONAL_ALTERNATIVE_MEDICINE: true,
        NEUROLOGY: true,
        NEUROSURGERY: true,
        NEONATOLOGY: true,
        NEPHROLOGY: true,
        GYNECOLOGIC_ONCOLOGY: true,
        ONCOLOGY: true,
        ONCOTOLARYNGOLOGY: true,
        SURGICAL_ONCOLOGY: true,
        PUBLIC_HEALTH_ORGANIZATION: true,
        ORTHODONTOLOGY: true,
        ORTHOPEDIC_DENTISTRY: true,
        ORTHOPAEDICS: true,
        OTORHINOLARYNGOLOGY: true,
        OPHTHALMOLOGY: true,
        PARASITOLOGY: true,
        PATHOLOGIC_ANATOMY: true,
        PEDIATRICIAN: true,
        ADOLESCENT_MEDICINE: true,
        PROCTOLOGY: true,
        RADIATION_THERAPY: true,
        OCCUPATIONAL_PATHOLOGY: true,
        PSYCHIATRY: true,
        PSYCHOTHERAPY: true,
        PSYCHOPHYSIOLOGY: true,
        PULMONOLOGY: true,
        RADIATION_HYGIENE: true,
        RADIOLOGY: true,
        RADIOLOGIC_DIAGNOSIS: true,
        RHEUMATOLOGY: true,
        X_RAY_RADIOLOGY: true,
        REFLEXOLOGY: true,
        SANOLOGY: true,
        SEXOPATHOLOGY: true,
        SPORTS_MEDICINE: true,
        STOMATOLOGY: true,
        VASCULAR_SURGERY: true,
        FORENSIC_MEDICINE: true,
        FORENSIC_MEDICAL_HISTOLOGY: true,
        FORENSIC_MEDICAL_EXAMINATION: true,
        FORENSIC_IMMUNOLOGY: true,
        FORENSIC_CRIMINOLOGY: true,
        FORENSIC_MEDICAL_TOXICOLOGY: true,
        FORENSIC_CYTOLOGY: true,
        FORENSIC_PSYCHIATRIC_EXAMINATION: true,
        AUDIOLOGY: true,
        THERAPEUTIC_DENTISTRY: true,
        THERAPIST: true,
        TOXICOLOGY: true,
        THORACIC_SURGERY: true,
        TRANSPLANTOLOGY: true,
        TRANSFUSIOLOGY: true,
        ULTRASONIC_DIAGNOSIS: true,
        UROLOGY: true,
        PHYSIOTHERAPY: true,
        PHYSICAL_MEDICINE_AND_REHABILITATION: true,
        PHTHISIOLOGY: true,
        FUNCTIONAL_DIAGNOSTICS: true,
        SURGICAL_DENTISTRY: true,
        GENERAL_SURGERY: true,
        CARDIOVASCULAR_SURGERY: true,
        AEROSPACE_MEDICINE: true,
        OBSTETRICS_AND_GYNECOLOGY: true,
        ALLERGOLOGY: true,
        ANAESTHETICS: true,
        BACTERIOLOGY: true,
        VIROLOGY: true,
        GASTROENTEROLOGY: true,
        GENERAL_HEMATOLOGY: true,
        LABORATORY_GENETICS: true,
        MEDICAL_GENETICS: true,
        GERIATRICS: true,
        PEDIATRIC_HYGIENE: true,
        OCCUPATIONAL_MEDICINE: true,
        FOOD_HYGIENE: true,
        DISINFECTION_: true,
        'DERMATO-VENEREOLOGY': true,
        PEDIATRIC_ALLERGY: true,
        PEDIATRIC_ANAESTHETICS: true,
        PEDIATRIC_GASTROENTEROLOGY: true,
        PEDIATRIC_HEMATOLOGY: true,
        PEDIATRIC_GYNECOLOGY: true,
        'PEDIATRIC_DERMATO-VENEREOLOGY': true,
        PEDIATRIC_ENDOCRINOLOGY: true,
        PEDIATRIC_IMMUNOLOGY: true,
        PEDIATRIC_CARDIOLOGY: true,
        PEDIATRIC_NEUROLOGY: true,
        PEDIATRIC_NEPHROLOGY: true,
        PEDIATRIC_ONCOLOGY: true,
        PEDIATRIC_ORTHOPAEDICS: true,
        PEDIATRIC_OTOLARYNGOLOGY: true,
        PEDIATRIC_OPHTHALMOLOGY: true,
        PEDIATRIC_PATHOLOGY: true,
        PEDIATRIC_PSYCHIATRY: true,
        PEDIATRIC_PULMONOLOGY: true,
        PEDIATRIC_STOMATOLOGY: true,
        PEDIATRIC_UROLOGY: true,
        PEDIATRIC_PHTHISIOLOGY: true,
        PEDIATRIC_SURGERY: true,
        PEDIATRIC_INFECTIOUS_DISEASE: true,
        DIETETICS: true,
        ENDOCRINOLOGY: true,
        ENDOSCOPY: true,
        EPIDEMIOLOGY: true,
        COMMON_HYGIENE: true,
        PEDIATRIC_HEMATOLOGY_AND_ONCOLOGY: true,
        INVASIVE_ELECTROPHYSIOLOGY: true,
        INTERVENTIONAL_CARDIOLOGY: true,
        PEDIATRIC_NEUROLOGICAL_SURGERY: true,
        PERIODONTOLOGY: true,
        PLASTIC_SURGERY: true,
        ORAL_AND_MAXILLOFACIAL_SURGERY: true,
        CHILD_CARDIOLOGY: true,
        PEDIATRIC_RHEUMATOLOGY: true,
    },
    _a[EmployeeType.MED_COORDINATOR] = {
        PHYSICAL_THERAPEUTICS: true,
        ERGOTHERAPEUTICS: true,
        IMMUNOLOGY: true,
        INFECTIOUS_DISEASES: true,
        CARDIOLOGY: true,
        CLINICAL_BIOCHEMISTRY: true,
        CLINICAL_IMMUNOLOGY: true,
        CLINICAL_DIAGNOSTIC: true,
        COMBUSTIOLOGY: true,
        COMMUNAL_HYGIENE: true,
        LABORATORY_IMMUNOLOGY: true,
        LABORATORY_RESEARCH_OF_ENVIRONMENTAL_FACTORS: true,
        LABORATORY_RESEARCH_OF_ENVIRONMENT_PHYSICAL_FACTORS: true,
        LABORATORY_RESEARCH_OF_ENVIRONMENT_CHEMICAL_FACTORS: true,
        PHYSICAL_THERAPY: true,
        PHYSICAL_THERAPY_AND_SPORTS_MEDICINE: true,
        EMERGENCY_MEDICINE: true,
        MEDICAL_PSYCHOLOGY: true,
        MICROBIOLOGY: true,
        NARCOLOGY: true,
        TRADITIONAL_ALTERNATIVE_MEDICINE: true,
        NEUROLOGY: true,
        NEUROSURGERY: true,
        NEONATOLOGY: true,
        NEPHROLOGY: true,
        GYNECOLOGIC_ONCOLOGY: true,
        ONCOLOGY: true,
        ONCOTOLARYNGOLOGY: true,
        SURGICAL_ONCOLOGY: true,
        PUBLIC_HEALTH_ORGANIZATION: true,
        ORTHODONTOLOGY: true,
        ORTHOPEDIC_DENTISTRY: true,
        ORTHOPAEDICS: true,
        OTORHINOLARYNGOLOGY: true,
        OPHTHALMOLOGY: true,
        PARASITOLOGY: true,
        PATHOLOGIC_ANATOMY: true,
        PEDIATRICIAN: true,
        ADOLESCENT_MEDICINE: true,
        PROCTOLOGY: true,
        RADIATION_THERAPY: true,
        OCCUPATIONAL_PATHOLOGY: true,
        PSYCHIATRY: true,
        PSYCHOTHERAPY: true,
        PSYCHOPHYSIOLOGY: true,
        PULMONOLOGY: true,
        RADIATION_HYGIENE: true,
        RADIOLOGY: true,
        RADIOLOGIC_DIAGNOSIS: true,
        RHEUMATOLOGY: true,
        X_RAY_RADIOLOGY: true,
        REFLEXOLOGY: true,
        SANOLOGY: true,
        SEXOPATHOLOGY: true,
        SPORTS_MEDICINE: true,
        STOMATOLOGY: true,
        VASCULAR_SURGERY: true,
        FORENSIC_MEDICINE: true,
        FORENSIC_MEDICAL_HISTOLOGY: true,
        FORENSIC_MEDICAL_EXAMINATION: true,
        FORENSIC_IMMUNOLOGY: true,
        FORENSIC_CRIMINOLOGY: true,
        FORENSIC_MEDICAL_TOXICOLOGY: true,
        FORENSIC_CYTOLOGY: true,
        FORENSIC_PSYCHIATRIC_EXAMINATION: true,
        AUDIOLOGY: true,
        THERAPEUTIC_DENTISTRY: true,
        THERAPIST: true,
        TOXICOLOGY: true,
        THORACIC_SURGERY: true,
        TRANSPLANTOLOGY: true,
        TRANSFUSIOLOGY: true,
        ULTRASONIC_DIAGNOSIS: true,
        UROLOGY: true,
        PHYSIOTHERAPY: true,
        PHYSICAL_MEDICINE_AND_REHABILITATION: true,
        PHTHISIOLOGY: true,
        FUNCTIONAL_DIAGNOSTICS: true,
        SURGICAL_DENTISTRY: true,
        GENERAL_SURGERY: true,
        CARDIOVASCULAR_SURGERY: true,
        AEROSPACE_MEDICINE: true,
        OBSTETRICS_AND_GYNECOLOGY: true,
        ALLERGOLOGY: true,
        ANAESTHETICS: true,
        BACTERIOLOGY: true,
        VIROLOGY: true,
        GASTROENTEROLOGY: true,
        GENERAL_HEMATOLOGY: true,
        LABORATORY_GENETICS: true,
        MEDICAL_GENETICS: true,
        GERIATRICS: true,
        PEDIATRIC_HYGIENE: true,
        OCCUPATIONAL_MEDICINE: true,
        FOOD_HYGIENE: true,
        DISINFECTION_: true,
        'DERMATO-VENEREOLOGY': true,
        PEDIATRIC_ALLERGY: true,
        PEDIATRIC_ANAESTHETICS: true,
        PEDIATRIC_GASTROENTEROLOGY: true,
        PEDIATRIC_HEMATOLOGY: true,
        PEDIATRIC_GYNECOLOGY: true,
        'PEDIATRIC_DERMATO-VENEREOLOGY': true,
        PEDIATRIC_ENDOCRINOLOGY: true,
        PEDIATRIC_IMMUNOLOGY: true,
        PEDIATRIC_CARDIOLOGY: true,
        PEDIATRIC_NEUROLOGY: true,
        PEDIATRIC_NEPHROLOGY: true,
        PEDIATRIC_ONCOLOGY: true,
        PEDIATRIC_ORTHOPAEDICS: true,
        PEDIATRIC_OTOLARYNGOLOGY: true,
        PEDIATRIC_OPHTHALMOLOGY: true,
        PEDIATRIC_PATHOLOGY: true,
        PEDIATRIC_PSYCHIATRY: true,
        PEDIATRIC_PULMONOLOGY: true,
        PEDIATRIC_STOMATOLOGY: true,
        PEDIATRIC_UROLOGY: true,
        PEDIATRIC_PHTHISIOLOGY: true,
        PEDIATRIC_SURGERY: true,
        PEDIATRIC_INFECTIOUS_DISEASE: true,
        DIETETICS: true,
        ENDOCRINOLOGY: true,
        ENDOSCOPY: true,
        EPIDEMIOLOGY: true,
        COMMON_HYGIENE: true,
        PEDIATRIC_HEMATOLOGY_AND_ONCOLOGY: true,
        INVASIVE_ELECTROPHYSIOLOGY: true,
        INTERVENTIONAL_CARDIOLOGY: true,
        PEDIATRIC_NEUROLOGICAL_SURGERY: true,
        PERIODONTOLOGY: true,
        PLASTIC_SURGERY: true,
        ORAL_AND_MAXILLOFACIAL_SURGERY: true,
        CHILD_CARDIOLOGY: true,
        PEDIATRIC_RHEUMATOLOGY: true,
    },
    _a[EmployeeType.ASSISTANT] = {
        ORTHOPEDIC_DENTISTRY: true,
        X_RAY_RADIOLOGY: true,
        SANOLOGY: true,
        STOMATOLOGY: true,
        GENERAL_MEDICINE: true,
        MEDICAL_CASE_EMERGENCY_MEDICINE: true,
        PUBLIC_HEALTH_AND_PREVENTIVE_MEDICINE: true,
        CLINICAL_LABORATORY: true,
        HYGIENE_LABORATORY: true,
        PATHOLOGY_LABORATORY: true,
        OBSTETRICS: true,
        NURSING: true,
        OPERATING_NURSING: true,
        MEDICAL_STATISTICS: true,
        PHYSICAL_THERAPEUTICS: true,
        ERGOTHERAPEUTICS: true,
    },
    _a[EmployeeType.PHARMASIST] = {
        PHARMACEUTICS_ORGANIZATION: true,
        PROVISOR: true,
        ANALYTICAL_AND_CONTROL_PHARMACY: true,
        CLINICAL_PROVISOR: true,
        PHARMACIST: true,
    },
    _a[EmployeeType.LABORANT] = {
        VIROLOGY: true,
        MICROBIOLOGY: true,
        LABORATORY_GENETICS: true,
        LABORATORY_IMMUNOLOGY: true,
        CLINICAL_DIAGNOSTIC: true,
        PARASITOLOGY: true,
        BACTERIOLOGY: true,
        CLINICAL_BIOCHEMISTRY: true,
        CLINICAL_LABORATORY: true,
        HYGIENE_LABORATORY: true,
        PATHOLOGY_LABORATORY: true,
        GENERAL_MEDICINE: true,
        PUBLIC_HEALTH_AND_PREVENTIVE_MEDICINE: true,
    },
    _a);
