export var ExpertFreeHoursActionType;
(function (ExpertFreeHoursActionType) {
    ExpertFreeHoursActionType["GET_EXPERT_FREE_HOURS_PENDING"] = "GET_EXPERT_FREE_HOURS_PENDING";
    ExpertFreeHoursActionType["GET_EXPERT_FREE_HOURS_SUCCESS"] = "GET_EXPERT_FREE_HOURS_SUCCESS";
    ExpertFreeHoursActionType["GET_EXPERT_FREE_HOURS_ERROR"] = "GET_EXPERT_FREE_HOURS_ERROR";
    ExpertFreeHoursActionType["SET_EXPERT"] = "SET_EXPERT";
    ExpertFreeHoursActionType["SET_DATE"] = "SET_DATE";
    ExpertFreeHoursActionType["SET_SERVICE"] = "SET_SERVICE";
    ExpertFreeHoursActionType["SET_TIME"] = "SET_TIME";
})(ExpertFreeHoursActionType || (ExpertFreeHoursActionType = {}));
