import escapeRegExp from 'lodash/escapeRegExp';
var Router = /** @class */ (function () {
    function Router(origin, rootPath) {
        this.origin = origin;
        this.rootPath = rootPath;
        this.routes = {};
    }
    Router.prototype.getOrigin = function () {
        return this.origin;
    };
    Router.prototype.getRootPath = function () {
        return this.rootPath;
    };
    Router.prototype.setRoute = function (name, path) {
        this.routes[name] = path;
        return this;
    };
    Router.prototype.getPath = function (name) {
        if (!this.routes[name]) {
            return name;
        }
        return "".concat(this.rootPath).concat(this.routes[name]);
    };
    Router.prototype.getUrl = function (name, params, search, hashFragment) {
        if (params === void 0) { params = {}; }
        if (search === void 0) { search = {}; }
        if (!this.routes[name]) {
            return name;
        }
        var url = Object.keys(params).reduce(function (prev, curr) {
            return prev.replace(new RegExp(":".concat(escapeRegExp(curr), "\\??")), params[curr]);
        }, "".concat(this.routes[name]));
        var searchPart = Object.keys(search).length ? "?".concat(new URLSearchParams(search)) : '';
        var hash = !!(hashFragment === null || hashFragment === void 0 ? void 0 : hashFragment.length) ? "#".concat(hashFragment) : '';
        return "".concat(this.origin).concat(url.replace(/:\w+\??/, '')).concat(searchPart).concat(hash);
    };
    return Router;
}());
export { Router };
