var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { clearObject } from '../../../../../../../assets/js/utils/etc';
import { ResponseError } from '../../../../../shared/error/response-error';
import { clearAndSerialize } from '../../../../../shared/util/normalizer';
var Composition = /** @class */ (function () {
    function Composition(params) {
        this.authService = params.authService;
        this.baseUrl = params.baseUrl;
        this.itemsPerPage = params.itemsPerPage;
        this.requestService = params.requestService;
        this.signService = params.signService;
        this.compositionSingleUrl = params.compositionSingleUrl;
        this.compositionRemoveUrl = params.compositionRemoveUrl;
        this.compositionCreateUrl = params.compositionCreateUrl;
    }
    Composition.prototype.search = function (patientId, page, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var preparedParams, offset, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        preparedParams = clearObject(filter || {});
                        offset = (+page - 1) * +this.itemsPerPage;
                        return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/searchComposition"), __assign(__assign({}, preparedParams), { offset: "".concat(offset), limit: "".concat(this.itemsPerPage), subject: patientId }))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    Composition.prototype.create = function (data) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return __awaiter(this, void 0, void 0, function () {
            var _j, _k, key, resp, json;
            var e_1, _l;
            return __generator(this, function (_m) {
                switch (_m.label) {
                    case 0:
                        try {
                            for (_j = __values(Object.keys(data)), _k = _j.next(); !_k.done; _k = _j.next()) {
                                key = _k.value;
                                if (!data[key].identifier) {
                                    continue;
                                }
                                data[key] = data[key].identifier;
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_k && !_k.done && (_l = _j.return)) _l.call(_j);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        // because, name of dictionaries & systems are different
                        data.category.coding[0].system = 'eHealth/composition_categories';
                        data.type.coding[0].system = 'eHealth/composition_types';
                        (_a = data === null || data === void 0 ? void 0 : data.section) === null || _a === void 0 ? true : delete _a.filter;
                        (_b = data === null || data === void 0 ? void 0 : data.section) === null || _b === void 0 ? true : delete _b.age;
                        (_c = data === null || data === void 0 ? void 0 : data.section) === null || _c === void 0 ? true : delete _c.gender;
                        (_d = data === null || data === void 0 ? void 0 : data.subject) === null || _d === void 0 ? true : delete _d.filter;
                        (_e = data === null || data === void 0 ? void 0 : data.subject) === null || _e === void 0 ? true : delete _e.age;
                        (_f = data === null || data === void 0 ? void 0 : data.subject) === null || _f === void 0 ? true : delete _f.gender;
                        if (!['PARENTAL_CARE', 'FAMILY_CARE', 'CHILD_CARE'].includes((_h = (_g = data === null || data === void 0 ? void 0 : data.category.coding) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.code)) {
                            data.subject.value = data.section.focus.value;
                        }
                        return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/patients/composition"), undefined, clearAndSerialize(data))];
                    case 1:
                        resp = _m.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        json = _m.sent();
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    Composition.prototype.getSingle = function (patientId, episodeId, encounterId, compositionId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/composition/").concat(compositionId, "/episode/").concat(episodeId, "/encounter/").concat(encounterId))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    Composition.prototype.sign = function (compositionId, signParams, data) {
        return __awaiter(this, void 0, void 0, function () {
            var signedData, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        return [4 /*yield*/, this.signService.sign(clearAndSerialize(data), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/patients/composition/").concat(compositionId, "/sign"), undefined, JSON.stringify({
                                data: signedData,
                            }))];
                    case 4:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 5:
                        json = _a.sent();
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    Composition.prototype.cancel = function (compositionId, signParams, data) {
        return __awaiter(this, void 0, void 0, function () {
            var signedData, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        return [4 /*yield*/, this.signService.sign(clearAndSerialize(data), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/patients/composition/").concat(compositionId, "/cancel"), undefined, JSON.stringify({
                                data: signedData,
                            }))];
                    case 4:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 5:
                        json = _a.sent();
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    Composition.prototype.erln = function (compositionId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/patients/composition/").concat(compositionId, "/erln"))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        json = _a.sent();
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    Composition.prototype.getStoredItem = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var query, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = new URLSearchParams({ id: id });
                        return [4 /*yield*/, fetch("".concat(this.compositionSingleUrl, "?").concat(query), {
                                credentials: 'include',
                            })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            return [2 /*return*/, null];
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    Composition.prototype.createStoredItem = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!payload.relatesTo) {
                            payload.relatesTo = null;
                        }
                        return [4 /*yield*/, fetch(this.compositionCreateUrl, {
                                credentials: 'include',
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json; charset=utf-8',
                                },
                                body: JSON.stringify({ payload: payload }),
                            })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Composition.prototype.removeStoredItem = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var query, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = new URLSearchParams({ id: id });
                        return [4 /*yield*/, fetch("".concat(this.compositionRemoveUrl, "?").concat(query), {
                                credentials: 'include',
                                method: 'DELETE',
                            })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return Composition;
}());
export { Composition };
