import { Button, Typography } from '@mui/material';
import React from 'react';
import classNames from 'classnames';
import { useStyles } from './ButtonAction.jss';
export default function ButtonActionCabinet(props) {
    var classes = useStyles();
    return (React.createElement(Button, { sx: {
            width: "".concat(props.width, "px"),
            height: "".concat(props.height, "px"),
            borderRadius: "".concat(props.width, "px"),
            backgroundColor: props.backgroundColor,
            marginRight: '10px',
        }, 
        //onClick={() => (window.location.href = props.buttonLink)}
        onClick: props.isLogin ? function () { return (window.location.href = props.buttonLink); } : props.voidLink },
        props.icon,
        !props.hideText && (React.createElement(Typography, { sx: { color: props.textColor }, className: classNames(classes.buttonActionTitle) }, props.buttonTitle))));
}
