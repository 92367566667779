import { LegalEntityService } from './legal-entity';
import { PARAM_EHEALTH_GET_MOI_LEGAL_ENTITIES, PARAM_EHEALTH_LEGAL_ENTITY_GET_LIST_URL, PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_REGISTER_MOI_USER_URL, } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
import { SERVICE_EHEALTH_AUTH } from '../../auth';
import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
export var SERVICE_EHEALTH_LEGAL_ENTITY = 'SERVICE_EHEALTH_LEGAL_ENTITY';
export var ehealthLegalEntityFactory = function (container) {
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var requestService = container.require(SERVICE_REQUEST);
    var registerMoiUserUrl = "".concat(container.get(PARAM_EHEALTH_REGISTER_MOI_USER_URL));
    var authService = container.require(SERVICE_EHEALTH_AUTH);
    var leGetListUrl = "".concat(container.get(PARAM_EHEALTH_LEGAL_ENTITY_GET_LIST_URL));
    var getMoiLegalEntitiesUrl = "".concat(container.get(PARAM_EHEALTH_GET_MOI_LEGAL_ENTITIES));
    return new LegalEntityService({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        authService: authService,
        registerMoiUserUrl: registerMoiUserUrl,
        leGetListUrl: leGetListUrl,
        getMoiLegalEntitiesUrl: getMoiLegalEntitiesUrl,
    });
};
