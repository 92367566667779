var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { connect } from 'react-redux';
import { createClearErrorChannelAction } from '../../../store/app/factory';
import { BasePage } from './BasePage';
import { ErrorChannel } from '../../../enum/error/chanel';
function mapDispatchToProps(dispatch, ownProps) {
    return __assign(__assign({}, ownProps), { clearError: function () {
            dispatch(createClearErrorChannelAction(ErrorChannel.GLOBAL));
        } });
}
function mapStateToProps(state, ownProps) {
    return __assign(__assign({}, ownProps), { error: state.app.errors[ErrorChannel.GLOBAL], viewportWidth: state.app.viewportWidth });
}
export var ConnectedBasePage = connect(mapStateToProps, mapDispatchToProps)(BasePage);
