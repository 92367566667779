import React from 'react';
import classNames from 'classnames';
import { useStyles } from './index.jss';
import { Box, List, ListItem, Typography } from '@mui/material';
import ButtonAction from './ButtonAction/ButtonAction';
import SearchIconSizedSvg from '../../assets/svg_components/SearchIconSizedSvg';
import { Swicher } from './LanguageSwitcher';
import PersonIconSvg from '../../assets/svg_components/PersonIconSvg';
import PhoneIconSvg from '../../assets/svg_components/PhoneIconSvg';
import LogoGradientIconSvg from '../../assets/svg_components/LogoIconSvg/LogoGradientIconSvg';
import LogoTitleIconSvg from '../../assets/svg_components/LogoIconSvg/LogoTitleIconSvg';
export var Header = function (props) {
    var classes = useStyles();
    //const user = useSelector(getCurrentUserSelector);
    return (React.createElement(Box, { className: classNames(classes.wrapper) },
        React.createElement(Box, { className: classNames(classes.container) },
            React.createElement(Box, { className: classNames(classes.bottomMenu) }),
            React.createElement("a", { href: "/" },
                React.createElement(Box, { className: classNames(classes.logoBlock) },
                    React.createElement(LogoGradientIconSvg, null),
                    React.createElement(LogoTitleIconSvg, null))),
            React.createElement(List, { className: classNames(classes.list) },
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/patient" }, "\u041F\u0430\u0446\u0456\u0454\u043D\u0442\u0430\u043C")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/doctor" }, "\u041B\u0456\u043A\u0430\u0440\u044F\u043C")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/clinics" }, "\u041A\u043B\u0456\u043D\u0456\u043A\u0430\u043C")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/news" }, "\u041D\u043E\u0432\u0438\u043D\u0438")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/faq" }, "\u0414\u043E\u0432\u0456\u0434\u043A\u0430")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/mic" }, "MIC MyHeal"))),
            React.createElement(Box, { className: classNames(classes.rightBlock) },
                React.createElement(Box, { className: classNames(classes.phoneIcon) },
                    React.createElement(PhoneIconSvg, null)),
                React.createElement(Box, { className: classNames(classes.phoneWrapper) },
                    React.createElement(Typography, { className: classNames(classes.phoneTitle) }, "\u041F\u0456\u0434\u0442\u0440\u0438\u043C\u043A\u0430"),
                    React.createElement(Typography, { className: classNames(classes.phoneNumber) },
                        React.createElement("a", { href: "tel: +38 (093) 059 43 44" }, "+38 (093) 059 43 44"))),
                React.createElement(ButtonAction, { width: 180, height: 50, borderRadius: 25, backgroundColor: "#29F499", textColor: "#000000", icon: React.createElement(SearchIconSizedSvg, { classname: classNames(classes.buttonActionIcon) }), buttonTitle: "\u0417\u043D\u0430\u0439\u0442\u0438 \u043B\u0456\u043A\u0430\u0440\u044F", buttonLink: "/uk/experts" }),
                React.createElement(ButtonAction, { width: 130, height: 50, borderRadius: 35, backgroundColor: "#1E1E1E", textColor: "#FFFFFF", icon: React.createElement(PersonIconSvg, { color: "white" }), buttonTitle: "\u041A\u0430\u0431\u0456\u043D\u0435\u0442", 
                    //buttonLink={user ? `/uk/cabinet/settings` : `${window.location.origin}/?showModal=register`}
                    buttonLink: "".concat(window.location.origin, "/?showModal=login") }),
                React.createElement(Swicher, null)))));
};
