export var EhealthMEEncounterAction;
(function (EhealthMEEncounterAction) {
    EhealthMEEncounterAction["ADD_ITEMS"] = "EHEALTH_ME_ENCOUNTER_ADD_ITEMS";
    EhealthMEEncounterAction["CLEAR"] = "EHEALTH_ME_ENCOUNTER_CLEAR";
    EhealthMEEncounterAction["UPDATE"] = "EHEALTH_ME_ENCOUNTER_UPDATE";
    EhealthMEEncounterAction["SET_IS_LOADING"] = "EHEALTH_ME_ENCOUNTER_SET_IS_LOADING";
    EhealthMEEncounterAction["SETUP_JOBS"] = "EHEALTH_ME_ENCOUNTER_SETUP_JOBS";
    EhealthMEEncounterAction["REMOVE_JOB"] = "EHEALTH_ME_ENCOUNTER_REMOVE_JOB";
    EhealthMEEncounterAction["SET_FILTER"] = "EHEALTH_ME_ENCOUNTER_SET_FILTER";
})(EhealthMEEncounterAction || (EhealthMEEncounterAction = {}));
