import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
export var AntSwitchGreen = styled(Switch)(function (_a) {
    var theme = _a.theme;
    return ({
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: '#29F499',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: '#173236',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 12,
            height: 12,
            borderRadius: 6,
            transition: theme.transitions.create(['width'], {
                duration: 200,
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: '#A5BDDB',
            boxSizing: 'border-box',
        },
    });
});
