export var ChargesAction;
(function (ChargesAction) {
    ChargesAction["INIT"] = "CHARES_INIT";
    ChargesAction["ADD_ITEM"] = "CHARES_ADD_ITEM";
    ChargesAction["REMOVE_ITEM"] = "CHARES_REMOVE_ITEM";
    ChargesAction["UPDATE_ITEM"] = "CHARES_UPDATE_ITEM";
})(ChargesAction || (ChargesAction = {}));
export var WardsActionType;
(function (WardsActionType) {
    WardsActionType["GET_WARDS_PENDING"] = "GET_WARDS_PENDING";
    WardsActionType["GET_WARDS_SUCCESS"] = "GET_WARDS_SUCCESS";
    WardsActionType["GET_WARDS_ERROR"] = "GET_WARDS_ERROR";
    WardsActionType["ADD_WARD_PENDING"] = "ADD_WARD_PENDING";
    WardsActionType["ADD_WARD_SUCCESS"] = "ADD_WARD_SUCCESS";
    WardsActionType["ADD_WARD_MODAL_CLOSE"] = "ADD_WARD_MODAL_CLOSE";
    WardsActionType["ADD_WARD_ERROR"] = "ADD_WARD_ERROR";
    WardsActionType["EDIT_WARD_PENDING"] = "EDIT_WARD_PENDING";
    WardsActionType["EDIT_WARD_SUCCESS"] = "EDIT_WARD_SUCCESS";
    WardsActionType["EDIT_WARD_ERROR"] = "EDIT_WARD_ERROR";
})(WardsActionType || (WardsActionType = {}));
