var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { RecordsCategoriesActionType } from './action';
import { initialState } from './state';
export var recordsCategoriesAsyncReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case RecordsCategoriesActionType.GET_RECORDS_CATEGORIES_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null });
        case RecordsCategoriesActionType.GET_RECORDS_CATEGORIES_SUCCESS:
            return __assign(__assign({}, state), { loading: false, categories: __spreadArray(__spreadArray([], __read(state.categories), false), __read(action.payload), false) });
        case RecordsCategoriesActionType.GET_RECORDS_CATEGORIES_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload });
        default:
            return state;
    }
};
