import React from 'react';
import { useStyles } from '../HeaderCabinetStyle';
import { Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
export var ItemCollapse = function (_a) {
    var icon = _a.icon, text = _a.text, route = _a.route;
    var classes = useStyles();
    var history = useHistory();
    var clickHandler = function () {
        history.push(route);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: classes.CollapseMenuItem, onClick: clickHandler },
            icon,
            " ",
            React.createElement(Typography, { className: classes.CollapseMenuText }, text))));
};
