export var ModalInitialState = {
    cabinetMenuModal: false,
    createChatModal: false,
    serviceModal: false,
    serviceEditModal: false,
    serviceSuccessfullyModal: false,
    clinicScheduleModal: false,
    galleryPhotoModal: false,
    reviewsDoctorModal: false,
    reviewsPatientModal: false,
};
export var initialState = ModalInitialState;
