import React from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useStyles } from './Login.jss';
import { Box, Button, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPasswordRestore } from '../../../../store/password-restore/password-restore';
import { useTranslation } from 'react-i18next';
export default function PasswordRestoreForm() {
    var classes = useStyles();
    var dispatch = useDispatch();
    var passwordRestoreError = useSelector(function (state) { return state.moi.passwordRestore.error; });
    var t = useTranslation().t;
    var initialValue = {
        phone: '',
    };
    var phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
    var requiredMessage = 'service.field.required';
    var phoneMessage = 'phoneFormat';
    var RestoreSchema = Yup.object().shape({
        phone: Yup.string()
            .required(requiredMessage)
            .matches(phoneRegExp, phoneMessage)
            .min(13, phoneMessage)
            .max(13, phoneMessage),
    });
    var handleRestore = function (_a, props) {
        var phone = _a.phone;
        dispatch(fetchPasswordRestore(phone));
        return;
    };
    return (React.createElement(Box, { className: classNames(classes.wrapper) },
        React.createElement(Box, { sx: { width: '100%' } },
            React.createElement(Typography, { className: classNames(classes.title) }, t('login.recoveryPassword'))),
        React.createElement(Typography, { className: classNames(classes.subtitle) }, t('phoneNumberRestore')),
        React.createElement(Formik, { onSubmit: handleRestore, initialValues: initialValue, validationSchema: RestoreSchema }, function (props) {
            var phone = props.values.phone;
            return (React.createElement(Form, null,
                React.createElement(Field, { as: TextField, placeholder: "+380 ( _ _ )  _ _ _  _ _  _ _", id: "phone", name: "phone", value: phone, inputProps: { maxLength: 13 }, className: classNames(classes.input), InputLabelProps: { shrink: false } }),
                props.errors.phone && (React.createElement(Typography, { className: classNames(classes.inputError) }, t(props.errors.phone))),
                passwordRestoreError && (React.createElement(Box, { className: classNames(classes.loginError) },
                    React.createElement(Typography, { className: classNames(classes.loginErrorTitle) }, t(passwordRestoreError)))),
                React.createElement(Button, { type: "submit", className: classNames(classes.submitButton) }, t('button.send'))));
        })));
}
