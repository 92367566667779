import { createStyles, makeStyles } from '@mui/styles';
export var useStyles = makeStyles(function (theme) {
    return createStyles({
        MainWrapper: {
            padding: '10px',
            paddingRight: '20px',
            paddingBottom: '20px',
            border: '1px solid #F1F6FA',
            borderRadius: 20,
        },
        header: {
            marginBottom: '20px',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
        },
        TextMain: {
            color: '#1E1E1E',
        },
        paginationIndicatorActive: {
            backgroundColor: '#173236',
            height: '6px',
            borderRadius: '50px',
        },
        paginationIndicator: {
            backgroundColor: '#A5BDDB',
            height: '6px',
            borderRadius: '50px',
        },
    });
});
