import { createUseStyles } from 'react-jss';
export var useStyles = createUseStyles({
    wrapper: {
        position: 'absolute',
        top: '20px',
        right: '20px',
        bottom: '20px',
        width: '100%',
        maxWidth: '480px',
        backgroundColor: '#FFFFFF',
        borderRadius: '30px',
        height: 'calc(100% - 40px)',
        minHeight: '740px',
    },
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        padding: '60px 30px 40px 30px',
        overflow: 'auto',
    },
    closeBox: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        width: '40px',
        height: '40px',
        borderRadius: '25px',
        border: '1px solid #1E1E1E',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    registerSwitch: {
        fontFamily: 'GraphikLCG',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '16px',
        letterSpacing: '-0.03em',
        color: '#32D29F',
        cursor: 'pointer',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    '@media screen and (max-width: 768px)': {
        wrapper: {
            margin: '10px',
            left: '0px',
            right: '0px',
            top: '0px',
            bottom: '0px',
            width: 'calc(100% - 20px)',
            minWidth: '340px',
            overflow: 'auto',
        },
    },
});
