import { PARAM_MOI_BOOKINGS_CANCEL_URL, PARAM_MOI_BOOKINGS_CREATE_URL, PARAM_MOI_BOOKINGS_LIST_SUMMARY_URL, } from './../../config/di/params/keys/index';
import { PARAM_MOI_BOOKINGS_LIST_URL } from '../../config/di/params/keys';
import { ClientBookings } from './client-bookings';
export var SERVICE_MOI_CLIENT_BOOKINGS = 'SERVICE_MOI_CLIENT_BOOKINGS';
export function clientBookingsFactory(container) {
    var getClientBookingsListUrl = "".concat(container.get(PARAM_MOI_BOOKINGS_LIST_URL));
    var getClientBookingsListSummaryUrl = "".concat(container.get(PARAM_MOI_BOOKINGS_LIST_SUMMARY_URL));
    var createClientBookingUrl = "".concat(container.get(PARAM_MOI_BOOKINGS_CREATE_URL));
    var cancelClientBookingUrl = "".concat(container.get(PARAM_MOI_BOOKINGS_CANCEL_URL));
    return new ClientBookings({
        getClientBookingsListUrl: getClientBookingsListUrl,
        getClientBookingsListSummaryUrl: getClientBookingsListSummaryUrl,
        createClientBookingUrl: createClientBookingUrl,
        cancelClientBookingUrl: cancelClientBookingUrl,
    });
}
