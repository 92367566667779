import { PARAM_EHEALTH_MEDZAKUPIVLI_GET_VACCINES_BY_LOTS_URL, PARAM_EHEALTH_MEDZAKUPIVLI_GET_VACCINES_LIST_URL, PARAM_MEDZAKUPIVLI_EHEALTH_PROXY_URL } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
import { VaccinesMedzakupivli } from './vaccines-medzakupivli';
import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
export var SERVICE_EHEALTH_VACCINES_MEDZAKUPIVLI = 'SERVICE_EHEALTH_VACCINES_MEDZAKUPIVLI';
export var ehealthVaccinesMedzakupivliFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_MEDZAKUPIVLI_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var medzakupivliVaccinesListUrl = "".concat(container.get(PARAM_EHEALTH_MEDZAKUPIVLI_GET_VACCINES_LIST_URL));
    var medzakupivliVaccinesListByLotsUrl = "".concat(container.get(PARAM_EHEALTH_MEDZAKUPIVLI_GET_VACCINES_BY_LOTS_URL));
    return new VaccinesMedzakupivli({
        baseUrl: baseUrl,
        requestService: requestService,
        itemsPerPage: itemsPerPage,
        medzakupivliVaccinesListUrl: medzakupivliVaccinesListUrl,
        medzakupivliVaccinesListByLotsUrl: medzakupivliVaccinesListByLotsUrl
    });
};
