var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { ResponseError } from '../../../../../shared/error/response-error';
import { clearAndSerialize } from '../../../../../shared/util/normalizer';
import { HealthcareServiceCategoriesEnum } from './enum/healthcare-service-categories.enum';
var HealthcareServiceService = /** @class */ (function () {
    function HealthcareServiceService(params) {
        this.authService = params.authService;
        this.baseUrl = params.baseUrl;
        this.itemsPerPage = params.itemsPerPage;
        this.requestService = params.requestService;
    }
    HealthcareServiceService.prototype.getList = function (page) {
        if (page === void 0) { page = 1; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.search({
                        page: page,
                        page_size: this.itemsPerPage,
                    })];
            });
        });
    };
    HealthcareServiceService.prototype.search = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var preparedParams, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        preparedParams = Object.keys(params).reduce(function (obj, key) {
                            // @ts-ignore
                            var val = params[key];
                            if (!val) {
                                return obj;
                            }
                            else if (val instanceof Array) {
                                obj[key] = val.join(',');
                            }
                            else {
                                obj[key] = "".concat(val);
                            }
                            return obj;
                        }, {});
                        return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/healthcare_services"), preparedParams)];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    HealthcareServiceService.prototype.getSingle = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/healthcare_services/").concat(id), {
                            page: "1",
                            page_size: "1",
                        })];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            return [2 /*return*/, null];
                        }
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    HealthcareServiceService.prototype.create = function (data) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var _d, _e, time, resp;
            var e_1, _f;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        try {
                            for (_d = __values(data.available_time || []), _e = _d.next(); !_e.done; _e = _d.next()) {
                                time = _e.value;
                                if (time.all_day) {
                                    delete time.available_start_time;
                                    delete time.available_end_time;
                                }
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_e && !_e.done && (_f = _d.return)) _f.call(_d);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        if (((_c = (_b = (_a = data.category) === null || _a === void 0 ? void 0 : _a.coding) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.code) !== HealthcareServiceCategoriesEnum.PHARMACY_DRUGS) {
                            delete data.type;
                        }
                        return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/healthcare_services"), undefined, clearAndSerialize(data))];
                    case 1:
                        resp = _g.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    HealthcareServiceService.prototype.update = function (id, data) {
        return __awaiter(this, void 0, void 0, function () {
            var objKeys, resp, err;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        objKeys = ['comment', 'available_time', 'not_available'];
                        console.log('data', data);
                        return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/healthcare_services/").concat(id), undefined, clearAndSerialize(data, objKeys))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            err = new ResponseError(resp);
                            throw err;
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    HealthcareServiceService.prototype.deactivate = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, err;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/healthcare_services/").concat(id, "/actions/deactivate"))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            err = new ResponseError(resp);
                            throw err;
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    HealthcareServiceService.prototype.activate = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, err;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/healthcare_services/").concat(id, "/actions/activate"))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            err = new ResponseError(resp);
                            throw err;
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    HealthcareServiceService.prototype.cancelRequest = function () {
        this.requestService.cancel();
    };
    return HealthcareServiceService;
}());
export { HealthcareServiceService };
