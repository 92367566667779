export var ExpertSingleInitialState = {
    company: {},
    expert: {
        id: 0,
        avatar: null,
        tags: [],
        experience: null,
        fullName: null,
        education: null,
        aboutUs: null,
        category: [],
        languages: {
            main: null,
            other: [],
        },
        phones: {
            main: null,
            other: [],
        },
        workInCompany: null,
        rating: null,
        reviewCount: null,
        reviews: [],
        location: {
            city: null,
            address: null,
            coords: null,
        },
        services: [],
        schedules: [],
        lastActivityAt: null,
    },
    categories: null,
    languages: [],
    consultantRequest: null,
    managerRequest: null,
    reviews: [],
    myClients: [],
    isConciliumOrganizer: false,
    conciliumList: [],
    isFavorite: false,
    isOnline: false,
};
export var initialState = {
    expertSingle: ExpertSingleInitialState,
    isLoading: false,
    error: null,
};
