var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ResponseError } from '../../../../../../shared/error/response-error';
import { clearObject } from '../../../../../../../../assets/js/utils/etc';
import { Role } from '../../../../enum/role';
var DiagnosticReportService = /** @class */ (function () {
    function DiagnosticReportService(params) {
        this.baseUrl = params.baseUrl;
        this.storageUrl = params.storageUrl;
        this.itemsPerPage = params.itemsPerPage;
        this.requestService = params.requestService;
        this.signService = params.signService;
        this.authService = params.authService;
        this.getSingleStoredUrl = params.getSingleStoredUrl;
    }
    DiagnosticReportService.prototype.getList = function (patientId, page, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var roles, args, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        roles = this.authService.getCreds().roles;
                        args = __assign({ page: "".concat(page), page_size: "".concat(this.itemsPerPage) }, (filter || {}));
                        if (roles.includes(Role.ROLE_ASSISTANT) ||
                            roles.includes(Role.ROLE_SPECIALIST) ||
                            roles.includes(Role.ROLE_MED_ADMIN)) {
                            args.managing_organization_id = this.authService.getCreds().legalEntityId;
                        }
                        return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/diagnostic_reports"), args)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    DiagnosticReportService.prototype.getSingle = function (patientId, diagnosticReportId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/diagnostic_reports/").concat(diagnosticReportId))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __assign(__assign({}, json.data), (json.urgent || {}))];
                }
            });
        });
    };
    DiagnosticReportService.prototype.getApprovedReports = function (patientId, page, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var args, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        args = __assign(__assign({}, (filter || {})), { page: "".concat(page), page_size: "".concat(this.itemsPerPage) });
                        return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/diagnostic_reports/approved"), args)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    DiagnosticReportService.prototype.create = function (data, patientId, signParams) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var preparedData, signedData, resp, json;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _d.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _d.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        if (!!((_a = data.diagnostic_report.paper_referral) === null || _a === void 0 ? void 0 : _a.enabled)) {
                            delete data.diagnostic_report.paper_referral.enabled;
                        }
                        else {
                            delete data.diagnostic_report.paper_referral;
                        }
                        if (data.diagnostic_report.primary_source) {
                            delete data.diagnostic_report.report_origin;
                        }
                        else {
                            (_b = data.diagnostic_report) === null || _b === void 0 ? true : delete _b.asserter;
                            (_c = data.diagnostic_report) === null || _c === void 0 ? true : delete _c.performer;
                        }
                        preparedData = clearObject(__assign({}, data));
                        return [4 /*yield*/, this.signService.sign(JSON.stringify(preparedData), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _d.sent();
                        return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/diagnostic_report_package"), undefined, JSON.stringify({
                                signed_data: signedData,
                            }))];
                    case 4:
                        resp = _d.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp.clone());
                        }
                        return [4 /*yield*/, resp.json()];
                    case 5:
                        json = _d.sent();
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    DiagnosticReportService.prototype.cancel = function (data, patientId, signParams) {
        return __awaiter(this, void 0, void 0, function () {
            var signedData, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        return [4 /*yield*/, this.signService.sign(JSON.stringify(__assign({}, data)), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/diagnostic_report_package"), undefined, JSON.stringify({
                                signed_data: signedData,
                            }))];
                    case 4:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 5:
                        json = _a.sent();
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    DiagnosticReportService.prototype.cancelRequest = function () {
        this.requestService.cancel();
    };
    DiagnosticReportService.prototype.getSingleStored = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var query, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = new URLSearchParams({
                            id: id,
                        });
                        return [4 /*yield*/, fetch("".concat(this.getSingleStoredUrl, "?").concat(query), {
                                credentials: 'include',
                            })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    return DiagnosticReportService;
}());
export { DiagnosticReportService };
