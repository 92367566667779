import React from 'react';
import classNames from 'classnames';
import { useStyles } from './DesktopMenu.jss';
import { Item } from './Item';
export var DesktopMenu = function (props) {
    var styles = useStyles();
    if (!props.items.length) {
        return null;
    }
    return (React.createElement("div", { className: classNames('cabinet-sidebar-block', styles.navListWrapper) },
        React.createElement("div", { className: "cabinet-sidebar-title title" }, props.title),
        React.createElement("div", { className: classNames('cabinet-sidebar-nav', styles.navList) }, props.items.map(function (item) {
            return React.createElement(Item, { item: item, key: item.link });
        }))));
};
