var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { useStyles } from './SideBarMenuStyle';
import { Box, Button, Drawer, Stack, useMediaQuery } from '@mui/material';
import { Logo } from '../../../../../app/moi/shared/SideBarMenu/components/Logo';
import List from '@mui/material/List';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MessageMenu } from './message/MessageMenu';
import { MenuItemDesktop } from './MenuItem';
import { clinicMenuItems, doctorMenuItems, patientMenuItems } from './MenuList';
import { useDispatch, useSelector } from 'react-redux';
import { CabinetSelector } from '../../../../../app/moi/store/cabinet/selector';
import { SecondaryMenuItemDesktop } from './SecondaryMenuItem';
import ChevronLeftIconSvg from './assets/ChevronLeftIconSvg';
import { CabinetMenuModalSelector } from '../../../../../app/moi/store/modal/cabinetMenu/selectors';
import { hideCabinetMenuModal } from '../../../../../app/moi/store/modal/cabinetMenu/actions';
import { useOutsideClick } from '../../../../../app/hooks/useOutsideClick';
var secondaryList = [
    {
        link: '/uk/cabinet/settings',
        icon: 'settingsMenu',
        target: 'menu.profile.settings',
        title: 'menu.profile.settings',
    },
    {
        link: '/uk/logout',
        reloadPage: true,
        icon: 'exitMenu',
        target: 'menu.main.logout',
        title: 'menu.main.logout',
    },
];
export var SideBarMenuDesktop = function () {
    var classes = useStyles();
    var t = useTranslation().t;
    var cabinetType = useSelector(CabinetSelector);
    var history = useHistory();
    var matches1024 = useMediaQuery('(max-width:1024px)');
    var cabinetMenuModal = useSelector(CabinetMenuModalSelector);
    var dispatch = useDispatch();
    var _a = __read(useState(false), 2), isVisibleMessage = _a[0], setIsVisibleMessage = _a[1];
    var onCloseVisibleMessage = function () { return setIsVisibleMessage(false); };
    var pathname = useLocation().pathname;
    var ref = useOutsideClick(function () {
        dispatch(hideCabinetMenuModal());
    });
    var MainListItems = (React.createElement(List, { className: classes.topMenuItemsWrapper }, (cabinetType === 'clinic'
        ? clinicMenuItems
        : cabinetType === 'doctor'
            ? doctorMenuItems
            : cabinetType === 'patient'
                ? patientMenuItems
                : []).map(function (item, index) {
        var isActive = pathname.includes(item.link);
        //const subMenuItems = props.subMenusItems
        //  ? props.subMenusItems.find(i => i.parentMenuItem.link === item.link)?.subMenuItems
        //  : undefined;
        // console.log('props.items ', props.items);
        // console.log('subMenuItems', subMenuItems);
        return (React.createElement(MenuItemDesktop, { key: index, isActive: isActive, title: item.title, icon: item.icon, link: item.link, reloadPage: item.reloadPage }));
    })));
    var SecondaryListItems = (React.createElement(List, { className: classes.bottomMenuItemsWrapper }, secondaryList
        .filter(function (item) {
        if (cabinetType === 'clinic' && item.title === 'menu.profile.settings')
            return;
        return item;
    })
        .map(function (item, index) {
        var isActive = item.link === pathname;
        return (React.createElement(SecondaryMenuItemDesktop, { key: index, isActive: isActive, title: t(item.title || ''), icon: item.icon, link: item.link, reloadPage: item.reloadPage }));
    })));
    //if (!props.items.length) {
    //  return null;
    //}
    return (React.createElement(Drawer, { variant: "permanent", open: cabinetMenuModal, sx: {
            width: '240px',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: '240px',
                boxSizing: 'border-box',
                overflow: 'hidden',
                overflowY: 'auto',
                border: 'none',
                '&::-webkit-scrollbar': {
                    width: '0px',
                },
            },
            '@media screen and (max-width: 1024px)': {
                display: cabinetMenuModal ? 'block' : 'none',
            },
        }, anchor: "left" },
        React.createElement("div", { ref: ref, className: classes.divWrapper },
            React.createElement(Stack, { role: "presentation", sx: { height: '100%' } },
                React.createElement(Stack, { flexGrow: 1, sx: { position: 'relative' } },
                    React.createElement(Box, { className: classes.LogoWrapper, onClick: function () { return history.push('/uk/cabinet/settings'); } },
                        React.createElement(Logo, { fill: cabinetType === 'clinic' ? '#CB8CF2' : cabinetType === 'doctor' ? '#07D9F3' : '#29F499' })),
                    MainListItems),
                isVisibleMessage && React.createElement(MessageMenu, { onCloseVisibleMessage: onCloseVisibleMessage }),
                SecondaryListItems,
                matches1024 && (React.createElement(Button, { className: classes.closeButton, startIcon: React.createElement(ChevronLeftIconSvg, null), sx: { '& .MuiButton-startIcon': { margin: '0px' }, minWidth: 0 }, onClick: function () { return dispatch(hideCabinetMenuModal()); } }))))));
};
