export var RecordsFoldersActionType;
(function (RecordsFoldersActionType) {
    RecordsFoldersActionType["GET_FOLDERS_PENDING"] = "GET_FOLDERS_PENDING";
    RecordsFoldersActionType["GET_FOLDERS_SUCCESS"] = "GET_FOLDERS_SUCCESS";
    RecordsFoldersActionType["GET_FOLDERS_ERROR"] = "GET_FOLDERS_ERROR";
    RecordsFoldersActionType["ADD_FOLDER_PENDING"] = "ADD_FOLDER_PENDING";
    RecordsFoldersActionType["ADD_FOLDER_SUCCESS"] = "ADD_FOLDER_SUCCESS";
    RecordsFoldersActionType["ADD_FOLDER_ERROR"] = "ADD_FOLDER_ERROR";
    RecordsFoldersActionType["DELETE_FOLDER_PENDING"] = "DELETE_FOLDER_PENDING";
    RecordsFoldersActionType["DELETE_FOLDER_SUCCESS"] = "DELETE_FOLDER_SUCCESS";
    RecordsFoldersActionType["DELETE_FOLDER_ERROR"] = "DELETE_FOLDER_ERROR";
    RecordsFoldersActionType["EDIT_FOLDER_PENDING"] = "EDIT_FOLDER_PENDING";
    RecordsFoldersActionType["EDIT_FOLDER_SUCCESS"] = "EDIT_FOLDER_SUCCESS";
    RecordsFoldersActionType["EDIT_FOLDER_ERROR"] = "EDIT_FOLDER_ERROR";
})(RecordsFoldersActionType || (RecordsFoldersActionType = {}));
