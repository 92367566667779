import { createUseStyles } from 'react-jss';
export var useStyles = createUseStyles({
    wrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontFamily: 'GraphikLCG',
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '30px',
        letterSpacing: '-0.03em',
        color: '#000000',
        marginBottom: '20px!important',
    },
    subtitle: {
        fontFamily: 'GraphikLCG',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.03em',
        color: '#000000',
        marginBottom: '10px!important',
    },
    input: {
        width: '100%',
        height: '50px',
        border: '#90A4BE',
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '14px',
        letterSpacing: '-0.03em',
        color: '#000000',
        marginTop: '20px!important',
        '&::placeholder': {
            fontFamily: 'GraphikLCG',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '14px',
            letterSpacing: '-0.03em',
            color: '#777E90',
        },
    },
    inputError: {
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '14px',
        letterSpacing: '-0.03em',
        color: '#ED4337',
        marginLeft: '10px!important',
        marginTop: '10px!important',
    },
    submitButton: {
        width: '100%',
        height: '50px',
        borderRadius: '10px',
        backgroundColor: '#29F499',
        fontFamily: 'GraphikLCG',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '16px',
        letterSpacing: '-0.03em',
        color: '#000000',
        marginTop: '10px!important',
        marginBottom: '40px!important',
    },
    registerError: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '20px!important',
    },
    registerErrorTitle: {
        fontFamily: 'GraphikLCG',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '14px',
        letterSpacing: '-0.03em',
        color: '#ED4337',
        marginBottom: '5px!important',
    },
});
