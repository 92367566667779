// @ts-ignore
import { QUEUE_NAME, QUEUE_VERSION } from '../../../../../assets/js/const/ehealth/ehealth.js';
import { PARAM_EHEALTH_GET_DICT_MAP_URL, PARAM_EHEALTH_GET_DICT_URL, PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_SEARCH_DICT_URL, PARAM_EHEALTH_SIGNATURE_CAS_LIST_URL, PARAM_EHEALTH_SIGNATURE_CERTS_URL, PARAM_EHEALTH_SIGNATURE_CHECK_SERTS_URL, } from '../../../app/ehealth/config/di/params/keys';
import { DiStrategy } from '../../../shared/service/di-container/enum/di-strategy';
import { normalizeOrigin } from '../../../shared/util/normalizer';
import { PARAM_APP_IMAGE_ALLOWED_FORMATS, PARAM_APP_IMAGE_DEFAULT_PATH, PARAM_APP_IMAGE_MAXIMUM_SIZE, PARAM_APP_ORIGIN, PARAM_APP_ROOT_PATH, PARAM_CITEMS_IN_AUTOCOMPLETE, PARAM_CITEMS_PER_PAGE, PARAM_ITEMS_IN_AUTOCOMPLETE, PARAM_ITEMS_PER_PAGE, PARAM_LOCALIZE_DATE_PREVIEW_FORMAT, PARAM_LOCALIZE_DATE_VALUE_FORMAT, PARAM_LOCALIZE_LOCALE, PARAM_LOCALIZE_TIME_FORMAT, PARAM_MQ_NAME, PARAM_MQ_VERSION, PARAM_NAV_CONFIG, PARAM_USER_ROLES, PARAM_USER_ID, PARAM_WORKER_SRC, E_HEALTH_REDIRECT_URL, PARAM_SPECIALIST_MODE, } from './keys';
export var configureParams = function (container) {
    container.register(PARAM_WORKER_SRC, window.__moni.pageConfig.workerSrc, DiStrategy.STATIC);
    container.register(PARAM_MQ_NAME, QUEUE_NAME, DiStrategy.STATIC);
    container.register(PARAM_MQ_VERSION, QUEUE_VERSION, DiStrategy.STATIC);
    container.register(PARAM_ITEMS_PER_PAGE, +window.__moni.pageConfig.itemsPerPage, DiStrategy.STATIC);
    container.register(PARAM_ITEMS_IN_AUTOCOMPLETE, +window.__moni.pageConfig.itemsInAutocomplete, DiStrategy.STATIC);
    container.register(PARAM_CITEMS_PER_PAGE, +window.__moni.pageConfig.cItemsPerPage, DiStrategy.STATIC);
    container.register(PARAM_CITEMS_IN_AUTOCOMPLETE, +window.__moni.pageConfig.cItemsInAutocomplete, DiStrategy.STATIC);
    container.register(PARAM_NAV_CONFIG, window.__moni.pageConfig.navigation, DiStrategy.STATIC);
    container.register(PARAM_LOCALIZE_LOCALE, window.__moni.pageConfig.localize.locale, DiStrategy.STATIC);
    container.register(PARAM_LOCALIZE_DATE_VALUE_FORMAT, window.__moni.pageConfig.localize.dateValueFormat, DiStrategy.STATIC);
    container.register(PARAM_LOCALIZE_DATE_PREVIEW_FORMAT, window.__moni.pageConfig.localize.datePreviewFormat, DiStrategy.STATIC);
    container.register(PARAM_LOCALIZE_TIME_FORMAT, window.__moni.pageConfig.localize.timeFormat, DiStrategy.STATIC);
    container.register(PARAM_APP_ORIGIN, window.__moni.pageConfig.app.appOrigin, DiStrategy.STATIC);
    container.register(PARAM_APP_ROOT_PATH, window.__moni.pageConfig.app.appRootPath, DiStrategy.STATIC);
    container.register(PARAM_USER_ROLES, window.__moni.pageConfig.app.userRoles, DiStrategy.STATIC);
    container.register(PARAM_USER_ID, window.__moni.pageConfig.app.userId, DiStrategy.STATIC);
    container.register(PARAM_SPECIALIST_MODE, window.__moni.pageConfig.app.specialistMode, DiStrategy.STATIC);
    container.register(E_HEALTH_REDIRECT_URL, window.__moni.pageConfig.app.e_health_redirect_url, DiStrategy.STATIC);
    container.register(PARAM_APP_IMAGE_ALLOWED_FORMATS, window.__moni.pageConfig.app.images.allowedImages, DiStrategy.STATIC);
    container.register(PARAM_APP_IMAGE_DEFAULT_PATH, window.__moni.pageConfig.app.images.default, DiStrategy.STATIC);
    container.register(PARAM_APP_IMAGE_MAXIMUM_SIZE, window.__moni.pageConfig.app.images.maximumFileSize, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_SIGNATURE_CAS_LIST_URL, normalizeOrigin(window.__moni.pageConfig.ehealth.sign.casListUrl), DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_SIGNATURE_CERTS_URL, normalizeOrigin(window.__moni.pageConfig.ehealth.sign.certsUrl), DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_SIGNATURE_CHECK_SERTS_URL, normalizeOrigin(window.__moni.pageConfig.ehealth.sign.checkCertsUrl), DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_GET_DICT_URL, window.__moni.pageConfig.ehealth.urls.getDict, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_SEARCH_DICT_URL, window.__moni.pageConfig.ehealth.urls.searchInDict, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_GET_DICT_MAP_URL, window.__moni.pageConfig.ehealth.urls.getDictMapUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_PROXY_URL, normalizeOrigin(window.__moni.pageConfig.ehealth.urls.ehealthProxyUrl), DiStrategy.STATIC);
};
