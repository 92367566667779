import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_GET_VACCINE_DISEASES_URL } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
import { VaccineDiseases } from './vaccine-diseases';
export var SERVICE_EHEALTH_VACCINE_DISEASES = 'SERVICE_EHEALTH_VACCINE_DISEASES';
export var ehealthVaccineDiseasesFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var getVaccineDiseasesUrl = "".concat(container.get(PARAM_EHEALTH_GET_VACCINE_DISEASES_URL));
    return new VaccineDiseases({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        getVaccineDiseasesUrl: getVaccineDiseasesUrl,
    });
};
