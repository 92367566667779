import { Signature } from './signature';
import { PARAM_EHEALTH_SIGNATURE_CAS_LIST_URL, PARAM_EHEALTH_SIGNATURE_CHECK_SERTS_URL, PARAM_EHEALTH_SIGNATURE_CERTS_URL, } from '../../../config/di/params/keys';
export var SERVICE_EHEALTH_SIGN = 'SERVICE_EHEALTH_SIGN';
export var ehealthSignatureFactory = function (container) {
    return new Signature({
        casListUrl: "".concat(container.get(PARAM_EHEALTH_SIGNATURE_CAS_LIST_URL)),
        certsUrl: "".concat(container.get(PARAM_EHEALTH_SIGNATURE_CERTS_URL)),
        checkCertsUrl: "".concat(container.get(PARAM_EHEALTH_SIGNATURE_CHECK_SERTS_URL)),
    });
};
