var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Box, ClickAwayListener, Fab, Stack, TextField } from '@mui/material';
import { useStyles } from './HeaderCabinetStyle';
import InputAdornment from '@mui/material/InputAdornment';
import { ThemeToggle } from './components/ThemeToggle';
import { CabinetProfile } from './components/CabinetProfile';
import { CabinetProfileMenu } from './components/CabinetProfileMenu';
import { NotificationMenu } from './components/NotificationMenu';
import { SearchResult } from './components/SearchResult';
import { SearchIcon } from './assets/SearchIcon';
import { NotificationIcon } from './assets/NotificationIcon';
import { ChatIcon } from './assets/ChatIcon';
export var HeaderCabinet = function () {
    var classes = useStyles();
    var _a = __read(React.useState(false), 2), openNotification = _a[0], setOpenNotification = _a[1];
    var _b = __read(React.useState(false), 2), open = _b[0], setOpen = _b[1];
    var _c = __read(React.useState(false), 2), isSearch = _c[0], setIsSearch = _c[1];
    var handleOpenNotification = function () { return setOpenNotification(true); };
    var handleCloseNotification = function () { return setOpenNotification(false); };
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () { return setOpen(false); };
    function CabinetClick() {
        if (open)
            handleClose();
        else
            handleOpen();
    }
    function NotificationClick() {
        if (open)
            handleCloseNotification();
        else
            handleOpenNotification();
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: classes.MainWrapper },
            React.createElement(Stack, { flexDirection: 'column', gap: '5px', height: '600px' },
                React.createElement(ClickAwayListener, { onClickAway: function () {
                        setIsSearch(false);
                    } },
                    React.createElement("div", null,
                        React.createElement(TextField, { sx: {
                                paddingLeft: '0px',
                                '& fieldset': { border: 'none' },
                                '& input': {
                                    paddingLeft: '0px',
                                    '&::placeholder': {
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        lineHeight: '14px',
                                        letterSpacing: '-0.03em',
                                        opacity: 1,
                                        color: '#173236',
                                    },
                                },
                            }, onFocus: function () {
                                setIsSearch(true);
                            }, placeholder: "\u0428\u0443\u043A\u0430\u0442\u0438...", className: classes.SearchField, InputProps: {
                                className: classes.inputRoot,
                                startAdornment: (React.createElement(InputAdornment, { position: "start", sx: { marginRight: '10px' } },
                                    React.createElement(SearchIcon, null))),
                                // disableUnderline: true,
                            } }),
                        isSearch ? (React.createElement(Box, { sx: { position: 'fixed', height: '538px', width: '358px', marginTop: '5px', zIndex: '100' } },
                            React.createElement(SearchResult, null))) : null))),
            React.createElement(Box, { className: classes.ContentCenter },
                React.createElement(ThemeToggle, null),
                React.createElement(Stack, { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '6px', marginRight: '10px', marginLeft: '20px' },
                    React.createElement(Fab, { className: classes.ButtonHeader },
                        React.createElement(ChatIcon, null)),
                    React.createElement(Fab, { className: classes.ButtonHeader, onClick: NotificationClick, sx: [openNotification && { backgroundColor: '#173236 !important' }] },
                        React.createElement(NotificationIcon, { isOpen: openNotification }))),
                React.createElement(Box, { onClick: CabinetClick, sx: {
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    } },
                    React.createElement(CabinetProfile, { isOpen: open })))),
        open && React.createElement(CabinetProfileMenu, null),
        React.createElement(NotificationMenu, { open: openNotification, setOpen: setOpenNotification })));
};
