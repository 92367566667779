var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ClinicBookingsActionType } from './action';
import { initialState } from './state';
export var clinicBookingsReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ClinicBookingsActionType.SET_DATA_FALSE:
            return __assign(__assign({}, state), { isChanged: false, isLoading: false, error: null });
        case ClinicBookingsActionType.GET_CLINIC_BOOKINGS_PENDING:
            return __assign(__assign({}, state), { isLoading: true, error: null });
        case ClinicBookingsActionType.GET_CLINIC_BOOKINGS_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, clinicBookings: action.payload.clinicBookings, total_count: action.payload.total_count });
        case ClinicBookingsActionType.GET_CLINIC_BOOKINGS_ERROR:
            return __assign(__assign({}, state), { isLoading: false, error: action.payload });
        case ClinicBookingsActionType.GET_CLINIC_BOOKINGS_COMPLETED_PENDING:
            return __assign(__assign({}, state), { isLoading: true, error: null });
        case ClinicBookingsActionType.GET_CLINIC_BOOKINGS_COMPLETED_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, clinicBookingsCompleted: action.payload.clinicBookingsCompleted, total_count_completed: action.payload.total_count_completed });
        case ClinicBookingsActionType.GET_CLINIC_BOOKINGS_COMPLETED_ERROR:
            return __assign(__assign({}, state), { isLoading: false, error: action.payload });
        case ClinicBookingsActionType.CHANGE_CLINIC_BOOKING_PENDING:
            return __assign(__assign({}, state), { isChanged: false, isLoading: true, error: null });
        case ClinicBookingsActionType.CHANGE_CLINIC_BOOKING_SUCCESS:
            return __assign(__assign({}, state), { isChanged: true, isLoading: false });
        case ClinicBookingsActionType.CHANGE_CLINIC_BOOKING_ERROR:
            return __assign(__assign({}, state), { isChanged: false, isLoading: false, error: action.payload });
        default:
            return state;
    }
};
