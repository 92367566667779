var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ResponseError } from '../../../../../../shared/error/response-error';
import { clearObject } from '../../../../../../../../assets/js/utils/etc';
import { clearAndSerialize } from '../../../../../../shared/util/normalizer';
import { CarePlanActivityScheduleType } from './enum/activity-schedule-type';
import { CarePlanActivityBoundType } from './enum/activity-bound-type';
import { CarePlanActivityKind } from './enum/activity-kind';
var CarePlan = /** @class */ (function () {
    function CarePlan(params) {
        this.baseUrl = params.baseUrl;
        this.itemsPerPage = params.itemsPerPage;
        this.signService = params.signService;
        this.requestService = params.requestService;
    }
    CarePlan.prototype.search = function (patientId, page, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var args, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filter === null || filter === void 0 ? true : delete filter.isRequisitionSearch;
                        filter === null || filter === void 0 ? true : delete filter.requisition;
                        args = __assign(__assign({}, (clearObject(filter || {}) || {})), { page: "".concat(page), page_size: "".concat(this.itemsPerPage) });
                        return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/care_plans"), args)];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    CarePlan.prototype.getSingle = function (patientId, carePlanId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/care_plans/").concat(carePlanId))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            console.error('Care plan getSingle error: ', resp);
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __assign(__assign({}, json.data), (json.urgent || {}))];
                }
            });
        });
    };
    CarePlan.prototype.searchActivities = function (patientId, carePlanId, page, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var preparedParams, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        preparedParams = clearObject(filter || {});
                        return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/care_plans/").concat(carePlanId, "/activities"), __assign(__assign({}, preparedParams), { page: "".concat(page), page_size: "".concat(this.itemsPerPage) }))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    CarePlan.prototype.getCarePlanActivity = function (patientId, carePlanId, id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/care_plans/").concat(carePlanId, "/activities/").concat(id))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    };
    CarePlan.prototype.getCarePlan = function (patientId, id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/care_plans/").concat(id))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    };
    CarePlan.prototype.cancelCarePlanActivity = function (patientId, carePlanId, id, data, signParams) {
        return __awaiter(this, void 0, void 0, function () {
            var signedData, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        return [4 /*yield*/, this.signService.sign(JSON.stringify(clearObject(data)), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/care_plans/").concat(carePlanId, "/activities/").concat(id, "/actions/cancel"), undefined, JSON.stringify({
                                signed_data: signedData,
                            }))];
                    case 4:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    CarePlan.prototype.completeCarePlanActivity = function (patientId, carePlanId, id, data) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/care_plans/").concat(carePlanId, "/activities/").concat(id, "/actions/complete"), undefined, clearAndSerialize(data))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    CarePlan.prototype.create = function (data, patientId, signParams) {
        return __awaiter(this, void 0, void 0, function () {
            var signedData, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        return [4 /*yield*/, this.signService.sign(JSON.stringify(clearObject(data)), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/care_plans"), undefined, JSON.stringify({
                                signed_data: signedData,
                            }))];
                    case 4:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    CarePlan.prototype.prepareCarePlanActivityData = function (data) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        switch (data.schedule_type) {
            case CarePlanActivityScheduleType.PERIOD:
                delete data.detail.scheduled_timing;
                delete data.detail.scheduled_string;
                break;
            case CarePlanActivityScheduleType.TIMING:
                delete data.detail.scheduled_period;
                delete data.detail.scheduled_string;
                break;
            default:
                delete data.detail.scheduled_period;
                delete data.detail.scheduled_timing;
                break;
        }
        delete data.schedule_type;
        switch ((_b = (_a = data.detail.scheduled_timing) === null || _a === void 0 ? void 0 : _a.repeat) === null || _b === void 0 ? void 0 : _b.bounds_type) {
            case CarePlanActivityBoundType.DURATION:
                (_d = (_c = data.detail.scheduled_timing) === null || _c === void 0 ? void 0 : _c.repeat) === null || _d === void 0 ? true : delete _d.bounds_range;
                (_f = (_e = data.detail.scheduled_timing) === null || _e === void 0 ? void 0 : _e.repeat) === null || _f === void 0 ? true : delete _f.bounds_period;
                break;
            case CarePlanActivityBoundType.PERIOD:
                (_h = (_g = data.detail.scheduled_timing) === null || _g === void 0 ? void 0 : _g.repeat) === null || _h === void 0 ? true : delete _h.bounds_range;
                (_k = (_j = data.detail.scheduled_timing) === null || _j === void 0 ? void 0 : _j.repeat) === null || _k === void 0 ? true : delete _k.bounds_duration;
                break;
            case CarePlanActivityBoundType.RANGE:
                (_m = (_l = data.detail.scheduled_timing) === null || _l === void 0 ? void 0 : _l.repeat) === null || _m === void 0 ? true : delete _m.bounds_period;
                (_p = (_o = data.detail.scheduled_timing) === null || _o === void 0 ? void 0 : _o.repeat) === null || _p === void 0 ? true : delete _p.bounds_duration;
                break;
        }
        delete data.detail.category;
        (_r = (_q = data.detail.scheduled_timing) === null || _q === void 0 ? void 0 : _q.repeat) === null || _r === void 0 ? true : delete _r.bounds_type;
        data = clearObject(data);
        if (isNaN(parseFloat((_s = data.detail.quantity) === null || _s === void 0 ? void 0 : _s.value))) {
            (_t = data.detail) === null || _t === void 0 ? true : delete _t.quantity;
        }
        else {
            data.detail.quantity.value = +data.detail.quantity.value;
        }
        if (isNaN(parseFloat((_u = data.detail.daily_amount) === null || _u === void 0 ? void 0 : _u.value)) || data.kind !== CarePlanActivityKind.MEDICATION_REQUEST) {
            (_v = data.detail) === null || _v === void 0 ? true : delete _v.daily_amount;
        }
        else {
            data.detail.daily_amount.value = +data.detail.daily_amount.value;
        }
        return data;
    };
    CarePlan.prototype.createActivity = function (data, patientId, carePlanId, signParams) {
        return __awaiter(this, void 0, void 0, function () {
            var signedData, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        return [4 /*yield*/, this.signService.sign(JSON.stringify(this.prepareCarePlanActivityData(data)), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/care_plans/").concat(carePlanId, "/activities"), undefined, JSON.stringify({
                                signed_data: signedData,
                            }))];
                    case 4:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    CarePlan.prototype.getCarePlanById = function (patientId, carePlanId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/care_plans/").concat(carePlanId))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __assign(__assign({}, json.data), (json.urgent || {}))];
                }
            });
        });
    };
    CarePlan.prototype.getCarePlanByRequisition = function (page, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var args, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filter === null || filter === void 0 ? true : delete filter.isRequisitionSearch;
                        filter === null || filter === void 0 ? true : delete filter.period_date;
                        filter === null || filter === void 0 ? true : delete filter.encounter_id;
                        filter === null || filter === void 0 ? true : delete filter.based_on;
                        filter === null || filter === void 0 ? true : delete filter.part_of;
                        args = __assign(__assign({}, (filter || {})), { page: "".concat(page), page_size: "".concat(this.itemsPerPage) });
                        return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/care_plans"), 
                            // @ts-ignore
                            args)];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    return CarePlan;
}());
export { CarePlan };
