import { MoiRoutes } from './enum/moi-routes';
export function configureRoutes(router) {
    router.setRoute(MoiRoutes.MEDICAL_ARCHIVE, '/cabinet/data_archive');
    router.setRoute(MoiRoutes.SCREENING_AND_MONITORING, '/cabinet/screaning-screaning');
    router.setRoute(MoiRoutes.USER_PROFILE, '/cabinet/settings');
    router.setRoute(MoiRoutes.USER_NOTIFICATIONS, '/cabinet/notification/all');
    router.setRoute(MoiRoutes.HEALTH_DYNAMIC, '/cabinet/my-health-dynamic');
    router.setRoute(MoiRoutes.HEALTH_DYNAMIC_BLOODY_PRESSURE, '/cabinet/my-health-dynamic/health-dynamics/111');
    router.setRoute(MoiRoutes.BOOKING_VIDEO_CALL, '/cabinet/service-one/:bookingId');
    router.setRoute(MoiRoutes.CHAT_MESSAGE_TEMPLATE, '/cabinet/message-templates');
    router.setRoute(MoiRoutes.CHAT_EMAIL_MESSAGE_TEMPLATE, '/cabinet/email-message-templates');
    router.setRoute(MoiRoutes.COMPANY_CLIENTS, '/cabinet/company/clients');
    router.setRoute(MoiRoutes.COMPANY_EXPERT_CREATE_REPORT, '/cabinet/company/experts/make-report');
    router.setRoute(MoiRoutes.COMPANY_EXPERT_LIST, '/cabinet/company/experts-new');
    router.setRoute(MoiRoutes.COMPANY_PATIENT_ENTRIES, '/cabinet/company/patient-entries');
    router.setRoute(MoiRoutes.COMPANY_EXPERT_INVITE, '/cabinet/company/experts/register-expert');
    router.setRoute(MoiRoutes.COMPANY_EXPERTS, '/cabinet/company/experts');
    router.setRoute(MoiRoutes.COMPANY_NEWS, '/cabinet/company/news');
    router.setRoute(MoiRoutes.COMPANY_SERVICES, '/cabinet/company/services');
    router.setRoute(MoiRoutes.COMPANY_SERVICES_CREATE, '/cabinet/company/service/create');
    router.setRoute(MoiRoutes.COMPANY_SERVICES_EDIT, '/cabinet/company/service/:id/edit');
    router.setRoute(MoiRoutes.COMPANY_SCHEDULE, '/cabinet/company/schedule');
    router.setRoute(MoiRoutes.COMPANY_PROFILE_CREATE, '/cabinet/register-company');
    router.setRoute(MoiRoutes.COMPANY_E_HEALTH_PROFILE, '/cabinet/company/legal-entity');
    router.setRoute(MoiRoutes.COMPANY_PROFILE_EDIT, '/cabinet/company/profile');
    router.setRoute(MoiRoutes.EXPERT_BOOKED_SERVICES, '/cabinet/booked-services');
    router.setRoute(MoiRoutes.COMPANY_NEWS_ARTICLE, '/cabinet/company/news/article');
    router.setRoute(MoiRoutes.COMPANY_CABINET, '/cabinet/company/profile/new');
    router.setRoute(MoiRoutes.COMPANY_EXPERT_SERVICES, '/cabinet/company/expert/:id/services');
    router.setRoute(MoiRoutes.COMPANY_EXPERT_SCHEDULE, '/cabinet/company/expert/:id/schedule');
    router.setRoute(MoiRoutes.COMPANY_EXPERTS_SCHEDULE, '/cabinet/company/experts-schedule');
    router.setRoute(MoiRoutes.REFERENCE, '/cabinet/reference');
    router.setRoute(MoiRoutes.REFERENCE_CREATE, '/cabinet/reference-create/:clientId/:form');
    router.setRoute(MoiRoutes.MY_ANALYSE, '/cabinet/my-analyse');
    router.setRoute(MoiRoutes.SEARCH, '/cabinet/search-results/:search');
    router.setRoute(MoiRoutes.CHARGES, '/cabinet/charges');
    router.setRoute(MoiRoutes.REFERRAL, '/cabinet/referral');
    router.setRoute(MoiRoutes.MY_BOOKED, '/cabinet/my-booked-services');
    router.setRoute(MoiRoutes.EXPERT_CLIENTS, '/cabinet/clients');
    router.setRoute(MoiRoutes.EXPERT_SCHEDULE, '/cabinet/schedule');
    router.setRoute(MoiRoutes.EXPERT_SERVICES, '/cabinet/service');
    router.setRoute(MoiRoutes.HOME_PAGE, '/');
    router.setRoute(MoiRoutes.MESSAGES, '/cabinet/messages');
}
