export var ConclusionActionType;
(function (ConclusionActionType) {
    ConclusionActionType["GET_CONCLUSIONS_PENDING"] = "GET_CONCLUSIONS_PENDING";
    ConclusionActionType["GET_CONCLUSIONS_SUCCESS"] = "GET_CONCLUSIONS_SUCCESS";
    ConclusionActionType["GET_CONCLUSIONS_ERROR"] = "GET_CONCLUSIONS_ERROR";
    ConclusionActionType["SET_CONCLUSION_PENDING"] = "SET_CONCLUSION_PENDING";
    ConclusionActionType["SET_CONCLUSION_SUCCESS"] = "SET_CONCLUSION_SUCCESS";
    ConclusionActionType["SET_CONCLUSION_ERROR"] = "SET_CONCLUSION_ERROR";
    ConclusionActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(ConclusionActionType || (ConclusionActionType = {}));
