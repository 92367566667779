export var ExpertListActionType;
(function (ExpertListActionType) {
    ExpertListActionType["GET_EXPERT_LIST_PENDING"] = "GET_EXPERT_LIST_PENDING";
    ExpertListActionType["GET_EXPERT_LIST_SUCCESS"] = "GET_EXPERT_LIST_SUCCESS";
    ExpertListActionType["GET_EXPERT_LIST_ERROR"] = "GET_EXPERT_LIST_ERROR";
    ExpertListActionType["SET_SELECTED_CITY"] = "SET_SELECTED_CITY";
    ExpertListActionType["SET_PRICE_FROM"] = "SET_PRICE_FROM";
    ExpertListActionType["SET_PRICE_TO"] = "SET_PRICE_TO";
    ExpertListActionType["SET_LIMIT"] = "SET_LIMIT";
})(ExpertListActionType || (ExpertListActionType = {}));
