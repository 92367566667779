var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Box, Button, Popover, Typography } from '@mui/material';
import { useStyles } from '../HeaderCabinetStyle';
import { Arrow } from '../assets/Arrow';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IsExpertSelector, PersonalInfoSelector, RoleSelector, } from '../../../../../app/moi/store/user/personalInfo/selector';
import { setCabinetExpertLocation, setCabinetPatientLocation, setCabinetType, } from '../../../../../app/moi/store/cabinet/factory';
import { CabinetDataSelector, CabinetSelector } from '../../../../../app/moi/store/cabinet/selector';
import ArrowRightIconSvg from '../assets/ArrowRightIconSvg';
import { ClinicInfoSelector } from '../../../../../app/moi/store/clinic/clinicInfo/selector';
export var CabinetProfileMenu = function (_a) {
    var _b, _c;
    var handleClose = _a.handleClose, open = _a.open, id = _a.id, anchorEl = _a.anchorEl;
    var dispatch = useDispatch();
    var classes = useStyles();
    var _d = __read(React.useState(false), 2), collapseExpanded = _d[0], setCollapseExpanded = _d[1];
    var isExpert = useSelector(IsExpertSelector);
    var roles = useSelector(RoleSelector);
    var personalInfo = useSelector(PersonalInfoSelector);
    var clinicInfo = useSelector(ClinicInfoSelector).clinic;
    var cabinetType = useSelector(CabinetSelector);
    var _e = useSelector(CabinetDataSelector), cabinetPatientLocation = _e.cabinetPatientLocation, cabinetExpertLocation = _e.cabinetExpertLocation, cabinetClinicLocation = _e.cabinetClinicLocation;
    var history = useHistory();
    var location = useLocation();
    //console.log(cabinetPatientLocation, cabinetExpertLocation, cabinetClinicLocation);
    var CollapseMenu = function () {
        if (collapseExpanded)
            setCollapseExpanded(false);
        else
            setCollapseExpanded(true);
    };
    var handleCabinetType = function (cabinetType) {
        dispatch(setCabinetType(cabinetType));
    };
    return (React.createElement(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: -20,
            horizontal: 'right',
        }, classes: {
            paper: classes.expandMenuParent,
        } },
        React.createElement(Box, { className: classes.MenuContent, sx: {
                height: '100%',
                backgroundColor: '#F1F6FA',
            }, onClick: function () {
                cabinetType === 'clinic'
                    ? history.push('/uk/cabinet/company/profile/new')
                    : history.push('/uk/cabinet/settings');
                handleClose;
            } },
            React.createElement(Box, { className: classes.MenuContentButtonWrapper }, cabinetType === 'clinic' ? (React.createElement(React.Fragment, null,
                React.createElement(Typography, { className: classes.menuHeaderTextBig }, clinicInfo.full_name),
                React.createElement(Typography, { className: classes.menuHeaderTextSmall }, clinicInfo.email))) : (React.createElement(React.Fragment, null,
                React.createElement(Typography, { className: classes.menuHeaderTextBig },
                    personalInfo.last_name,
                    " ", (_b = personalInfo.first_name) === null || _b === void 0 ? void 0 :
                    _b.slice(0, 1).toUpperCase(),
                    ".",
                    ' ',
                    ((_c = personalInfo.second_name) === null || _c === void 0 ? void 0 : _c.slice(0, 1).toUpperCase()) && '.'),
                React.createElement(Typography, { className: classes.menuHeaderTextSmall }, personalInfo.email)))),
            React.createElement(Arrow, { color: '#A5BDDB' })),
        cabinetType !== 'doctor' ? (roles.includes('expert') && !roles.includes('clinic') ? (React.createElement(Button, { sx: { '& .MuiButton-startIcon': { margin: '0px' }, minWidth: 0, color: '#FFFFFF' }, classes: { endIcon: classes.endIcon }, endIcon: React.createElement(ArrowRightIconSvg, { color: '#29F499' }), className: classes.MenuContentDarkButton, onClick: function () {
                //cabinetType === 'clinic'
                //  ? dispatch(setCabinetClinicLocation(location.pathname))
                //  :
                dispatch(setCabinetPatientLocation(location.pathname));
                handleCabinetType('doctor');
                if (cabinetExpertLocation.length > 0) {
                    return history.push(cabinetExpertLocation);
                }
                else
                    history.push('/uk/cabinet/settings');
            } }, "\u0412 \u043A\u0430\u0431\u0456\u043D\u0435\u0442 \u043B\u0456\u043A\u0430\u0440\u044F")) : roles.includes('clinic') ? ('') : (
        //<Box
        //  className={classes.MenuContent}
        //  sx={{
        //    backgroundColor: '#173236 !important',
        //    border: '1px solid #E5EEFF',
        //    marginTop: '10px',
        //  }}
        //>
        //  <Box className={classes.MenuContentButtonWrapper} onClick={() => handleCabinetType('doctor')}>
        //    <Typography className={classes.menuHeaderTextBig} sx={{ color: 'white !important' }}>
        //      В кабінет лікаря
        //    </Typography>
        //  </Box>
        //  <Arrow color={'#29F499'} />
        //</Box>
        React.createElement(Button, { sx: { '& .MuiButton-startIcon': { margin: '0px' }, minWidth: 0 }, classes: { endIcon: classes.endIcon }, endIcon: React.createElement(ArrowRightIconSvg, null), className: classes.MenuContentWhiteButton, onClick: function () { return (document.location.href = '/uk/register'); } }, "\u0421\u0442\u0432\u043E\u0440\u0438\u0442\u0438 \u043A\u0430\u0431\u0456\u043D\u0435\u0442 \u043B\u0456\u043A\u0430\u0440\u044F \u0430\u0431\u043E \u043A\u043B\u0456\u043D\u0456\u043A\u0438")
        //<Box
        //  className={classes.MenuContent}
        //  sx={{
        //    border: '1px solid #E5EEFF',
        //    marginTop: '10px',
        //  }}
        //>
        //  {/*<Box
        //    className={classes.MenuContentButtonWrapper}
        //    //onClick={() => history.push('/uk/register')}
        //    onClick={() => (document.location.href = '/uk/register')}
        //  >
        //    <Typography className={classes.menuHeaderTextBig} sx={{ width: '150px' }}>
        //      Створити кабінет лікаря або клініки
        //    </Typography>
        //  </Box>*/}
        //</Box>
        )) : (React.createElement(Button, { sx: { '& .MuiButton-startIcon': { margin: '0px' }, minWidth: 0, color: '#173236' }, classes: { endIcon: classes.endIcon }, endIcon: React.createElement(ArrowRightIconSvg, { color: '#A5BDDB' }), className: classes.MenuContentWhiteButton, onClick: function () {
                dispatch(setCabinetExpertLocation(location.pathname));
                handleCabinetType('patient');
                if (cabinetPatientLocation.length > 0) {
                    return history.push(cabinetPatientLocation);
                }
                else
                    history.push('/uk/cabinet/settings');
            } }, "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u0443 \u0432\u043B\u0430\u0441\u043D\u0438\u0439 \u043A\u0430\u0431\u0456\u043D\u0435\u0442 \u043F\u0430\u0446\u0456\u0454\u043D\u0442\u0430")
        //<>
        //  <Box
        //    className={classes.MenuContent}
        //    sx={{
        //      backgroundColor: '#173236 !important',
        //      border: '1px solid #E5EEFF',
        //      marginTop: '10px',
        //    }}
        //  >
        //    <Box className={classes.MenuContentButtonWrapper} onClick={() => handleCabinetType('patient')}>
        //      <Typography className={classes.menuHeaderTextBig} sx={{ color: 'white !important' }}>
        //        В кабінет пацієнта
        //      </Typography>
        //    </Box>
        //    <Arrow color={'#29F499'} />
        //  </Box>
        //</>
        ),
        React.createElement(Box, { sx: { margin: '16px 10px 10px 10px', display: 'flex', flexDirection: 'column', gap: '20px' } },
            cabinetType !== 'clinic' && (React.createElement(Typography, { className: classes.menuHeaderTextBigMarginTop, onClick: function () {
                    history.push('/uk/cabinet/settings');
                    handleClose();
                } }, "\u041D\u0430\u043B\u0430\u0448\u0442\u0443\u0432\u0430\u043D\u043D\u044F")),
            React.createElement(Typography, { className: classes.menuHeaderTextBigMarginTop, onClick: function () {
                    document.cookie = "cabinetType=''";
                    document.cookie = "cabinetClinicType=''";
                    document.cookie = "cabinetExpertType=''";
                    document.cookie = "cabinetPatientType=''";
                    //history.push('/uk/logout');
                    document.location = '/uk/logout';
                } }, "\u0412\u0438\u0439\u0442\u0438"))));
};
