var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import classNames from 'classnames';
import { useStyles } from './Login.jss';
import { Box, Typography, TextField, Checkbox, Button, FormControlLabel, useMediaQuery } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import HidePasswordIconSvg from '../../../../assets/svg_components/HidePasswordIconSvg';
import * as Yup from 'yup';
export default function LoginForm(_a) {
    var _this = this;
    var handleClose = _a.handleClose, setIsForgetPassword = _a.setIsForgetPassword;
    var classes = useStyles();
    var _b = __read(useState(false), 2), showPassword = _b[0], setShowPassword = _b[1];
    var _c = __read(useState(false), 2), specialist = _c[0], setSpecialist = _c[1];
    var _d = __read(useState(false), 2), isLoginError = _d[0], setIsLoginError = _d[1];
    var extension420 = useMediaQuery('(max-width:420px)');
    var initialValue = {
        username: '',
        password: '',
    };
    var SignupSchema = Yup.object().shape({
        username: Yup.string().required("\u0426\u0435 \u043F\u043E\u043B\u0435 \u043E\u0431\u043E\u0432'\u044F\u0437\u043A\u043E\u0432\u0435."),
        password: Yup.string().required("\u0426\u0435 \u043F\u043E\u043B\u0435 \u043E\u0431\u043E\u0432'\u044F\u0437\u043A\u043E\u0432\u0435."),
    });
    var handleLogin = function (_a, props) {
        var username = _a.username, password = _a.password;
        return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                fetch('/api/login_check', {
                    method: 'POST',
                    body: JSON.stringify({ username: username, password: password }),
                })
                    .then(function (resp) {
                    var status = resp.status;
                    if (status === 200) {
                        return resp.json();
                    }
                    else {
                        setIsLoginError(true);
                        return;
                    }
                })
                    .then(function (data) {
                    if (data) {
                        console.log(data);
                        document.cookie = "bearer=".concat(data.token);
                        document.cookie = 'specialistMode=0'; // Delete this when backend done getting specialistMode
                        props.resetForm();
                        handleClose();
                        setIsLoginError(false);
                        window.location.replace('/uk/cabinet/settings#personalData');
                    }
                })
                    .catch(function (err) { return console.log(err); });
                return [2 /*return*/];
            });
        });
    };
    return (React.createElement(Box, { className: classNames(classes.wrapper) },
        React.createElement(Box, { sx: { width: '100%' } },
            React.createElement(Typography, { className: classNames(classes.title) }, "\u0412\u0445\u0456\u0434"),
            React.createElement(Box, { className: classNames(classes.specialistBox) },
                React.createElement(Box, { className: classNames(classes.specialistBoxItem), onClick: function () { return setSpecialist(false); } },
                    React.createElement(Typography, { className: classNames(specialist ? classes.specialistBoxTitle : classes.specialistBoxTitleActive) }, "\u041F\u0430\u0446\u0456\u0454\u043D\u0442"),
                    specialist ? '' : React.createElement(Box, { className: classNames(classes.specialistBoxLine) })),
                React.createElement(Box, { className: classNames(classes.specialistBoxItem), onClick: function () { return setSpecialist(true); } },
                    React.createElement(Typography, { className: classNames(specialist ? classes.specialistBoxTitleActive : classes.specialistBoxTitle) }, "\u041B\u0456\u043A\u0430\u0440"),
                    specialist ? React.createElement(Box, { className: classNames(classes.specialistBoxLine) }) : '')),
            React.createElement(Formik, { initialValues: initialValue, onSubmit: handleLogin, validationSchema: SignupSchema }, function (props) {
                var _a = props.values, username = _a.username, password = _a.password;
                return (React.createElement(Form, null,
                    React.createElement(Box, { className: classNames(classes.loginBox) },
                        React.createElement(Typography, { className: classNames(classes.inputTitle) },
                            "\u041C\u043E\u0431\u0456\u043B\u044C\u043D\u0438\u0439 \u043D\u043E\u043C\u0435\u0440 \u0430\u0431\u043E",
                            extension420 ? '' : ' електронна',
                            " \u043F\u043E\u0448\u0442\u0430"),
                        React.createElement(Field, { as: TextField, placeholder: "+380 ( _ _ )  _ _ _  _ _  _ _", id: "username", name: "username", value: username, className: classNames(classes.input), InputLabelProps: { shrink: false } }),
                        props.errors.username && (React.createElement(Typography, { className: classNames(classes.inputError) }, props.errors.username))),
                    React.createElement(Box, { className: classNames(classes.passwordBox) },
                        React.createElement(Typography, { className: classNames(classes.inputTitle) }, "\u041F\u0430\u0440\u043E\u043B\u044C"),
                        React.createElement(Box, { className: classNames(classes.passwordInputBox) },
                            React.createElement(Field, { as: TextField, placeholder: "\u0412\u0432\u0435\u0434\u0456\u0442\u044C \u043F\u0430\u0440\u043E\u043B\u044C", id: "password", name: "password", value: password, type: showPassword ? 'text' : 'password', className: classNames(classes.input), InputLabelProps: { shrink: false } }),
                            React.createElement(HidePasswordIconSvg, { classname: classNames(classes.passwordIcon), onclick: function () { return setShowPassword(!showPassword); } })),
                        props.errors.password && (React.createElement(Typography, { className: classNames(classes.inputError) }, props.errors.password))),
                    React.createElement(Box, { className: classNames(classes.rememberBox) },
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { sx: {
                                    color: '#90A4BE',
                                    '&.Mui-checked': {
                                        color: '#32D29F',
                                    },
                                } }), label: React.createElement(Typography, { className: classNames(classes.rememberBoxTitle) }, "\u0417\u0430\u043F\u0430\u043C\u2019\u044F\u0442\u0430\u0442\u0438") }),
                        React.createElement(Typography, { className: classNames(classes.forgetPassword), onClick: function () { return setIsForgetPassword(true); } }, "\u0417\u0430\u0431\u0443\u043B\u0438 \u043F\u0430\u0440\u043E\u043B\u044C?")),
                    isLoginError && (React.createElement(Box, { className: classNames(classes.loginError) },
                        React.createElement(Typography, { className: classNames(classes.loginErrorTitle) }, "\u041D\u0435\u0432\u0456\u0440\u043D\u043E \u0432\u043A\u0430\u0437\u0430\u043D\u043E \u0434\u0430\u043D\u0456 \u0434\u043B\u044F \u0432\u0445\u043E\u0434\u0443."),
                        React.createElement(Typography, { className: classNames(classes.loginErrorText) }, "\u041F\u0435\u0440\u0435\u043A\u043E\u043D\u0430\u0439\u0442\u0435\u0441\u044F, \u0449\u043E \u043E\u0431\u0440\u0430\u043D\u0430 \u043F\u0440\u0430\u0432\u0438\u043B\u044C\u043D\u0430 \u043C\u043E\u0432\u0430 \u0432\u0432\u0435\u0434\u0435\u043D\u043D\u044F, \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0443 \u043F\u043E\u0447\u0438\u043D\u0430\u0454\u0442\u044C\u0441\u044F \u0437 +380 \u0456 \u0441\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0449\u0435 \u0440\u0430\u0437. \u041C\u043E\u0436\u043B\u0438\u0432\u043E, \u0432\u043A\u043B\u044E\u0447\u0435\u043D\u0430 \u043A\u043D\u043E\u043F\u043A\u0430 Caps Lock."))),
                    React.createElement(Button, { type: "submit", className: classNames(classes.submitButton) }, "\u0423\u0432\u0456\u0439\u0442\u0438 \u043D\u0430 \u043F\u043B\u0430\u0442\u0444\u043E\u0440\u043C\u0443")));
            })),
        React.createElement(Typography, { className: classNames(classes.help) }, "\u0430\u0431\u043E \u0437\u0430 \u0434\u043E\u043F\u043E\u043C\u043E\u0433\u043E\u044E"),
        React.createElement(Box, { className: classNames(classes.social) },
            React.createElement(Button, { className: classNames(classes.socialButton), sx: { backgroundColor: '#3B5898', marginRight: '10px' }, onClick: function () {
                    return window.location.replace("https://www.facebook.com/login.php?skip_api_login=1&api_key=207605933491218&kid_directed_site=0&app_id=207605933491218&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fv8.0%2Fdialog%2Foauth%3Fclient_id%3D207605933491218%26state%3D%257B%2522action%2522%253A%2522login%2522%252C%2522social%2522%253A%2522facebook%2522%252C%2522locale%2522%253A%2522uk%2522%257D%26response_type%3Dcode%26sdk%3Dphp-sdk-5.7.0%26redirect_uri%3Dhttps%253A%252F%252Fmyheal.com.ua%252Fsocial-data-handler%26scope%3Dpublish_actions%252Cemail%26ret%3Dlogin%26fbapp_pres%3D0%26logger_id%3D6df11132-7191-4c97-a03e-7b0568fc96a9%26tp%3Dunspecified&cancel_url=https%3A%2F%2Fmyheal.com.ua%2Fsocial-data-handler%3Ferror%3Daccess_denied%26error_code%3D200%26error_description%3DPermissions%2Berror%26error_reason%3Duser_denied%26state%3D%257B%2522action%2522%253A%2522login%2522%252C%2522social%2522%253A%2522facebook%2522%252C%2522locale%2522%253A%2522uk%2522%257D%23_%3D_&display=page&locale=ru_RU&pl_dbl=0");
                } },
                React.createElement(Box, { className: classNames(classes.socialIcon) }),
                React.createElement(Typography, { className: classNames(classes.socialTitle) }, "Facebook")),
            React.createElement(Button, { className: classNames(classes.socialButton), sx: { backgroundColor: '#000000', marginRight: '10px' }, onClick: function () {
                    return window.location.replace("https://appleid.apple.com/auth/authorize?client_id=com.MoniHeal&redirect_uri=https%3A%2F%2Fmyheal.com.ua%2Fsocial-data-handler&response_type=code+id_token&scope=name+email&nonce=code&response_mode=form_post&state=%7B%22action%22%3A%22login%22%2C%22social%22%3A%22apple%22%2C%22locale%22%3A%22uk%22%7D");
                } },
                React.createElement(Box, { className: classNames(classes.socialIcon) }),
                React.createElement(Typography, { className: classNames(classes.socialTitle) }, "Apple ID")),
            React.createElement(Button, { className: classNames(classes.socialButton), sx: { backgroundColor: '#A5BDDB' }, onClick: function () {
                    return window.location.replace("https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?response_type=code&access_type=online&client_id=976144271696-7krurkdfa2ht0csegljd4p8b482rtdkb.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fmyheal.com.ua%2Fsocial-data-handler&state=%7B%22action%22%3A%22login%22%2C%22social%22%3A%22google%22%2C%22locale%22%3A%22uk%22%7D&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&approval_prompt=auto&service=lso&o2v=1&ddm=1&flowName=GeneralOAuthFlow");
                } },
                React.createElement(Box, { className: classNames(classes.socialIcon) }),
                React.createElement(Typography, { className: classNames(classes.socialTitle) }, "Google")))));
}
