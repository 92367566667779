export var EhealthMEEpisodeAction;
(function (EhealthMEEpisodeAction) {
    EhealthMEEpisodeAction["ADD_ITEMS"] = "EHEALTH_ME_EPISODE_ADD_ITEMS";
    EhealthMEEpisodeAction["CLEAR"] = "EHEALTH_ME_EPISODE_CLEAR";
    EhealthMEEpisodeAction["UPDATE"] = "EHEALTH_ME_EPISODE_UPDATE";
    EhealthMEEpisodeAction["SET_IS_LOADING"] = "EHEALTH_ME_EPISODE_SET_IS_LOADING";
    EhealthMEEpisodeAction["SETUP_JOBS"] = "EHEALTH_ME_EPISODE_SETUP_JOBS";
    EhealthMEEpisodeAction["REMOVE_JOB"] = "EHEALTH_ME_EPISODE_REMOVE_JOB";
    EhealthMEEpisodeAction["SET_FILTER"] = "EHEALTH_ME_EPISODE_SET_FILTER";
})(EhealthMEEpisodeAction || (EhealthMEEpisodeAction = {}));
