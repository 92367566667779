import React from 'react';
export default function YoutubeWhiteIconSvg() {
    return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_205_36)" },
            React.createElement("path", { d: "M19.5879 5.19897C19.3574 4.34219 18.6819 3.66684 17.8252 3.43612C16.2602 3.00781 9.99981 3.00781 9.99981 3.00781C9.99981 3.00781 3.73961 3.00781 2.17452 3.4198C1.33438 3.65036 0.642392 4.34234 0.411833 5.19897C0 6.7639 0 10.0094 0 10.0094C0 10.0094 0 13.2713 0.411833 14.8199C0.642545 15.6765 1.3179 16.352 2.17467 16.5827C3.75609 17.011 9.99996 17.011 9.99996 17.011C9.99996 17.011 16.2602 17.011 17.8252 16.5991C18.682 16.3685 19.3574 15.693 19.5881 14.8364C19.9999 13.2713 19.9999 10.0259 19.9999 10.0259C19.9999 10.0259 20.0164 6.7639 19.5879 5.19897Z", fill: "white" }),
            React.createElement("path", { d: "M8.00659 13.0079L13.2124 10.0096L8.00659 7.01123V13.0079Z", fill: "#1E1E1E" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_205_36" },
                React.createElement("rect", { width: "20", height: "20", fill: "white" })))));
}
