import { PARAM_MOI_CHAT_MESSAGE_TEMPLATE_SEND_LIST_URL, } from '../../../config/di/params/keys';
import { MessageTemplateSendService } from './message-template-send';
import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
export var SERVICE_MOI_CHAT_MESSAGE_TEMPLATE_SEND = 'SERVICE_MOI_CHAT_MESSAGE_TEMPLATE_SEND';
export function messageTemplateSendFactory(container) {
    var listUrl = "".concat(container.get(PARAM_MOI_CHAT_MESSAGE_TEMPLATE_SEND_LIST_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    return new MessageTemplateSendService({
        listUrl: listUrl,
        itemsPerPage: itemsPerPage,
    });
}
