export var Role;
(function (Role) {
    Role["ROLE_MIS_USER"] = "MIS";
    Role["ROLE_OWNER"] = "OWNER";
    Role["ROLE_ADMIN"] = "ADMIN";
    Role["ROLE_DOCTOR"] = "DOCTOR";
    Role["ROLE_SPECIALIST"] = "SPECIALIST";
    Role["ROLE_HR"] = "HR";
    Role["ROLE_RECEPTIONIST"] = "RECEPTIONIST";
    Role["ROLE_ASSISTANT"] = "ASSISTANT";
    Role["ROLE_MSP_LIMITED"] = "MSP_LIMITED";
    Role["ROLE_MED_ADMIN"] = "MED_ADMIN";
    Role["ROLE_LABORANT"] = "LABORANT";
})(Role || (Role = {}));
