import { EmployeeService } from './employee';
import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_ASSIGN_EMPLOYEE_REQUEST_URL, PARAM_EHEALTH_ASSIGN_EMPLOYEE_COMPLETE_URL, PARAM_EHEALTH_EMPLOYEE_UPDATE_STATUS, } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
export var SERVICE_EHEALTH_EMPLOYEE = 'SERVICE_EHEALTH_EMPLOYEE';
export var ehealthEmployeeFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var assignUrl = "".concat(container.get(PARAM_EHEALTH_ASSIGN_EMPLOYEE_REQUEST_URL));
    var assignCompleteUrl = "".concat(container.get(PARAM_EHEALTH_ASSIGN_EMPLOYEE_COMPLETE_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var updateEmployeeStatusUrl = "".concat(container.get(PARAM_EHEALTH_EMPLOYEE_UPDATE_STATUS));
    return new EmployeeService({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        assignUrl: assignUrl,
        assignCompleteUrl: assignCompleteUrl,
        updateEmployeeStatusUrl: updateEmployeeStatusUrl,
    });
};
