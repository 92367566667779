import { PARAM_MOI_COMPANY_EXPERT_REQUEST_ACCEPT_URL, PARAM_MOI_COMPANY_EXPERT_REQUEST_DECLINE_URL, PARAM_MOI_COMPANY_EXPERT_REQUEST_URL, PARAM_MOI_COMPANY_EXPERTS_LIST_URL, } from '../../../config/di/params/keys';
import { CompanyExperts } from './company-experts';
export var SERVICE_MOI_COMPANY_EXPERTS = 'SERVICE_MOI_COMPANY_EXPERTS';
export function companyExpertsFactory(container) {
    var companyExpertsListUrl = "".concat(container.get(PARAM_MOI_COMPANY_EXPERTS_LIST_URL));
    var companyRequestUrl = "".concat(container.get(PARAM_MOI_COMPANY_EXPERT_REQUEST_URL));
    var companyRequestDeclineUrl = "".concat(container.get(PARAM_MOI_COMPANY_EXPERT_REQUEST_DECLINE_URL));
    var companyRequestAcceptUrl = "".concat(container.get(PARAM_MOI_COMPANY_EXPERT_REQUEST_ACCEPT_URL));
    return new CompanyExperts({
        urls: {
            companyExpertsListUrl: companyExpertsListUrl,
            companyRequestUrl: companyRequestUrl,
            companyRequestDeclineUrl: companyRequestDeclineUrl,
            companyRequestAcceptUrl: companyRequestAcceptUrl,
        },
    });
}
