import React from 'react';
import { Box, Button, List, ListItem, Typography } from '@mui/material';
import classNames from 'classnames';
import { useStyles } from './Footer.jss';
import ArrowRight90degIconSvg from '../../assets/svg_components/ArrowRight90degIconSvg';
import FacebookWhiteIconSvg from '../../assets/svg_components/FacebookWhiteIconSvg';
import InstagramWhiteIconSvg from '../../assets/svg_components/InstagramWhiteIconSvg';
import YoutubeWhiteIconSvg from '../../assets/svg_components/YoutubeWhiteIconSvg';
import ViberWhiteIconSvg from '../../assets/svg_components/ViberWhiteIconSvg';
import TelegramWhiteIconSvg from '../../assets/svg_components/TelegramWhiteIconSvg';
import WhatsappWhiteIconSvg from '../../assets/svg_components/WhatsappWhiteIconSvg';
import AppAppleIconSvg from '../../assets/svg_components/AppAppleIconSvg';
import AppGoogleIconSvg from '../../assets/svg_components/AppGoogleIconSvg';
import VisaIconSvg from '../../assets/svg_components/VisaIconSvg';
export default function Footer() {
    var classes = useStyles();
    return (React.createElement(Box, { className: classNames(classes.wrapper) },
        React.createElement(Box, { className: classNames(classes.container) },
            React.createElement(List, { className: classNames(classes.list) },
                React.createElement(Typography, { className: classNames(classes.listTitle) }, "\u041A\u043E\u0440\u0438\u0441\u0442\u0443\u0432\u0430\u0447\u0430\u043C"),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/cabinet/settings#personalData" }, "\u041A\u0430\u0431\u0456\u043D\u0435\u0442")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/patient" }, "\u041F\u0430\u0446\u0456\u0454\u043D\u0442\u0430\u043C")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/doctor" }, "\u041B\u0456\u043A\u0430\u0440\u044F\u043C")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/clinics" }, "\u041A\u043B\u0456\u043D\u0456\u043A\u0430\u043C")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/news" }, "\u041D\u043E\u0432\u0438\u043D\u0438")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/clinic" }, "\u041A\u043B\u0456\u043D\u0456\u043A\u0438")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/mic" }, "MIC MyHeal"))),
            React.createElement(List, { className: classNames(classes.list) },
                React.createElement(Typography, { className: classNames(classes.listTitle) }, "\u0420\u0435\u0441\u0443\u0440\u0441\u0438"),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/page/about-platform" }, "\u041F\u0440\u043E \u043D\u0430\u0441")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/page/policy-payments" }, "\u0417\u0430\u0445\u0438\u0441\u0442 \u0434\u0430\u043D\u0438\u0445")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/page/privacy-policy" }, "\u041F\u043E\u043B\u0456\u0442\u0438\u043A\u0430 \u043F\u0440\u0438\u0432\u0430\u0442\u043D\u043E\u0441\u0442\u0456")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/page/agreement" }, "\u041F\u0440\u0430\u0432\u0438\u043B\u0430 \u043A\u043E\u0440\u0438\u0441\u0442\u0443\u0432\u0430\u043D\u043D\u044F")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/faq" }, "\u0414\u043E\u0432\u0456\u0434\u043A\u043E\u0432\u0438\u0439 \u0446\u0435\u043D\u0442\u0440")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/" }, "\u0417\u0432\u043E\u0440\u043E\u0442\u043D\u0456\u0439 \u0437\u0432\u044F\u0437\u043E\u043A"))),
            React.createElement(Box, { className: classNames(classes.contactsBlock) },
                React.createElement(Typography, { className: classNames(classes.listTitle) }, "\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u0438"),
                React.createElement(Typography, { className: classNames(classes.contactItem) },
                    React.createElement("a", { href: "mailto:info@myheal.com.ua" }, "info@myheal.com.ua")),
                React.createElement(Typography, { className: classNames(classes.contactItem) },
                    React.createElement("a", { href: "tel:+380930594344" }, "+38 (093) 059 43 44")),
                React.createElement(Button, { className: classNames(classes.contactButton) },
                    React.createElement("a", { href: "/uk/experts" },
                        React.createElement(Typography, { className: classNames(classes.contactButtonTitle) }, "\u0417\u0430\u043F\u0438\u0441 \u043D\u0430 \u043F\u0440\u0438\u0439\u043E\u043C")),
                    React.createElement(Box, { className: classNames(classes.contactButtonBlock) },
                        React.createElement(ArrowRight90degIconSvg, null))),
                React.createElement(Box, { className: classNames(classes.buttonsBlock) },
                    React.createElement("a", { href: "https://www.facebook.com/myhealua" },
                        React.createElement(Box, { className: classNames(classes.socialIcon) },
                            React.createElement(FacebookWhiteIconSvg, null))),
                    React.createElement("a", { href: "https://www.instagram.com/myheal.com.ua" },
                        React.createElement(Box, { className: classNames(classes.socialIcon) },
                            React.createElement(InstagramWhiteIconSvg, null))),
                    React.createElement(Box, { className: classNames(classes.socialIcon) },
                        React.createElement(YoutubeWhiteIconSvg, null)),
                    React.createElement(Box, { className: classNames(classes.socialIcon) },
                        React.createElement(ViberWhiteIconSvg, null)),
                    React.createElement(Box, { className: classNames(classes.socialIcon) },
                        React.createElement(TelegramWhiteIconSvg, null)),
                    React.createElement(Box, { className: classNames(classes.socialIcon) },
                        React.createElement(WhatsappWhiteIconSvg, null)))),
            React.createElement(Box, { className: classNames(classes.appBlock) },
                React.createElement(Typography, { className: classNames(classes.appTitle) }, "\u0417\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0443\u0439\u0442\u0435 \u043D\u0430\u0448 \u043C\u043E\u0431\u0456\u043B\u044C\u043D\u0438\u0439 \u0434\u043E\u0434\u0430\u0442\u043E\u043A"),
                React.createElement(Box, { className: classNames(classes.buttonsBlock) },
                    React.createElement(Box, { sx: { cursor: 'pointer' } },
                        React.createElement("a", { href: "https://apps.apple.com/ua/app/myheal/id1503514770?l=uk" },
                            React.createElement(AppAppleIconSvg, null))),
                    React.createElement(Box, { sx: { cursor: 'pointer' } },
                        React.createElement("a", { href: "https://play.google.com/store/apps/details?id=com.moniHeal&hl=uk" },
                            React.createElement(AppGoogleIconSvg, null))))),
            React.createElement(Typography, { className: classNames(classes.copyright) }, "\u00A9 2023-2024 MyHeal. All rights reserved"),
            React.createElement(Box, { className: classNames(classes.visa) },
                React.createElement(VisaIconSvg, null)))));
}
