import { createUseStyles } from 'react-jss';
export var useStyles = createUseStyles({
    wrapper: {
        height: '60px',
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        position: 'absolute',
        left: '20px',
        right: '20px',
        top: '20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
        zIndex: '100',
    },
    bottomMenu: {
        display: 'flex',
        height: '80px',
        width: '100%',
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        position: 'absolute',
        right: '0px',
        top: '70px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
    },
    listItem: {
        whiteSpace: 'nowrap',
        fontFamily: 'GraphikLCG',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '16px',
        letterSpacing: '-0.03em',
        color: '#173236',
        paddingRight: '10px',
    },
    rightBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    menuButton: {
        width: '40px',
        height: '40px',
        borderRadius: '25px',
        backgroundColor: '#1E1E1E',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    phoneBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    phoneIcon: {
        width: '40px',
        height: '40px',
        border: '1px solid #1E1E1E',
        borderRadius: '25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '10px',
    },
    phoneWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '10px',
    },
    phoneTitle: {
        fontFamily: 'GraphikLCG',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '14px',
        letterSpacing: '-0.03em',
        color: '#221F1F',
    },
    phoneNumber: {
        fontFamily: 'GraphikLCG',
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '18px',
        letterSpacing: '-0.03em',
        color: '#000000',
    },
    buttonActionIcon: {
        height: '20px',
        width: '20px',
    },
    logoBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    listItemLine: {
        position: 'absolute',
        backgroundColor: '#32D29F',
        height: '4px',
        width: '100%',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        top: '44px',
    },
    '@media screen and (max-width: 1350px)': {
        bottomMenu: {
            flexDirection: 'column',
            height: '100%',
            minHeight: '400px',
            maxHeight: '480px',
            width: '300px',
            alignItems: 'flex-start',
        },
        list: {
            flexDirection: 'column',
            marginTop: '50px',
            marginBottom: '14px',
            padding: 0,
        },
        listItem: {
            padding: 0,
            marginBottom: '26px',
        },
        rightBlock: {
            width: '100%',
            justifyContent: 'end',
        },
    },
});
