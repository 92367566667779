import { AppAction } from './action.enum';
export function createLoadingAction(isLoading) {
    return {
        type: AppAction.SET_IS_LOADING,
        payload: {
            isLoading: isLoading,
        },
    };
}
export function createPostErrorToChannelAction(channel, error) {
    return {
        type: AppAction.POPULATE_ERROR,
        payload: {
            error: error,
            channel: channel,
        },
    };
}
export function createClearErrorChannelAction(channel) {
    return {
        type: AppAction.CLEAR_ERROR_CHANNEL,
        payload: {
            channel: channel,
        },
    };
}
export function createSetViewPortWidh(viewportWidth) {
    return {
        type: AppAction.SET_VIEWPORT_WIDTH,
        payload: {
            viewportWidth: viewportWidth,
        },
    };
}
