import { createUseStyles } from 'react-jss';
export var useStyles = createUseStyles({
    wrapper: {
        width: '70px',
        height: '50px',
        border: '1px solid #1E1E1E',
        borderRadius: '25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        position: 'relative',
    },
    swichLink: {
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '-0.03em',
        color: '#000000',
        marginBottom: '20px',
        '&:last-child': {
            marginBottom: '0px',
        },
    },
    active: {
        fontFamily: 'GraphikLCG',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '16px',
        letterSpacing: '-0.03em',
        color: '#000000',
    },
    menu: {
        width: '70px',
        backgroundColor: '#FFFFFF',
        border: '1px solid #EAEAEA',
        borderRadius: '10px',
        position: 'absolute',
        top: '70px',
        padding: '10px 20px',
    },
});
