import { NotificationsActionType } from './action';
export var fetchGetAllNotificationsBegin = function () { return ({
    type: NotificationsActionType.GET_ALL_NOTIFICATIONS_PENDING,
}); };
export var fetchGetAllNotificationsSuccess = function (notifications) { return ({
    type: NotificationsActionType.GET_ALL_NOTIFICATIONS_SUCCESS,
    payload: notifications,
}); };
export var fetchGetAllNotificationsFailure = function (error) { return ({
    type: NotificationsActionType.GET_ALL_NOTIFICATIONS_ERROR,
    payload: { error: error },
}); };
export var fetchMarkAllNotificationsBegin = function () { return ({
    type: NotificationsActionType.MARK_ALL_NOTIFICATIONS_PENDING,
}); };
export var fetchMarkAllNotificationsSuccess = function () { return ({
    type: NotificationsActionType.MARK_ALL_NOTIFICATIONS_SUCCESS,
}); };
export var fetchMarkAllNotificationsFailure = function (error) { return ({
    type: NotificationsActionType.MARK_ALL_NOTIFICATIONS_ERROR,
    payload: { error: error },
}); };
export var fetchMarkOneNotificationBegin = function () { return ({
    type: NotificationsActionType.MARK_ONE_NOTIFICATION_PENDING,
}); };
export var fetchMarkOneNotificationSuccess = function () { return ({
    type: NotificationsActionType.MARK_ONE_NOTIFICATION_SUCCESS,
}); };
export var fetchMarkOneNotificationFailure = function (error) { return ({
    type: NotificationsActionType.MARK_ONE_NOTIFICATION_ERROR,
    payload: { error: error },
}); };
export var fetchDeleteAllViewedNotificationsBegin = function () { return ({
    type: NotificationsActionType.DELETE_ALL_VIEWED_NOTIFICATIONS_PENDING,
}); };
export var fetchDeleteAllViewedNotificationsSuccess = function () { return ({
    type: NotificationsActionType.DELETE_ALL_VIEWED_NOTIFICATIONS_SUCCESS,
}); };
export var fetchDeleteAllViewedNotificationsFailure = function (error) { return ({
    type: NotificationsActionType.DELETE_ALL_VIEWED_NOTIFICATIONS_ERROR,
    payload: { error: error },
}); };
export var fetchDeleteOneNotificationBegin = function () { return ({
    type: NotificationsActionType.DELETE_ONE_NOTIFICATION_PENDING,
}); };
export var fetchDeleteOneNotificationSuccess = function () { return ({
    type: NotificationsActionType.DELETE_ONE_NOTIFICATION_SUCCESS,
}); };
export var fetchDeleteOneNotificationFailure = function (error) { return ({
    type: NotificationsActionType.DELETE_ONE_NOTIFICATION_ERROR,
    payload: { error: error },
}); };
