export var EhealthMEApprovalAction;
(function (EhealthMEApprovalAction) {
    EhealthMEApprovalAction["ADD_ITEMS"] = "EHEALTH_ME_APPROVAL_ADD_ITEMS";
    EhealthMEApprovalAction["CLEAR"] = "EHEALTH_ME_APPROVAL_CLEAR";
    EhealthMEApprovalAction["UPDATE"] = "EHEALTH_ME_APPROVAL_UPDATE";
    EhealthMEApprovalAction["SET_IS_LOADING"] = "EHEALTH_ME_APPROVAL_SET_IS_LOADING";
    EhealthMEApprovalAction["SETUP_JOBS"] = "EHEALTH_ME_APPROVAL_SETUP_JOBS";
    EhealthMEApprovalAction["REMOVE_JOB"] = "EHEALTH_ME_APPROVAL_REMOVE_JOB";
    EhealthMEApprovalAction["SET_FILTER"] = "EHEALTH_ME_APPROVAL_SET_FILTER";
})(EhealthMEApprovalAction || (EhealthMEApprovalAction = {}));
