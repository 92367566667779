export var EhealthMedicationRRAction;
(function (EhealthMedicationRRAction) {
    EhealthMedicationRRAction["ADD_ITEMS"] = "EHEALTH_MEDICATION_REQUEST_REQUEST_ADD_ITEMS";
    EhealthMedicationRRAction["CLEAR"] = "EHEALTH_MEDICATION_REQUEST_REQUEST_CLEAR";
    EhealthMedicationRRAction["UPDATE"] = "EHEALTH_MEDICATION_REQUEST_REQUEST_UPDATE";
    EhealthMedicationRRAction["SET_IS_LOADING"] = "EHEALTH_MEDICATION_REQUEST_REQUEST_SET_IS_LOADING";
    EhealthMedicationRRAction["SETUP_JOBS"] = "EHEALTH_MEDICATION_REQUEST_REQUEST_SETUP_JOBS";
    EhealthMedicationRRAction["REMOVE_JOB"] = "EHEALTH_MEDICATION_REQUEST_REQUEST_REMOVE_JOB";
    EhealthMedicationRRAction["SET_FILTER"] = "EHEALTH_MEDICATION_REQUEST_REQUEST_FILTER";
})(EhealthMedicationRRAction || (EhealthMedicationRRAction = {}));
