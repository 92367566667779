var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { HomeIcon } from './HomeIcon';
import { Box, Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import { useStyles } from './BreadcrumbStyle';
import { SeparatorIcon } from './SeparatorIcon';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { MeetingSelector } from '../../../../app/moi/store/booking/meeting/selectors';
import { useSelector } from 'react-redux';
import { SingleBookingSelector } from '../../../../app/moi/store/booking/booking/selector';
import moment from 'moment';
import { CabinetSelector } from '../../../../app/moi/store/cabinet/selector';
import { useTranslation } from 'react-i18next';
var breadcrumbNameMap = {
    '/cabinet/data_archive': 'record.title',
    '/cabinet/screaning-screaning': 'menu.profile.screaning',
    '/cabinet/my-health-dynamic': 'healthDynamic',
    '/cabinet/my-health-dynamic/health-dynamics/101': 'measure_group.101',
    '/cabinet/my-health-dynamic/health-dynamics/102': 'measure_group.102',
    '/cabinet/my-health-dynamic/health-dynamics/103': 'measure_group.103',
    '/cabinet/my-health-dynamic/health-dynamics/104': 'measure_group.104',
    '/cabinet/my-health-dynamic/health-dynamics/105': 'measure_group.105',
    '/cabinet/my-health-dynamic/health-dynamics/106': 'measure_group.106',
    '/cabinet/my-health-dynamic/health-dynamics/107': 'measure_group.107',
    '/cabinet/booked-services': 'patientRecordsTitle',
    '/cabinet/my-booked-services': 'menu.profile.reservations',
    '/cabinet/my-analyse': 'menu.profile.my-analyses',
    '/cabinet/reference': 'menuReferences',
    '/cabinet/charges': 'menu.profile.myFamily',
    '/cabinet/messages': 'oneExpert.message',
    '/cabinet/dialog': 'oneExpert.message',
    '/cabinet/notification': 'menu.profile.notification',
    '/cabinet/schedule': 'schedule.name',
    '/cabinet/clients': 'patients',
    '/cabinet/service': 'oneExpert.services',
    '/cabinet/referral': 'menu.profile.my-analyses',
    '/cabinet/settings': 'myProfile',
    '/e-health': 'e-health',
    '/cabinet/company/patient-entries': 'patientRecordsTitle',
    '/cabinet/company/services': 'company.services',
    '/cabinet/company/reviews': 'menu.profile.responses',
    '/cabinet/company/gallery': 'gallery',
    '/cabinet/company/experts-new': 'company.page.header.doctors',
    '/cabinet/company/experts-schedule': 'company.expertsSchedule',
    '/cabinet/company/news': 'news.news',
    '/cabinet/company/profile/new': 'company.menu.cabinet',
    '/cabinet/company/legal-entity': 'company.menu.eHealth',
    '/cabinet/service-one': 'record.label',
    '/cabinet/booking-summary': 'record.label',
};
function LinkRouter(props) {
    var cabinetType = useSelector(CabinetSelector);
    var i18n = useTranslation().i18n;
    return (React.createElement(Link, __assign({}, props, { component: RouterLink, to: props.to === "/".concat(i18n.language, "/cabinet/booking-summary") || "/".concat(i18n.language, "/cabinet/service-one")
            ? cabinetType === 'doctor'
                ? "/".concat(i18n.language, "/cabinet/booked-services")
                : cabinetType === 'patient'
                    ? "/".concat(i18n.language, "/cabinet/my-booked-services")
                    : props.to
            : props.to })));
}
export var Breadcrumb = function () {
    var classes = useStyles();
    var location = useLocation();
    var pathnames = location.pathname.split('/').filter(function (x) { return x; });
    var meeting = useSelector(MeetingSelector);
    var booking = useSelector(SingleBookingSelector).booking;
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    return (React.createElement(Box, { sx: { marginTop: '20px', marginBottom: '20px' } },
        React.createElement(Breadcrumbs, { "aria-label": "breadcrumb", separator: React.createElement(SeparatorIcon, null) },
            React.createElement(Stack, { justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: '4px' },
                React.createElement(HomeIcon, null),
                React.createElement(Link, { underline: "none", key: "1", className: classes.customTypography, href: "/".concat(i18n.language, "/cabinet/settings") }, t('profileBreadcrumbs01'))),
            pathnames.map(function (value, index) {
                var _a;
                var last = index === pathnames.length - 1;
                var to = "/".concat(pathnames
                    .slice(0, index + 1)
                    .join('/')
                    .slice(3));
                return last ? (location.pathname.includes('/cabinet/service-one/') ? (React.createElement(LinkRouter, { underline: "hover", color: "inherit", to: "/".concat(i18n.language).concat(to), key: to },
                    React.createElement(Typography, { className: classes.customTypographyNow }, meeting === null || meeting === void 0 ? void 0 : meeting.expert.fullName))) : location.pathname.includes('/cabinet/booking-summary/') ? (React.createElement(LinkRouter, { underline: "hover", color: "inherit", to: "/".concat(i18n.language).concat(to), key: to },
                    React.createElement(Typography, { className: classes.customTypographyNow }, "".concat((_a = booking === null || booking === void 0 ? void 0 : booking.service.title) !== null && _a !== void 0 ? _a : '', ". ").concat(moment(booking === null || booking === void 0 ? void 0 : booking.start_date).format('DD.MM.YYYY'))))) : (React.createElement(Typography, { key: to, className: classes.customTypographyNow }, t(breadcrumbNameMap[to])))) : breadcrumbNameMap[to] ? (React.createElement(LinkRouter, { underline: "hover", color: "inherit", to: "/".concat(i18n.language).concat(to), key: to },
                    React.createElement(Typography, { className: classes.customTypography }, t(breadcrumbNameMap[to])))) : ('');
            }))));
};
