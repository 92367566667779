export var CompanyScheduleAction;
(function (CompanyScheduleAction) {
    CompanyScheduleAction["SET_EXPERTS"] = "MOI_COMPANY_SCHEDULE_SET_EXPERTS";
    CompanyScheduleAction["SET_EXPERTS_LOADING"] = "MOI_COMPANY_SCHEDULE_SET_EXPERTS_LOADING";
    CompanyScheduleAction["TOGGLE_SELECTION_EXPERT"] = "MOI_COMPANY_SCHEDULE_TOGGLE_SELECTION_EXPERT";
    CompanyScheduleAction["SET_SCHEDULES"] = "MOI_COMPANY_SCHEDULE_SET_SCHEDULES";
    CompanyScheduleAction["SET_SERVICES"] = "MOI_COMPANY_SCHEDULE_SET_SERVICES";
    CompanyScheduleAction["SET_BOOKINGS"] = "MOI_COMPANY_SCHEDULE_SET_BOOKINGS";
    CompanyScheduleAction["ADD_BOOKINGS"] = "MOI_COMPANY_SCHEDULE_ADD_BOOKINGS";
    CompanyScheduleAction["EDIT_BOOKING"] = "MOI_COMPANY_SCHEDULE_EDIT_BOOKING";
    CompanyScheduleAction["SET_BOOKINGS_LOADING"] = "MOI_COMPANY_SCHEDULE_SET_BOOKINGS_LOADING";
})(CompanyScheduleAction || (CompanyScheduleAction = {}));
