export var ClinicScheduleActionType;
(function (ClinicScheduleActionType) {
    ClinicScheduleActionType["GET_SHEDULES_PENDING"] = "GET_SHEDULES_PENDING";
    ClinicScheduleActionType["GET_SHEDULES_SUCCESS"] = "GET_SHEDULES_SUCCESS";
    ClinicScheduleActionType["GET_SHEDULES_ERROR"] = "GET_SHEDULES_ERROR";
    ClinicScheduleActionType["ADD_SCHEDULE_PENDING"] = "ADD_SCHEDULE_PENDING";
    ClinicScheduleActionType["ADD_SCHEDULE_SUCCESS"] = "ADD_SCHEDULE_SUCCESS";
    ClinicScheduleActionType["ADD_SCHEDULE_ERROR"] = "ADD_SCHEDULE_ERROR";
    ClinicScheduleActionType["EDIT_SCHEDULE_PENDING"] = "EDIT_SCHEDULE_PENDING";
    ClinicScheduleActionType["EDIT_SCHEDULE_SUCCESS"] = "EDIT_SCHEDULE_SUCCESS";
    ClinicScheduleActionType["EDIT_SCHEDULE_ERROR"] = "EDIT_SCHEDULE_ERROR";
    ClinicScheduleActionType["DELETE_SCHEDULE_PENDING"] = "DELETE_SCHEDULE_PENDING";
    ClinicScheduleActionType["DELETE_SCHEDULE_SUCCESS"] = "DELETE_SCHEDULE_SUCCESS";
    ClinicScheduleActionType["DELETE_SCHEDULE_ERROR"] = "DELETE_SCHEDULE_ERROR";
    ClinicScheduleActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(ClinicScheduleActionType || (ClinicScheduleActionType = {}));
