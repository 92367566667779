var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { notificationReducer } from './notification/reducer';
import { appReducer } from './app/reducer';
import { navReducer } from './nav/reducer';
import { resourceReducer } from './resouce';
import { ehealthStore } from '../../app/ehealth/store';
import { moiStore } from '../../app/moi/store';
import { thunk } from 'redux-thunk';
var middleware = [thunk];
export var store = createStore(combineReducers({
    ehealth: ehealthStore,
    moi: moiStore,
    notification: notificationReducer,
    app: appReducer,
    nav: navReducer,
    resource: resourceReducer,
}), composeWithDevTools(applyMiddleware.apply(void 0, __spreadArray([], __read(middleware), false))));
store.subscribe(function () {
    if (store.getState().moi.cabinet.cabinetType.length > 0 && store.getState().moi.cabinet.cabinetType !== '') {
        document.cookie = "cabinetType=".concat(store.getState().moi.cabinet.cabinetType);
    }
});
