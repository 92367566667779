export var CabinetMenuModalSelector = function (state) { return state.moi.modal.cabinetModal.cabinetMenuModal; };
export var CreateChatModalSelector = function (state) { return state.moi.modal.cabinetModal.createChatModal; };
export var ServiceModalSelector = function (state) { return state.moi.modal.cabinetModal.serviceModal; };
export var ServiceEditModalSelector = function (state) { return state.moi.modal.cabinetModal.serviceEditModal; };
export var ServiceSuccessfullyModalSelector = function (state) {
    return state.moi.modal.cabinetModal.serviceSuccessfullyModal;
};
export var ClinicScheduleModalSelector = function (state) { return state.moi.modal.cabinetModal.clinicScheduleModal; };
export var PhotoGalleryModalSelector = function (state) { return state.moi.modal.cabinetModal.galleryPhotoModal; };
export var ReviewsDoctorModalSelector = function (state) { return state.moi.modal.cabinetModal.reviewsDoctorModal; };
export var ReviewsPatientModalSelector = function (state) { return state.moi.modal.cabinetModal.reviewsPatientModal; };
