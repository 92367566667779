import { createStyles, makeStyles } from '@mui/styles';
export var useStyles = makeStyles(function (theme) {
    return createStyles({
        modalContainer: {
            width: '452px',
            height: '360px',
            backgroundColor: '#FFFFFF',
            borderRadius: '20px',
            position: 'absolute',
            top: '28%',
            left: '80%',
            transform: 'translate(-50%, -50%)',
        },
        modalTextHeader: {
            fontWeight: '600',
            color: '#000000',
            fontSize: '16px',
            lineHeight: '16px',
            fontFamily: 'GraphikLCG',
            letterSpacing: '-0.03em',
        },
        modalTextNotRead: {
            fontWeight: '500',
            color: '#1E1E1E',
            fontSize: '12px',
            lineHeight: '12px',
            fontFamily: 'GraphikLCG',
            letterSpacing: '-0.03em',
        },
        modalCountMessageText: {
            fontWeight: '500',
            color: '#FFFFFF',
            fontSize: '12px',
            lineHeight: '12px',
            fontFamily: 'GraphikLCG',
        },
        modalCountMessageCircle: {
            width: '24px',
            height: '20px',
            borderRadius: '10px',
            backgroundColor: '#173236',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalText: {
            fontWeight: '500',
            textAlign: 'center',
            color: '#1E1E1E',
            fontSize: '16px',
            lineHeight: '12px',
            fontFamily: 'GraphikLCG',
        },
        modalDayText: {
            fontWeight: '500',
            padding: '20px',
            color: '#1E1E1E',
            fontSize: '12px',
            lineHeight: '12px',
            fontFamily: 'GraphikLCG',
        },
        ListModal: {
            maxHeight: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            bottom: '-20px',
            right: '-20px',
            overflowY: 'scroll',
            backgroundColor: 'white',
            borderRadius: '20px',
        },
        ListWrapper: {
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
            borderRadius: '20px',
        },
        HeaderNotificationWrapper: {
            margin: '5px 20px 0px 20px',
        },
    });
});
