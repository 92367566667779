export var PARAM_EHEALTH_LOGIN_URL = 'PARAM_EHEALTH_LOGIN_URL';
export var PARAM_EHEALTH_LOGOUT_URL = 'PARAM_EHEALTH_LOGOUT_URL';
export var PARAM_EHEALTH_REAUTH_URL = 'PARAM_EHEALTH_REAUTH_URL';
export var PARAM_EHEALTH_REGISTER_MOI_USER_URL = 'PARAM_EHEALTH_REGISTER_MOI_USER_URL';
export var PARAM_EHEALTH_CREATE_JOB_URL = 'PARAM_EHEALTH_CREATE_JOB_URL';
export var PARAM_EHEALTH_DELETE_JOB_URL = 'PARAM_EHEALTH_DELETE_JOB_URL';
export var PARAM_EHEALTH_UPDATE_JOB_URL = 'PARAM_EHEALTH_UPDATE_JOB_URL';
export var PARAM_EHEALTH_LIST_JOB_URL = 'PARAM_EHEALTH_LIST_JOB_URL';
export var PARAM_EHEALTH_SINGLE_JOB_URL = 'PARAM_EHEALTH_SINGLE_JOB_URL';
export var PARAM_EHEALTH_ENTITIES_JOB_URL = 'PARAM_EHEALTH_ENTITIES_JOB_URL';
export var PARAM_EHEALTH_SINGLE_MEDICAL_EVENT_URL = 'PARAM_EHEALTH_SINGLE_MEDICAL_EVENT_URL';
export var PARAM_EHEALTH_ALL_MEDICAL_PROGRAM_URL = 'PARAM_EHEALTH_ALL_MEDICAL_PROGRAM_URL';
export var PARAM_EHEALTH_MEDICAL_PROGRAM_FOR_MEDICATION_REQUEST_URL = 'PARAM_EHEALTH_MEDICAL_PROGRAM_FOR_MEDICATION_REQUEST_URL';
export var PARAM_EHEALTH_PROGRAM_SERVICE_FOR_SERVICE_REQUEST_URL = 'PARAM_EHEALTH_PROGRAM_SERVICE_FOR_SERVICE_REQUEST_URL';
export var PARAM_EHEALTH_STORED_MEDICATION_REQUEST_REQUEST_CREATE_URL = 'PARAM_EHEALTH_STORED_MEDICATION_REQUEST_REQUEST_CREATE_URL';
export var PARAM_EHEALTH_GET_VACCINE_DISEASES_URL = 'PARAM_EHEALTH_GET_VACCINE_DISEASES_URL';
export var PARAM_EHEALTH_GET_DICT_MAP_URL = 'PARAM_EHEALTH_GET_DICT_MAP_URL';
export var PARAM_EHEALTH_SEARCH_IN_CATEGORY_SERVICE_CATALOG_URL = 'PARAM_EHEALTH_SEARCH_IN_CATEGORY_SERVICE_CATALOG_URL';
export var PARAM_EHEALTH_GET_SERVICE_CATALOG_ITEM_URL = 'PARAM_EHEALTH_GET_SERVICE_CATALOG_ITEM_URL';
export var PARAM_EHEALTH_GET_SERVICE_CATALOG_ITEMS_LIST_URL = 'PARAM_EHEALTH_GET_SERVICE_CATALOG_ITEMS_LIST_URL';
export var PARAM_EHEALTH_PAYLOAD_STORE_LIST_URL = 'PARAM_EHEALTH_PAYLOAD_STORE_LIST_URL';
export var PARAM_EHEALTH_PAYLOAD_STORE_SINGLE_URL = 'PARAM_EHEALTH_PAYLOAD_STORE_SINGLE_URL';
export var PARAM_EHEALTH_PAYLOAD_FIND_SINGLE_URL = 'PARAM_EHEALTH_PAYLOAD_FIND_SINGLE_URL';
export var PARAM_EHEALTH_PAYLOAD_STORE_CREATE_URL = 'PARAM_EHEALTH_PAYLOAD_STORE_CREATE_URL';
export var PARAM_EHEALTH_PAYLOAD_STORE_DELETE_URL = 'PARAM_EHEALTH_PAYLOAD_STORE_DELETE_URL';
export var PARAM_EHEALTH_LEGAL_ENTITY_GET_LIST_URL = 'PARAM_EHEALTH_LEGAL_ENTITY_GET_LIST_URL';
export var PARAM_EHEALTH_SINGLE_STORED_PERSON_URL = 'PARAM_EHEALTH_SINGLE_STORED_PERSON_URL';
export var PARAM_EHEALTH_GET_MONI_HEAL_PERSON = 'PARAM_EHEALTH_GET_MONI_HEAL_PERSON';
export var PARAM_EHEALTH_ASSIGN_MONI_HEAL_PERSON_URL = 'PARAM_EHEALTH_ASSIGN_MONI_HEAL_PERSON_URL';
export var PARAM_EHEALTH_BULK_CREATE_STORED_PERSON_URL = 'PARAM_EHEALTH_BULK_CREATE_STORED_PERSON_URL';
export var PARAM_EHEALTH_SINGLE_STORED_COMPOSITION_URL = 'PARAM_EHEALTH_SINGLE_STORED_COMPOSITION_URL';
export var PARAM_EHEALTH_CREATE_STORED_COMPOSITION_URL = 'PARAM_EHEALTH_CREATE_STORED_COMPOSITION_URL';
export var PARAM_EHEALTH_REMOVE_STORED_COMPOSITION_URL = 'PARAM_EHEALTH_REMOVE_STORED_COMPOSITION_URL';
export var PARAM_EHEALTH_ASSIGN_EMPLOYEE_REQUEST_URL = 'PARAM_EHEALTH_ASSIGN_EMPLOYEE_REQUEST_URL';
export var PARAM_EHEALTH_ASSIGN_EMPLOYEE_COMPLETE_URL = 'PARAM_EHEALTH_ASSIGN_EMPLOYEE_COMPLETE_URL';
export var PARAM_EHEALTH_GET_STORED_PREPERSON_LIST_URL = 'PARAM_EHEALTH_GET_STORED_PREPERSON_LIST_URL';
export var PARAM_EHEALTH_PROXY_URL = 'PARAM_EHEALTH_PROXY_URL';
export var PARAM_MEDZAKUPIVLI_EHEALTH_PROXY_URL = 'PARAM_MEDZAKUPIVLI_EHEALTH_PROXY_URL';
export var PARAM_EHEALTH_STORAGE_PROXY_URL = 'PARAM_EHEALTH_STORAGE_PROXY_URL';
export var PARAM_EHEALTH_GET_DICT_URL = 'PARAM_EHEALTH_GET_DICT_URL';
export var PARAM_EHEALTH_SEARCH_DICT_URL = 'PARAM_EHEALTH_SEARCH_DICT_URL';
export var PARAM_EHEALTH_MEDZAKUPIVLI_GET_VACCINES_LIST_URL = 'PARAM_EHEALTH_MEDZAKUPIVLI_GET_VACCINES_LIST_URL';
export var PARAM_EHEALTH_MEDZAKUPIVLI_GET_VACCINES_BY_LOTS_URL = 'PARAM_EHEALTH_MEDZAKUPIVLI_GET_VACCINES_BY_LOTS_URL';
export var PARAM_EHEALTH_SIGNATURE_CAS_LIST_URL = 'PARAM_EHEALTH_SIGNATURE_CAS_LIST_URL';
export var PARAM_EHEALTH_SIGNATURE_CERTS_URL = 'PARAM_EHEALTH_SIGNATURE_CERTS_URL';
export var PARAM_EHEALTH_SIGNATURE_CHECK_SERTS_URL = 'PARAM_EHEALTH_SIGNATURE_CHECK_SERTS_URL';
export var PARAM_VERIFY_EMPLOYEE_ERROR = 'PARAM_VERIFY_EMPLOYEE_ERROR';
export var PARAM_EHEALTH_PERSON_REQUEST_SAVE = 'PARAM_EHEALTH_PERSON_REQUEST_SAVE';
export var PARAM_EHEALTH_PERSON_REQUEST_SINGLE = 'PARAM_EHEALTH_PERSON_REQUEST_SINGLE';
export var PARAM_EHEALTH_PERSON_REQUEST_SAVE_PERSON_ID = 'PARAM_EHEALTH_PERSON_REQUEST_SAVE_PERSON_ID';
export var PARAM_EHEALTH_GET_MOI_LEGAL_ENTITIES = 'PARAM_EHEALTH_GET_MOI_LEGAL_ENTITIES';
export var PARAM_EHEALTH_SAVE_REORGANIZE_EMPLOYEE_DECLARATION_URL = 'PARAM_EHEALTH_SAVE_REORGANIZE_EMPLOYEE_DECLARATION_URL';
export var PARAM_EHEALTH_GET_REORGANIZE_DECLARATION_LIST_URL = 'PARAM_EHEALTH_GET_REORGANIZE_DECLARATION_LIST_URL';
export var PARAM_EHEALTH_CHANGE_REORGANIZE_DECLARATION_STATUS_URL = 'PARAM_EHEALTH_CHANGE_REORGANIZE_DECLARATION_STATUS_URL';
export var PARAM_SAVE_RELATED_LEGAL_ENTITIES_IDS_URL = 'PARAM_SAVE_RELATED_LEGAL_ENTITIES_IDS_URL';
export var PARAM_EHEALTH_EMPLOYEE_UPDATE_STATUS = 'PARAM_EHEALTH_EMPLOYEE_UPDATE_STATUS';
