import { PARAM_ITEMS_PER_PAGE } from '../../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL } from '../../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../../request';
import { DiagnoseGroupService } from './diagnose-group';
export var SERVICE_EHEALTH_MEDICAL_EVENT_DIAGNOSE_GROUP = 'SERVICE_EHEALTH_MEDICAL_EVENT_DIAGNOSE_GROUP';
export var ehealthDiagnoseGroupFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    return new DiagnoseGroupService({ baseUrl: baseUrl, itemsPerPage: itemsPerPage, requestService: requestService });
};
