import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
import { PARAM_EHEALTH_CHANGE_REORGANIZE_DECLARATION_STATUS_URL, PARAM_EHEALTH_GET_REORGANIZE_DECLARATION_LIST_URL, PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_SAVE_REORGANIZE_EMPLOYEE_DECLARATION_URL, PARAM_SAVE_RELATED_LEGAL_ENTITIES_IDS_URL, } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
import { ReorganizeService } from './reorganize';
import { SERVICE_EHEALTH_AUTH } from '../../auth';
export var SERVICE_EHEALTH_REORGANIZE = 'SERVICE_EHEALTH_REORGANIZE';
export var ehealthReorganizeFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var authService = container.require(SERVICE_EHEALTH_AUTH);
    var saveReorganizeEmployeeDeclarationUrl = "".concat(container.get(PARAM_EHEALTH_SAVE_REORGANIZE_EMPLOYEE_DECLARATION_URL));
    var changeReorganizeDeclarationStatusUrl = "".concat(container.get(PARAM_EHEALTH_CHANGE_REORGANIZE_DECLARATION_STATUS_URL));
    var getReorganizeDeclarationListUrl = "".concat(container.get(PARAM_EHEALTH_GET_REORGANIZE_DECLARATION_LIST_URL));
    var saveRelatedLegalEntitiesIdsUrl = "".concat(container.get(PARAM_SAVE_RELATED_LEGAL_ENTITIES_IDS_URL));
    return new ReorganizeService({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        authService: authService,
        saveReorganizeEmployeeDeclarationUrl: saveReorganizeEmployeeDeclarationUrl,
        changeReorganizeDeclarationStatusUrl: changeReorganizeDeclarationStatusUrl,
        getReorganizeDeclarationListUrl: getReorganizeDeclarationListUrl,
        saveRelatedLegalEntitiesIdsUrl: saveRelatedLegalEntitiesIdsUrl,
    });
};
