var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Size } from '../../../../enum/size';
import { Icon } from '../../../misc/Icon/Icon';
import { PopupMenu } from './PopupMenu/PopupMenu';
export var MobileMenu = function (props) {
    var t = useTranslation().t;
    var _a = __read(useState(false), 2), isOpen = _a[0], setIsOpen = _a[1];
    var documentClickHandler = function (event) {
        event.preventDefault();
        event.stopPropagation();
        setIsOpen(false);
        document.removeEventListener('click', documentClickHandler);
    };
    var menuClickHandler = function (event) {
        event.preventDefault();
        event.stopPropagation();
        if (isOpen) {
            return documentClickHandler(event.nativeEvent);
        }
        setIsOpen(true);
        setTimeout(function () {
            document.addEventListener('click', documentClickHandler);
        }, 150);
    };
    return (React.createElement("div", { className: "cabinet-mobile-nav" },
        isOpen && React.createElement(PopupMenu, { items: props.items, isOpen: isOpen, title: t('headerMenu') }),
        React.createElement("ul", { className: "cabinet-mobile-nav__wrapper" },
            props.shortcuts.map(function (item) {
                return (React.createElement("li", { key: item.link, className: "cabinet-mobile-nav__item" },
                    React.createElement("a", { className: "cabinet-mobile-nav__link ", href: item.link }, item.icon)));
            }),
            React.createElement("li", { className: "cabinet-mobile-nav__item" },
                React.createElement("a", { className: classNames('cabinet-mobile-nav__link', 'cabinet-mobile-more', { active: isOpen }), href: "#", onClick: menuClickHandler },
                    React.createElement(Icon, { outlined: true, size: Size.EXTRA_LARGE, icon: "more_horiz" }),
                    t('headerMenu'))))));
};
