import { PARAM_MOI_COMPANY_SERVICES_LIST_URL, PARAM_MOI_COMPANY_SERVICES_CREATE_URL, PARAM_MOI_COMPANY_SERVICES_EDIT_URL, PARAM_MOI_COMPANY_SERVICES_ASSIGN_URL, PARAM_MOI_COMPANY_SERVICES_UNASSIGN_URL, PARAM_MOI_COMPANY_SERVICES_CATEGORIES_LIST_URL, PARAM_MOI_COMPANY_SERVICES_TEMPLATES_LIST_URL, PARAM_MOI_COMPANY_GET_SERVICE_BY_ID_URL, PARAM_MOI_COMPANY_SERVICES_TEMPLATES_LIST_BY_USER_URL, PARAM_MOI_COMPANY_SERVICE_TO_ARCHIVE_URL, } from '../../../config/di/params/keys';
import { CompanyServices } from './company-services';
export var SERVICE_MOI_COMPANY_SERVICES = 'SERVICE_MOI_COMPANY_SERVICES';
export function companyServicesFactory(container) {
    var servicesListUrl = "".concat(container.get(PARAM_MOI_COMPANY_SERVICES_LIST_URL));
    var servicesTemplatesListUrl = "".concat(container.get(PARAM_MOI_COMPANY_SERVICES_TEMPLATES_LIST_URL));
    var getServicesTemplatesListByUserUrl = "".concat(container.get(PARAM_MOI_COMPANY_SERVICES_TEMPLATES_LIST_BY_USER_URL));
    var servicesCreateUrl = "".concat(container.get(PARAM_MOI_COMPANY_SERVICES_CREATE_URL));
    var servicesEditUrl = "".concat(container.get(PARAM_MOI_COMPANY_SERVICES_EDIT_URL));
    var servicesAssignUrl = "".concat(container.get(PARAM_MOI_COMPANY_SERVICES_ASSIGN_URL));
    var servicesUnassignUrl = "".concat(container.get(PARAM_MOI_COMPANY_SERVICES_UNASSIGN_URL));
    var servicesCategoriesListUrl = "".concat(container.get(PARAM_MOI_COMPANY_SERVICES_CATEGORIES_LIST_URL));
    var getServiceByIdUrl = "".concat(container.get(PARAM_MOI_COMPANY_GET_SERVICE_BY_ID_URL));
    var servicesToArchiveUrl = "".concat(container.get(PARAM_MOI_COMPANY_SERVICE_TO_ARCHIVE_URL));
    return new CompanyServices({
        urls: {
            servicesListUrl: servicesListUrl,
            getServiceByIdUrl: getServiceByIdUrl,
            servicesTemplatesListUrl: servicesTemplatesListUrl,
            getServicesTemplatesListByUserUrl: getServicesTemplatesListByUserUrl,
            servicesCreateUrl: servicesCreateUrl,
            servicesEditUrl: servicesEditUrl,
            servicesAssignUrl: servicesAssignUrl,
            servicesUnassignUrl: servicesUnassignUrl,
            servicesCategoriesListUrl: servicesCategoriesListUrl,
            servicesToArchiveUrl: servicesToArchiveUrl,
        },
    });
}
