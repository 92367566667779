var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultState } from './state';
import { DictionaryAction } from './action.enum';
export function dictionaryReducer(state, action) {
    var _a;
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case DictionaryAction.SET_DICTIONARY:
            return __assign(__assign({}, state), (_a = {}, _a["".concat(action.payload.key)] = action.payload.dict, _a));
        case DictionaryAction.BULK_SET_DICTS:
            return __assign(__assign({}, state), action.payload.dictMap);
    }
    return state;
}
