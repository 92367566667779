export var workInitialState = {
    receiving_street: '',
    receiving_building: '',
    receiving_cabinet: '',
    id: 0,
    status: '',
    role: '',
    categories: [
        {
            title: '',
            description: null,
            id: 0,
        },
    ],
    reason: null,
    license_date: '',
    receiving_city: '',
    receiving_location: '',
    receiving_latitude: 0,
    receiving_longitude: 0,
    experience: '',
    protected_files: [],
    phones: [
        {
            id: 0,
            type: null,
            phone: '',
        },
    ],
};
export var initialState = {
    work: workInitialState,
    loading: false,
    error: null,
};
