var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NotificationAction } from './action.enum';
import { NotificationType } from './notification-type.enum';
export var createAddNotificationAction = function (notification) {
    return {
        type: NotificationAction.ADD_NOTIFICATION,
        payload: {
            notification: __assign(__assign({}, notification), { uid: "notification-".concat(Date.now()), type: notification.type || NotificationType.SUCCESS }),
        },
    };
};
export var createRemoveNotificationAction = function (uid) {
    return {
        type: NotificationAction.REMOVE_NOTIFICATION,
        payload: {
            uid: uid,
        },
    };
};
