var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Box, Typography, Modal } from '@mui/material';
import { useStyles } from './ModalComponent.jss';
import CloseIconSvg from '../../../assets/svg_components/CloseIconSvg';
import Login from './Login/Login';
import Register from './Register/Register';
import { useLocation } from 'react-router-dom';
import RegisterComplete from './Register/RegisterComplete';
import { useSelector } from 'react-redux';
export default function ModalComponent(_a) {
    var open = _a.open, handleClose = _a.handleClose;
    var classes = useStyles();
    var _b = __read(useState(false), 2), showRegister = _b[0], setShowRegister = _b[1];
    var location = useLocation();
    var registrationRedirect = useSelector(function (state) { return state.moi.register.registrationRedirect; });
    useEffect(function () {
        if (location.search === '?showModal=register') {
            setShowRegister(true);
        }
        if (location.search === '?showModal=login') {
            setShowRegister(false);
        }
    }, [location]);
    return (React.createElement(Modal, { open: open, onClose: handleClose },
        React.createElement(Box, { className: classNames(classes.wrapper) },
            React.createElement(Box, { className: classNames(classes.container) },
                React.createElement(Box, { className: classNames(classes.closeBox), onClick: handleClose },
                    React.createElement(CloseIconSvg, null)),
                showRegister ? (registrationRedirect ? (React.createElement(RegisterComplete, null)) : (React.createElement(Register, { handleClose: handleClose }))) : (React.createElement(Login, { handleClose: handleClose })),
                React.createElement(Box, { className: classNames(classes.footer) },
                    React.createElement(Typography, null, showRegister ? 'Вже є акаунт?' : 'Немає акаунту? '),
                    " ",
                    '\u00A0',
                    React.createElement(Typography, { onClick: function () { return setShowRegister(!showRegister); }, className: classNames(classes.registerSwitch) }, showRegister ? 'Увійти в кабінет ' : 'Зареєструйтесь'))))));
}
