import React from 'react';
export default function WhatsappWhiteIconSvg() {
    return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_205_28)" },
            React.createElement("path", { d: "M14.799 11.4922C14.7601 11.4735 13.302 10.7555 13.0429 10.6623C12.9372 10.6243 12.8239 10.5872 12.7033 10.5872C12.5065 10.5872 12.3411 10.6853 12.2122 10.878C12.0666 11.0945 11.6257 11.6099 11.4894 11.7639C11.4716 11.7842 11.4474 11.8085 11.4328 11.8085C11.4198 11.8085 11.1941 11.7156 11.1258 11.6859C9.56171 11.0065 8.3745 9.37265 8.21171 9.09713C8.18846 9.05752 8.18749 9.03954 8.1873 9.03954C8.19301 9.01856 8.24561 8.96584 8.27275 8.93863C8.35217 8.86006 8.43821 8.75648 8.52145 8.65629C8.56087 8.60882 8.60035 8.56129 8.63911 8.51648C8.75989 8.37597 8.81366 8.26687 8.876 8.14051L8.90866 8.07486C9.06087 7.77246 8.93087 7.51726 8.88885 7.43486C8.85437 7.3659 8.23872 5.88006 8.17327 5.72395C8.01587 5.34726 7.80788 5.17188 7.51885 5.17188C7.49204 5.17188 7.51885 5.17187 7.40639 5.17662C7.26944 5.18239 6.52366 5.28058 6.19392 5.48843C5.84424 5.70889 5.25269 6.41162 5.25269 7.64746C5.25269 8.75973 5.95853 9.80993 6.26158 10.2093C6.26911 10.2194 6.28295 10.2399 6.30301 10.2692C7.46359 11.9641 8.91041 13.2203 10.3771 13.8062C11.7891 14.3702 12.4578 14.4354 12.8379 14.4354H12.8379C12.9977 14.4354 13.1255 14.4228 13.2383 14.4117L13.3099 14.4049C13.7977 14.3617 14.8696 13.8062 15.1135 13.1286C15.3055 12.5949 15.3562 12.0118 15.2284 11.8002C15.1409 11.6563 14.99 11.5839 14.799 11.4922Z", fill: "white" }),
            React.createElement("path", { d: "M10.1775 0C4.76089 0 0.354141 4.37364 0.354141 9.74955C0.354141 11.4883 0.819465 13.1903 1.70096 14.6799L0.0137512 19.6569C-0.0176774 19.7497 0.0056992 19.8523 0.0743356 19.9221C0.123881 19.9727 0.191089 20 0.259725 20C0.286024 20 0.312517 19.996 0.338362 19.9878L5.52797 18.3387C6.9481 19.0975 8.55317 19.498 10.1776 19.498C15.5937 19.4981 20 15.1249 20 9.74955C20 4.37364 15.5937 0 10.1775 0ZM10.1775 17.4671C8.64901 17.4671 7.16856 17.0258 5.89596 16.1907C5.85317 16.1626 5.80349 16.1482 5.75349 16.1482C5.72706 16.1482 5.70057 16.1522 5.67479 16.1604L3.07511 16.9868L3.91434 14.5108C3.94148 14.4307 3.92791 14.3423 3.87791 14.274C2.90882 12.9499 2.39654 11.3854 2.39654 9.74955C2.39654 5.49351 5.88706 2.03091 10.1775 2.03091C14.4673 2.03091 17.9575 5.49351 17.9575 9.74955C17.9575 14.0051 14.4675 17.4671 10.1775 17.4671Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_205_28" },
                React.createElement("rect", { width: "20", height: "20", fill: "white" })))));
}
