export var AccessesActionType;
(function (AccessesActionType) {
    AccessesActionType["GET_ACCESSES_PENDING"] = "GET_ACCESSES_PENDING";
    AccessesActionType["GET_ACCESSES_SUCCESS"] = "GET_ACCESSES_SUCCESS";
    AccessesActionType["GET_ACCESSES_ERROR"] = "GET_ACCESSES_ERROR";
    AccessesActionType["ADD_ACCESS_PENDING"] = "ADD_ACCESS_PENDING";
    AccessesActionType["ADD_ACCESS_SUCCESS"] = "ADD_ACCESS_SUCCESS";
    AccessesActionType["ADD_ACCESS_ERROR"] = "ADD_ACCESS_ERROR";
    AccessesActionType["DELETE_ACCESS_PENDING"] = "DELETE_ACCESS_PENDING";
    AccessesActionType["DELETE_ACCESS_SUCCESS"] = "DELETE_ACCESS_SUCCESS";
    AccessesActionType["DELETE_ACCESS_ERROR"] = "DELETE_ACCESS_ERROR";
    AccessesActionType["EDIT_ACCESS_PENDING"] = "EDIT_ACCESS_PENDING";
    AccessesActionType["EDIT_ACCESS_SUCCESS"] = "EDIT_ACCESS_SUCCESS";
    AccessesActionType["EDIT_ACCESS_ERROR"] = "EDIT_ACCESS_ERROR";
})(AccessesActionType || (AccessesActionType = {}));
