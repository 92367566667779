import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotification } from '../../../hook/misc/use-notification';
import { useContainer } from '../../../service/di-container/react/useContainer';
import { SERVICE_NAV_CONFIGURATOR } from '../../../service/nav';
import { isDesktop } from '../../../util/device';
import { ConnectedNotificationList } from '../../misc/ConnectedNotificationList/ConnectedNotificationList';
import { ConnectedDesktopMenu } from '../../nav/Menu/DesktopMenu/ConnectedDesktopMenu';
import { ConnectedMobileMenu } from '../../nav/Menu/MobileMenu/ConnectedMobileMenu';
import { Box, useMediaQuery } from '@mui/material';
import { Container } from '@mui/material';
import { Header } from '../../../../app/moi/components/Header';
import { TabletHeader } from '../../../../app/moi/components/Header/TabletHeader';
export var BasePage = function (props) {
    var children = props.children;
    var container = useContainer();
    var errorNotification = useNotification().errorNotification;
    var navService = container.require(SERVICE_NAV_CONFIGURATOR);
    var showDesktopMenu = isDesktop(props.viewportWidth);
    var matches = useMediaQuery('(max-width:1700px)');
    var t = useTranslation().t;
    useEffect(function () {
        if (!props.error) {
            return;
        }
        errorNotification(t('documents.error'), t('eHealth.reloadAndRetry'));
        console.error(props.error);
        props.clearError();
    }, [props.error]);
    var Sidebar = function () { return (React.createElement("div", { className: "cabinet-sidebar" },
        React.createElement(ConnectedDesktopMenu, null))); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Sidebar, null),
        React.createElement(Box, { component: "main", sx: { padding: '0px 0px', flexGrow: 1, overflow: 'hidden' } },
            React.createElement(Container, { disableGutters: true, maxWidth: false },
                matches ? React.createElement(TabletHeader, null) : React.createElement(Header, null),
                React.createElement("div", { className: "cabinet-content" }, children))),
        !showDesktopMenu && React.createElement(ConnectedMobileMenu, { title: t('profile.menu') }),
        React.createElement(ConnectedNotificationList, null)));
};
