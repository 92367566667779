/// <reference path="./etc.d.ts" />
import { importTemplate } from './dom';

export const debounce = (callback, time = 50) => {
  let timeout = 0;
  return function () {
    const args = arguments;

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback.apply(callback, args);
    }, time);
  };
};

export const tryParseJson = (value, _default = {}) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return _default;
  }
};

export const tryParseNumber = str => {
  const num = parseFloat(str);

  return isNaN(num) ? 0 : num
};

export const stopPropagation = event => {
  event.stopPropagation();
};

export const preventEvent = event => {
  event.preventDefault();
  event.stopPropagation();
};

export const attachIdToUrl = (url, id) => {
  return url.replace(/[0-9]+$/gis, id);
};

export const objectToFormData = obj => {
  const form = new FormData();
  for (const key in obj) {
    if (!obj.hasOwnProperty(key)) {
      continue;
    }

    form.append(key, obj[key]);
  }

  return form;
};

export const getComponentConfig = (component, id, _default = {}) => {
  try {
    const config = window.__moni.components[component][id];

    return { ..._default, ...config };
  } catch (_) {
    return _default;
  }
};

export const getObjectLength = obj => {
  return Object.keys(obj).length;
};

export const clearInputName = name => {
  const matches = [...`${name}`.matchAll(/\[(.+)\]/gis)];

  return (matches[0] || [])[1] || name;
};

export const serializeInputsToObject = inputs => {
  return inputs.reduce((obj, input) => {
    if ((input.type === 'checkbox' || input.type === 'radio') && !input.checked) {
      return obj;
    }

    obj[clearInputName(input.name)] = input.value;

    return obj;
  }, {});
};

export const serializeInputsToFormData = inputs => {
  return inputs.reduce((formData, input) => {
    const type = input.type;

    if ((type === 'checkbox' || type === 'radio') && (!input.checked || input.disabled)) {
      return formData;
    } else if (type === 'file') {
      for (const file of input.files) {
        formData.append(input.name, file);
      }

      return formData;
    }

    formData.append(input.name, input.value);

    return formData;
  }, new FormData());
};

export const getSimpleFormStateMutator = (submitBtn, errorBlock) => (error, submitDisabled) => {
  const submitClassMutator = submitDisabled
    ? submitBtn.classList.add.bind(submitBtn.classList)
    : submitBtn.classList.remove.bind(submitBtn.classList);
  submitBtn.disabled = submitDisabled;
  submitClassMutator('js-wait');

  requestAnimationFrame(() => {
    errorBlock.innerHTML = error;
  });
};

export const mapTemplate = (template, data, schema) => {
  for (const key of Object.keys(schema)) {
    mapKeyToTemplate(template, data[key], schema[key]);
  }

  return template;
};

export const mapNestedDataToTemplate = (template, dataset, config) => {
  const nestedTemplateHolder = template.querySelector(config.template);
  const target = template.querySelector(config.target);
  if (!nestedTemplateHolder || !target || !config.mapping) {
    return template;
  }

  const frag = document.createDocumentFragment();
  for (const data of dataset) {
    const nestedTemplate = importTemplate(nestedTemplateHolder);
    mapTemplate(nestedTemplate, data, config.mapping);
    frag.appendChild(nestedTemplate);
  }

  target.appendChild(frag);

  return template;
};

export const mapKeyToTemplate = (template, value, items) => {
  if (items.nested) {
    return mapNestedDataToTemplate(template, value, items.nested);
  }

  for (const item of items) {
    const elem = item.selector === 'root' ? template : template.querySelector(item.selector);

    for (const prop of item.props || []) {
      elem[prop] = value;
    }

    for (const attr of item.attrs || []) {
      elem.setAttribute(attr, value);
    }
  }

  return template;
};

export const dateFormat = date => {
  const dateObj = new Date(date);

  const day = `${dateObj.getDate()}`.padStart(2, '0');
  const month = `${dateObj.getMonth() + 1}`.padStart(2, '0');
  const year = `${dateObj.getFullYear()}`.substring(2);

  return `${day}.${month}.${year}`;
};

export const durationFormat = seconds => {
  seconds = Math.floor(seconds);

  const parts = [];
  if (seconds > 3600) {
    const hours = Math.floor(seconds / 3600);
    parts.push(`${hours}`.padStart(2, '0'));
    seconds = seconds - hours * 3600;
  }

  const minutes = Math.floor(seconds / 60);
  parts.push(`${minutes}`.padStart(2, '0'));
  seconds = seconds - minutes * 60;

  parts.push(`${Math.floor(seconds)}`.padStart(2, '0'));

  return parts.join(':');
};

export function clearObject(obj) {
  obj =
      obj instanceof Array
          ? obj.filter((item) => item !== undefined && item !== null)
          : obj;
  for (const key of Object.keys(obj)) {
    const isObjectOrArray =
        typeof obj[key] === "object" || obj[key] instanceof Array;
    if (
        (obj[key] === null ||
            obj[key] === undefined ||
            !`${obj[key] || ""}`.length) &&
        obj[key] !== false &&
        obj[key] !== 0
    ) {
      delete obj[key];
    } else if (obj[key] instanceof Array && !obj[key].length) {
      delete obj[key];
    } else if (isObjectOrArray && !Object.keys(obj[key]).length) {
      delete obj[key];
    } else if (isObjectOrArray) {
      obj[key] = clearObject(obj[key]);
    } else if (typeof obj[key] === "undefined") {
      delete obj[key];
    }
  }

  return obj;
}

export function recordFromArray(arr, key) {
  return arr.reduce((acc, val) => {
    acc[val[key]] = val;

    return acc;
  }, {});
}

export function setValueWithChange(input, value) {
  input.value = value;
  input.dispatchEvent(new Event('change'));
}