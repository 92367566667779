import { createUseStyles } from 'react-jss';
export var useStyles = createUseStyles({
    wrapper: {
        height: '480px',
        width: '100vw',
        background: 'linear-gradient(217deg, #E9F3FD, #EBFBF9)',
        display: 'flex',
        justifyContent: 'center',
    },
    container: {
        width: '100%',
        maxWidth: '1920px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'relative',
        padding: '60px 250px 30px 250px',
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0px',
        marginRight: '40px',
    },
    listTitle: {
        fontFamily: 'GraphikLCG',
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '18px',
        letterSpacing: '-0.03em',
        color: '#000000',
        marginBottom: '30px!important',
    },
    listItem: {
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '16px',
        letterSpacing: '-0.03em',
        color: '#000000',
        marginBottom: '22px',
        paddingLeft: '0px',
        paddingBottom: '0px',
    },
    contactsBlock: {
        width: '290px',
    },
    contactItem: {
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '20px',
        letterSpacing: '-0.03em',
        color: '#000000',
        marginBottom: '22px!important',
    },
    contactButton: {
        width: '220px',
        height: '50px',
        borderRadius: '30px',
        backgroundColor: '#29F499',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        marginTop: '64px',
        marginBottom: '126px',
    },
    contactButtonTitle: {
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '16px',
        letterSpacing: '-0.03em',
        color: '#000000',
        marginLeft: '21px!important',
    },
    contactButtonBlock: {
        position: 'absolute',
        width: '50px',
        height: '46px',
        borderRadius: '30px',
        top: '2px',
        right: '2px',
        backgroundColor: '#1E1E1E',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonsBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    socialIcon: {
        width: '40px',
        height: '40px',
        borderRadius: '25px',
        backgroundColor: '#1E1E1E',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    appBlock: {
        width: '320px',
    },
    appTitle: {
        fontFamily: 'GraphikLCG',
        fontWeight: '600',
        fontSize: '30px',
        lineHeight: '40px',
        letterSpacing: '-0.03em',
        color: '#000000',
        marginBottom: '44px!important',
    },
    copyright: {
        position: 'absolute',
        bottom: '30px',
        left: '250px',
        fontFamily: 'GraphikLCG',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '14px',
        letterSpacing: '-0.03em',
        color: '#1E1E1E',
    },
    visa: {
        position: 'absolute',
        bottom: '30px',
        right: '250px',
    },
    '@media screen and (max-width: 1700px)': {
        container: {
            paddingLeft: '100px',
            paddingRight: '100px',
        },
        copyright: {
            left: '100px',
        },
        visa: {
            right: '100px',
        },
    },
    '@media screen and (max-width: 1200px)': {
        wrapper: {
            height: '1200px',
        },
        container: {
            flexWrap: 'wrap',
        },
    },
    '@media screen and (max-width: 768px)': {
        container: {
            paddingLeft: '13px',
            paddingRight: '13px',
            paddingBottom: '150px',
        },
        list: {
            marginRight: '10px',
        },
        copyright: {
            left: '13px',
            bottom: '86px',
        },
        visa: {
            left: '13px',
        },
        contactButton: {
            marginTop: '60px',
            marginBottom: '50px',
        },
    },
});
