import { createUseStyles } from 'react-jss';
export var useStyles = createUseStyles({
    wrapper: {
        width: '100%',
        minWidth: '420px',
        paddingLeft: '10px!important',
        paddingRight: '10px!important',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontFamily: 'GraphikLCG',
        fontWeight: '600',
        fontSize: '30px',
        lineHeight: '30px',
        letterSpacing: '-0.03em',
        color: '#000000',
        marginBottom: '50px!important',
    },
    specialistBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid #E9F2FD',
        height: '30px',
        alignItems: 'start',
        marginBottom: '40px!important',
    },
    specialistBoxItem: {
        position: 'relative',
        marginRight: '50px',
        height: '100%',
    },
    specialistBoxTitle: {
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '20px',
        letterSpacing: '-0.03em',
        color: '#A5BDDB',
        cursor: 'pointer',
    },
    specialistBoxTitleActive: {
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '20px',
        letterSpacing: '-0.03em',
        color: '#173236',
        cursor: 'pointer',
    },
    specialistBoxLine: {
        position: 'absolute',
        width: 'calc(100% + 20px)',
        left: '-10px',
        bottom: '0px',
        height: '4px',
        backgroundColor: '#173236',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
    },
    loginBox: {
        marginBottom: '30px!important',
    },
    inputTitle: {
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '16px',
        letterSpacing: '-0.03em',
        color: '#000000',
        marginBottom: '10px!important',
    },
    input: {
        width: '100%',
        height: '50px',
        border: '#90A4BE',
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '14px',
        letterSpacing: '-0.03em',
        color: '#000000',
        '&::placeholder': {
            fontFamily: 'GraphikLCG',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '14px',
            letterSpacing: '-0.03em',
            color: '#777E90',
        },
    },
    inputError: {
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '14px',
        letterSpacing: '-0.03em',
        color: '#ED4337',
        marginLeft: '10px!important',
        marginTop: '10px!important',
    },
    loginError: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '20px!important',
    },
    loginErrorTitle: {
        fontFamily: 'GraphikLCG',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '14px',
        letterSpacing: '-0.03em',
        color: '#ED4337',
        marginBottom: '5px!important',
    },
    loginErrorText: {
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '14px',
        letterSpacing: '-0.03em',
        color: '#777E90',
    },
    passwordBox: {
        marginBottom: '20px!important',
    },
    passwordInputBox: {
        position: 'relative',
    },
    passwordIcon: {
        position: 'absolute',
        top: '15px',
        right: '15px',
        cursor: 'pointer',
    },
    rememberBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    checkbox: {
        backgroundColor: '#32D29F',
        color: '#FFFFFF',
    },
    checked: {
        backgroundColor: '#32D29F',
        color: '#FFFFFF',
    },
    rememberBoxTitle: {
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '14px',
        letterSpacing: '-0.03em',
        color: '#000000',
    },
    forgetPassword: {
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '14px',
        letterSpacing: '-0.03em',
        color: '#32D29F',
        cursor: 'pointer',
    },
    help: {
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '14px',
        letterSpacing: '-0.03em',
        color: '#777E90',
        marginBottom: '30px!important',
    },
    submitButton: {
        width: '100%',
        height: '50px',
        borderRadius: '10px',
        backgroundColor: '#29F499',
        fontFamily: 'GraphikLCG',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '16px',
        letterSpacing: '-0.03em',
        color: '#000000',
        marginTop: '46px!important',
        marginBottom: '30px!important',
    },
    social: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    socialButton: {
        height: '50px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '10px',
        padding: '10px!important',
    },
    socialIcon: {
        width: '24px',
        height: '24px',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
    },
    socialTitle: {
        fontFamily: 'GraphikLCG',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '16px',
        letterSpacing: '-0.03em',
        color: '#FFFFFF',
        marginLeft: '8px!important',
    },
    subtitle: {
        fontFamily: 'GraphikLCG',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.03em',
        color: '#000000',
        marginBottom: '10px!important',
    },
    '@media screen and (max-width: 768px)': {
        wrapper: {
            minWidth: '300px',
            paddinLeft: '0px!important',
            paddingRight: '0px!important',
        },
        title: {
            fontSize: '26px',
        },
        specialistBoxTitle: {
            fontSize: '18px',
        },
        specialistBoxTitleActive: {
            fontSize: '18px',
        },
        submitButton: {
            marginBottom: '30px!important',
        },
        help: {
            marginBottom: '20px!important',
        },
        socialTitle: {
            display: 'none',
        },
    },
});
