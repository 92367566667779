export const listenElement = (wrapper, path, event, callback) => {
  const el = wrapper.querySelector(path);
  el && el.addEventListener(event, callback);

  return el;
};

export const findAll = (wrapper, path) => {
  return Array.from(wrapper.querySelectorAll(path));
};

export const listenAllElements = (wrapper, path, event, callback) => {
  const items = findAll(wrapper, path);

  for (const item of items) {
    item.addEventListener(event, callback);
  }

  return items;
};

export const htmlToDom = html => {
  const template = document.createElement('template');
  template.innerHTML = html;

  return template.content;
};

export const importTemplate = template => {
  if ('content' in document.createElement('template')) {
    return document.importNode(template.content, true);
  }

  return template.firstElementChild;
};

export const setAllAvailableViewportHeight = elem => {
  let resizeTimeout = null;
  let maxHeight = 0;

  const setSize = () => {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {
      const rect = elem.getBoundingClientRect();

      requestAnimationFrame(() => {
        const menuOffset = window.innerWidth < 992 ? 60 : 0;
        const height = window.innerHeight - (window.scrollY + rect.top + menuOffset);
        if (height > maxHeight) {
          maxHeight = height;
          elem.style.height = `${maxHeight}px`;
        } else if (height < 0) {
          // if element out of viewport
          elem.style.height = `${Math.round(window.innerHeight / 2)}px`;
        }
      });
    }, 300);
  };

  setSize();
  window.addEventListener('resize', setSize);
};

export const jsonToDom = json => {
  const fragment = document.createDocumentFragment();
  const iterate = (items, callback) => {
    if (items) {
      for (const key in items) {
        if (!items.hasOwnProperty(key)) {
          continue;
        }

        callback(items[key], key);
      }
    }
  };

  for (const key in json) {
    if (!json.hasOwnProperty(key)) {
      continue;
    }

    const { tag = 'div', props, atts, data, listeners, children, ref, elem } = json[key];
    elem = elem ? elem : document.createElement(tag);

    iterate(props, (value, key) => {
      elem[key] = value;
    });

    iterate(atts, (value, key) => {
      elem.setAttribute(key, value);
    });

    iterate(data, (value, key) => {
      elem.dataset[key] = value;
    });

    iterate(listeners, (value, key) => {
      elem.addEventListener(key, value);
    });

    if (children && children.length > 0) {
      elem.appendChild(jsonToDom(children));
    }

    ref && ref(elem);

    fragment.appendChild(elem);
  }

  return fragment;
};
