export var ErrorTypes;
(function (ErrorTypes) {
    ErrorTypes["cast"] = "cast";
    ErrorTypes["required"] = "required";
    ErrorTypes["format"] = "format";
    ErrorTypes["inclusion"] = "inclusion";
    ErrorTypes["exclusion"] = "exclusion";
    ErrorTypes["subset"] = "subset";
    ErrorTypes["length"] = "length";
    ErrorTypes["number"] = "number";
    ErrorTypes["confirmation"] = "confirmation";
    ErrorTypes["acceptance"] = "acceptance";
    ErrorTypes["email"] = "email";
    ErrorTypes["phone_number"] = "phone_number";
    ErrorTypes["card_number"] = "card_number";
    ErrorTypes["metadata"] = "metadata";
    ErrorTypes["unique"] = "unique";
    ErrorTypes["schemata"] = "schemata";
    ErrorTypes["schema"] = "schema";
    ErrorTypes["dependency"] = "dependency";
    ErrorTypes["json"] = "json";
    ErrorTypes["authentification_code"] = "authentification_code";
    ErrorTypes["date"] = "date";
    ErrorTypes["datetime"] = "datetime";
    ErrorTypes["timestamp"] = "timestamp";
    ErrorTypes["url"] = "url";
})(ErrorTypes || (ErrorTypes = {}));
