import { createStyles, makeStyles } from '@mui/styles';
export var useStyles = makeStyles(function (theme) {
    return createStyles({
        ToggleWrapper: {
            border: '1px solid #F1F6FA',
            width: '60px',
            height: '30px',
            borderRadius: '30px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
        },
        ButtonToggle: {
            backgroundColor: 'transparent',
            width: '22px',
            height: '22px',
            minHeight: '22px',
            margin: '4px',
            boxShadow: 'none',
        },
        ButtonToggleActive: {
            backgroundColor: '#F1F6FA',
        },
    });
});
