import { PARAM_MOI_COMPANY_EXPERT_SCHEDULE_CREATE_URL, PARAM_MOI_COMPANY_EXPERT_SCHEDULE_DELETE_URL, PARAM_MOI_COMPANY_EXPERT_SCHEDULE_EDIT_URL, PARAM_MOI_COMPANY_EXPERT_SCHEDULE_LIST_URL, } from '../../../config/di/params/keys';
import { CompanyExpertSchedule } from './company-expert-schedule';
export var SERVICE_MOI_COMPANY_EXPERT_SCHEDULE = 'SERVICE_MOI_COMPANY_EXPERT_SCHEDULE';
export function companyExpertScheduleFactory(container) {
    var companyExpertScheduleListUrl = "".concat(container.get(PARAM_MOI_COMPANY_EXPERT_SCHEDULE_LIST_URL));
    var companyExpertScheduleCreateUrl = "".concat(container.get(PARAM_MOI_COMPANY_EXPERT_SCHEDULE_CREATE_URL));
    var companyExpertScheduleEditUrl = "".concat(container.get(PARAM_MOI_COMPANY_EXPERT_SCHEDULE_EDIT_URL));
    var companyExpertScheduleDeleteUrl = "".concat(container.get(PARAM_MOI_COMPANY_EXPERT_SCHEDULE_DELETE_URL));
    return new CompanyExpertSchedule({
        urls: {
            companyExpertScheduleListUrl: companyExpertScheduleListUrl,
            companyExpertScheduleCreateUrl: companyExpertScheduleCreateUrl,
            companyExpertScheduleEditUrl: companyExpertScheduleEditUrl,
            companyExpertScheduleDeleteUrl: companyExpertScheduleDeleteUrl,
        },
    });
}
