import { AccessesActionType } from './action';
export var fetchGetAccessesBegin = function () { return ({
    type: AccessesActionType.GET_ACCESSES_PENDING,
}); };
export var fetchGetAccessesSuccess = function (accesses) { return ({
    type: AccessesActionType.GET_ACCESSES_SUCCESS,
    payload: accesses,
}); };
export var fetchGetAccessesFailure = function (error) { return ({
    type: AccessesActionType.GET_ACCESSES_ERROR,
    payload: { error: error },
}); };
export var fetchAddAccessBegin = function () { return ({
    type: AccessesActionType.ADD_ACCESS_PENDING,
}); };
export var fetchAddAccessSuccess = function () { return ({
    type: AccessesActionType.ADD_ACCESS_SUCCESS,
}); };
export var fetchAddAccessFailure = function (error) { return ({
    type: AccessesActionType.ADD_ACCESS_ERROR,
    payload: { error: error },
}); };
export var fetchDeleteAccessBegin = function () { return ({
    type: AccessesActionType.DELETE_ACCESS_PENDING,
}); };
export var fetchDeleteAccessSuccess = function () { return ({
    type: AccessesActionType.DELETE_ACCESS_SUCCESS,
}); };
export var fetchDeleteAccessFailure = function (error) { return ({
    type: AccessesActionType.DELETE_ACCESS_ERROR,
    payload: { error: error },
}); };
export var fetchEditAccessBegin = function () { return ({
    type: AccessesActionType.EDIT_ACCESS_PENDING,
}); };
export var fetchEditAccessSuccess = function () { return ({
    type: AccessesActionType.EDIT_ACCESS_SUCCESS,
}); };
export var fetchEditAccessFailure = function (error) { return ({
    type: AccessesActionType.EDIT_ACCESS_ERROR,
    payload: { error: error },
}); };
