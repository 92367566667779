export var ModalActionTypes;
(function (ModalActionTypes) {
    ModalActionTypes[ModalActionTypes["ShowCabinetMenuModal"] = 0] = "ShowCabinetMenuModal";
    ModalActionTypes[ModalActionTypes["HideCabinetMenuModal"] = 1] = "HideCabinetMenuModal";
    ModalActionTypes[ModalActionTypes["ShowCreateChatModal"] = 2] = "ShowCreateChatModal";
    ModalActionTypes[ModalActionTypes["HideCreateChatModal"] = 3] = "HideCreateChatModal";
    ModalActionTypes[ModalActionTypes["ShowServiceModal"] = 4] = "ShowServiceModal";
    ModalActionTypes[ModalActionTypes["HideServiceModal"] = 5] = "HideServiceModal";
    ModalActionTypes[ModalActionTypes["ShowEditServiceModal"] = 6] = "ShowEditServiceModal";
    ModalActionTypes[ModalActionTypes["HideEditServiceModal"] = 7] = "HideEditServiceModal";
    ModalActionTypes[ModalActionTypes["ShowServiceSuccessfullyModal"] = 8] = "ShowServiceSuccessfullyModal";
    ModalActionTypes[ModalActionTypes["HideServiceSuccessfullyModal"] = 9] = "HideServiceSuccessfullyModal";
    ModalActionTypes[ModalActionTypes["ShowClinicScheduleModal"] = 10] = "ShowClinicScheduleModal";
    ModalActionTypes[ModalActionTypes["HideClinicScheduleModal"] = 11] = "HideClinicScheduleModal";
    ModalActionTypes[ModalActionTypes["ShowGalleryModal"] = 12] = "ShowGalleryModal";
    ModalActionTypes[ModalActionTypes["HideGalleryModal"] = 13] = "HideGalleryModal";
    ModalActionTypes[ModalActionTypes["ShowReviewsDoctorModal"] = 14] = "ShowReviewsDoctorModal";
    ModalActionTypes[ModalActionTypes["HideReviewsDoctorModal"] = 15] = "HideReviewsDoctorModal";
    ModalActionTypes[ModalActionTypes["ShowReviewsPatientModal"] = 16] = "ShowReviewsPatientModal";
    ModalActionTypes[ModalActionTypes["HideReviewsPatientModal"] = 17] = "HideReviewsPatientModal";
})(ModalActionTypes || (ModalActionTypes = {}));
export function showCabinetMenuModal() {
    return {
        type: ModalActionTypes.ShowCabinetMenuModal,
    };
}
export function hideCabinetMenuModal() {
    return {
        type: ModalActionTypes.HideCabinetMenuModal,
    };
}
export function showCreateChatModal() {
    return {
        type: ModalActionTypes.ShowCreateChatModal,
    };
}
export function hideCreateChatModal() {
    return {
        type: ModalActionTypes.HideCreateChatModal,
    };
}
export function showServiceModal() {
    return {
        type: ModalActionTypes.ShowServiceModal,
    };
}
export function hideServiceModal() {
    return {
        type: ModalActionTypes.HideServiceModal,
    };
}
export function showEditServiceModal() {
    return {
        type: ModalActionTypes.ShowEditServiceModal,
    };
}
export function hideEditServiceModal() {
    return {
        type: ModalActionTypes.HideEditServiceModal,
    };
}
export function showServiceSuccessfullyModal() {
    return {
        type: ModalActionTypes.ShowServiceSuccessfullyModal,
    };
}
export function hideServiceSuccessfullyModal() {
    return {
        type: ModalActionTypes.HideServiceSuccessfullyModal,
    };
}
export function showClinicScheduleModal() {
    return {
        type: ModalActionTypes.ShowClinicScheduleModal,
    };
}
export function hideClinicScheduleModal() {
    return {
        type: ModalActionTypes.HideClinicScheduleModal,
    };
}
export function showGalleryModal() {
    return {
        type: ModalActionTypes.ShowGalleryModal,
    };
}
export function hideGalleryModal() {
    return {
        type: ModalActionTypes.HideGalleryModal,
    };
}
export function showReviewsDoctorModal() {
    return {
        type: ModalActionTypes.ShowReviewsDoctorModal,
    };
}
export function hideReviewsDoctorModal() {
    return {
        type: ModalActionTypes.HideReviewsDoctorModal,
    };
}
export function showReviewsPatientModal() {
    return {
        type: ModalActionTypes.ShowReviewsPatientModal,
    };
}
export function hideReviewsPatientModal() {
    return {
        type: ModalActionTypes.HideReviewsPatientModal,
    };
}
