export var ChoiceListKey;
(function (ChoiceListKey) {
    ChoiceListKey["EMPLOYEE"] = "employee";
    ChoiceListKey["DIAGNOSTIC_REPORT"] = "diagnosticReport";
    ChoiceListKey["OBSERVATION"] = "observation";
    ChoiceListKey["MEDICATION_REQUEST"] = "medicationRequest";
    ChoiceListKey["CONDITION"] = "condition";
    ChoiceListKey["SERVICE_REQUEST"] = "serviceRequest";
    ChoiceListKey["HC_SERVICE"] = "hcService";
    ChoiceListKey["EPISODE"] = "episode";
    ChoiceListKey["ENCOUNTER"] = "encounter";
    ChoiceListKey["DIVISION"] = "division";
    ChoiceListKey["MEDICATION_ADMINISTRATION"] = "medicationAdministration";
    ChoiceListKey["MEDICATION_STATEMENT"] = "medicationStatement";
    ChoiceListKey["PROCEDURE"] = "procedure";
    ChoiceListKey["IMMUNIZATION"] = "immunization";
    ChoiceListKey["DEVICE"] = "device";
    ChoiceListKey["DEVICE_DEFINITION"] = "deviceDefinition";
    ChoiceListKey["MEDICAL_PROGRAM"] = "medicalProgram";
    ChoiceListKey["MEDICAL_PROGRAM_FOR_MEDICATION_REQUEST"] = "medicalProgramForMedicationRequest";
    ChoiceListKey["CARE_PLAN"] = "carePlan";
    ChoiceListKey["CATALOG_SERVICE_ITEMS"] = "catalogServiceItems";
    ChoiceListKey["CARE_PLAN_ACTIVITIES"] = "carePlanActivities";
    ChoiceListKey["LEGAL_ENTITIES"] = "legalEntities";
    ChoiceListKey["FORBIDDEN_GROUPS"] = "forbiddenGroups";
    ChoiceListKey["LICENSE"] = "license";
    ChoiceListKey["CAPITATION_CONTRACT"] = "capitationContract";
    ChoiceListKey["DIAGNOSES_GROUP"] = "diagnosesGroup";
    ChoiceListKey["SERVICE_GROUP"] = "serviceGroup";
    ChoiceListKey["PATIENT"] = "patient";
})(ChoiceListKey || (ChoiceListKey = {}));
