var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fetchAddAccessBegin, fetchAddAccessFailure, fetchAddAccessSuccess, fetchGetAccessesBegin, fetchGetAccessesFailure, fetchGetAccessesSuccess, } from './factory';
export var getAccesses = function () {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(fetchGetAccessesBegin());
            try {
                fetch('/api/v1/user/archive/accesses')
                    .then(function (resp) {
                    return resp.json();
                })
                    .then(function (data) {
                    //console.log(data.archiveAccessList);
                    dispatch(fetchGetAccessesSuccess(data.archiveAccessList));
                });
            }
            catch (err) {
                dispatch(fetchGetAccessesFailure(err));
            }
            return [2 /*return*/];
        });
    }); };
};
export var addRecordsFolder = function (_a) {
    var experts = _a.experts, records = _a.records;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(fetchAddAccessBegin());
            try {
                fetch('/api/v1/user/archive/give/access', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ experts: experts, records: records }),
                }).then(function (resp) {
                    if (resp.status === 200) {
                        dispatch(fetchAddAccessSuccess());
                    }
                });
            }
            catch (err) {
                dispatch(fetchAddAccessFailure(err));
                console.log(err);
            }
            return [2 /*return*/];
        });
    }); };
};
//export const deleteRecordsFolder = (id: number) => {
//  return async (dispatch: Dispatch) => {
//    dispatch(fetchDeleteRecorsFolderBegin());
//    try {
//      fetch(`/api/v1/user/archive/folder/${id}/delete`, {
//        method: 'DELETE',
//        headers: {
//          'Content-Type': 'application/json',
//        },
//      }).then(resp => {
//        console.log(resp);
//        if (resp.status == 200) {
//          dispatch(fetchDeleteRecorsFolderSuccess());
//        }
//        return resp.json();
//      });
//    } catch (err: any) {
//      dispatch(fetchDeleteRecorsFolderFailure(err));
//    }
//  };
//};
//export const editRecordsFolder = (id: number, folderName: string) => {
//  return async (dispatch: Dispatch) => {
//    dispatch(fetchEditRecorsFolderBegin());
//    try {
//      fetch(`/api/v1/user/archive/folder/${id}/edit`, {
//        method: 'POST',
//        headers: {
//          'Content-Type': 'application/json',
//        },
//        body: JSON.stringify({
//          folderName,
//        }),
//      }).then(resp => {
//        if (resp.status == 200) {
//          dispatch(fetchEditRecorsFolderSuccess());
//        }
//        return resp.json();
//      });
//    } catch (err: any) {
//      dispatch(fetchEditRecorsFolderFailure(err));
//    }
//  };
//};
