var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
export var CapitalizeFirstLetter = function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
export var IdentifyMobileOperator = function (phone) {
    return /^(\+380(96|68){1})\d{7}$/.test(phone)
        ? 'kyivstar'
        : /^(\+380(99){1})\d{7}$/.test(phone)
            ? 'vodaphone'
            : /^(\+380(63){1})\d{7}$/.test(phone)
                ? 'lifecell'
                : null;
};
export function getCookieValue(name) {
    var regex = new RegExp("(^| )".concat(name, "=([^;]+)"));
    var match = document.cookie.match(regex);
    if (match) {
        return match[2];
    }
}
export var getCabinetTypeFromCookies = function () {
    var data = getCookieValue('cabinetType');
    var cabinetType = data ? data : '';
    return cabinetType;
};
export var getCabinetLocationFromCookies = function (type) {
    var data = getCookieValue("cabinet".concat(type, "Location"));
    var cabinetLocation = data ? data : '';
    return cabinetLocation;
};
export function useDebounce(value, delay) {
    var _a = __read(useState(value), 2), debouncedValue = _a[0], setDebouncedValue = _a[1];
    useEffect(function () {
        var handler = setTimeout(function () {
            setDebouncedValue(value);
        }, delay);
        return function () {
            clearTimeout(handler);
        };
    }, [value]);
    return debouncedValue;
}
