import classNames from 'classnames';
import React from 'react';
import { useStyles } from './index.jss';
import { Box, Typography } from '@mui/material';
import ArrowDownIconSvg from '../../../assets/svg_components/ArrowDownIconSvg';
export var Swicher = function (props) {
    var classes = useStyles();
    return (React.createElement(Box, { className: classNames(classes.wrapper) },
        React.createElement(Typography, { className: classNames(classes.active) }, "\u0423\u043A\u0440"),
        React.createElement(ArrowDownIconSvg, { color: '#1E1E1E' })));
};
