import { CarePlan } from './care-plan';
import { PARAM_ITEMS_PER_PAGE } from '../../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL } from '../../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../../request';
import { SERVICE_EHEALTH_SIGN } from '../../signature';
export var SERVICE_EHEALTH_MEDICAL_EVENT_CARE_PLAN = 'SERVICE_EHEALTH_MEDICAL_EVENT_CARE_PLAN';
export var ehealthCarePlanFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var signService = container.require(SERVICE_EHEALTH_SIGN);
    return new CarePlan({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        signService: signService,
        requestService: requestService,
    });
};
