var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { CapitationContract } from '../../../../enum/CapitationContract';
import { hashFile, uploadFile } from '../../../../../../shared/util/file';
import { ResponseError } from '../../../../../../shared/error/response-error';
import { clearAndSerialize } from '../../../../../../shared/util/normalizer';
var CapitationContractRequestService = /** @class */ (function () {
    function CapitationContractRequestService(params) {
        this.authService = params.authService;
        this.baseUrl = params.baseUrl;
        this.storageUrl = params.storageUrl;
        this.itemsPerPage = params.itemsPerPage;
        this.requestService = params.requestService;
        this.signService = params.signService;
    }
    CapitationContractRequestService.prototype.initializeContract = function (contractType) {
        if (contractType === void 0) { contractType = CapitationContract.CAPITATION; }
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/contract_requests/").concat(contractType))];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    CapitationContractRequestService.prototype.putDocument = function (file, url) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, uploadFile(file, "".concat(this.storageUrl).concat(url))];
            });
        });
    };
    CapitationContractRequestService.prototype.prepareContractDataForSign = function (data) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _b, statuteHash, docHash;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            hashFile(data.statute_md5),
                            hashFile(data.additional_document_md5),
                        ])];
                    case 1:
                        _b = __read.apply(void 0, [_c.sent(), 2]), statuteHash = _b[0], docHash = _b[1];
                        if (!((_a = data.external_contractors) === null || _a === void 0 ? void 0 : _a.length)) {
                            delete data.external_contractors;
                        }
                        data.statute_md5 = statuteHash;
                        data.additional_document_md5 = docHash;
                        data.id_form = CapitationContract.PMD_1;
                        return [2 /*return*/, clearAndSerialize(data)];
                }
            });
        });
    };
    CapitationContractRequestService.prototype.createContract = function (data, initializeContractId, signParams, contractType) {
        if (contractType === void 0) { contractType = CapitationContract.CAPITATION; }
        return __awaiter(this, void 0, void 0, function () {
            var preparedData, signedData, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        return [4 /*yield*/, this.prepareContractDataForSign(__assign({}, data))];
                    case 3:
                        preparedData = _a.sent();
                        return [4 /*yield*/, this.signService.sign(preparedData, 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 4:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/contract_requests/").concat(contractType, "/").concat(initializeContractId), undefined, JSON.stringify({
                                signed_content: signedData,
                                signed_content_encoding: 'base64',
                            }))];
                    case 5:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    CapitationContractRequestService.prototype.getContractList = function (page, contractType) {
        if (page === void 0) { page = 1; }
        if (contractType === void 0) { contractType = CapitationContract.CAPITATION; }
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/contract_requests/").concat(contractType), {
                            page: "".concat(page),
                            page_size: "".concat(this.itemsPerPage),
                        })];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    CapitationContractRequestService.prototype.getContractDetails = function (id, contractType) {
        if (contractType === void 0) { contractType = CapitationContract.CAPITATION; }
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/contract_requests/").concat(contractType, "/").concat(id))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __assign(__assign({}, json.data), (json.urgent || {}))];
                }
            });
        });
    };
    CapitationContractRequestService.prototype.terminateContract = function (id, data, contractType) {
        if (contractType === void 0) { contractType = CapitationContract.CAPITATION; }
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/contract_requests/").concat(contractType, "/").concat(id, "/actions/terminate"), undefined, JSON.stringify(data))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    CapitationContractRequestService.prototype.getPrintoutContent = function (id, contractType) {
        if (contractType === void 0) { contractType = CapitationContract.CAPITATION; }
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/contract_requests/").concat(contractType, "/").concat(id, "/printout_content"))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp.clone());
                        }
                        return [2 /*return*/, json.data.printout_content];
                }
            });
        });
    };
    CapitationContractRequestService.prototype.approveContract = function (id, contractType) {
        if (contractType === void 0) { contractType = CapitationContract.CAPITATION; }
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/contract_requests/").concat(contractType, "/").concat(id, "/actions/approve_msp"))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    CapitationContractRequestService.prototype.getDocumentToSign = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("".concat(this.storageUrl).concat(url), {
                            headers: {
                                Accept: 'plain/text',
                            },
                        })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.blob()];
                }
            });
        });
    };
    CapitationContractRequestService.prototype.getSignDocumentUrl = function (documents) {
        var e_1, _a;
        try {
            for (var documents_1 = __values(documents), documents_1_1 = documents_1.next(); !documents_1_1.done; documents_1_1 = documents_1.next()) {
                var document_1 = documents_1_1.value;
                if (document_1.type === 'SIGNED_CONTENT') {
                    return document_1.url;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (documents_1_1 && !documents_1_1.done && (_a = documents_1.return)) _a.call(documents_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return '';
    };
    CapitationContractRequestService.prototype.signContract = function (id, signParams, contractType) {
        if (contractType === void 0) { contractType = CapitationContract.CAPITATION; }
        return __awaiter(this, void 0, void 0, function () {
            var contractRequest, url, data, signedData, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.getContractDetails(id)];
                    case 3:
                        contractRequest = _a.sent();
                        url = new URL(this.getSignDocumentUrl(contractRequest.documents));
                        return [4 /*yield*/, this.getDocumentToSign("".concat(url.pathname).concat(url.search))];
                    case 4:
                        data = _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        return [4 /*yield*/, this.signService.sign(data, 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 5:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/contract_requests/").concat(contractType, "/").concat(id, "/actions/sign_msp"), undefined, JSON.stringify({
                                signed_content: signedData,
                                signed_content_encoding: 'base64',
                            }))];
                    case 6:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    CapitationContractRequestService.prototype.cancelRequest = function () {
        this.requestService.cancel();
    };
    return CapitationContractRequestService;
}());
export { CapitationContractRequestService };
