var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import PasswordRestoreForm from './PasswordRestoreForm';
import LoginForm from './LoginForm';
export default function Login(_a) {
    var handleClose = _a.handleClose;
    var _b = __read(useState(false), 2), isForgetPassword = _b[0], setIsForgetPassword = _b[1];
    return (React.createElement(React.Fragment, null, isForgetPassword ? (React.createElement(PasswordRestoreForm, null)) : (React.createElement(LoginForm, { handleClose: handleClose, setIsForgetPassword: setIsForgetPassword }))));
}
