import { PARAM_ITEMS_PER_PAGE } from '../../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL } from '../../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../../request';
import { SERVICE_EHEALTH_JOB } from '../../job';
import { EpisodeService } from './episode';
import { SERVICE_EHEALTH_AUTH } from "../../../auth";
export var SERVICE_EHEALTH_EPISODES = 'SERVICE_EHEALTH_EPISODES';
export var ehealthEpisodesFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var jobService = container.require(SERVICE_EHEALTH_JOB);
    var authService = container.require(SERVICE_EHEALTH_AUTH);
    return new EpisodeService({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        jobService: jobService,
        authService: authService
    });
};
