export var initialState = {
    clinicGallery: [],
    onePhoto: [],
    totalCount: 0,
    selectedPhoto: 0,
    isAdded: false,
    isEdited: false,
    isDeleted: false,
    isLoading: false,
    error: null,
};
