import { PARAM_ITEMS_PER_PAGE } from '../../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_STORAGE_PROXY_URL } from '../../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../../request';
import { CapitationContractService } from './capitation-contract';
export var SERVICE_EHEALTH_CAPITATION_CONTRACT = 'SERVICE_EHEALTH_CAPITATION_CONTRACT';
export var ehealthCapitationContractFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var storageUrl = "".concat(container.get(PARAM_EHEALTH_STORAGE_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    return new CapitationContractService({
        baseUrl: baseUrl,
        storageUrl: storageUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
    });
};
