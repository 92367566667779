import { PARAM_MOI_CLIENT_TAGS_LIST_URL, PARAM_MOI_CLIENT_TAGS_CREATE_URL, PARAM_MOI_CLIENT_TAGS_DELETE_URL, PARAM_MOI_CLIENT_TAGS_EDIT_URL, PARAM_MOI_CLIENT_TAGS_ATTACH_URL, PARAM_MOI_CLIENT_TAGS_DETACH_URL, PARAM_MOI_CLIENT_TAGS_MASS_APPLY_URL } from '../../config/di/params/keys';
import { ClientTags } from './client-tags';
export var SERVICE_MOI_CLIENT_TAGS = 'SERVICE_MOI_CLIENT_TAGS';
export function clientTagsFactory(container) {
    var listUrl = "".concat(container.get(PARAM_MOI_CLIENT_TAGS_LIST_URL));
    var createUrl = "".concat(container.get(PARAM_MOI_CLIENT_TAGS_CREATE_URL));
    var deleteUrl = "".concat(container.get(PARAM_MOI_CLIENT_TAGS_DELETE_URL));
    var editUrl = "".concat(container.get(PARAM_MOI_CLIENT_TAGS_EDIT_URL));
    var attachUrl = "".concat(container.get(PARAM_MOI_CLIENT_TAGS_ATTACH_URL));
    var detachUrl = "".concat(container.get(PARAM_MOI_CLIENT_TAGS_DETACH_URL));
    var massApplyUrl = "".concat(container.get(PARAM_MOI_CLIENT_TAGS_MASS_APPLY_URL));
    return new ClientTags({
        urls: {
            listUrl: listUrl,
            createUrl: createUrl,
            deleteUrl: deleteUrl,
            editUrl: editUrl,
            attachUrl: attachUrl,
            detachUrl: detachUrl,
            massApplyUrl: massApplyUrl
        }
    });
}
