var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Accordion, AccordionDetails, AccordionSummary, Box, Collapse, Stack, useTheme, } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { useStyles } from './MenuItemStyle';
import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
export var MenuItemDesktop = function (_a) {
    var isActive = _a.isActive, title = _a.title, icon = _a.icon, link = _a.link, subMenuItems = _a.subMenuItems, reloadPage = _a.reloadPage;
    var classes = useStyles();
    var theme = useTheme();
    var pathname = useLocation().pathname;
    var t = useTranslation().t;
    var _b = __read(useState(true), 2), isExpandedMenuItemAccordion = _b[0], setIsExpandedMenuItemAccordion = _b[1];
    useEffect(function () {
        if (title && isActive) {
            document.title = t(title);
        }
    }, [title]);
    var handleMenuItemAccordionChange = function () {
        setIsExpandedMenuItemAccordion(function (prev) { return !prev; });
    };
    var SubmenuItem = function (_a) {
        var item = _a.item;
        var SubmenuItemLabel = function () {
            var _a;
            // Is parent for selected secondary submenu item
            var isParent = subMenuItems
                ? ((_a = subMenuItems.find(function (subMenuItem) {
                    if (subMenuItem.children && subMenuItem.children.length) {
                        // If selected secondary submenu item is child for submenu item
                        var found = subMenuItem.children.find(function (secondarySubMenuItem) { return secondarySubMenuItem.link === pathname; });
                        if (found) {
                            return subMenuItem;
                        }
                        return undefined;
                    }
                })) === null || _a === void 0 ? void 0 : _a.link) === item.link
                    ? true
                    : false
                : undefined;
            var isActive = item.link === pathname || isParent === true;
            return (React.createElement(ListItemButton, { selected: isActive, component: item.navType === 'LINK' ? 'a' : NavLink, className: classes.subMenuLinkButtonContainer, target: item.target ? item.target : undefined, to: item.navType !== 'LINK' ? (!isActive ? item.link : pathname) : undefined, href: item.navType === 'LINK' ? item.link : undefined },
                React.createElement(Box, { className: classes.subMenuListItemIconContainer },
                    React.createElement(CircleIcon, { htmlColor: isActive ? theme.palette.primary.main : '#A5BDDB', sx: { fontSize: '8px' } })),
                React.createElement(Box, { className: "".concat(classes.subMenuListItemLabelContainer, " ").concat(isActive ? 'active' : '') },
                    React.createElement(ListItemText, { disableTypography: true, className: "".concat(classes.subMenuLinkButtonText, " ").concat(isActive ? 'active' : ''), primary: item.title ? t(item.title) : '' }))));
        };
        var SecondarySubmenuItem = function (_a) {
            var item = _a.item;
            var isActive = item.link === pathname;
            return (React.createElement(ListItemButton, { selected: isActive, component: NavLink, className: classes.subMenuLinkButtonContainer, to: !isActive ? item.link : pathname },
                React.createElement(Box, { className: classes.subMenuListItemIconContainer },
                    React.createElement(CircleIcon, { htmlColor: isActive ? theme.palette.primary.main : '#A5BDDB', sx: { fontSize: '8px' } })),
                React.createElement(Box, { className: "".concat(classes.subMenuListItemLabelContainer, " ").concat(classes.secondarySubMenuListItemLabelContainer, "  ").concat(isActive ? 'active' : '') },
                    React.createElement(ListItemText, { disableTypography: true, className: "".concat(classes.subMenuLinkButtonText, " ").concat(isActive ? 'active' : ''), primary: item.title ? t(item.title) : '' }))));
        };
        if (item.children && item.children.length) {
            return (React.createElement(Accordion, { className: "".concat(classes.accordion, " ").concat(classes.secondaryAccordion), defaultExpanded: true, 
                // expanded={expandedSecondarySubMenuItemsAccordion.includes(item.link)}
                // onChange={handleSubmenuItemAccordionChange(item.link)}
                slots: { transition: Collapse }, slotProps: { transition: { timeout: 400 } }, disableGutters: true, elevation: 0, sx: {
                    '&:before': {
                        display: 'none',
                    },
                } },
                React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, { htmlColor: theme.palette.primary.main, sx: { fontSize: '20px' } }), "aria-controls": "panel1-content", id: "panel1-header", className: classes.accordionSummary },
                    React.createElement(SubmenuItemLabel, null)),
                React.createElement(AccordionDetails, { className: classes.accordionDetails },
                    React.createElement(Stack, { className: "".concat(classes.secondarySubMenuContainer, " ").concat(item.children.length === 1 ? 'one' : '') }, item.children.map(function (item, index) {
                        return React.createElement(SecondarySubmenuItem, { key: index, item: item });
                    })))));
        }
        return React.createElement(SubmenuItemLabel, null);
    };
    var MenuListItem = function () {
        var MenuItemLabel = function () {
            return (React.createElement(ListItemButton, { selected: isActive, component: NavLink, className: classes.linkButtonContainer, to: reloadPage ? pathname : !isActive ? link : pathname, onClick: function () { return (reloadPage ? (document.location = link) : ''); } },
                React.createElement(Box, { className: classes.listItemMainContentContainer },
                    React.createElement(Box, { className: classes.listItemIconContainer },
                        React.createElement("img", { src: require("./assets/".concat(isActive ? icon + 'Active.svg' : icon + '.svg')) })),
                    React.createElement(ListItemText, { disableTypography: true, className: "".concat(classes.linkButtonText, " ").concat(isActive ? 'active' : ''), primary: title ? t(title) : '' }))));
        };
        if (isActive && subMenuItems && subMenuItems.length) {
            return (React.createElement(Accordion, { className: classes.accordion, defaultExpanded: true, expanded: isExpandedMenuItemAccordion, onChange: handleMenuItemAccordionChange, slots: { transition: Collapse }, slotProps: { transition: { timeout: 400 } }, disableGutters: true, elevation: 0, sx: {
                    '&:before': {
                        display: 'none',
                    },
                } },
                React.createElement(AccordionSummary, { expandIcon: !isExpandedMenuItemAccordion ? (React.createElement(AddIcon, { htmlColor: theme.palette.primary.main, sx: { fontSize: '20px' } })) : (React.createElement(RemoveIcon, { htmlColor: theme.palette.primary.main, sx: { fontSize: '20px' } })), "aria-controls": "panel1-content", id: "panel1-header", className: classes.accordionSummary },
                    React.createElement(MenuItemLabel, null)),
                React.createElement(AccordionDetails, { className: classes.accordionDetails },
                    React.createElement(Stack, { className: "".concat(classes.subMenuContainer, " ").concat(subMenuItems.length === 1 ? 'one' : '') }, subMenuItems.map(function (item, index) {
                        return React.createElement(SubmenuItem, { key: index, item: item });
                    })))));
        }
        return React.createElement(MenuItemLabel, null);
    };
    return (React.createElement(ListItem, { disablePadding: true, className: classes.listItemContainer },
        React.createElement(MenuListItem, null)));
};
