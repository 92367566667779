export var ClinicGalleryActionType;
(function (ClinicGalleryActionType) {
    ClinicGalleryActionType["GET_CLINIC_GALLERY_PENDING"] = "GET_CLINIC_GALLERY_PENDING";
    ClinicGalleryActionType["GET_CLINIC_GALLERY_SUCCESS"] = "GET_CLINIC_GALLERY_SUCCESS";
    ClinicGalleryActionType["GET_CLINIC_GALLERY_ERROR"] = "GET_CLINIC_GALLERY_ERROR";
    ClinicGalleryActionType["GET_ONE_CLINIC_GALLERY_PENDING"] = "GET_ONE_CLINIC_GALLERY_PENDING";
    ClinicGalleryActionType["GET_ONE_CLINIC_GALLERY_SUCCESS"] = "GET_ONE_CLINIC_GALLERY_SUCCESS";
    ClinicGalleryActionType["GET_ONE_CLINIC_GALLERY_ERROR"] = "GET_ONE_CLINIC_GALLERY_ERROR";
    ClinicGalleryActionType["ADD_CLINIC_GALLERY_PENDING"] = "ADD_CLINIC_GALLERY_PENDING";
    ClinicGalleryActionType["ADD_CLINIC_GALLERY_SUCCESS"] = "ADD_CLINIC_GALLERY_SUCCESS";
    ClinicGalleryActionType["ADD_CLINIC_GALLERY_ERROR"] = "ADD_CLINIC_GALLERY_ERROR";
    ClinicGalleryActionType["EDIT_CLINIC_GALLERY_PENDING"] = "EDIT_CLINIC_GALLERY_PENDING";
    ClinicGalleryActionType["EDIT_CLINIC_GALLERY_SUCCESS"] = "EDIT_CLINIC_GALLERY_SUCCESS";
    ClinicGalleryActionType["EDIT_CLINIC_GALLERY_ERROR"] = "EDIT_CLINIC_GALLERY_ERROR";
    ClinicGalleryActionType["DELETE_CLINIC_GALLERY_PENDING"] = "DELETE_CLINIC_GALLERY_PENDING";
    ClinicGalleryActionType["DELETE_CLINIC_GALLERY_SUCCESS"] = "DELETE_CLINIC_GALLERY_SUCCESS";
    ClinicGalleryActionType["DELETE_CLINIC_GALLERY_ERROR"] = "DELETE_CLINIC_GALLERY_ERROR";
    ClinicGalleryActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
    ClinicGalleryActionType["SET_SELECTED_PHOTO"] = "SET_SELECTED_PHOTO";
})(ClinicGalleryActionType || (ClinicGalleryActionType = {}));
