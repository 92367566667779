import { createUseStyles } from 'react-jss';
export var useStyles = createUseStyles({
    materialIcons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '&.marginRight': { marginRight: 6 },
        '&.extra-large': {
            minWidth: 24,
            height: 24,
            width: 24,
            fontSize: 24,
        },
        '&.large': {
            minWidth: 20,
            height: 20,
            width: 20,
            fontSize: 20,
        },
        '&.medium': {
            minWidth: 16,
            height: 16,
            width: 16,
            fontSize: 16,
        },
        '&.reg-small': {
            minWidth: 12,
            height: 12,
            width: 12,
            fontSize: 12,
        },
    },
    pointer: {
        cursor: 'pointer',
    },
});
