export var EhealthCompositionAction;
(function (EhealthCompositionAction) {
    EhealthCompositionAction["ADD_ITEMS"] = "EHEALTH_COMPOSITION_ADD_ITEMS";
    EhealthCompositionAction["CLEAR"] = "EHEALTH_COMPOSITION_CLEAR";
    EhealthCompositionAction["UPDATE"] = "EHEALTH_COMPOSITION_UPDATE";
    EhealthCompositionAction["SET_IS_LOADING"] = "EHEALTH_COMPOSITION_SET_IS_LOADING";
    EhealthCompositionAction["SETUP_JOBS"] = "EHEALTH_COMPOSITION_SETUP_JOBS";
    EhealthCompositionAction["REMOVE_JOB"] = "EHEALTH_COMPOSITION_REMOVE_JOB";
    EhealthCompositionAction["SET_FILTER"] = "EHEALTH_COMPOSITION_SET_FILTER";
})(EhealthCompositionAction || (EhealthCompositionAction = {}));
