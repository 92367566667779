var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChargesRequestAction } from './action';
import { defaultState } from './state';
export var ChargesRequestsReducer = function (state, action) {
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case ChargesRequestAction.INIT:
            return __assign(__assign({}, state), { items: __assign({}, action.payload.items) });
        case ChargesRequestAction.ADD_ITEM:
        case ChargesRequestAction.UPDATE_ITEM:
            return __assign(__assign({}, state), { items: __assign(__assign({}, state.items), action.payload.items) });
        case ChargesRequestAction.REMOVE_ITEM:
            var next = __assign({}, state);
            delete next.items[action.payload.id];
            return next;
    }
    return state;
};
