import { EmployeeRoleService } from './employee-role';
import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
export var SERVICE_EHEALTH_EMPLOYEE_ROLE = 'SERVICE_EHEALTH_EMPLOYEE_ROLE';
export var ehealthEmployeeRoleFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    return new EmployeeRoleService({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
    });
};
