import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_ALL_MEDICAL_PROGRAM_URL, PARAM_EHEALTH_MEDICAL_PROGRAM_FOR_MEDICATION_REQUEST_URL, } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
import { MedicalProgram } from './medical-program';
export var SERVICE_EHEALTH_MEDICAL_PROGRAM = 'SERVICE_EHEALTH_MEDICAL_PROGRAM';
export var ehealthMedicalProgramFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var allProgramsUrl = "".concat(container.get(PARAM_EHEALTH_ALL_MEDICAL_PROGRAM_URL));
    var programsForMedicationRequestUrl = "".concat(container.get(PARAM_EHEALTH_MEDICAL_PROGRAM_FOR_MEDICATION_REQUEST_URL));
    return new MedicalProgram({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        allProgramsUrl: allProgramsUrl,
        programsForMedicationRequestUrl: programsForMedicationRequestUrl,
    });
};
