export var NotificationsActionType;
(function (NotificationsActionType) {
    NotificationsActionType["GET_ALL_NOTIFICATIONS_PENDING"] = "GET_ALL_NOTIFICATIONS_PENDING";
    NotificationsActionType["GET_ALL_NOTIFICATIONS_SUCCESS"] = "GET_ALL_NOTIFICATIONS_SUCCESS";
    NotificationsActionType["GET_ALL_NOTIFICATIONS_ERROR"] = "GET_ALL_NOTIFICATIONS_ERROR";
    NotificationsActionType["MARK_ALL_NOTIFICATIONS_PENDING"] = "MARK_ALL_NOTIFICATIONS_PENDING";
    NotificationsActionType["MARK_ALL_NOTIFICATIONS_SUCCESS"] = "MARK_ALL_NOTIFICATIONS_SUCCESS";
    NotificationsActionType["MARK_ALL_NOTIFICATIONS_ERROR"] = "MARK_ALL_NOTIFICATIONS_ERROR";
    NotificationsActionType["MARK_ONE_NOTIFICATION_PENDING"] = "MARK_ONE_NOTIFICATION_PENDING";
    NotificationsActionType["MARK_ONE_NOTIFICATION_SUCCESS"] = "MARK_ONE_NOTIFICATION_SUCCESS";
    NotificationsActionType["MARK_ONE_NOTIFICATION_ERROR"] = "MARK_ONE_NOTIFICATION_ERROR";
    NotificationsActionType["DELETE_ALL_VIEWED_NOTIFICATIONS_PENDING"] = "DELETE_ALL_VIEWED_NOTIFICATIONS_PENDING";
    NotificationsActionType["DELETE_ALL_VIEWED_NOTIFICATIONS_SUCCESS"] = "DELETE_ALL_VIEWED_NOTIFICATIONS_SUCCESS";
    NotificationsActionType["DELETE_ALL_VIEWED_NOTIFICATIONS_ERROR"] = "DELETE_ALL_VIEWED_NOTIFICATIONS_ERROR";
    NotificationsActionType["DELETE_ONE_NOTIFICATION_PENDING"] = "DELETE_ONE_NOTIFICATION_PENDING";
    NotificationsActionType["DELETE_ONE_NOTIFICATION_SUCCESS"] = "DELETE_ONE_NOTIFICATION_SUCCESS";
    NotificationsActionType["DELETE_ONE_NOTIFICATION_ERROR"] = "DELETE_ONE_NOTIFICATION_ERROR";
})(NotificationsActionType || (NotificationsActionType = {}));
