import { Box } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
export var Logo = function (props) {
    var history = useHistory();
    return (React.createElement(Box, { onClick: function () { return history.push('/'); }, sx: { width: '140px' } },
        React.createElement("svg", { width: "140", height: "40", viewBox: "0 0 140 40", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M64.0097 12.5C65.7382 12.5 67.115 13.0617 68.14 14.1852C69.1851 15.3086 69.7077 16.8133 69.7077 18.6991V27.9676H65.8186V18.9699C65.8186 18.0671 65.5975 17.365 65.1553 16.8634C64.7132 16.3619 64.0901 16.1111 63.2862 16.1111C62.4018 16.1111 61.7084 16.402 61.206 16.9838C60.7236 17.5656 60.4824 18.4082 60.4824 19.5116V27.9676H56.5933V18.9699C56.5933 18.0671 56.3722 17.365 55.9301 16.8634C55.4879 16.3619 54.8648 16.1111 54.0609 16.1111C53.1966 16.1111 52.5032 16.402 51.9807 16.9838C51.4782 17.5656 51.227 18.4082 51.227 19.5116V27.9676H47.3379V12.9213H51.227V14.5162C52.1314 13.1721 53.5283 12.5 55.4175 12.5C57.2666 12.5 58.6333 13.2222 59.5177 14.6667C60.5226 13.2222 62.0199 12.5 64.0097 12.5Z", fill: "black" }),
            React.createElement("path", { d: "M81.3092 12.9213H85.4696L79.9827 27.9676C79.1989 30.1342 78.1537 31.699 76.8473 32.662C75.561 33.645 73.9531 34.0864 72.0237 33.9861V30.375C73.0688 30.395 73.8928 30.1743 74.4958 29.7129C75.1188 29.2515 75.6113 28.5092 75.973 27.4861L69.7927 12.9213H74.0436L77.9628 23.0324L81.3092 12.9213Z", fill: "black" }),
            React.createElement("path", { d: "M94.4957 12.5C96.1437 12.5 97.5004 13.0517 98.5656 14.1551C99.651 15.2585 100.194 16.7832 100.194 18.7291V27.9676H96.3045V19.2106C96.3045 18.2075 96.0332 17.4452 95.4905 16.9236C94.9479 16.3819 94.2243 16.1111 93.3199 16.1111C92.315 16.1111 91.511 16.4221 90.9081 17.044C90.3051 17.6659 90.0036 18.5987 90.0036 19.8426V27.9676H86.1145V6.90278H90.0036V14.6065C90.9482 13.2022 92.4456 12.5 94.4957 12.5Z", fill: "black" }),
            React.createElement("path", { d: "M105.595 22.0393C106.117 23.9251 107.534 24.868 109.845 24.868C111.333 24.868 112.458 24.3665 113.222 23.3634L116.357 25.169C114.87 27.3156 112.679 28.3889 109.785 28.3889C107.293 28.3889 105.293 27.6365 103.786 26.1319C102.278 24.6273 101.525 22.7315 101.525 20.4444C101.525 18.1775 102.268 16.2917 103.756 14.787C105.243 13.2623 107.152 12.5 109.484 12.5C111.695 12.5 113.513 13.2623 114.94 14.787C116.388 16.3117 117.111 18.1975 117.111 20.4444C117.111 20.946 117.061 21.4776 116.96 22.0393H105.595ZM105.534 19.0301H113.222C113.001 18.0069 112.539 17.2446 111.835 16.743C111.152 16.2415 110.368 15.9907 109.484 15.9907C108.439 15.9907 107.574 16.2616 106.891 16.8032C106.208 17.3248 105.755 18.0671 105.534 19.0301Z", fill: "black" }),
            React.createElement("path", { d: "M127.634 26.4027H127.393C126.931 26.9645 126.368 27.446 125.705 27.8472C125.062 28.2484 124.228 28.449 123.203 28.449C122.439 28.449 121.715 28.3186 121.032 28.0578C120.369 27.8171 119.786 27.476 119.283 27.0347C118.781 26.5933 118.389 26.0617 118.108 25.4398C117.826 24.8179 117.686 24.1358 117.686 23.3935C117.686 22.6311 117.836 21.949 118.138 21.3472C118.439 20.7253 118.861 20.1937 119.404 19.7523C119.947 19.3109 120.58 18.9799 121.303 18.7592C122.047 18.5185 122.851 18.3981 123.715 18.3981C124.68 18.3981 125.474 18.4784 126.097 18.6389C126.72 18.7793 127.232 18.9298 127.634 19.0903V18.6389C127.634 17.8565 127.323 17.2145 126.7 16.7129C126.077 16.1913 125.303 15.9305 124.378 15.9305C123.012 15.9305 121.856 16.5023 120.911 17.6458L118.047 15.6898C119.615 13.7238 121.756 12.7407 124.469 12.7407C126.76 12.7407 128.509 13.2824 129.715 14.3657C130.921 15.429 131.523 17.0139 131.523 19.1204V27.9676H127.634V26.4027ZM127.634 21.9791C127.172 21.7585 126.69 21.5879 126.187 21.4676C125.705 21.3271 125.172 21.2569 124.589 21.2569C123.645 21.2569 122.911 21.4676 122.389 21.8889C121.886 22.2901 121.635 22.7916 121.635 23.3935C121.635 23.9953 121.876 24.4768 122.358 24.8379C122.861 25.199 123.434 25.3796 124.077 25.3796C124.599 25.3796 125.082 25.2893 125.524 25.1088C125.966 24.9082 126.338 24.6574 126.64 24.3565C126.961 24.0355 127.202 23.6744 127.363 23.2731C127.544 22.8518 127.634 22.4205 127.634 21.9791Z", fill: "black" }),
            React.createElement("path", { d: "M132.252 27.9676L136.111 6H140L136.141 27.9676H132.252Z", fill: "black" }),
            React.createElement("path", { d: "M17.448 1.51469C19.0212 0.580937 20.9788 0.580938 22.552 1.51469L28.6 5.10436L34.7328 8.54725C36.328 9.44282 37.3068 11.1381 37.2847 12.9674L37.2 20L37.2847 27.0326C37.3068 28.8619 36.328 30.5572 34.7328 31.4528L28.6 34.8956L22.552 38.4853C20.9788 39.4191 19.0212 39.4191 17.448 38.4853L11.4 34.8956L5.26725 31.4527C3.67199 30.5572 2.69321 28.8619 2.71525 27.0326L2.8 20L2.71525 12.9674C2.69321 11.1381 3.67199 9.44282 5.26725 8.54725L11.4 5.10436L17.448 1.51469Z", fill: props.fill ? props.fill : '#29F499' }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.5915 8.00617C17.1676 8.06242 17.6332 8.49382 17.7259 9.05722L20.4785 25.7888L25.2788 12.8542C25.4771 12.3199 26.0142 11.9832 26.5889 12.0328C27.1637 12.0824 27.6333 12.506 27.7345 13.066L28.9185 19.6217L34 19.6217V22.1392L27.8492 22.1392C27.2305 22.1392 26.7008 21.7017 26.5923 21.1011L26.0505 18.1008L21.1989 31.1738C20.9993 31.7114 20.4569 32.0486 19.8789 31.9943C19.3009 31.94 18.8328 31.5079 18.7399 30.9428L15.9755 14.1398L12.7135 22.7305C12.5268 23.2221 12.0503 23.5479 11.5179 23.5479H6V21.0304H10.6327L15.2702 8.81742C15.4731 8.28289 16.0155 7.94992 16.5915 8.00617Z", fill: "white" }))));
};
