export var LocaleActionTypes;
(function (LocaleActionTypes) {
    LocaleActionTypes[LocaleActionTypes["GET_LOCALE"] = 0] = "GET_LOCALE";
    LocaleActionTypes[LocaleActionTypes["SET_LOCALE"] = 1] = "SET_LOCALE";
})(LocaleActionTypes || (LocaleActionTypes = {}));
export function getLocale(locale) {
    return {
        type: LocaleActionTypes.GET_LOCALE,
        payload: locale,
    };
}
export function setLocale(locale) {
    return {
        type: LocaleActionTypes.SET_LOCALE,
        payload: locale,
    };
}
