export var clinicInitialState = {
    full_name: null,
    short_name: null,
    type: null,
    webAddress: null,
    city: null,
    company_logo: null,
    createdAt: null,
    description: null,
    eHealthRegister: false,
    email: null,
    legalEntity: null,
    lunch: null,
    phone: null,
    phones: null,
    schedule: null,
};
export var initialState = {
    clinic: clinicInitialState,
    address: {
        oblast: null,
        region: null,
        settlement: null,
        street: null,
    },
    types: {},
    isClinicInfoChanged: false,
    isClinicAddritionalChanged: false,
    isClinicAddressChanged: false,
    loading: false,
    error: null,
};
