var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangesHistoryAction } from './action';
import { defaultState } from './state';
export var changesHistoryReducer = function (state, action) {
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case ChangesHistoryAction.INIT: {
            return __assign(__assign({}, state), { items: __assign({}, action.payload.items) });
        }
        case ChangesHistoryAction.SET_IS_LOADING: {
            return __assign(__assign({}, state), { isLoading: action.payload.isLoading });
        }
    }
    return state;
};
