import { Box, Button, TextField, Typography } from '@mui/material';
import React from 'react';
import classNames from 'classnames';
import ConfirmPhoneIconSvg from '../../../../assets/svg_components/ConfirmPhoneIconSvg';
import { useStyles } from './RegisterComplete.jss';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { fetchRegisterComplete } from '../../../../store/register/userRegister/register';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
export default function RegisterComplete() {
    var classes = useStyles();
    var registerError = useSelector(function (state) { return state.moi.register.userRegister.error; });
    var phone = useSelector(function (state) { return state.moi.register.userRegister.phone; });
    var dispatch = useDispatch();
    var history = useHistory();
    var initialValue = {
        code: '',
    };
    var codeRegex = /^[0-9]*$/;
    var requiredMessage = "Це поле обов'язкове та має містити 6 цифр.";
    var CodeSchema = Yup.object().shape({
        code: Yup.string()
            .required(requiredMessage)
            .matches(codeRegex, requiredMessage)
            .min(6, requiredMessage)
            .max(6, requiredMessage),
    });
    var handleRegisterComplete = function (_a, props) {
        var code = _a.code;
        dispatch(fetchRegisterComplete({ code: code, phone: phone, history: history }));
        return;
    };
    return (React.createElement(Box, { className: classNames(classes.wrapper) },
        React.createElement(ConfirmPhoneIconSvg, null),
        React.createElement(Typography, { className: classNames(classes.title) }, "\u0412\u0456\u0442\u0430\u0454\u043C\u043E! \u0412\u0438 \u0443\u0441\u043F\u0456\u0448\u043D\u043E \u0437\u0430\u0440\u0435\u0454\u0441\u0442\u0440\u0443\u0432\u0430\u043B\u0438\u0441\u044F \u0432 \u043E\u043D\u043B\u0430\u0439\u043D-\u043E\u0440\u0433\u0430\u043D\u0430\u0439\u0437\u0435\u0440\u0456 \u0437\u0434\u043E\u0440\u043E\u0432'\u044F"),
        React.createElement(Typography, { className: classNames(classes.subtitle) }, "\u041D\u0430 \u0432\u0430\u0448 \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0443 \u0431\u0443\u043B\u043E \u0432\u0456\u0434\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0435 SMS \u0437 \u043A\u043E\u0434\u043E\u043C. \u0412\u0432\u0435\u0434\u0456\u0442\u044C \u0439\u043E\u0433\u043E \u0443 \u0432\u0456\u0434\u043F\u043E\u0432\u0456\u0434\u043D\u0435 \u043F\u043E\u043B\u0435."),
        React.createElement(Typography, { className: classNames(classes.subtitle) }, "\u0412 \u0446\u0435 \u043F\u043E\u043B\u0435 \u043D\u0435\u043E\u0431\u0445\u0456\u0434\u043D\u043E \u0432\u0432\u0435\u0441\u0442\u0438 \u043A\u043E\u0434 \u0437 SMS \u0430\u0431\u043E \u043F\u043E\u0448\u0442\u043E\u0432\u043E\u0457 \u0430\u0434\u0440\u0435\u0441\u0438. \u041D\u0456\u043A\u043E\u043C\u0443 \u0439\u043E\u0433\u043E \u043D\u0435 \u043F\u043E\u0432\u0456\u0434\u043E\u043C\u043B\u044F\u0439\u0442\u0435."),
        React.createElement(Formik, { onSubmit: handleRegisterComplete, initialValues: initialValue, validationSchema: CodeSchema }, function (props) {
            var code = props.values.code;
            return (React.createElement(Form, null,
                React.createElement(Field, { as: TextField, placeholder: "______", id: "code", name: "code", value: code, inputProps: { maxLength: 6 }, className: classNames(classes.input), InputLabelProps: { shrink: false } }),
                props.errors.code && (React.createElement(Typography, { className: classNames(classes.inputError) }, props.errors.code)),
                registerError && (React.createElement(Box, { className: classNames(classes.registerError) },
                    React.createElement(Typography, { className: classNames(classes.registerErrorTitle) }, registerError))),
                React.createElement(Button, { type: "submit", className: classNames(classes.submitButton) }, "\u041F\u0456\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u0438")));
        })));
}
