var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import cloneDeep from 'lodash/cloneDeep';
import { ResponseError } from '../../../../../../shared/error/response-error';
import { clearAndSerialize } from '../../../../../../shared/util/normalizer';
import { PayloadStoreType } from '../../medical-events/enum/payload-store-type';
import { clearFilter } from '../utill';
var MedicationRequestRequestService = /** @class */ (function () {
    function MedicationRequestRequestService(params) {
        this.baseUrl = params.baseUrl;
        this.storageUrl = params.storageUrl;
        this.itemsPerPage = params.itemsPerPage;
        this.requestService = params.requestService;
        this.payloadStoreCreateUrl = params.payloadStoreCreateUrl;
        this.payloadStoreDeleteUrl = params.payloadStoreDeleteUrl;
        this.payloadStoreListUrl = params.payloadStoreListUrl;
        this.payloadStoreSingleUrl = params.payloadStoreSingleUrl;
        this.signService = params.signService;
    }
    MedicationRequestRequestService.prototype.getList = function (patientId, page, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var args, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        args = __assign(__assign({}, clearFilter(filter)), { page: "".concat(page), page_size: "".concat(this.itemsPerPage) });
                        return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/persons/").concat(patientId, "/medication_request_requests"), args)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    MedicationRequestRequestService.prototype.getListInCarePlanContext = function (patientId, page, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var carePlanId, args, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        carePlanId = filter === null || filter === void 0 ? void 0 : filter.care_plan_id;
                        args = __assign(__assign({}, clearFilter(filter)), { page: "".concat(page), person_id: "".concat(patientId), page_size: "".concat(this.itemsPerPage) });
                        return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/care_plans/").concat(carePlanId, "/medication_request_requests"), args)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    MedicationRequestRequestService.prototype.getSingle = function (patientId, medicationRequestId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/persons/").concat(patientId, "/medication_request_requests/").concat(medicationRequestId))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __assign({}, json.data)];
                }
            });
        });
    };
    MedicationRequestRequestService.prototype.create = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var clearedData, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        clearedData = clearAndSerialize(this.prepareData(cloneDeep(data)));
                        return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/medication_request_requests"), undefined, clearedData)];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __assign({}, json.data)];
                }
            });
        });
    };
    MedicationRequestRequestService.prototype.sign = function (id, signParams, patientId, data) {
        return __awaiter(this, void 0, void 0, function () {
            var signedData, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        return [4 /*yield*/, this.signService.sign(JSON.stringify(data), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/medication_request_requests/").concat(id, "/actions/sign"), undefined, JSON.stringify({
                                signed_medication_request_request: signedData,
                                signed_content_encoding: 'base64',
                            }))];
                    case 4:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.clone().json()];
                    case 5:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, this.tmpStore(json.data.id, patientId, json.data, PayloadStoreType.SIGNED_MEDICATION_REQUEST)];
                    case 6:
                        _a.sent();
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    MedicationRequestRequestService.prototype.preQualify = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var clearedData, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data.programs = [
                            {
                                id: '63c1f32f-c8f4-4f5b-81a9-79b8452d6545',
                            },
                            {
                                id: '9a1364d7-2c61-4fe8-b889-ea788dbb7467',
                            },
                            {
                                id: '9994be00-47b4-4969-800e-87a767751b47',
                            },
                            {
                                id: '14e19712-b443-4391-b0f7-aba8e8662459',
                            },
                        ];
                        clearedData = clearAndSerialize(this.prepareData(__assign({}, data)));
                        return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/medication_request_requests/prequalify"), undefined, clearedData)];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, __assign({}, json.data)];
                }
            });
        });
    };
    MedicationRequestRequestService.prototype.reject = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/medication_request_requests/").concat(id, "/actions/reject"), undefined, undefined)];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        json = _a.sent();
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    MedicationRequestRequestService.prototype.prepareData = function (data) {
        try {
            delete data.medication_request_request.episode;
            if (data.medication_request_request.context && !data.medication_request_request.context.identifier.value) {
                delete data.medication_request_request.context;
            }
            data.medication_request_request.medication_qty = Number(data.medication_request_request.medication_qty);
            // Видалення не обовязкової групи полів, якщо їх toggle у вимкненому положені
            if (!data.medication_request_request.dosage_instruction[0].dose_and_rate.enabled) {
                delete data.medication_request_request.dosage_instruction[0].dose_and_rate;
            }
            else {
                delete data.medication_request_request.dosage_instruction[0].dose_and_rate.enabled;
            }
            // Видалення службових для payload полів
            delete data.medication_request_request.dosage_instruction[0].max_dose_per_period
                .max_dose_per_period_in_medication_unit;
            delete data.medication_request_request.dosage_instruction[0].max_dose_per_administration
                .max_dose_per_administration_in_medication_unit;
            delete data.medication_request_request.dosage_instruction[0].max_dose_per_lifetime
                .max_dose_per_lifetime_in_medication_unit;
            delete data.medication_request_request.ehealth_id;
            delete data.medication_request_request.medication;
            data.medication_request_request.dosage_instruction[0].max_dose_per_lifetime.value = +data
                .medication_request_request.dosage_instruction[0].max_dose_per_lifetime.value;
            data.medication_request_request.dosage_instruction[0].max_dose_per_period.numerator.value = +data
                .medication_request_request.dosage_instruction[0].max_dose_per_period.numerator.value;
            data.medication_request_request.dosage_instruction[0].max_dose_per_period.denominator.value = +data
                .medication_request_request.dosage_instruction[0].max_dose_per_period.denominator.value;
            data.medication_request_request.dosage_instruction[0].max_dose_per_administration.value = +data
                .medication_request_request.dosage_instruction[0].max_dose_per_administration.value;
            if (data.medication_request_request.based_on &&
                data.medication_request_request.based_on[0].identifier.value === '') {
                delete data.medication_request_request.based_on;
            }
            // for (const dosageInstruction of data.medication_request_request.dosage_instruction) {
            //   if (Object.keys(dosageInstruction.site).length === 0) {
            //     delete dosageInstruction.site;
            //   }
            //   if (Object.keys(dosageInstruction.route).length === 0) {
            //     delete dosageInstruction.route;
            //   }
            //   if (Object.keys(dosageInstruction.method).length === 0) {
            //     delete dosageInstruction.method;
            //   }
            // }
        }
        catch (e) {
            console.error('prepareData catch error', e);
        }
        return data;
    };
    MedicationRequestRequestService.prototype.cancelRequest = function () {
        this.requestService.cancel();
    };
    MedicationRequestRequestService.prototype.getStoredSignle = function (srId) {
        return __awaiter(this, void 0, void 0, function () {
            var query, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = new URLSearchParams({
                            id: srId,
                        });
                        return [4 /*yield*/, fetch("".concat(this.payloadStoreSingleUrl, "?").concat(query), {
                                credentials: 'include',
                            })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    MedicationRequestRequestService.prototype.tmpStore = function (srId, patientId, payload, type) {
        if (type === void 0) { type = PayloadStoreType.MEDICATION_REQUEST_REQUEST_STORE_TYPE; }
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("".concat(this.payloadStoreCreateUrl), {
                            credentials: 'include',
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                            },
                            body: JSON.stringify({
                                id: srId,
                                patient_id: patientId,
                                payload: payload,
                                type: type,
                            }),
                        })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    MedicationRequestRequestService.prototype.getStoredList = function (patientId, page) {
        return __awaiter(this, void 0, void 0, function () {
            var query, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = new URLSearchParams({
                            patient_id: patientId,
                            page: "".concat(page),
                            page_size: "".concat(this.itemsPerPage),
                            type: PayloadStoreType.MEDICATION_REQUEST_REQUEST_STORE_TYPE,
                        });
                        return [4 /*yield*/, fetch("".concat(this.payloadStoreListUrl, "?").concat(query), {
                                credentials: 'include',
                            })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    MedicationRequestRequestService.prototype.removeStored = function (patientId, ids) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("".concat(this.payloadStoreDeleteUrl), {
                            credentials: 'include',
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                            },
                            body: JSON.stringify({
                                ids: ids,
                                patient_id: patientId,
                            }),
                        })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    return MedicationRequestRequestService;
}());
export { MedicationRequestRequestService };
