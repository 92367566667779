export var DoctorReferralsActionType;
(function (DoctorReferralsActionType) {
    DoctorReferralsActionType["GET_REFERRALS_PENDING"] = "GET_REFERRALS_PENDING";
    DoctorReferralsActionType["GET_REFERRALS_SUCCESS"] = "GET_REFERRALS_SUCCESS";
    DoctorReferralsActionType["GET_REFERRALS_ERROR"] = "GET_REFERRALS_ERROR";
    DoctorReferralsActionType["ADD_REFERRAL_PENDING"] = "ADD_REFERRAL_PENDING";
    DoctorReferralsActionType["ADD_REFERRAL_SUCCESS"] = "ADD_REFERRAL_SUCCESS";
    DoctorReferralsActionType["ADD_REFERRAL_ERROR"] = "ADD_REFERRAL_ERROR";
    DoctorReferralsActionType["GET_ANALYSES_PENDING"] = "GET_ANALYSES_PENDING";
    DoctorReferralsActionType["GET_ANALYSES_SUCCESS"] = "GET_ANALYSES_SUCCESS";
    DoctorReferralsActionType["GET_ANALYSES_ERROR"] = "GET_ANALYSES_ERROR";
    DoctorReferralsActionType["GET_CLIENTS_PENDING"] = "GET_CLIENTS_PENDING";
    DoctorReferralsActionType["GET_CLIENTS_SUCCESS"] = "GET_CLIENTS_SUCCESS";
    DoctorReferralsActionType["GET_CLIENTS_ERROR"] = "GET_CLIENTS_ERROR";
})(DoctorReferralsActionType || (DoctorReferralsActionType = {}));
