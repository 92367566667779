var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultState } from './state';
import { BookingActiveAction } from './enum/action';
import { calculateCoutdown } from './util';
export function activeBookingReducer(state, action) {
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case BookingActiveAction.SETUP:
            return __assign(__assign({}, state), { serviceName: action.payload.serviceName, interlocutorsNames: action.payload.interlocutorsNames, categories: action.payload.categories, createdAt: action.payload.createdAt, duration: action.payload.duration, countdown: action.payload.countdown });
        case BookingActiveAction.COUNTDOWN_TICK:
            var countdown = calculateCoutdown(state.createdAt, state.duration);
            return __assign(__assign({}, state), { countdown: countdown });
    }
    return state;
}
