export var ObservationCodeKeys;
(function (ObservationCodeKeys) {
    ObservationCodeKeys["weight_under_1_year"] = "weight_under_1_year";
    ObservationCodeKeys["94564-2"] = "94564-2";
    ObservationCodeKeys["APGAR_5"] = "APGAR_5";
    ObservationCodeKeys["APGAR_1"] = "APGAR_1";
    ObservationCodeKeys["11638-4"] = "11638-4";
    ObservationCodeKeys["8339-4"] = "8339-4";
    ObservationCodeKeys["8302-2"] = "8302-2";
    ObservationCodeKeys["75859-9"] = "75859-9";
    ObservationCodeKeys["8480-6"] = "8480-6";
    ObservationCodeKeys["14743-9"] = "14743-9";
    ObservationCodeKeys["31044-1"] = "31044-1";
    ObservationCodeKeys["covid_vac_groups"] = "covid_vac_groups";
    ObservationCodeKeys["8867-4"] = "8867-4";
    ObservationCodeKeys["4548-4"] = "4548-4";
    ObservationCodeKeys["94558-4"] = "94558-4";
    ObservationCodeKeys["73773-4"] = "73773-4";
    ObservationCodeKeys["9279-1"] = "9279-1";
    ObservationCodeKeys["68496-9"] = "68496-9";
    ObservationCodeKeys["94562-6"] = "94562-6";
    ObservationCodeKeys["8310-5"] = "8310-5";
    ObservationCodeKeys["eye_colour"] = "eye_colour";
    ObservationCodeKeys["14578-9"] = "14578-9";
    ObservationCodeKeys["73771-8"] = "73771-8";
    ObservationCodeKeys["94762-2"] = "94762-2";
    ObservationCodeKeys["39156-5"] = "39156-5";
    ObservationCodeKeys["stature"] = "stature";
    ObservationCodeKeys["clothes"] = "clothes";
    ObservationCodeKeys["80319-7"] = "80319-7";
    ObservationCodeKeys["hair_length"] = "hair_length";
    ObservationCodeKeys["57722-1"] = "57722-1";
    ObservationCodeKeys["hair_color"] = "hair_color";
    ObservationCodeKeys["beard"] = "beard";
    ObservationCodeKeys["sex"] = "sex";
    ObservationCodeKeys["mustache"] = "mustache";
    ObservationCodeKeys["10331-7"] = "10331-7";
    ObservationCodeKeys["29463-7"] = "29463-7";
    ObservationCodeKeys["11884-4"] = "11884-4";
    ObservationCodeKeys["8462-4"] = "8462-4";
    ObservationCodeKeys["94563-4"] = "94563-4";
    ObservationCodeKeys["94500-6"] = "94500-6";
    ObservationCodeKeys["peculiarity"] = "peculiarity";
    ObservationCodeKeys["56086-2"] = "56086-2";
    ObservationCodeKeys["82810-3"] = "82810-3";
    ObservationCodeKeys["29572-5"] = "29572-5";
    ObservationCodeKeys["38473-5"] = "38473-5";
    ObservationCodeKeys["48633-2"] = "48633-2";
    ObservationCodeKeys["29575-8"] = "29575-8";
})(ObservationCodeKeys || (ObservationCodeKeys = {}));
