import React from 'react';
export default function ViberWhiteIconSvg() {
    return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_205_39)" },
            React.createElement("path", { d: "M19.2959 11.5778C19.8926 6.55526 19.0092 3.38443 17.4159 1.95026L17.4167 1.94943C14.8451 -0.499735 6.16007 -0.862235 3.0834 2.05943C1.70173 3.4886 1.21507 5.5861 1.16173 8.18277C1.1084 10.7803 1.04507 15.6461 5.57923 16.9661H5.5834L5.57923 18.9819C5.57923 18.9819 5.5484 19.7986 6.07007 19.9628C6.66673 20.1561 6.93673 19.7769 8.79257 17.5603C11.8959 17.8294 14.2792 17.2128 14.5501 17.1228C15.1767 16.9128 18.7226 16.4436 19.2959 11.5778ZM9.0984 16.1419C9.0984 16.1419 7.13423 18.5911 6.5234 19.2269C6.3234 19.4336 6.10423 19.4144 6.10757 19.0044C6.10757 18.7353 6.12257 15.6578 6.12257 15.6578C2.2784 14.5561 2.50507 10.4128 2.54673 8.24527C2.5884 6.07693 2.98507 4.3011 4.15507 3.10526C6.85423 0.574431 14.4684 1.14026 16.4084 2.9636C18.7801 5.0661 17.9359 11.0061 17.9409 11.2086C17.4534 15.2703 14.5801 15.5278 14.0517 15.7036C13.8259 15.7786 11.7301 16.3178 9.0984 16.1419Z", fill: "white" }),
            React.createElement("path", { d: "M10.185 3.58105C9.86413 3.58105 9.86413 4.08105 10.185 4.08522C12.6741 4.10439 14.7241 5.83939 14.7466 9.02189C14.7466 9.35772 15.2383 9.35356 15.2341 9.01772H15.2333C15.2066 5.58856 12.9683 3.60022 10.185 3.58105Z", fill: "white" }),
            React.createElement("path", { d: "M13.4592 8.4942C13.4517 8.82586 13.9425 8.8417 13.9467 8.50586C13.9875 6.61503 12.8217 5.05753 10.6309 4.89336C10.31 4.87003 10.2767 5.3742 10.5967 5.39753C12.4967 5.5417 13.4975 6.83836 13.4592 8.4942Z", fill: "white" }),
            React.createElement("path", { d: "M12.9343 10.6452C12.5226 10.4069 12.1034 10.5552 11.9301 10.7894L11.5676 11.2586C11.3834 11.4969 11.0393 11.4653 11.0393 11.4653C8.52759 10.8011 7.85593 8.17275 7.85593 8.17275C7.85593 8.17275 7.82509 7.81692 8.05509 7.62608L8.50843 7.25108C8.73509 7.07108 8.87843 6.63775 8.64759 6.21192C8.03093 5.09775 7.61676 4.71358 7.40593 4.41858C7.18426 4.14108 6.85093 4.07858 6.50426 4.26608H6.49676C5.77593 4.68775 4.98676 5.47692 5.23926 6.28942C5.6701 7.14608 6.46176 9.87692 8.98509 11.9402C10.1709 12.9161 12.0476 13.9161 12.8443 14.1469L12.8518 14.1586C13.6368 14.4202 14.4001 13.6002 14.8076 12.8577V12.8519C14.9884 12.4928 14.9284 12.1527 14.6643 11.9302C14.1959 11.4736 13.4893 10.9694 12.9343 10.6452Z", fill: "white" }),
            React.createElement("path", { d: "M10.9744 6.75324C11.7752 6.7999 12.1635 7.21824 12.2052 8.0774C12.2202 8.41324 12.7077 8.38991 12.6927 8.05407C12.6394 6.9324 12.0544 6.3074 11.001 6.24907C10.6802 6.2299 10.6502 6.73407 10.9744 6.75324Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_205_39" },
                React.createElement("rect", { width: "20", height: "20", fill: "white" })))));
}
