var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ResponseError } from '../../../../../../shared/error/response-error';
import { uploadFile } from '../../../../../../shared/util/file';
import { clearAndSerialize } from '../../../../../../shared/util/normalizer';
import { getRelativeUrl } from '../../../../../../shared/util/url';
import omit from 'lodash/omit';
import { clearObject } from '../../../../../../../../assets/js/utils/etc';
import { AuthenticationMethhod } from './enum/AuthenticationMethhod';
var PersonRequestService = /** @class */ (function () {
    function PersonRequestService(params) {
        this.baseUrl = params.baseUrl;
        this.itemsPerPage = params.itemsPerPage;
        this.requestService = params.requestService;
        this.signService = params.signService;
        this.savePersonRequestUrl = params.savePersonRequestUrl;
        this.singlePersonRequestUrl = params.singlePersonRequestUrl;
        this.savePersonRequestPersonIdUrl = params.savePersonRequestPersonIdUrl;
        this.storageUrl = "".concat(params.storageUrl);
    }
    PersonRequestService.prototype.getList = function (page, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var args, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        args = __assign(__assign({}, (filter || {})), { page: "".concat(page), page_size: "".concat(this.itemsPerPage) });
                        return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/person_requests"), args)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    PersonRequestService.prototype.formatSingleResponse = function (resp) {
        return __awaiter(this, void 0, void 0, function () {
            var json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, resp.json()];
                    case 1:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            return [2 /*return*/, null];
                        }
                        return [2 /*return*/, __assign(__assign({}, json.data), json.urgent)];
                }
            });
        });
    };
    PersonRequestService.prototype.getSingle = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/person_requests/").concat(id))];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, this.formatSingleResponse(resp)];
                }
            });
        });
    };
    PersonRequestService.prototype.prepareData = function (data) {
        data.patient_signed = false;
        delete data.patient_agreed;
        if ("".concat(data.person.id || '').length) {
            delete data.person.authentication_methods;
            return data;
        }
        if (!data.person.authentication_methods.length) {
            return data;
        }
        if (data.person.authentication_methods[0].type !== AuthenticationMethhod.THIRD_PERSON) {
            delete data.person.authentication_methods[0].value;
        }
        if (data.person.authentication_methods[0].type === AuthenticationMethhod.THIRD_PERSON) {
            delete data.person.authentication_methods[0].phone_number;
        }
        return data;
    };
    PersonRequestService.prototype.create = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var preparedData, resp, respJson, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        preparedData = clearAndSerialize(this.prepareData(__assign({}, data)));
                        return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/person_requests"), undefined, preparedData)];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        respJson = _a.sent();
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, this.saveRequest(respJson.data)];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _a.sent();
                        console.error('Person request save error!');
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/, respJson];
                }
            });
        });
    };
    PersonRequestService.prototype.saveRequest = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("".concat(this.savePersonRequestUrl), {
                            credentials: 'include',
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                            },
                            body: JSON.stringify({
                                requestId: data.id,
                                payload: data,
                            }),
                        })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    PersonRequestService.prototype.saveRequestPersonId = function (requestId, personId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("".concat(this.savePersonRequestPersonIdUrl), {
                            credentials: 'include',
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                            },
                            body: JSON.stringify({
                                requestId: requestId,
                                personId: personId,
                            }),
                        })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    PersonRequestService.prototype.getSavedRequest = function (requestId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // @TO-DO: Temp solution, undefined singlePersonRequestUrl
                    return [2 /*return*/, null];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PersonRequestService.prototype.approve = function (id, code) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/person_requests/").concat(id, "/actions/approve"), undefined, code
                            ? JSON.stringify({
                                verification_code: +code,
                            })
                            : undefined)];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    PersonRequestService.prototype.uploadFile = function (url, file) {
        return uploadFile(file, "".concat(this.storageUrl).concat(getRelativeUrl(url)));
    };
    PersonRequestService.prototype.reject = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/person_requests/").concat(id, "/actions/reject"))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, this.formatSingleResponse(resp)];
                }
            });
        });
    };
    PersonRequestService.prototype.sign = function (id, data, signParams) {
        return __awaiter(this, void 0, void 0, function () {
            var filtredData, signedData, resp, respJson, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        data.patient_signed = true;
                        filtredData = omit(clearObject(data), ['documents', 'authentication_method_current']);
                        return [4 /*yield*/, this.signService.sign(JSON.stringify(filtredData), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/person_requests/").concat(id, "/actions/sign"), undefined, JSON.stringify({
                                signed_content: signedData,
                            }))];
                    case 4:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 5:
                        respJson = _a.sent();
                        _a.label = 6;
                    case 6:
                        _a.trys.push([6, 8, , 9]);
                        return [4 /*yield*/, this.saveRequestPersonId(data.id, respJson.data.person_id)];
                    case 7:
                        _a.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        e_2 = _a.sent();
                        console.error('Person ID at person request save error!');
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/, respJson];
                }
            });
        });
    };
    PersonRequestService.prototype.resendOTP = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/person_requests/").concat(id, "/actions/resend_otp"))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    return PersonRequestService;
}());
export { PersonRequestService };
