import { PARAM_MOI_WARDS_ACCEPT_REQUEST_URL, PARAM_MOI_WARDS_DECLINE_REQUEST_URL, PARAM_MOI_WARDS_LIST_URL, PARAM_MOI_WARDS_REGISTER_URL, PARAM_MOI_WARDS_REQUESTS_LIST_URL, PARAM_MOI_WARDS_SEND_REQUEST_URL, } from '../../config/di/params/keys';
import { Wards } from './wards';
export var SERVICE_MOI_WARDS = 'SERVICE_MOI_WARDS';
export function wardsFactory(container) {
    var getWardsListUrl = "".concat(container.get(PARAM_MOI_WARDS_LIST_URL));
    var getWardenRequestsListUrl = "".concat(container.get(PARAM_MOI_WARDS_REQUESTS_LIST_URL));
    var acceptWardenRequestUrl = "".concat(container.get(PARAM_MOI_WARDS_ACCEPT_REQUEST_URL));
    var declineWardenRequestUrl = "".concat(container.get(PARAM_MOI_WARDS_DECLINE_REQUEST_URL));
    var sendWardenRequestUrl = "".concat(container.get(PARAM_MOI_WARDS_SEND_REQUEST_URL));
    var registerWardUrl = "".concat(container.get(PARAM_MOI_WARDS_REGISTER_URL));
    return new Wards({
        getWardsListUrl: getWardsListUrl,
        getWardenRequestsListUrl: getWardenRequestsListUrl,
        acceptWardenRequestUrl: acceptWardenRequestUrl,
        declineWardenRequestUrl: declineWardenRequestUrl,
        sendWardenRequestUrl: sendWardenRequestUrl,
        registerWardUrl: registerWardUrl,
    });
}
