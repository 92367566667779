import { combineReducers } from 'redux';
import { jobReducer } from './job/reducer';
import { medicalEventReducer } from './medical-event';
import { serviceRequestReducer } from './service-request/reducer';
import { compositionReducer } from './composition/reducer';
import { patientSummaryReducer } from './patient-summary';
import { medicationRRReducer } from './medication-request-request/reducer';
import { medicationRReducer } from './medication-request/reducer';
import { personReducer } from './person/reducer';
import { serviceListReducer } from './service-list/reducer';
import { medicalProgramsReducer } from './medical-programs/reducer';
import { deviceRReducer } from './device-request/reducer';
import { deviceDefinitionsReducer } from './device-definitions/reducer';
export var contentReducer = combineReducers({
    job: jobReducer,
    medicalEvent: medicalEventReducer,
    serviceRequest: serviceRequestReducer,
    patientSummary: patientSummaryReducer,
    composition: compositionReducer,
    medicalPrograms: medicalProgramsReducer,
    medicationRR: medicationRRReducer,
    medicationR: medicationRReducer,
    person: personReducer,
    serviceList: serviceListReducer,
    deviceR: deviceRReducer,
    deviceDefinitions: deviceDefinitionsReducer,
});
