import { PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_CHANGE_STATUS_URL, PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_CREATE_URL, PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_EDIT_URL, PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_LIST_URL, PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_REMOVE_URL, PARAM_MOI_CHAT_MESSAGE_TEMPLATE_SEND_URL, PARAM_MOI_CHAT_MESSAGE_TEMPLATE_SINGLE_URL, } from '../../../config/di/params/keys';
import { EmailMessageTaskService } from './email-message-task';
import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
export var SERVICE_MOI_CHAT_EMAIL_MESSAGE_TASK = 'SERVICE_MOI_CHAT_EMAIL_MESSAGE_TASK';
export function emailMessageTaskFactory(container) {
    var listUrl = "".concat(container.get(PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_LIST_URL));
    var deleteUrl = "".concat(container.get(PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_REMOVE_URL));
    var createUrl = "".concat(container.get(PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_CREATE_URL));
    var updateUrl = "".concat(container.get(PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_EDIT_URL));
    var changeStatusUrl = "".concat(container.get(PARAM_MOI_CHAT_EMAIL_MESSAGE_TASK_CHANGE_STATUS_URL));
    var singleUrl = "".concat(container.get(PARAM_MOI_CHAT_MESSAGE_TEMPLATE_SINGLE_URL));
    var sendUrl = "".concat(container.get(PARAM_MOI_CHAT_MESSAGE_TEMPLATE_SEND_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    return new EmailMessageTaskService({
        urls: {
            singleUrl: singleUrl,
            listUrl: listUrl,
            updateUrl: updateUrl,
            createUrl: createUrl,
            deleteUrl: deleteUrl,
        },
        changeStatusUrl: changeStatusUrl,
        sendUrl: sendUrl,
        itemsPerPage: itemsPerPage,
    });
}
