var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { NavAction } from './action.enum';
export function navItemsToMap(items) {
    var e_1, _a;
    var map = new Map();
    try {
        for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
            var item = items_1_1.value;
            map.set(item.link, item);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return map;
}
export function navSubMenuItemsToMap(items) {
    var e_2, _a, e_3, _b;
    var map = new Map();
    try {
        for (var items_2 = __values(items), items_2_1 = items_2.next(); !items_2_1.done; items_2_1 = items_2.next()) {
            var item = items_2_1.value;
            var subMenuMap = new Map();
            var subMenuItems = item.items;
            try {
                for (var subMenuItems_1 = (e_3 = void 0, __values(subMenuItems)), subMenuItems_1_1 = subMenuItems_1.next(); !subMenuItems_1_1.done; subMenuItems_1_1 = subMenuItems_1.next()) {
                    var subMenuItem = subMenuItems_1_1.value;
                    subMenuMap.set(subMenuItem.link, subMenuItem);
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (subMenuItems_1_1 && !subMenuItems_1_1.done && (_b = subMenuItems_1.return)) _b.call(subMenuItems_1);
                }
                finally { if (e_3) throw e_3.error; }
            }
            map.set(item.parentMenuItem, subMenuMap);
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (items_2_1 && !items_2_1.done && (_a = items_2.return)) _a.call(items_2);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return map;
}
export function createSetMenusAction(menus) {
    var e_4, _a;
    var menusMaps = {};
    try {
        for (var _b = __values(Object.keys(menus)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var place = _c.value;
            menusMaps[place] = navItemsToMap(menus[place]);
        }
    }
    catch (e_4_1) { e_4 = { error: e_4_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_4) throw e_4.error; }
    }
    return {
        type: NavAction.SET_ALL_MENUS,
        payload: {
            menus: menusMaps,
        },
    };
}
export function createUpdateMenuItemAction(place, key, partialItem) {
    return {
        type: NavAction.UPDATE_MENU_ITEM,
        payload: {
            navPlace: place,
            itemKey: key,
            item: partialItem,
        },
    };
}
export function createSetMenuAction(place, items) {
    return {
        type: NavAction.SET_MENU,
        payload: {
            items: navItemsToMap(items),
            place: place,
        },
    };
}
export function createSetSubMenuAction(place, items) {
    return {
        type: NavAction.SET_SUB_MENU,
        payload: {
            items: navSubMenuItemsToMap(items),
            place: place,
        },
    };
}
