import { useDispatch } from 'react-redux';
import { createAddNotificationAction } from '../../store/notification/factory';
import { NotificationType } from '../../store/notification/notification-type.enum';
export function useNotification() {
    var dispatch = useDispatch();
    var successNotification = function (title, message) {
        dispatch(createAddNotificationAction({
            title: title,
            message: message,
        }));
    };
    var errorNotification = function (title, message) {
        dispatch(createAddNotificationAction({
            title: title,
            message: message,
            type: NotificationType.ERROR,
        }));
    };
    return {
        successNotification: successNotification,
        errorNotification: errorNotification,
    };
}
