import React from 'react';
import { DesktopMenu } from '../../DesktopMenu/DesktopMenu';
import classNames from 'classnames';
import { useStyles } from './PopupMenu.jss';
export var PopupMenu = function (props) {
    var _a;
    var _b = useStyles(), activePopup = _b.activePopup, menuWrapper = _b.menuWrapper;
    return (React.createElement("div", { className: classNames('cabinet-mobile-menu', (_a = { active: props.isOpen }, _a[activePopup] = props.isOpen, _a), menuWrapper) },
        React.createElement(DesktopMenu, { items: props.items, title: props.title })));
};
