var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { NotificationType } from '../../../../../store/notification/notification-type.enum';
import { Button } from '@mui/material';
export var Notification = function (props) {
    var timer = 0;
    var type = props.type || NotificationType.SUCCESS;
    var DEFAULT_CLASSES = classNames('notification-item', "notification-item--".concat(type));
    var ACTIVE_CLASSES = classNames(DEFAULT_CLASSES, 'show');
    var _a = __read(useState(ACTIVE_CLASSES), 2), wrapperClass = _a[0], setWrapperClass = _a[1];
    var clickHandler = function () {
        clearTimeout(timer);
        setWrapperClass(DEFAULT_CLASSES);
        setTimeout(function () { return props.onClick && props.onClick(props.uid); }, 400);
    };
    useEffect(function () {
        timer = window.setTimeout(function () {
            clickHandler();
        }, 8000);
    }, [props.uid]);
    return (React.createElement("div", { className: wrapperClass, onClick: clickHandler },
        React.createElement("div", { className: "notification-item__title" }, props.title),
        React.createElement("div", { className: "notification-item__message" }, props.message),
        React.createElement(Button, { className: "notification-item__button", onClick: clickHandler }, "\u0417\u0430\u043A\u0440\u0438\u0442\u0438")));
};
