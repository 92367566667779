var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotification } from '../../../hook/misc/use-notification';
import { ConnectedNotificationList } from '../../misc/ConnectedNotificationList/ConnectedNotificationList';
import { HeaderCabinet } from '../../nav/HeaderCabinet/HeaderCabinet';
import { Breadcrumb } from '../../nav/Breadcrumbs/Breadcrumb';
import { Box, useMediaQuery } from '@mui/material';
import { Container } from '@mui/material';
import { Header } from '../../../../app/moi/components/Header';
import { TabletHeader } from '../../../../app/moi/components/Header/TabletHeader';
import Footer from '../../../../app/moi/components/Footer/Footer';
import { useHistory, useLocation } from 'react-router-dom';
import { SideBarMenuDesktop } from '../../nav/Menu/SideBarDesktop/SideBarMenuDesktop';
import { batch, useDispatch, useSelector } from 'react-redux';
import { getPersonalInformation } from '../../../../app/moi/store/user/personalInfo/factory';
import { getMedicalInformation } from '../../../../app/moi/store/user/medicalInfo/medicalInfo';
import { getAddressInformation } from '../../../../app/moi/store/user/addressInfo/factory';
import { CabinetSelector } from '../../../../app/moi/store/cabinet/selector';
import { RoleSelector } from '../../../../app/moi/store/user/personalInfo/selector';
import { setCabinetType } from '../../../../app/moi/store/cabinet/factory';
import { NotificationsDeletedSelector, NotificationsMarkedSelector, } from '../../../../app/moi/store/notifications/selector';
import { getAllNotifications } from '../../../../app/moi/store/notifications/asyncActions';
import { getWorkInformation } from '../../../../app/moi/store/user/workInfo/factory';
import { getCategories } from '../../../../app/moi/store/categories/factory';
import { getAdditionalInformation } from '../../../../app/moi/store/user/additionalInfo/factory';
import { RecordsFolderCreatedSelector, RecordsFolderDeletedSelector, RecordsFolderEditedSelector, } from '../../../../app/moi/store/data-archive/my-records/records-folders/selector';
import { getRecordsFolders } from '../../../../app/moi/store/data-archive/my-records/records-folders/asyncActions';
import { getRecordsCategories } from '../../../../app/moi/store/data-archive/my-records/records-categories-async/asyncActions';
import { getDeclarationInformation } from '../../../../app/moi/store/user/declarationInfo/factory';
import { IsDeclarationInfoChangedSelector } from '../../../../app/moi/store/user/declarationInfo/selector';
import { ClinicInfoSelector } from '../../../../app/moi/store/clinic/clinicInfo/selector';
import { getClinicInformation } from '../../../../app/moi/store/clinic/clinicInfo/asyncActions';
export var BasePage = function (props) {
    //const { action } = useRequestState();
    var dispatch = useDispatch();
    var children = props.children;
    var errorNotification = useNotification().errorNotification;
    var matches = useMediaQuery('(max-width:1530px)');
    var matches768 = useMediaQuery('(max-width:768px)');
    var location = useLocation();
    var history = useHistory();
    var isCabinet = location.pathname.includes('/cabinet') || location.pathname.includes('/e-health/');
    var cabinetType = useSelector(CabinetSelector);
    var roles = useSelector(RoleSelector);
    var _a = __read(useState(false), 2), isLogin = _a[0], setIsLogin = _a[1];
    var isMarked = useSelector(NotificationsMarkedSelector);
    var isDeleted = useSelector(NotificationsDeletedSelector);
    var isFolderCreated = useSelector(RecordsFolderCreatedSelector);
    var isFolderDeleted = useSelector(RecordsFolderDeletedSelector);
    var isFolderEdited = useSelector(RecordsFolderEditedSelector);
    var isDeclarationInfoChanged = useSelector(IsDeclarationInfoChangedSelector);
    var clinicInfo = useSelector(ClinicInfoSelector).clinic;
    var hideChat = document.querySelectorAll('.chat-frame-wrapper');
    var _b = useTranslation(), t = _b.t, i18n = _b.i18n;
    var isHideHeaderAndFooter = location.pathname.includes("/".concat(i18n.language, "/register"));
    useEffect(function () {
        setIsLogin(document.cookie.includes('bearer'));
    }, [isLogin]);
    useEffect(function () {
        if (hideChat[0]) {
            if (location.pathname.includes("/".concat(i18n.language, "/cabinet/messages")) ||
                location.pathname.includes("/".concat(i18n.language, "/cabinet/dialog"))) {
                hideChat[0].style.display = 'block';
            }
            else {
                hideChat[0].style.display = 'none';
            }
        }
    }, [hideChat, location]);
    useEffect(function () {
        if (!props.error) {
            return;
        }
        errorNotification(t('documents.error'), t('eHealth.reloadAndRetry'));
        console.error(props.error);
        props.clearError();
    }, [props.error]);
    useEffect(function () {
        if (isLogin) {
            dispatch(getRecordsFolders());
        }
    }, [dispatch, isLogin, isFolderCreated, isFolderDeleted, isFolderEdited]);
    useEffect(function () {
        //action(async () => {
        if (isLogin && cabinetType !== 'clinic') {
            batch(function () {
                dispatch(getPersonalInformation());
                dispatch(getMedicalInformation());
                dispatch(getAddressInformation());
                dispatch(getWorkInformation());
                dispatch(getAdditionalInformation());
                dispatch(getDeclarationInformation());
                dispatch(getCategories(i18n.language));
                dispatch(getRecordsCategories(i18n.language));
            });
        }
        //});
    }, [dispatch, isLogin, isDeclarationInfoChanged, cabinetType, i18n.language]);
    useEffect(function () {
        if (isLogin && cabinetType === 'clinic') {
            batch(function () {
                dispatch(getPersonalInformation());
                dispatch(getClinicInformation());
            });
        }
    }, [dispatch, isLogin, cabinetType]);
    useEffect(function () {
        if (isLogin) {
            dispatch(getAllNotifications());
        }
    }, [dispatch, isLogin, isMarked, isDeleted]);
    useEffect(function () {
        if ((isLogin && (cabinetType === null || cabinetType === void 0 ? void 0 : cabinetType.length) == 0) || (isLogin && cabinetType === "''")) {
            switch (true) {
                case roles.includes('clinic'):
                    dispatch(setCabinetType('clinic'));
                    break;
                case roles.includes('expert'):
                    dispatch(setCabinetType('doctor'));
                    break;
                case roles.includes('patient'):
                    dispatch(setCabinetType('patient'));
                    break;
            }
        }
    }, [dispatch, isLogin, roles, cabinetType]);
    useEffect(function () {
        if (isLogin && cabinetType === 'clinic' && location.pathname.includes("/".concat(i18n.language, "/cabinet/settings")))
            history.push("/".concat(i18n.language, "/cabinet/company/profile/new"));
    }, [dispatch, isLogin, roles, cabinetType, location]);
    var Sidebar = function () { return (React.createElement("div", { className: "cabinet-sidebar" },
        React.createElement(SideBarMenuDesktop, null))); };
    return (React.createElement(React.Fragment, null,
        isCabinet && React.createElement(Sidebar, null),
        React.createElement(Box, { component: "main", sx: {
                padding: "0px ".concat(isCabinet ? (matches768 ? '20px' : '32px') : '0px'),
                flexGrow: 1,
                overflow: 'hidden',
                background: "".concat(isCabinet
                    ? cabinetType === 'clinic'
                        ? 'linear-gradient(to right, #F8F5FF, #FFF1F5)'
                        : cabinetType === 'doctor'
                            ? 'linear-gradient(to right, #E0F2F4, #DDF3FF)'
                            : 'linear-gradient(to right, #E9EEEA, #E9F0E2)'
                    : ''),
                minHeight: '100vh',
            } },
            React.createElement(Container, { disableGutters: true, maxWidth: false },
                isCabinet && React.createElement(HeaderCabinet, null),
                isCabinet && React.createElement(Breadcrumb, null),
                !isCabinet && !isHideHeaderAndFooter && (matches ? React.createElement(TabletHeader, null) : React.createElement(Header, null)),
                React.createElement("div", { className: "cabinet-content" }, children),
                !isCabinet && !isHideHeaderAndFooter && React.createElement(Footer, null))),
        React.createElement(ConnectedNotificationList, null)));
};
