import { makeStyles } from '@mui/styles';
export var useStyles = makeStyles(function (theme) { return ({
    container: {
        width: 240,
        paddingLeft: '10px',
        paddingRight: '10px',
        marginBottom: '20px',
        height: '100%',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { width: '0px', display: 'none' },
    },
    divWrapper: {
        height: '100%',
    },
    topMenuItemsWrapper: {
        paddingTop: '0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,
    },
    bottomMenuItemsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        //gap: '24px',
    },
    MenuWrapper: {
        height: '100%',
        float: 'left',
        position: 'fixed',
        zIndex: '1',
        width: '240px',
        backgroundColor: theme.palette.background.paper,
    },
    LogoWrapper: {
        marginLeft: '16px',
        marginTop: '20px',
        marginBottom: '30px',
        cursor: 'pointer',
    },
    BottomWrapper: {
        position: 'absolute',
        bottom: '0px',
    },
    closeButton: {
        width: '30px',
        height: '30px',
        borderRaduis: '25px',
        backgroundColor: '#F1F6FA !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '25px',
        right: '10px',
    },
}); });
