export var EhealthMedicationRAction;
(function (EhealthMedicationRAction) {
    EhealthMedicationRAction["ADD_ITEMS"] = "EHEALTH_MEDICATION_REQUEST_ADD_ITEMS";
    EhealthMedicationRAction["CLEAR"] = "EHEALTH_MEDICATION_REQUEST_CLEAR";
    EhealthMedicationRAction["UPDATE"] = "EHEALTH_MEDICATION_REQUEST_UPDATE";
    EhealthMedicationRAction["SET_IS_LOADING"] = "EHEALTH_MEDICATION_REQUEST_SET_IS_LOADING";
    EhealthMedicationRAction["SETUP_JOBS"] = "EHEALTH_MEDICATION_REQUEST_SETUP_JOBS";
    EhealthMedicationRAction["REMOVE_JOB"] = "EHEALTH_MEDICATION_REQUEST_REMOVE_JOB";
    EhealthMedicationRAction["SET_FILTER"] = "EHEALTH_MEDICATION_REQUEST_FILTER";
})(EhealthMedicationRAction || (EhealthMedicationRAction = {}));
