import { PARAM_ITEMS_PER_PAGE } from '../../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_STORAGE_PROXY_URL } from '../../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../../request';
import { MedicationAdministrationService } from './medication-administration';
export var SERVICE_EHEALTH_MEDICAL_EVENT_MEDICATION_ADMINISTRATION = 'SERVICE_EHEALTH_MEDICAL_EVENT_MEDICATION_ADMINISTRATION';
export var ehealthMedicationAdministrationFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var storageUrl = "".concat(container.get(PARAM_EHEALTH_STORAGE_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    return new MedicationAdministrationService({
        baseUrl: baseUrl,
        storageUrl: storageUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
    });
};
