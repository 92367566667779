var _a;
import { ChoiceListKey } from './enum/choice-list-key';
export var defaultState = (_a = {},
    _a[ChoiceListKey.EMPLOYEE] = {},
    _a[ChoiceListKey.DIAGNOSTIC_REPORT] = {},
    _a[ChoiceListKey.OBSERVATION] = {},
    _a[ChoiceListKey.MEDICATION_REQUEST] = {},
    _a[ChoiceListKey.CONDITION] = {},
    _a[ChoiceListKey.SERVICE_REQUEST] = {},
    _a[ChoiceListKey.HC_SERVICE] = {},
    _a[ChoiceListKey.EPISODE] = {},
    _a[ChoiceListKey.ENCOUNTER] = {},
    _a[ChoiceListKey.DIVISION] = {},
    _a[ChoiceListKey.MEDICATION_ADMINISTRATION] = {},
    _a[ChoiceListKey.MEDICATION_STATEMENT] = {},
    _a[ChoiceListKey.PROCEDURE] = {},
    _a[ChoiceListKey.IMMUNIZATION] = {},
    _a[ChoiceListKey.DEVICE] = {},
    _a[ChoiceListKey.MEDICAL_PROGRAM] = {},
    _a[ChoiceListKey.CARE_PLAN] = {},
    _a[ChoiceListKey.CARE_PLAN_ACTIVITIES] = {},
    _a[ChoiceListKey.CATALOG_SERVICE_ITEMS] = {},
    _a[ChoiceListKey.LEGAL_ENTITIES] = {},
    _a[ChoiceListKey.FORBIDDEN_GROUPS] = {},
    _a[ChoiceListKey.MEDICAL_PROGRAM_FOR_MEDICATION_REQUEST] = {},
    _a[ChoiceListKey.LICENSE] = {},
    _a[ChoiceListKey.CAPITATION_CONTRACT] = {},
    _a[ChoiceListKey.DIAGNOSES_GROUP] = {},
    _a[ChoiceListKey.SERVICE_GROUP] = {},
    _a[ChoiceListKey.PATIENT] = {},
    _a);
