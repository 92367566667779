import { createStyles, makeStyles } from '@mui/styles';
export var useStyles = makeStyles(function (theme) {
    return createStyles({
        wrapper: {
            height: '538px',
            width: '358px',
            borderRadius: '15px',
            backgroundColor: '#FFFFFF',
            zIndex: 1111,
            padding: '15px 10px 20px 10px',
            marginTop: '-10px',
            marginLeft: '10px',
        },
        textWhat: {
            fontSize: '12px',
            lineHeight: '12px',
            fontWeight: '500',
            color: '#90A4BE',
        },
        textAll: {
            cursor: 'pointer',
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '12px',
            color: '#173236',
            letterSpacing: '-0.03em',
            fontFamily: 'GraphikLCG',
        },
        textName: {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '14px',
            letterSpacing: '-0.03em',
            fontFamily: 'GraphikLCG',
        },
        iconWrapper: {
            width: '30px',
            height: '30px',
            borderRadius: '20px',
            backgroundColor: '#E5EEFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    });
});
