var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ExpertListActionType } from './action';
import { initialState } from './state';
export var expertListReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ExpertListActionType.GET_EXPERT_LIST_PENDING:
            return __assign(__assign({}, state), { isLoading: true, error: null });
        case ExpertListActionType.GET_EXPERT_LIST_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, expertList: action.payload });
        case ExpertListActionType.GET_EXPERT_LIST_ERROR:
            return __assign(__assign({}, state), { isLoading: false, error: action.payload });
        case ExpertListActionType.SET_SELECTED_CITY:
            return __assign(__assign({}, state), { selectedCity: action.payload });
        case ExpertListActionType.SET_PRICE_FROM:
            return __assign(__assign({}, state), { selectedPriceFrom: action.payload });
        case ExpertListActionType.SET_PRICE_TO:
            return __assign(__assign({}, state), { selectedPriceTo: action.payload });
        case ExpertListActionType.SET_LIMIT:
            return __assign(__assign({}, state), { limit: action.payload });
        default:
            return state;
    }
};
