export var ChatUsersActionType;
(function (ChatUsersActionType) {
    ChatUsersActionType["GET_CHAT_USERS_PENDING"] = "GET_CHAT_USERS_PENDING";
    ChatUsersActionType["GET_CHAT_USERS_SUCCESS"] = "GET_CHAT_USERS_SUCCESS";
    ChatUsersActionType["GET_CHAT_USERS_ERROR"] = "GET_CHAT_USERS_ERROR";
    ChatUsersActionType["ADD_DIALOG_PENDING"] = "ADD_DIALOG_PENDING";
    ChatUsersActionType["ADD_DIALOG_SUCCESS"] = "ADD_DIALOG_SUCCESS";
    ChatUsersActionType["ADD_DIALOG_ERROR"] = "ADD_DIALOG_ERROR";
    ChatUsersActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(ChatUsersActionType || (ChatUsersActionType = {}));
