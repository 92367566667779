import { combineReducers } from 'redux';
import { recordsCategoriesReducer } from './my-records/records-categories/reducer';
import { recordsReducer } from './my-records/records/reducer';
import { changesHistoryReducer } from './changes-history/reducer';
import { recordsFoldersReducer } from './my-records/records-folders';
import { accessesReducer } from './accesses';
import { recordsCategoriesAsyncReducer } from './my-records/records-categories-async';
export var dataArchiveReducer = combineReducers({
    recordsCategories: recordsCategoriesReducer,
    records: recordsReducer,
    recordsFolders: recordsFoldersReducer,
    changesHistory: changesHistoryReducer,
    accesses: accessesReducer,
    recordsCategoriesAsync: recordsCategoriesAsyncReducer,
});
