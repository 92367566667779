var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ResponseError } from '../../../../../shared/error/response-error';
import { clearObject } from '../../../../../../../assets/js/utils/etc';
import { clearAndSerialize } from '../../../../../shared/util/normalizer';
import { PayloadStoreType } from '../medical-events/enum/payload-store-type';
import { Role } from '../../../enum/role';
import cloneDeep from 'lodash/cloneDeep';
var ServiceRequest = /** @class */ (function () {
    function ServiceRequest(params) {
        this.authService = params.authService;
        this.baseUrl = params.baseUrl;
        this.itemsPerPage = params.itemsPerPage;
        this.requestService = params.requestService;
        this.signService = params.signService;
        this.payloadStoreCreateUrl = params.payloadStoreCreateUrl;
        this.payloadStoreDeleteUrl = params.payloadStoreDeleteUrl;
        this.payloadStoreListUrl = params.payloadStoreListUrl;
        this.payloadStoreSingleUrl = params.payloadStoreSingleUrl;
    }
    ServiceRequest.prototype.search = function (patientId, page, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var roles, preparedParams, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        roles = this.authService.getCreds().roles;
                        preparedParams = clearObject(filter || {});
                        if (roles.includes(Role.ROLE_ASSISTANT) ||
                            roles.includes(Role.ROLE_SPECIALIST) ||
                            roles.includes(Role.ROLE_RECEPTIONIST)) {
                            preparedParams.requester_legal_entity = this.authService.getCreds().legalEntityId;
                        }
                        return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/service_requests"), __assign(__assign({}, preparedParams), { page: "".concat(page), page_size: "".concat(this.itemsPerPage) }))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    ServiceRequest.prototype.preQualify = function (patientId, data) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/service_requests/prequalify"), undefined, clearAndSerialize(data))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    ServiceRequest.prototype.create = function (patientId, signParams, data) {
        return __awaiter(this, void 0, void 0, function () {
            var cloneData, signedData, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cloneData = cloneDeep(data);
                        return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        delete cloneData.episode;
                        return [4 /*yield*/, this.signService.sign(clearAndSerialize(cloneData), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/service_requests"), undefined, JSON.stringify({
                                signed_data: signedData,
                            }))];
                    case 4:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 5:
                        json = _a.sent();
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    ServiceRequest.prototype.getListInEpisodeContext = function (patientId, page, episodeId, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var preparedParams, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        preparedParams = clearObject(filter || {});
                        return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/episodes/").concat(episodeId, "/service_requests"), __assign(__assign({}, preparedParams), { page: "".concat(page), page_size: "".concat(this.itemsPerPage) }))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    ServiceRequest.prototype.getSingleWithEpisode = function (patientId, episodeId, srId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/episodes/").concat(episodeId, "/service_requests/").concat(srId))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    };
    ServiceRequest.prototype.getSingleWithoutEpisode = function (patientId, srId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/service_requests/").concat(srId))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    };
    ServiceRequest.prototype.cancel = function (patientId, srId, signParams, data) {
        return __awaiter(this, void 0, void 0, function () {
            var signedData, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        return [4 /*yield*/, this.signService.sign(JSON.stringify(data), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/service_requests/").concat(srId, "/actions/cancel"), undefined, JSON.stringify({
                                signed_data: signedData,
                            }))];
                    case 4:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 5:
                        json = _a.sent();
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    ServiceRequest.prototype.recall = function (patientId, srId, signParams, data) {
        return __awaiter(this, void 0, void 0, function () {
            var signedData, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        return [4 /*yield*/, this.signService.sign(JSON.stringify(data), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/service_requests/").concat(srId, "/actions/recall"), undefined, JSON.stringify({
                                signed_data: signedData,
                            }))];
                    case 4:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 5:
                        json = _a.sent();
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    ServiceRequest.prototype.resendSms = function (patientId, srId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/service_requests/").concat(srId, "/actions/resend"))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    ServiceRequest.prototype.getListByRequisition = function (requisition, page) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/service_requests"), {
                            page: "".concat(page),
                            page_size: "".concat(this.itemsPerPage),
                            requisition: requisition,
                        })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        json = _a.sent();
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    ServiceRequest.prototype.qualify = function (srId, data) {
        return __awaiter(this, void 0, void 0, function () {
            var programs, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        programs = {
                            programs: [data.program],
                        };
                        return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/service_requests/").concat(srId, "/actions/qualify"), undefined, clearAndSerialize(programs))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        json = _a.sent();
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    ServiceRequest.prototype.use = function (srId, data) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('srId', srId);
                        console.log('data', data);
                        return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/service_requests/").concat(srId, "/actions/use"), undefined, clearAndSerialize(data))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    ServiceRequest.prototype.makeInProgress = function (srId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/service_requests/").concat(srId, "/actions/process"))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    ServiceRequest.prototype.release = function (srId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/service_requests/").concat(srId, "/actions/release"))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        json = _a.sent();
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    ServiceRequest.prototype.complete = function (srId, data) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/service_requests/").concat(srId, "/actions/complete"), undefined, clearAndSerialize(data))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    ServiceRequest.prototype.getStoredSignle = function (srId) {
        return __awaiter(this, void 0, void 0, function () {
            var query, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = new URLSearchParams({
                            id: srId,
                        });
                        return [4 /*yield*/, fetch("".concat(this.payloadStoreSingleUrl, "?").concat(query), {
                                credentials: 'include',
                            })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    ServiceRequest.prototype.tmpStore = function (srId, patientId, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("".concat(this.payloadStoreCreateUrl), {
                            credentials: 'include',
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                            },
                            body: JSON.stringify({
                                id: srId,
                                patient_id: patientId,
                                payload: payload,
                                type: PayloadStoreType.SERVICE_REQUEST_STORE_TYPE,
                            }),
                        })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    ServiceRequest.prototype.getStoredList = function (patientId, page) {
        return __awaiter(this, void 0, void 0, function () {
            var query, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = new URLSearchParams({
                            patient_id: patientId,
                            page: "".concat(page),
                            page_size: "".concat(this.itemsPerPage),
                            type: PayloadStoreType.SERVICE_REQUEST_STORE_TYPE,
                        });
                        return [4 /*yield*/, fetch("".concat(this.payloadStoreListUrl, "?").concat(query), {
                                credentials: 'include',
                            })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    ServiceRequest.prototype.removeStored = function (patientId, ids) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("".concat(this.payloadStoreDeleteUrl), {
                            credentials: 'include',
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                            },
                            body: JSON.stringify({
                                ids: ids,
                                patient_id: patientId,
                            }),
                        })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    return ServiceRequest;
}());
export { ServiceRequest };
