import { CabinetActionEnum } from './action';
export var setCabinetType = function (cabinetType) { return ({
    type: CabinetActionEnum.SET_CABINET_TYPE,
    payload: cabinetType,
}); };
export var setCabinetPatientLocation = function (cabinetLocation) { return ({
    type: CabinetActionEnum.SET_CABINET_PATIENT_LOCATION,
    payload: cabinetLocation,
}); };
export var setCabinetExpertLocation = function (cabinetLocation) { return ({
    type: CabinetActionEnum.SET_CABINET_EXPERT_LOCATION,
    payload: cabinetLocation,
}); };
export var setCabinetClinicLocation = function (cabinetLocation) { return ({
    type: CabinetActionEnum.SET_CABINET_CLINIC_LOCATION,
    payload: cabinetLocation,
}); };
