var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from '../HeaderCabinetStyle';
import Collapse from '@material-ui/core/Collapse';
import { Arrow } from '../assets/Arrow';
import { ItemCollapse } from './ItemCollapse';
import { CollapseMenuMedicalArchiveIcon } from '../assets/CollapseMenuMedicalArchiveIcon';
import { CollapseMenuMonitoringIcom } from '../assets/CollapseMenuMonitoringIcom';
import { CollapseMenuDynamicIcon } from '../assets/CollapseMenuDynamicIcon';
import { CollapseMenuToDoctorsIcon } from '../assets/CollapseMenuToDoctorsIcon';
import { CollapseMenuDirectionIcon } from '../assets/CollapseMenuDirectionIcon';
import { CollapseMenuReferencesIcon } from '../assets/CollapseMenuReferencesIcon';
import { CollapseMenuFamilyIcon } from '../assets/CollapseMenuFamilyIcon';
import { ArrowDown } from '../assets/ArrowDown';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IsExpertSelector, PersonalInfoSelector } from '../../../../../app/moi/store/user/personalInfo/selector';
import { setCabinetType } from '../../../../../app/moi/store/cabinet/factory';
import { CabinetSelector } from '../../../../../app/moi/store/cabinet/selector';
export var CabinetProfileMenu = function () {
    var dispatch = useDispatch();
    var classes = useStyles();
    var _a = __read(React.useState(false), 2), collapseExpanded = _a[0], setCollapseExpanded = _a[1];
    var isExpert = useSelector(IsExpertSelector);
    var personalInfo = useSelector(PersonalInfoSelector);
    var cabinetType = useSelector(CabinetSelector);
    var history = useHistory();
    var CollapseMenu = function () {
        if (collapseExpanded)
            setCollapseExpanded(false);
        else
            setCollapseExpanded(true);
    };
    var handleCabinetType = function (cabinetType) {
        dispatch(setCabinetType(cabinetType));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: classes.expandMenuParent },
            React.createElement(Box, { className: classes.MenuContent, sx: {
                    height: '100%',
                    backgroundColor: '#F1F6FA',
                } },
                React.createElement(Box, { className: classes.MenuContentButtonWrapper },
                    React.createElement(Typography, { className: classes.menuHeaderTextBig }, personalInfo.full_name),
                    React.createElement(Typography, { className: classes.menuHeaderTextSmall }, personalInfo.email)),
                React.createElement(Arrow, { color: '#A5BDDB' })),
            cabinetType !== 'doctor' ? (isExpert ? (React.createElement(Box, { className: classes.MenuContent, sx: {
                    backgroundColor: '#173236 !important',
                    border: '1px solid #E5EEFF',
                    marginTop: '10px',
                } },
                React.createElement(Box, { className: classes.MenuContentButtonWrapper, onClick: function () { return handleCabinetType('doctor'); } },
                    React.createElement(Typography, { className: classes.menuHeaderTextBig, sx: { color: 'white !important' } }, "\u0412 \u043A\u0430\u0431\u0456\u043D\u0435\u0442 \u043B\u0456\u043A\u0430\u0440\u044F")),
                React.createElement(Arrow, { color: '#29F499' }))) : (React.createElement(Box, { className: classes.MenuContent, sx: {
                    border: '1px solid #E5EEFF',
                    marginTop: '10px',
                } },
                React.createElement(Box, { className: classes.MenuContentButtonWrapper, 
                    //onClick={() => history.push('/uk/register')}
                    onClick: function () { return (document.location.href = '/uk/register'); } },
                    React.createElement(Typography, { className: classes.menuHeaderTextBig },
                        "\u0421\u0442\u0432\u043E\u0440\u0438\u0442\u0438 \u043A\u0430\u0431\u0456\u043D\u0435\u0442",
                        React.createElement("p", null),
                        " \u043B\u0456\u043A\u0430\u0440\u044F \u0430\u0431\u043E \u043A\u043B\u0456\u043D\u0456\u043A\u0438")),
                React.createElement(Arrow, { color: '#A5BDDB' })))) : (React.createElement(React.Fragment, null,
                React.createElement(Box, { className: classes.MenuContent, sx: {
                        backgroundColor: '#173236 !important',
                        border: '1px solid #E5EEFF',
                        marginTop: '10px',
                    } },
                    React.createElement(Box, { className: classes.MenuContentButtonWrapper, onClick: function () { return handleCabinetType('patient'); } },
                        React.createElement(Typography, { className: classes.menuHeaderTextBig, sx: { color: 'white !important' } }, "\u0412 \u043A\u0430\u0431\u0456\u043D\u0435\u0442 \u043F\u0430\u0446\u0456\u0454\u043D\u0442\u0430")),
                    React.createElement(Arrow, { color: '#29F499' })))),
            React.createElement(Box, { sx: { margin: '20px' } },
                React.createElement(Box, { onClick: CollapseMenu, sx: {
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    } },
                    collapseExpanded ? React.createElement(ArrowDown, null) : React.createElement(Arrow, { color: '#173236' }),
                    React.createElement(Typography, { sx: { paddingLeft: '10px' }, className: classes.menuHeaderTextBig }, "\u041E\u0441\u043D\u043E\u0432\u043D\u0435 \u043C\u0435\u043D\u044E")),
                React.createElement(Collapse, { in: collapseExpanded, className: classes.CollapseMenu },
                    React.createElement(ItemCollapse, { icon: React.createElement(CollapseMenuMedicalArchiveIcon, null), text: 'Медичний архів', route: '/uk/cabinet/data_archive#nav-my-records' }),
                    React.createElement(ItemCollapse, { icon: React.createElement(CollapseMenuMonitoringIcom, null), text: 'Скринінг та моніторинг', route: '/uk/cabinet/screaning-screaning' }),
                    React.createElement(ItemCollapse, { icon: React.createElement(CollapseMenuDynamicIcon, null), text: 'Динаміка здоровя', route: '/uk/cabinet/my-health-dynamic' }),
                    React.createElement(ItemCollapse, { icon: React.createElement(CollapseMenuToDoctorsIcon, null), text: 'Записи до лікарів', route: '/uk/cabinet/my-booked-services' }),
                    React.createElement(ItemCollapse, { icon: React.createElement(CollapseMenuDirectionIcon, null), text: 'Направлення', route: '/uk/cabinet/my-analyse' }),
                    React.createElement(ItemCollapse, { icon: React.createElement(CollapseMenuReferencesIcon, null), text: 'Довідки', route: '/uk/cabinet/reference' }),
                    React.createElement(ItemCollapse, { icon: React.createElement(CollapseMenuFamilyIcon, null), text: 'Моя родина', route: '/uk/cabinet/charges' }))),
            React.createElement(Box, { sx: { margin: '10px', display: 'flex', flexDirection: 'column', marginLeft: '20px' } },
                React.createElement(Typography, { className: classes.menuHeaderTextBig, onClick: function () {
                        history.push('');
                    } }, "\u041E\u0431\u0440\u0430\u043D\u0456 \u043B\u0456\u043A\u0430\u0440\u0456"),
                React.createElement(Typography, { className: classes.menuHeaderTextBigMarginTop, onClick: function () {
                        history.push('/uk/cabinet/settings');
                    } }, "\u041D\u0430\u043B\u0430\u0448\u0442\u0443\u0432\u0430\u043D\u043D\u044F"),
                React.createElement(Typography, { className: classes.menuHeaderTextBigMarginTop }, "\u0414\u043E\u043F\u043E\u043C\u043E\u0433\u0430"),
                React.createElement(Typography, { className: classes.menuHeaderTextBigExit, onClick: function () {
                        document.cookie = "cabinetType=''";
                        //history.push('/uk/logout');
                        document.location = '/uk/logout';
                    } }, "\u0412\u0438\u0439\u0442\u0438")))));
};
