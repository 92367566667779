import { PARAM_ITEMS_PER_PAGE } from '../../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL } from '../../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../../request';
import { ProcedureService } from './procedure';
import { SERVICE_EHEALTH_SIGN } from '../../signature';
import { SERVICE_EHEALTH_AUTH } from "../../../auth";
export var SERVICE_EHEALTH_PROCEDURE = 'SERVICE_EHEALTH_PROCEDURE';
export var ehealthProcedureFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var signService = container.require(SERVICE_EHEALTH_SIGN);
    var authService = container.require(SERVICE_EHEALTH_AUTH);
    return new ProcedureService({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        signService: signService,
        authService: authService
    });
};
