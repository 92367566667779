import { combineReducers } from 'redux';
import { clinicInfoReducer } from './clinicInfo';
import { clinicScheduleReducer } from './schedule';
import { expertsScheduleReducer } from './experts-schedule';
import { expertsListReducer } from './experts-list';
import { clinicNewsReducer } from './news';
import { clinicReviewsReducer } from './clinic-reviews';
import { clinicBookingsReducer } from './clinic-bookings';
import { clinicGalleryReducer } from './gallery';
export var clinicReducer = combineReducers({
    clinicInfo: clinicInfoReducer,
    clinicSchedule: clinicScheduleReducer,
    expertsSchedule: expertsScheduleReducer,
    expertsList: expertsListReducer,
    clinicNews: clinicNewsReducer,
    clinicReviews: clinicReviewsReducer,
    clinicBookings: clinicBookingsReducer,
    clinicGallery: clinicGalleryReducer,
});
