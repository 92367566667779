var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { readFileAsArrayBuffer, readFileAsBinaryString } from '../../../../../shared/util/file';
import { SignError } from '../../../../../shared/error/sign-error';
var Signature = /** @class */ (function () {
    function Signature(params) {
        this.casListUrl = params.casListUrl;
        this.certsUrl = params.certsUrl;
        this.checkCertsUrl = params.checkCertsUrl;
        this.servers = [];
        this.currentSignConfig = {};
        this.inited = false;
        // @ts-ignore
        this.euSign = EUSignCP();
    }
    Signature.prototype.setFsSettings = function () {
        var e_1, _a, e_2, _b;
        this.euSign.SetXMLHTTPProxyService(this.checkCertsUrl);
        var settings = this.euSign.CreateFileStoreSettings();
        settings.SetPath('/certificates');
        settings.SetSaveLoadedCerts(true);
        this.euSign.SetFileStoreSettings(settings);
        settings = this.euSign.CreateProxySettings();
        this.euSign.SetProxySettings(settings);
        settings = this.euSign.CreateTSPSettings();
        this.euSign.SetTSPSettings(settings);
        settings = this.euSign.CreateOCSPSettings();
        this.euSign.SetOCSPSettings(settings);
        settings = this.euSign.CreateCMPSettings();
        this.euSign.SetCMPSettings(settings);
        settings = this.euSign.CreateLDAPSettings();
        this.euSign.SetLDAPSettings(settings);
        settings = this.euSign.CreateOCSPAccessInfoModeSettings();
        settings.SetEnabled(true);
        this.euSign.SetOCSPAccessInfoModeSettings(settings);
        var CAs = this.servers;
        settings = this.euSign.CreateOCSPAccessInfoSettings();
        try {
            for (var CAs_1 = __values(CAs), CAs_1_1 = CAs_1.next(); !CAs_1_1.done; CAs_1_1 = CAs_1.next()) {
                var ca = CAs_1_1.value;
                settings.SetAddress(ca.ocspAccessPointAddress);
                settings.SetPort(ca.ocspAccessPointPort);
                try {
                    for (var _c = (e_2 = void 0, __values(ca.issuerCNs)), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var cn = _d.value;
                        settings.SetIssuerCN(cn);
                        this.euSign.SetOCSPAccessInfoSettings(settings);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (CAs_1_1 && !CAs_1_1.done && (_a = CAs_1.return)) _a.call(CAs_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    Signature.prototype.setCASettins = function (selectedIndex) {
        var caServer = this.servers[selectedIndex];
        var offline = !caServer || caServer.address == '';
        var useCMP = !offline && caServer.cmpAddress != '';
        var loadPKCertsFromFile = !caServer || (!useCMP && !caServer.certsInKey);
        this.currentSignConfig.CAServer = caServer;
        this.currentSignConfig.offline = offline;
        this.currentSignConfig.useCMP = useCMP;
        this.currentSignConfig.loadPKCertsFromFile = loadPKCertsFromFile;
        var settings = this.euSign.CreateTSPSettings();
        if (!offline) {
            settings.SetGetStamps(true);
            if (caServer.tspAddress != '') {
                settings.SetAddress(caServer.tspAddress);
                settings.SetPort(caServer.tspAddressPort);
            }
            else {
                settings.SetAddress('acskidd.gov.ua');
                settings.SetPort('80');
            }
        }
        this.euSign.SetTSPSettings(settings);
        settings = this.euSign.CreateOCSPSettings();
        if (!offline) {
            settings.SetUseOCSP(true);
            settings.SetBeforeStore(true);
            settings.SetAddress(caServer.ocspAccessPointAddress);
            settings.SetPort('80');
        }
        this.euSign.SetOCSPSettings(settings);
        settings = this.euSign.CreateCMPSettings();
        settings.SetUseCMP(useCMP);
        if (useCMP) {
            settings.SetAddress(caServer.cmpAddress);
            settings.SetPort('80');
        }
        this.euSign.SetCMPSettings(settings);
        settings = this.euSign.CreateLDAPSettings();
        this.euSign.SetLDAPSettings(settings);
    };
    Signature.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var certsResp, certs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.inited) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, fetch(this.certsUrl)];
                    case 1:
                        certsResp = _a.sent();
                        return [4 /*yield*/, certsResp.arrayBuffer()];
                    case 2:
                        certs = _a.sent();
                        this.euSign.Initialize();
                        this.euSign.SetJavaStringCompliant(true);
                        this.euSign.SetErrorMessageLanguage(EU_UA_LANG);
                        this.euSign.SetCharset('UTF-8');
                        this.euSign.SetRuntimeParameter(EU_MAKE_PKEY_PFX_CONTAINER_PARAMETER, true);
                        this.setFsSettings();
                        this.euSign.SaveCertificates(new Uint8Array(certs));
                        this.setCASettins(0);
                        this.euSign.SetRuntimeParameter(EU_SIGN_TYPE_PARAMETER, EU_SIGN_TYPE_CADES_X_LONG);
                        this.euSign.SetRuntimeParameter(EU_SIGN_INCLUDE_CA_CERTIFICATES_PARAMETER, true);
                        this.euSign.SetRuntimeParameter(EU_SIGN_INCLUDE_CONTENT_TIME_STAMP_PARAMETER, true);
                        this.inited = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    Signature.prototype.sign = function (data, keyFile, password) {
        return __awaiter(this, void 0, void 0, function () {
            var encodedFile, _a, cmpAddress, keyInfo, certificates, certificates_1, certificates_1_1, cert, rawData, signedData, signedBlob, signedBinaryString, e_3;
            var e_4, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = Uint8Array.bind;
                        return [4 /*yield*/, readFileAsArrayBuffer(keyFile)];
                    case 1:
                        encodedFile = new (_a.apply(Uint8Array, [void 0, _c.sent()]))();
                        _c.label = 2;
                    case 2:
                        _c.trys.push([2, 7, , 8]);
                        if (!this.currentSignConfig.useCMP) return [3 /*break*/, 5];
                        cmpAddress = this.currentSignConfig.CAServer.cmpAddress + ':80';
                        keyInfo = this.euSign.GetKeyInfoBinary(encodedFile, password);
                        certificates = this.euSign.GetCertificatesByKeyInfo(keyInfo, [cmpAddress]);
                        if (Array.isArray(certificates)) {
                            try {
                                for (certificates_1 = __values(certificates), certificates_1_1 = certificates_1.next(); !certificates_1_1.done; certificates_1_1 = certificates_1.next()) {
                                    cert = certificates_1_1.value;
                                    this.euSign.SaveCertificate(cert);
                                }
                            }
                            catch (e_4_1) { e_4 = { error: e_4_1 }; }
                            finally {
                                try {
                                    if (certificates_1_1 && !certificates_1_1.done && (_b = certificates_1.return)) _b.call(certificates_1);
                                }
                                finally { if (e_4) throw e_4.error; }
                            }
                        }
                        else {
                            this.euSign.SaveCertificates(certificates);
                        }
                        this.euSign.ReadPrivateKeyBinary(encodedFile, password);
                        return [4 /*yield*/, readFileAsArrayBuffer(new Blob([data]))];
                    case 3:
                        rawData = _c.sent();
                        signedData = this.euSign.SignDataInternal(true, new Uint8Array(rawData), false);
                        signedBlob = new Blob([signedData], { type: 'application/octet-stream' });
                        return [4 /*yield*/, readFileAsBinaryString(signedBlob)];
                    case 4:
                        signedBinaryString = _c.sent();
                        return [2 /*return*/, btoa(signedBinaryString)];
                    case 5:
                        if (this.currentSignConfig.loadPKCertsFromFile) {
                            console.log('USE LOAD FROM FILE');
                            return [2 /*return*/, null];
                        }
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        e_3 = _c.sent();
                        throw new SignError(e_3);
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    Signature.prototype.signPisDeclarationRequest = function (data, keyFile, password) {
        return __awaiter(this, void 0, void 0, function () {
            var encodedFile, _a, cmpAddress, keyInfo, certificates, certificates_2, certificates_2_1, cert, signedData, signedBlob, signedBinaryString, e_5;
            var e_6, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = Uint8Array.bind;
                        return [4 /*yield*/, readFileAsArrayBuffer(keyFile)];
                    case 1:
                        encodedFile = new (_a.apply(Uint8Array, [void 0, _c.sent()]))();
                        _c.label = 2;
                    case 2:
                        _c.trys.push([2, 6, , 7]);
                        if (!this.currentSignConfig.useCMP) return [3 /*break*/, 4];
                        cmpAddress = this.currentSignConfig.CAServer.cmpAddress + ':80';
                        keyInfo = this.euSign.GetKeyInfoBinary(encodedFile, password);
                        certificates = this.euSign.GetCertificatesByKeyInfo(keyInfo, [cmpAddress]);
                        if (Array.isArray(certificates)) {
                            try {
                                for (certificates_2 = __values(certificates), certificates_2_1 = certificates_2.next(); !certificates_2_1.done; certificates_2_1 = certificates_2.next()) {
                                    cert = certificates_2_1.value;
                                    this.euSign.SaveCertificate(cert);
                                }
                            }
                            catch (e_6_1) { e_6 = { error: e_6_1 }; }
                            finally {
                                try {
                                    if (certificates_2_1 && !certificates_2_1.done && (_b = certificates_2.return)) _b.call(certificates_2);
                                }
                                finally { if (e_6) throw e_6.error; }
                            }
                        }
                        else {
                            this.euSign.SaveCertificates(certificates);
                        }
                        this.euSign.ReadPrivateKeyBinary(encodedFile, password);
                        signedData = this.euSign.SignDataInternal(true, new Uint8Array(data), false);
                        signedBlob = new Blob([signedData], { type: 'application/octet-stream' });
                        return [4 /*yield*/, readFileAsBinaryString(signedBlob)];
                    case 3:
                        signedBinaryString = _c.sent();
                        return [2 /*return*/, btoa(signedBinaryString)];
                    case 4:
                        if (this.currentSignConfig.loadPKCertsFromFile) {
                            console.log('USE LOAD FROM FILE');
                            return [2 /*return*/, null];
                        }
                        _c.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_5 = _c.sent();
                        throw new SignError(e_5);
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    Signature.prototype.getCasList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var resp, _a, e_7;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 5]);
                        if (!!this.servers.length) return [3 /*break*/, 3];
                        return [4 /*yield*/, fetch("".concat(this.casListUrl))];
                    case 1:
                        resp = _b.sent();
                        _a = this;
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        _a.servers = _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/, this.servers];
                    case 4:
                        e_7 = _b.sent();
                        return [2 /*return*/, []];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return Signature;
}());
export { Signature };
