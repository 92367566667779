var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { clearObject } from '../../../../../../../assets/js/utils/etc';
import { ResponseError } from '../../../../../shared/error/response-error';
import { EmployeeRequestStatus } from '../../../screen/Employee/RequestList/status.enum';
import { formatEducation } from '../../../util/formatter';
import { EmployeeType } from './enum/employee.type';
import { EMPLOYEE_TYPE_TO_EDUCATION_DEGREE } from './res/employee-type-to-education-degree';
import { EMPLOYEE_TYPE_TO_POSITION } from './res/employee-type-to-position';
import { EMPLOYEE_TYPE_TO_QUALIFICATION_TYPE } from './res/employee-type-to-qualification-type';
import { EMPLOYEE_TYPE_TO_SPECIALITY_LEVEL } from './res/employee-type-to-speciality-level';
import { EMPLOYEE_TYPE_TO_SPECIALITY_TYPE } from './res/employee-type-to-speciality-type';
var EmployeeRequestService = /** @class */ (function () {
    function EmployeeRequestService(params) {
        this.baseUrl = params.baseUrl;
        this.itemsPerPage = params.itemsPerPage;
        this.requestService = params.requestService;
        this.registerMoiUserUrl = params.registerMoiUserUrl;
        this.signService = params.signService;
    }
    EmployeeRequestService.prototype.getSingle = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/employee_requests/").concat(id))];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    EmployeeRequestService.prototype.prepareData = function (data) {
        var clearedData = (function (_a) {
            var id = _a.id, division_id = _a.division_id, legal_entity_id = _a.legal_entity_id, position = _a.position, start_date = _a.start_date, end_date = _a.end_date, status = _a.status, employee_type = _a.employee_type, _b = _a.party, first_name = _b.first_name, last_name = _b.last_name, second_name = _b.second_name, birth_date = _b.birth_date, gender = _b.gender, no_tax_id = _b.no_tax_id, tax_id = _b.tax_id, email = _b.email, documents = _b.documents, phones = _b.phones, working_experience = _b.working_experience, about_myself = _b.about_myself, doctor = _a.doctor;
            return ({
                employee_id: id,
                division_id: division_id,
                legal_entity_id: legal_entity_id,
                position: position,
                start_date: start_date,
                end_date: end_date,
                status: status,
                employee_type: employee_type,
                party: {
                    first_name: first_name,
                    last_name: last_name,
                    second_name: second_name,
                    birth_date: birth_date,
                    gender: gender,
                    no_tax_id: no_tax_id,
                    tax_id: tax_id,
                    email: email,
                    documents: documents,
                    phones: phones,
                    working_experience: working_experience,
                    about_myself: about_myself,
                },
                doctor: doctor,
            });
        })(data);
        if (!!data.party.working_experience) {
            clearedData.party.working_experience = Number(data.party.working_experience);
        }
        clearedData.status = EmployeeRequestStatus.NEW;
        return clearedData;
    };
    EmployeeRequestService.prototype.filterPositionsByEmployeeType = function (type, positions) {
        // @ts-ignore
        if (!EMPLOYEE_TYPE_TO_POSITION[type]) {
            return Object.values(positions);
        }
        return positions.filter(function (position) {
            // @ts-ignore
            return !!EMPLOYEE_TYPE_TO_POSITION[type][position.value];
        });
    };
    EmployeeRequestService.prototype.filterSpecialitiesByEmployeeType = function (type, specialities) {
        // @ts-ignore
        if (!EMPLOYEE_TYPE_TO_SPECIALITY_TYPE[type]) {
            return Object.values(specialities);
        }
        return specialities.filter(function (speciality) {
            // @ts-ignore
            return !!EMPLOYEE_TYPE_TO_SPECIALITY_TYPE[type][speciality.value];
        });
    };
    EmployeeRequestService.prototype.filterSpecialitiesLevelByEmployeeType = function (type, levels) {
        // @ts-ignore
        if (!EMPLOYEE_TYPE_TO_SPECIALITY_LEVEL[type]) {
            return Object.values(levels);
        }
        return levels.filter(function (level) {
            // @ts-ignore
            return !!EMPLOYEE_TYPE_TO_SPECIALITY_LEVEL[type][level.value];
        });
    };
    EmployeeRequestService.prototype.filterEducationDegreeByEmployeeType = function (type, degrees) {
        // @ts-ignore
        if (!EMPLOYEE_TYPE_TO_EDUCATION_DEGREE[type]) {
            return Object.values(degrees);
        }
        return degrees.filter(function (degree) {
            // @ts-ignore
            return !!EMPLOYEE_TYPE_TO_EDUCATION_DEGREE[type][degree.value];
        });
    };
    EmployeeRequestService.prototype.filterQualificationTypeByEmployeeType = function (type, qualifications) {
        // @ts-ignore
        if (!EMPLOYEE_TYPE_TO_QUALIFICATION_TYPE[type]) {
            return Object.values(qualifications);
        }
        return qualifications.filter(function (qualification) {
            // @ts-ignore
            return !!EMPLOYEE_TYPE_TO_QUALIFICATION_TYPE[type][qualification.value];
        });
    };
    EmployeeRequestService.prototype.registerMonihealExpert = function (employeeRequest, educations, employeeId) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var jsonData, moiResp, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        jsonData = {};
                        jsonData['email'] = employeeRequest.party.email;
                        if (employeeId) {
                            jsonData['employeeId'] = employeeId;
                        }
                        else {
                            jsonData['employeeRequestId'] = employeeRequest.id;
                        }
                        jsonData['type'] = employeeRequest.employee_type;
                        jsonData['firstName'] = employeeRequest.party.first_name;
                        jsonData['lastName'] = employeeRequest.party.last_name;
                        jsonData['secondName'] = employeeRequest.party.second_name || '';
                        jsonData['birthDate'] = employeeRequest.party.birth_date;
                        jsonData['gender'] = employeeRequest.party.gender;
                        jsonData['phone'] = ((_b = (_a = employeeRequest.party.phones) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.number) || '';
                        jsonData['education'] = (educations === null || educations === void 0 ? void 0 : educations.length) ? educations.map(formatEducation).join(', ') : '';
                        jsonData['aboutUs'] = employeeRequest.party.about_myself || '';
                        return [4 /*yield*/, fetch("".concat(this.registerMoiUserUrl), {
                                method: 'POST',
                                credentials: 'include',
                                body: JSON.stringify(jsonData),
                                headers: {
                                    'Content-Type': 'application/json; charset=utf-8',
                                },
                            })];
                    case 1:
                        moiResp = _f.sent();
                        if (!!moiResp.ok) return [3 /*break*/, 3];
                        _d = (_c = console).log;
                        _e = [moiResp.status];
                        return [4 /*yield*/, moiResp.json()];
                    case 2:
                        _d.apply(_c, _e.concat([_f.sent()]));
                        _f.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    EmployeeRequestService.prototype.create = function (data, signParams) {
        return __awaiter(this, void 0, void 0, function () {
            var preparedData, signedData, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        preparedData = clearObject(this.prepareData(__assign({}, data)));
                        if (![
                            EmployeeType.DOCTOR,
                            EmployeeType.PHARMACIST,
                            EmployeeType.SPECIALIST,
                            EmployeeType.ASSISTANT,
                            EmployeeType.MED_ADMIN,
                            EmployeeType.LABORANT,
                        ].includes(preparedData.employee_type)) {
                            delete preparedData.doctor;
                        }
                        if (preparedData.doctor) {
                            delete preparedData.doctor.has_science_degree;
                        }
                        switch (preparedData.employee_type) {
                            case EmployeeType.PHARMACIST:
                                preparedData.pharmacist = preparedData.doctor;
                                delete preparedData.doctor;
                                break;
                            case EmployeeType.SPECIALIST:
                                preparedData.specialist = preparedData.doctor;
                                delete preparedData.doctor;
                                break;
                            case EmployeeType.ASSISTANT:
                                preparedData.assistant = preparedData.doctor;
                                delete preparedData.doctor;
                                break;
                            case EmployeeType.MED_ADMIN:
                                preparedData.med_admin = preparedData.doctor;
                                delete preparedData.doctor;
                                break;
                            case EmployeeType.LABORANT:
                                preparedData.laborant = preparedData.doctor;
                                delete preparedData.doctor;
                                break;
                        }
                        return [4 /*yield*/, this.signService.sign(JSON.stringify({ employee_request: preparedData }), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/v2/employee_requests"), undefined, JSON.stringify({
                                signed_content: signedData,
                                signed_content_encoding: 'base64',
                            }))];
                    case 4:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 5:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        this.registerMonihealExpert(json.data, json.data.educations);
                        return [2 /*return*/, json];
                }
            });
        });
    };
    EmployeeRequestService.prototype.getList = function (page) {
        if (page === void 0) { page = 1; }
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/employee_requests"), {
                            page: "".concat(page),
                            page_size: "".concat(this.itemsPerPage),
                        })];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    EmployeeRequestService.prototype.cancelRequest = function () {
        this.requestService.cancel();
    };
    return EmployeeRequestService;
}());
export { EmployeeRequestService };
