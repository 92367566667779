var selectedServiceState = {
    serviceId: null,
    clientId: null,
    description: null,
    date: null,
    startTime: null,
    type: 'offline',
    isNotifyClient: false,
    notifyClientType: 'email',
    clientEmail: null,
    clientPhone: null,
};
export var initialState = {
    services: [],
    selectedService: selectedServiceState,
    isServiceAdded: false,
    loading: false,
    error: null,
};
