import { PARAM_MOI_COMPANY_CLIENT_LIST_URL, PARAM_MOI_COMPANY_DELETE_CLIENT_URL, PARAM_MOI_COMPANY_REGISTER_CLIENT_URL, } from '../../../config/di/params/keys';
import { CompanyClients } from './company-clients';
import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
export var SERVICE_MOI_COMPANY_CLIENTS = 'SERVICE_MOI_COMPANY_CLIENTS';
export function companyClientsFactory(container) {
    var listUrl = "".concat(container.get(PARAM_MOI_COMPANY_CLIENT_LIST_URL));
    var clientRegisterUrl = "".concat(container.get(PARAM_MOI_COMPANY_REGISTER_CLIENT_URL));
    var deleteClientUrl = "".concat(container.get(PARAM_MOI_COMPANY_DELETE_CLIENT_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    return new CompanyClients({
        urls: {
            listUrl: listUrl,
            clientRegisterUrl: clientRegisterUrl,
            deleteClientUrl: deleteClientUrl,
        },
        itemsPerPage: itemsPerPage,
    });
}
