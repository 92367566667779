export var RecordsAction;
(function (RecordsAction) {
    RecordsAction["INIT"] = "RECORDS_ALL_INIT";
    RecordsAction["GET_ITEM"] = "RECORD_ONE_ITEM";
    RecordsAction["ADD_ITEM"] = "RECORD_ADD_ITEM";
    RecordsAction["REMOVE_ITEM"] = "RECORD_REMOVE_ITEM";
    RecordsAction["SET_FILTER"] = "SET_FILTER";
    RecordsAction["SET_IS_LOADING_GET_RECORDS_ALL"] = "SET_IS_LOADING_GET_RECORDS_ALL";
    RecordsAction["SET_IS_LOADING_GET_RECORD_ONE"] = "SET_IS_LOADING_GET_RECORD_ONE";
    RecordsAction["SET_IS_LOADING_CREATE_RECORD"] = "SET_IS_LOADING_CREATE_RECORD";
    RecordsAction["SET_IS_LOADING_REMOVE_RECORD"] = "SET_IS_LOADING_REMOVE_RECORD";
})(RecordsAction || (RecordsAction = {}));
