import { PARAM_MOI_COMPANY_BOOKINGS_URL, PARAM_MOI_COMPANY_DATA_URL, PARAM_MOI_COMPANY_REGISTER_URL, PARAM_MOI_COMPANY_SAVE_DATA_URL, PARAM_MOI_COMPANY_SCHEDULES_URL, PARAM_MOI_COMPANY_SIMPLE_EXPERTS_ALL_URL, } from '../../../config/di/params/keys';
import { Company } from './company';
import { SERVICE_EHEALTH_SIGN } from '../../../../ehealth/service/ehealth/signature';
export var SERVICE_MOI_COMPANY = 'SERVICE_MOI_COMPANY';
export function companyFactory(container) {
    var signService = container.require(SERVICE_EHEALTH_SIGN);
    var getSimpleExpertsUrl = "".concat(container.get(PARAM_MOI_COMPANY_SIMPLE_EXPERTS_ALL_URL));
    var getBookingsUrl = "".concat(container.get(PARAM_MOI_COMPANY_BOOKINGS_URL));
    var getSchedulesUrl = "".concat(container.get(PARAM_MOI_COMPANY_SCHEDULES_URL));
    var getCompanyDataUrl = "".concat(container.get(PARAM_MOI_COMPANY_DATA_URL));
    var saveCompanyDataUrl = "".concat(container.get(PARAM_MOI_COMPANY_SAVE_DATA_URL));
    var registerCompanyUrl = "".concat(container.get(PARAM_MOI_COMPANY_REGISTER_URL));
    return new Company({
        signService: signService,
        getSimpleExpertsUrl: getSimpleExpertsUrl,
        getSchedulesUrl: getSchedulesUrl,
        getBookingsUrl: getBookingsUrl,
        getCompanyDataUrl: getCompanyDataUrl,
        saveCompanyDataUrl: saveCompanyDataUrl,
        registerCompanyUrl: registerCompanyUrl,
    });
}
