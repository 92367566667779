export var ExpertScheduleInitialState = {
    id: 0,
    day: 0,
    start: null,
    end: null,
    lunchStart: null,
    lunchEnd: null,
    date: null,
};
export var initialState = {
    expertSchedule: [ExpertScheduleInitialState],
    isLoading: false,
    error: null,
};
