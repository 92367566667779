var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { ResponseError } from '../../../../../../shared/error/response-error';
import { clearObject } from '../../../../../../../../assets/js/utils/etc';
import { EncounterClass } from '../encounter/enum/encounter-class';
import { numberDataType, prepareValueFields } from './util';
import { EncounterStatus } from '../encounter/enum/encounter-status';
import { Dictionary } from '../../dictionary/dictionary.enum';
import { EhealthResource } from '../enum/ehealth-resource';
import { ProcedureStatus } from '../procedure/enum/procedure-status';
var EncounterPackageService = /** @class */ (function () {
    function EncounterPackageService(params) {
        this.baseUrl = params.baseUrl;
        this.itemsPerPage = params.itemsPerPage;
        this.requestService = params.requestService;
        this.signService = params.signService;
        this.getSingleStoredUrl = params.getSingleStoredUrl;
        this.encounterService = params.encounterService;
        this.serviceRequestService = params.serviceRequestService;
        this.procedureService = params.procedureService;
    }
    EncounterPackageService.prototype.prepareData = function (data) {
        var e_1, _a, e_2, _b, e_3, _c, e_4, _d, e_5, _e, e_6, _f, e_7, _g, e_8, _h;
        var _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6;
        var clearedData = clearObject(data);
        // @ts-ignore
        if (!((_k = (_j = data.encounter) === null || _j === void 0 ? void 0 : _j.paper_referral) === null || _k === void 0 ? void 0 : _k.enabled)) {
            (_l = data.encounter) === null || _l === void 0 ? true : delete _l.paper_referral;
        }
        else {
            // @ts-ignore
            (_o = (_m = data.encounter) === null || _m === void 0 ? void 0 : _m.paper_referral) === null || _o === void 0 ? true : delete _o.enabled;
            (_p = data.encounter) === null || _p === void 0 ? true : delete _p.incoming_referral;
        }
        if (((_r = (_q = data.encounter) === null || _q === void 0 ? void 0 : _q.class) === null || _r === void 0 ? void 0 : _r.code) === EncounterClass.PHC) {
            (_s = data.encounter) === null || _s === void 0 ? true : delete _s.hospitalization;
            (_t = data.encounter) === null || _t === void 0 ? true : delete _t.action_references;
        }
        try {
            for (var _7 = __values(data.observations || []), _8 = _7.next(); !_8.done; _8 = _7.next()) {
                var obs = _8.value;
                prepareValueFields(obs);
                numberDataType(obs);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_8 && !_8.done && (_a = _7.return)) _a.call(_7);
            }
            finally { if (e_1) throw e_1.error; }
        }
        try {
            for (var _9 = __values(data.diagnostic_reports || []), _10 = _9.next(); !_10.done; _10 = _9.next()) {
                var diag = _10.value;
                if (!!((_u = diag.paper_referral) === null || _u === void 0 ? void 0 : _u.enabled)) {
                    delete diag.paper_referral.enabled;
                    delete diag.based_on;
                }
                else {
                    delete diag.paper_referral;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_10 && !_10.done && (_b = _9.return)) _b.call(_9);
            }
            finally { if (e_2) throw e_2.error; }
        }
        try {
            for (var _11 = __values(data.procedures || []), _12 = _11.next(); !_12.done; _12 = _11.next()) {
                var proc = _12.value;
                if (!!((_v = proc.paper_referral) === null || _v === void 0 ? void 0 : _v.enabled)) {
                    delete proc.paper_referral.enabled;
                    delete proc.based_on;
                }
                else {
                    delete proc.paper_referral;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_12 && !_12.done && (_c = _11.return)) _c.call(_11);
            }
            finally { if (e_3) throw e_3.error; }
        }
        try {
            for (var _13 = __values(data.procedures || []), _14 = _13.next(); !_14.done; _14 = _13.next()) {
                var proc = _14.value;
                if (proc.status === ProcedureStatus.NOT_DONE) {
                    // @ts-ignore
                    delete proc.performed_date_time;
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_14 && !_14.done && (_d = _13.return)) _d.call(_13);
            }
            finally { if (e_4) throw e_4.error; }
        }
        try {
            for (var _15 = __values(data.immunizations || []), _16 = _15.next(); !_16.done; _16 = _15.next()) {
                var imm = _16.value;
                if (imm.not_given) {
                    imm.primary_source = true;
                    // @ts-ignore
                    delete imm.explanation.reasons;
                    // @ts-ignore
                    delete imm.manufacturer;
                    // @ts-ignore
                    delete imm.lot_number;
                    // @ts-ignore
                    delete imm.expiration_date;
                    // @ts-ignore
                    delete imm.site;
                    // @ts-ignore
                    delete imm.route;
                    // @ts-ignore
                    delete imm.dose_quantity;
                }
                else {
                    // @ts-ignore
                    delete imm.explanation.reasons_not_given;
                    // @ts-ignore
                    (_w = imm === null || imm === void 0 ? void 0 : imm.dose_quantity) === null || _w === void 0 ? void 0 : _w.value = +(((_x = imm === null || imm === void 0 ? void 0 : imm.dose_quantity) === null || _x === void 0 ? void 0 : _x.value) || 0);
                }
                // @ts-ignore
                delete imm.vaccinationType;
                // @ts-ignore
                (_z = (_y = imm === null || imm === void 0 ? void 0 : imm.vaccination_protocols) === null || _y === void 0 ? void 0 : _y[0]) === null || _z === void 0 ? void 0 : _z.dose_sequence = +(((_1 = (_0 = imm === null || imm === void 0 ? void 0 : imm.vaccination_protocols) === null || _0 === void 0 ? void 0 : _0[0]) === null || _1 === void 0 ? void 0 : _1.dose_sequence) || 0);
                // @ts-ignore
                (_3 = (_2 = imm === null || imm === void 0 ? void 0 : imm.vaccination_protocols) === null || _2 === void 0 ? void 0 : _2[0]) === null || _3 === void 0 ? void 0 : _3.series_doses = +(((_5 = (_4 = imm === null || imm === void 0 ? void 0 : imm.vaccination_protocols) === null || _4 === void 0 ? void 0 : _4[0]) === null || _5 === void 0 ? void 0 : _5.series_doses) || 0);
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (_16 && !_16.done && (_e = _15.return)) _e.call(_15);
            }
            finally { if (e_5) throw e_5.error; }
        }
        try {
            for (var _17 = __values(((_6 = clearedData.encounter) === null || _6 === void 0 ? void 0 : _6.diagnoses) || []), _18 = _17.next(); !_18.done; _18 = _17.next()) {
                var diagnose = _18.value;
                diagnose.rank = +(diagnose.rank || 0);
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (_18 && !_18.done && (_f = _17.return)) _f.call(_17);
            }
            finally { if (e_6) throw e_6.error; }
        }
        var clearParts = ['conditions', 'observations', 'immunizations', 'procedures', 'diagnostic_reports'];
        try {
            for (var clearParts_1 = __values(clearParts), clearParts_1_1 = clearParts_1.next(); !clearParts_1_1.done; clearParts_1_1 = clearParts_1.next()) {
                var key = clearParts_1_1.value;
                try {
                    // @ts-ignore
                    for (var _19 = (e_8 = void 0, __values(data[key] || [])), _20 = _19.next(); !_20.done; _20 = _19.next()) {
                        var item = _20.value;
                        if (item.primary_source) {
                            delete item.report_origin;
                        }
                        else {
                            item === null || item === void 0 ? true : delete item.asserter;
                            item === null || item === void 0 ? true : delete item.performer;
                        }
                    }
                }
                catch (e_8_1) { e_8 = { error: e_8_1 }; }
                finally {
                    try {
                        if (_20 && !_20.done && (_h = _19.return)) _h.call(_19);
                    }
                    finally { if (e_8) throw e_8.error; }
                }
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (clearParts_1_1 && !clearParts_1_1.done && (_g = clearParts_1.return)) _g.call(clearParts_1);
            }
            finally { if (e_7) throw e_7.error; }
        }
        return clearedData;
    };
    EncounterPackageService.prototype.prepareStatusData = function (patientId, data) {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var encounterPackageParts, encounterPackageParts_1, encounterPackageParts_1_1, key, _f, _g, item, procedure, e_9_1, e_10_1;
            var e_10, _h, e_9, _j;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        data.encounter.status = EncounterStatus.ENTERED_IN_ERROR;
                        encounterPackageParts = ['conditions', 'observations', 'immunizations', 'procedures', 'diagnostic_reports'];
                        _k.label = 1;
                    case 1:
                        _k.trys.push([1, 13, 14, 15]);
                        encounterPackageParts_1 = __values(encounterPackageParts), encounterPackageParts_1_1 = encounterPackageParts_1.next();
                        _k.label = 2;
                    case 2:
                        if (!!encounterPackageParts_1_1.done) return [3 /*break*/, 12];
                        key = encounterPackageParts_1_1.value;
                        _k.label = 3;
                    case 3:
                        _k.trys.push([3, 9, 10, 11]);
                        _f = (e_9 = void 0, __values(data[key] || [])), _g = _f.next();
                        _k.label = 4;
                    case 4:
                        if (!!_g.done) return [3 /*break*/, 8];
                        item = _g.value;
                        if (!(key === 'procedures')) return [3 /*break*/, 6];
                        // @ts-ignore
                        (_c = (_b = (_a = item === null || item === void 0 ? void 0 : item.status_reason) === null || _a === void 0 ? void 0 : _a.coding) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.code = 'typo';
                        // @ts-ignore
                        item === null || item === void 0 ? void 0 : item.status = EncounterStatus.ENTERED_IN_ERROR;
                        return [4 /*yield*/, this.procedureService.getSingle(patientId, item.id)];
                    case 5:
                        procedure = _k.sent();
                        item.origin_episode = {
                            identifier: {
                                type: {
                                    coding: [
                                        {
                                            system: Dictionary.EHEALTH_RESOURCES,
                                            code: EhealthResource.episode,
                                        },
                                    ],
                                },
                                value: ((_e = (_d = procedure === null || procedure === void 0 ? void 0 : procedure.origin_episode) === null || _d === void 0 ? void 0 : _d.identifier) === null || _e === void 0 ? void 0 : _e.value) || '',
                            },
                        };
                        return [3 /*break*/, 7];
                    case 6:
                        if (key === 'conditions') {
                            // @ts-ignore
                            item === null || item === void 0 ? void 0 : item.verification_status = EncounterStatus.ENTERED_IN_ERROR;
                            item === null || item === void 0 ? true : delete item.status;
                        }
                        else {
                            // @ts-ignore
                            item === null || item === void 0 ? void 0 : item.status = EncounterStatus.ENTERED_IN_ERROR;
                        }
                        _k.label = 7;
                    case 7:
                        _g = _f.next();
                        return [3 /*break*/, 4];
                    case 8: return [3 /*break*/, 11];
                    case 9:
                        e_9_1 = _k.sent();
                        e_9 = { error: e_9_1 };
                        return [3 /*break*/, 11];
                    case 10:
                        try {
                            if (_g && !_g.done && (_j = _f.return)) _j.call(_f);
                        }
                        finally { if (e_9) throw e_9.error; }
                        return [7 /*endfinally*/];
                    case 11:
                        encounterPackageParts_1_1 = encounterPackageParts_1.next();
                        return [3 /*break*/, 2];
                    case 12: return [3 /*break*/, 15];
                    case 13:
                        e_10_1 = _k.sent();
                        e_10 = { error: e_10_1 };
                        return [3 /*break*/, 15];
                    case 14:
                        try {
                            if (encounterPackageParts_1_1 && !encounterPackageParts_1_1.done && (_h = encounterPackageParts_1.return)) _h.call(encounterPackageParts_1);
                        }
                        finally { if (e_10) throw e_10.error; }
                        return [7 /*endfinally*/];
                    case 15: return [2 /*return*/, data];
                }
            });
        });
    };
    EncounterPackageService.prototype.create = function (data, patientId, signParams) {
        return __awaiter(this, void 0, void 0, function () {
            var signedData, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _a.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        return [4 /*yield*/, this.signService.sign(JSON.stringify(this.prepareData(__assign({}, data.data))), 
                            // @ts-ignore
                            signParams.keyFile, signParams.password)];
                    case 3:
                        signedData = _a.sent();
                        return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/encounter_package"), undefined, JSON.stringify({
                                visit: data.visit,
                                signed_data: signedData,
                            }))];
                    case 4:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    EncounterPackageService.prototype.cancel = function (patientId, data, signParams) {
        return __awaiter(this, void 0, void 0, function () {
            var signedData, _a, _b, _c, _d, _e, _f, resp;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0: return [4 /*yield*/, this.signService.getCasList()];
                    case 1:
                        _g.sent();
                        return [4 /*yield*/, this.signService.init()];
                    case 2:
                        _g.sent();
                        this.signService.setCASettins(signParams.casIndex);
                        _b = (_a = this.signService).sign;
                        _d = (_c = JSON).stringify;
                        _e = this.prepareData;
                        _f = [{}];
                        return [4 /*yield*/, this.prepareStatusData(patientId, data.data)];
                    case 3: return [4 /*yield*/, _b.apply(_a, [_d.apply(_c, [_e.apply(this, [__assign.apply(void 0, _f.concat([(_g.sent())]))])]),
                            // @ts-ignore
                            signParams.keyFile,
                            signParams.password])];
                    case 4:
                        signedData = _g.sent();
                        return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/patients/").concat(patientId, "/encounter_package"), undefined, JSON.stringify({
                                signed_data: signedData,
                            }))];
                    case 5:
                        resp = _g.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    EncounterPackageService.prototype.getSingleStored = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var query, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = new URLSearchParams({
                            id: id,
                        });
                        return [4 /*yield*/, fetch("".concat(this.getSingleStoredUrl, "?").concat(query), {
                                credentials: 'include',
                            })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    return EncounterPackageService;
}());
export { EncounterPackageService };
