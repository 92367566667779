var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DoctorReferralsActionType } from './action';
import { initialState } from './state';
export var doctorReferralsReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DoctorReferralsActionType.SET_DATA_FALSE:
            return __assign(__assign({}, state), { isReferralAdded: false, addedReferral: null, loading: false, error: null });
        case DoctorReferralsActionType.GET_REFERRALS_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null });
        case DoctorReferralsActionType.GET_REFERRALS_SUCCESS:
            return __assign(__assign({}, state), { loading: false, referrals: action.payload.referrals, totalCount: action.payload.totalCount });
        case DoctorReferralsActionType.GET_REFERRALS_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload });
        case DoctorReferralsActionType.GET_ANALYSES_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null });
        case DoctorReferralsActionType.GET_ANALYSES_SUCCESS:
            return __assign(__assign({}, state), { loading: false, analyses: action.payload });
        case DoctorReferralsActionType.GET_ANALYSES_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload });
        case DoctorReferralsActionType.GET_CLIENTS_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null });
        case DoctorReferralsActionType.GET_CLIENTS_SUCCESS:
            return __assign(__assign({}, state), { loading: false, clients: action.payload });
        case DoctorReferralsActionType.GET_CLIENTS_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload });
        case DoctorReferralsActionType.ADD_REFERRAL_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null, isReferralAdded: false });
        case DoctorReferralsActionType.ADD_REFERRAL_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isReferralAdded: true, addedReferral: action.payload });
        case DoctorReferralsActionType.ADD_REFERRAL_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, isReferralAdded: false });
        default:
            return state;
    }
};
