var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RecordsAction } from './action';
import { defaultState } from './state';
export var recordsReducer = function (state, action) {
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case RecordsAction.INIT: {
            return __assign(__assign({}, state), { items: __assign({}, action.payload.items) });
        }
        case RecordsAction.GET_ITEM: {
            return __assign(__assign({}, state), { item: __assign({}, action.payload.item) });
        }
        case RecordsAction.ADD_ITEM:
            return __assign(__assign({}, state), { items: __assign(__assign({}, state.items), action.payload.item) });
        case RecordsAction.REMOVE_ITEM:
            var next = __assign({}, state);
            delete next.items[action.payload.id];
            return next;
        case RecordsAction.SET_FILTER: {
            return __assign(__assign({}, state), { items: __assign({}, action.payload.items) });
        }
        case RecordsAction.SET_IS_LOADING_GET_RECORDS_ALL: {
            return __assign(__assign({}, state), { isLoadingGetRecordsAll: action.payload.isLoading });
        }
        case RecordsAction.SET_IS_LOADING_GET_RECORD_ONE: {
            return __assign(__assign({}, state), { isLoadingGetRecordOne: action.payload.isLoading });
        }
        case RecordsAction.SET_IS_LOADING_CREATE_RECORD: {
            return __assign(__assign({}, state), { isLoadingCreateRecord: action.payload.isLoading });
        }
        case RecordsAction.SET_IS_LOADING_REMOVE_RECORD: {
            return __assign(__assign({}, state), { isLoadingRemoveRecord: action.payload.isLoading });
        }
    }
    return state;
};
