import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
import { PatientSummaryService } from './patient-summary';
export var SERVICE_PATIENT_SUMMARY = 'SERVICE_PATIENT_SUMMARY';
export var ehealthPatientSummaryFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    return new PatientSummaryService({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService
    });
};
