import React from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useStyles } from './Login.jss';
import { Box, Button, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPasswordRestore } from '../../../../store/password-restore/password-restore';
export default function PasswordRestoreForm() {
    var classes = useStyles();
    var dispatch = useDispatch();
    var passwordRestoreError = useSelector(function (state) { return state.moi.passwordRestore.error; });
    var initialValue = {
        phone: '',
    };
    var phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
    var requiredMessage = "\u0426\u0435 \u043F\u043E\u043B\u0435 \u043E\u0431\u043E\u0432'\u044F\u0437\u043A\u043E\u0432\u0435.";
    var phoneMessage = 'Невірний формат даних. Телефон повинен мати наступний формат: +380ХХХХХХХХХ';
    var RestoreSchema = Yup.object().shape({
        phone: Yup.string()
            .required(requiredMessage)
            .matches(phoneRegExp, phoneMessage)
            .min(13, phoneMessage)
            .max(13, phoneMessage),
    });
    var handleRestore = function (_a, props) {
        var phone = _a.phone;
        console.log('handle restore');
        dispatch(fetchPasswordRestore(phone));
        return;
    };
    return (React.createElement(Box, { className: classNames(classes.wrapper) },
        React.createElement(Box, { sx: { width: '100%' } },
            React.createElement(Typography, { className: classNames(classes.title) }, "\u0417\u043C\u0456\u043D\u0438\u0442\u0438 \u043F\u0430\u0440\u043E\u043B\u044C")),
        React.createElement(Typography, { className: classNames(classes.subtitle) }, "\u0412\u0432\u0435\u0434\u0456\u0442\u044C \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0443, \u043D\u0430 \u044F\u043A\u0438\u0439 \u0431\u0443\u0434\u0435 \u043D\u0430\u0434\u0456\u0441\u043B\u0430\u043D\u043E \u043A\u043E\u0434 \u0434\u043B\u044F \u0437\u043C\u0456\u043D\u0438 \u043F\u0430\u0440\u043E\u043B\u044F."),
        React.createElement(Formik, { onSubmit: handleRestore, initialValues: initialValue, validationSchema: RestoreSchema }, function (props) {
            var phone = props.values.phone;
            return (React.createElement(Form, null,
                React.createElement(Field, { as: TextField, placeholder: "+380 ( _ _ )  _ _ _  _ _  _ _", id: "phone", name: "phone", value: phone, inputProps: { maxLength: 13 }, className: classNames(classes.input), InputLabelProps: { shrink: false } }),
                props.errors.phone && (React.createElement(Typography, { className: classNames(classes.inputError) }, props.errors.phone)),
                passwordRestoreError && (React.createElement(Box, { className: classNames(classes.loginError) },
                    React.createElement(Typography, { className: classNames(classes.loginErrorTitle) }, passwordRestoreError))),
                React.createElement(Button, { type: "submit", className: classNames(classes.submitButton) }, "\u041D\u0430\u0434\u0456\u0441\u043B\u0430\u0442\u0438")));
        })));
}
