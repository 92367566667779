import { openDB } from 'idb';

export class IDBQueue {
  /**
   * @param {string} name
   * @param {number} version
   */
  constructor(name, version) {
    this.idb = null;
    this.name = name;
    this.version = version;
  }

  async init() {
    if (!this.idb) {
      this.idb = await openDB(this.name, this.version, {
        upgrade: this.upgradeDB.bind(this),
      });
    }

    return this.idb;
  }

  /**
   * @param {IDBDatabase} db
   */
  upgradeDB(db) {
    const store = db.createObjectStore(this.name, {
      keyPath: 'id',
      autoIncrement: true,
    });
    store.createIndex('event', 'event', { unique: false });
    store.createIndex('payload', 'payload', { unique: false });
    store.createIndex('status', 'status', { unique: false });
    store.createIndex('timestamp', 'timestamp', { unique: false });
  }

  async getNext() {
    const cursor = await this.idb.transaction(this.name).store.openCursor();

    return cursor ? cursor.value : null;
  }

  async put({event, payload}) {
    const tr = this.idb.transaction(this.name, 'readwrite');
    const store = tr.objectStore(this.name);

    await store.put({
      event,
      payload,
      status: 'pending',
      timestamp: Date.now(),
    });
    await tr.done;
  }

  /**
   * @param {number} id
   */
  async delete(id) {
    const tr = this.idb.transaction(this.name, 'readwrite');
    const store = tr.objectStore(this.name);

    await store.delete(id);
    await tr.done;
  }
}