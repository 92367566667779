var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { forwardRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, IconButton, styled, Typography } from '@mui/material';
export var SearchType = function (_a) {
    var text = _a.text;
    var classes = useStyles();
    var _b = __read(useState(false), 2), isSelected = _b[0], setIsSelected = _b[1];
    console.log('is', isSelected);
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: classes.wrapper, onClick: function () { return setIsSelected(true); } },
            isSelected ? React.createElement(ButtonDelete, { onClick: function () { return setIsSelected(false); } }) : React.createElement(React.Fragment, null),
            React.createElement(Typography, { className: classes.text }, text))));
};
var DeleteIcon = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("svg", { width: "10", height: "10", viewBox: "0 0 10 10", fill: "#90A4BE", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M1.73268 9.08333L0.916016 8.26666L4.18268 5L0.916016 1.73333L1.73268 0.916664L4.99935 4.18333L8.26602 0.916664L9.08268 1.73333L5.81602 5L9.08268 8.26666L8.26602 9.08333L4.99935 5.81666L1.73268 9.08333Z", fill: "#90A4BE" }))));
};
var ButtonDelete = forwardRef(function (props, ref) {
    return (React.createElement(StyledIconButton, __assign({ ref: ref }, props),
        React.createElement(DeleteIcon, null)));
});
var StyledIconButton = styled(IconButton)(function () { return ({
    width: '18px',
    height: '18px',
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
}); });
var useStyles = makeStyles(function (theme) { return ({
    wrapper: {
        // padding: '6px',
        // height: '30px',
        // borderRadius: '20px',
        // backgroundColor: '#F1F6FA',
        // display: 'flex',
        // flexDirection: 'row',
        // justifyContent: 'center',
        // alignItems: 'center',
        // gap: '6px',
        backgroundColor: '#F1F6FA',
        borderRadius: '20px',
        padding: '9px 10px',
    },
    text: {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '12px',
        letterSpacing: '-0.03em',
        fontFamily: 'GraphikLCG',
    },
}); });
