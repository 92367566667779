var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ModalActionTypes } from './actions';
import { initialState } from './state';
export var cabinetModalReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ModalActionTypes.ShowCabinetMenuModal:
            return __assign(__assign({}, state), { cabinetMenuModal: true });
        case ModalActionTypes.HideCabinetMenuModal:
            return __assign(__assign({}, state), { cabinetMenuModal: false });
        case ModalActionTypes.ShowCreateChatModal:
            return __assign(__assign({}, state), { createChatModal: true });
        case ModalActionTypes.HideCreateChatModal:
            return __assign(__assign({}, state), { createChatModal: false });
        case ModalActionTypes.ShowServiceModal:
            return __assign(__assign({}, state), { serviceModal: true });
        case ModalActionTypes.HideServiceModal:
            return __assign(__assign({}, state), { serviceModal: false });
        case ModalActionTypes.ShowEditServiceModal:
            return __assign(__assign({}, state), { serviceEditModal: true });
        case ModalActionTypes.HideEditServiceModal:
            return __assign(__assign({}, state), { serviceEditModal: false });
        case ModalActionTypes.ShowServiceSuccessfullyModal:
            return __assign(__assign({}, state), { serviceSuccessfullyModal: true });
        case ModalActionTypes.HideServiceSuccessfullyModal:
            return __assign(__assign({}, state), { serviceSuccessfullyModal: false });
        case ModalActionTypes.ShowClinicScheduleModal:
            return __assign(__assign({}, state), { clinicScheduleModal: true });
        case ModalActionTypes.HideClinicScheduleModal:
            return __assign(__assign({}, state), { clinicScheduleModal: false });
        case ModalActionTypes.ShowGalleryModal:
            return __assign(__assign({}, state), { galleryPhotoModal: true });
        case ModalActionTypes.HideGalleryModal:
            return __assign(__assign({}, state), { galleryPhotoModal: false });
        case ModalActionTypes.ShowReviewsDoctorModal:
            return __assign(__assign({}, state), { reviewsDoctorModal: true });
        case ModalActionTypes.HideReviewsDoctorModal:
            return __assign(__assign({}, state), { reviewsDoctorModal: false });
        case ModalActionTypes.ShowReviewsPatientModal:
            return __assign(__assign({}, state), { reviewsPatientModal: true });
        case ModalActionTypes.HideReviewsPatientModal:
            return __assign(__assign({}, state), { reviewsPatientModal: false });
        default:
            return state;
    }
};
