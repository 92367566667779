var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState, useContext, createContext } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { darkTheme, lightTheme } from './index';
import useLocalStorage from './useLocalStorage';
var ThemeContext = createContext({
    mode: 'light',
    toggleMode: function () { },
    theme: lightTheme,
    isDarkMode: false,
});
var cacheKey = 'ui-theme-mode';
var ThemeConfig = function (_a) {
    var children = _a.children;
    var _b = __read(useLocalStorage(cacheKey, 'light'), 2), mode = _b[0], setMode = _b[1];
    var _c = __read(useState(mode === 'light' ? lightTheme : darkTheme), 2), theme = _c[0], setTheme = _c[1];
    function toggleMode() {
        if (mode === 'light') {
            setMode('dark');
        }
        else {
            setMode('light');
        }
    }
    useEffect(function () {
        if (mode === 'dark') {
            setTheme(darkTheme);
        }
        else {
            setTheme(lightTheme);
        }
    }, [mode]);
    return (React.createElement(ThemeContext.Provider, { value: {
            mode: mode,
            toggleMode: toggleMode,
            theme: theme,
            isDarkMode: mode === 'dark',
        } },
        React.createElement(MuiThemeProvider, { theme: theme },
            React.createElement(CssBaseline, null),
            children)));
};
export var useThemeMode = function () { return useContext(ThemeContext); };
export default ThemeConfig;
