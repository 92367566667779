import { ServiceRequest } from './service-request';
import { SERVICE_EHEALTH_AUTH } from '../../auth';
import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PAYLOAD_STORE_CREATE_URL, PARAM_EHEALTH_PAYLOAD_STORE_DELETE_URL, PARAM_EHEALTH_PAYLOAD_STORE_LIST_URL, PARAM_EHEALTH_PAYLOAD_STORE_SINGLE_URL, PARAM_EHEALTH_PROXY_URL, } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
import { SERVICE_EHEALTH_SIGN } from '../signature';
export var SERVICE_EHEALTH_SERVICE_REQUEST = 'SERVICE_EHEALTH_SERVICE_REQUEST';
export var ehealthServiceRequestFactory = function (container) {
    var authService = container.require(SERVICE_EHEALTH_AUTH);
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var signService = container.require(SERVICE_EHEALTH_SIGN);
    var payloadStoreSingleUrl = "".concat(container.get(PARAM_EHEALTH_PAYLOAD_STORE_SINGLE_URL));
    var payloadStoreCreateUrl = "".concat(container.get(PARAM_EHEALTH_PAYLOAD_STORE_CREATE_URL));
    var payloadStoreDeleteUrl = "".concat(container.get(PARAM_EHEALTH_PAYLOAD_STORE_DELETE_URL));
    var payloadStoreListUrl = "".concat(container.get(PARAM_EHEALTH_PAYLOAD_STORE_LIST_URL));
    return new ServiceRequest({
        authService: authService,
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        signService: signService,
        payloadStoreCreateUrl: payloadStoreCreateUrl,
        payloadStoreDeleteUrl: payloadStoreDeleteUrl,
        payloadStoreListUrl: payloadStoreListUrl,
        payloadStoreSingleUrl: payloadStoreSingleUrl,
    });
};
