import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useStyles } from './NotificationStyle';
import { Typography as CoreTypography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { markOneNotification } from '../../../store/notifications/asyncActions';
export var Notification = function (
//  {
//  text,
//  type,
//  date,
//  isHeader,
//}: {
//  text: string;
//  type: string;
//  date: string;
//  isHeader: boolean;
//}
_a) {
    var item = _a.item;
    var classes = useStyles();
    var t = useTranslation().t;
    var dispatch = useDispatch();
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: classes.NotificationWrapper, onDoubleClick: function () { return dispatch(markOneNotification(item.id)); } },
            React.createElement(Stack, { direction: "row", justifyContent: "flex-start", alignItems: "flex-start", spacing: 1 },
                React.createElement("img", { src: require('../assets/ImageDoctor.jpg') }),
                React.createElement(Stack, { direction: "column", justifyContent: "flex-start", alignItems: "flex-start", spacing: 0.5 },
                    React.createElement(CoreTypography, null, t(item.message).replace('%expert%', item.params['%expert%'])),
                    React.createElement(Typography, { className: classes.TimeText }, item.createdAt.slice(0, 19).replace('T', ' ')))))));
};
