export var BookingServicesActionType;
(function (BookingServicesActionType) {
    BookingServicesActionType["GET_SERVICES_PENDING"] = "GET_SERVICES_PENDING";
    BookingServicesActionType["GET_SERVICES_SUCCESS"] = "GET_SERVICES_SUCCESS";
    BookingServicesActionType["GET_SERVICES_ERROR"] = "GET_SERVICES_ERROR";
    BookingServicesActionType["ADD_SERVICE_PENDING"] = "ADD_SERVICE_PENDING";
    BookingServicesActionType["ADD_SERVICE_SUCCESS"] = "ADD_SERVICE_SUCCESS";
    BookingServicesActionType["ADD_SERVICE_ERROR"] = "ADD_SERVICE_ERROR";
    BookingServicesActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
    BookingServicesActionType["SET_SELECTED_SERVICE"] = "SET_SELECTED_SERVICE";
    BookingServicesActionType["SET_SELECTED_DATE"] = "SET_SELECTED_DATE";
    BookingServicesActionType["SET_SELECTED_TIME"] = "SET_SELECTED_TIME";
    BookingServicesActionType["SET_SELECTED_CLIENT"] = "SET_SELECTED_CLIENT";
    BookingServicesActionType["SET_SELECTED_IS_NOTIFY"] = "SET_SELECTED_IS_NOTIFY";
    BookingServicesActionType["SET_SELECTED_NOTIFY_TYPE"] = "SET_SELECTED_NOTIFY_TYPE";
    BookingServicesActionType["SET_SELECTED_EMAIL"] = "SET_SELECTED_EMAIL";
    BookingServicesActionType["SET_SELECTED_PHONE"] = "SET_SELECTED_PHONE";
    BookingServicesActionType["SET_SELECTED_DESCRIPTION"] = "SET_SELECTED_DESCRIPTION";
})(BookingServicesActionType || (BookingServicesActionType = {}));
