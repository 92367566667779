var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useStyles } from './index.jss';
import { Box, List, ListItem, Typography } from '@mui/material';
import ButtonAction from './ButtonAction/ButtonAction';
import SearchIconSizedSvg from '../../assets/svg_components/SearchIconSizedSvg';
import { Swicher } from './LanguageSwitcher';
import PersonIconSvg from '../../assets/svg_components/PersonIconSvg';
import PhoneIconSvg from '../../assets/svg_components/PhoneIconSvg';
import LogoGradientIconSvg from '../../assets/svg_components/LogoIconSvg/LogoGradientIconSvg';
import LogoTitleIconSvg from '../../assets/svg_components/LogoIconSvg/LogoTitleIconSvg';
import ModalComponent from './Modal/ModalComponent';
import ButtonActionCabinet from './ButtonAction/ButtonActionCabinet';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export var Header = function (props) {
    var classes = useStyles();
    var _a = __read(useState(false), 2), open = _a[0], setOpen = _a[1];
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () { return setOpen(false); };
    var _b = __read(useState(false), 2), isLogin = _b[0], setIsLogin = _b[1];
    var location = useLocation();
    var _c = __read(useState(false), 2), isNeedBorder = _c[0], setIsNeedBorder = _c[1];
    var _d = useTranslation(), t = _d.t, i18n = _d.i18n;
    var currentLocation = window.location.pathname.replace("/".concat(i18n.language, "/"), '');
    useEffect(function () {
        if (location.search.includes('?showModal=login') || location.search.includes('?showModal=register')) {
            setOpen(true);
        }
    }, []);
    useEffect(function () {
        setIsLogin(document.cookie.includes('bearer'));
    }, [isLogin]);
    useEffect(function () {
        if (location.pathname.includes('/expert/') ||
            location.pathname.includes("/".concat(i18n.language, "/news/")) ||
            location.pathname.includes("/".concat(i18n.language, "/page/"))) {
            setIsNeedBorder(true);
        }
    }, [location]);
    return (React.createElement(Box, { className: classNames(classes.wrapper), sx: { border: isNeedBorder ? '1px solid #EAEAEA' : '' } },
        React.createElement(Box, { className: classNames(classes.container) },
            React.createElement(Link, { to: "/".concat(i18n.language) },
                React.createElement(Box, { className: classNames(classes.logoBlock) },
                    React.createElement(LogoGradientIconSvg, null),
                    React.createElement(LogoTitleIconSvg, null))),
            React.createElement(List, { className: classNames(classes.list) },
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/".concat(i18n.language, "/patient") }, t('menu.main.for-clients'))),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/".concat(i18n.language, "/doctor") }, t('menu.main.for-doctors'))),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/".concat(i18n.language, "/clinics") }, t('menu.main.for-clinic'))),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement(Link, { to: "/".concat(i18n.language, "/news") }, t('menu.main.news')),
                    (currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.includes('news')) && React.createElement(Box, { className: classNames(classes.listItemLine) })),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement(Link, { to: "/".concat(i18n.language, "/page/about-platform") }, t('menu.static.helpCenter')),
                    (currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.includes('page/')) && React.createElement(Box, { className: classNames(classes.listItemLine) })),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/".concat(i18n.language, "/ehealth") }, "MIC MyHeal"))),
            React.createElement(Box, { className: classNames(classes.rightBlock) },
                React.createElement(Box, { className: classNames(classes.phoneIcon) },
                    React.createElement(PhoneIconSvg, null)),
                React.createElement(Box, { className: classNames(classes.phoneWrapper) },
                    React.createElement(Typography, { className: classNames(classes.phoneTitle) }, t('support')),
                    React.createElement(Typography, { className: classNames(classes.phoneNumber) },
                        React.createElement("a", { href: "tel: +38 (093) 059 43 44" }, "+38 (093) 059 43 44"))),
                React.createElement(ButtonAction, { width: 180, height: 50, borderRadius: 25, backgroundColor: "#29F499", textColor: "#000000", icon: React.createElement(SearchIconSizedSvg, { classname: classNames(classes.buttonActionIcon) }), buttonTitle: t('dashboard.searchForExpert'), reactLink: true, buttonLink: "/".concat(i18n.language, "/experts") }),
                React.createElement(ButtonActionCabinet, { buttonStyles: {
                        '&:hover': {
                            backgroundColor: '#666666',
                        },
                    }, width: 130, height: 50, borderRadius: 35, backgroundColor: "#1E1E1E", textColor: "#FFFFFF", icon: React.createElement(PersonIconSvg, { color: "white" }), buttonTitle: t('profileBreadcrumbs01'), buttonLink: "".concat(window.location.origin, "/").concat(i18n.language, "/cabinet/settings#personalData"), voidLink: handleOpen, isLogin: isLogin }),
                React.createElement(Swicher, null))),
        React.createElement(ModalComponent, { open: open, handleClose: handleClose })));
};
