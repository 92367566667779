import { ClinicInfoActionType } from './action';
export var fetchGetClinicBegin = function () { return ({
    type: ClinicInfoActionType.GET_CLINIC_INFO_PENDING,
}); };
export var fetchGetClinicSuccess = function (Clinic) { return ({
    type: ClinicInfoActionType.GET_CLINIC_INFO_SUCCESS,
    payload: Clinic,
}); };
export var fetchGetClinicFailure = function (error) { return ({
    type: ClinicInfoActionType.GET_CLINIC_INFO_ERROR,
    payload: { error: error },
}); };
export var fetchSetClinicBegin = function () { return ({
    type: ClinicInfoActionType.SET_CLINIC_INFO_PENDING,
}); };
export var fetchSetClinicSuccess = function () { return ({
    type: ClinicInfoActionType.SET_CLINIC_INFO_SUCCESS,
}); };
export var fetchSetClinicFailure = function (error) { return ({
    type: ClinicInfoActionType.SET_CLINIC_INFO_ERROR,
    payload: { error: error },
}); };
export var fetchGetClinicTypesBegin = function () { return ({
    type: ClinicInfoActionType.GET_CLINIC_TYPES_PENDING,
}); };
export var fetchGetClinicTypesSuccess = function (types) { return ({
    type: ClinicInfoActionType.GET_CLINIC_TYPES_SUCCESS,
    payload: types,
}); };
export var fetchGetClinicTypesFailure = function (error) { return ({
    type: ClinicInfoActionType.GET_CLINIC_TYPES_ERROR,
    payload: { error: error },
}); };
export var fetchGetClinicAddressBegin = function () { return ({
    type: ClinicInfoActionType.GET_CLINIC_ADDRESS_PENDING,
}); };
export var fetchGetClinicAddressSuccess = function (address) { return ({
    type: ClinicInfoActionType.GET_CLINIC_ADDRESS_SUCCESS,
    payload: address,
}); };
export var fetchGetClinicAddressFailure = function (error) { return ({
    type: ClinicInfoActionType.GET_CLINIC_ADDRESS_ERROR,
    payload: { error: error },
}); };
export var fetchSetClinicAdditionalBegin = function () { return ({
    type: ClinicInfoActionType.SET_CLINIC_ADDITIONAL_PENDING,
}); };
export var fetchSetClinicAdditionalSuccess = function () { return ({
    type: ClinicInfoActionType.SET_CLINIC_ADDITIONAL_SUCCESS,
}); };
export var fetchSetClinicAdditionalFailure = function (error) { return ({
    type: ClinicInfoActionType.SET_CLINIC_ADDITIONAL_ERROR,
    payload: { error: error },
}); };
export var fetchSetClinicAddressBegin = function () { return ({
    type: ClinicInfoActionType.SET_CLINIC_ADDRESS_PENDING,
}); };
export var fetchSetClinicAddressSuccess = function () { return ({
    type: ClinicInfoActionType.SET_CLINIC_ADDRESS_SUCCESS,
}); };
export var fetchSetClinicAddressFailure = function (error) { return ({
    type: ClinicInfoActionType.SET_CLINIC_ADDRESS_ERROR,
    payload: { error: error },
}); };
export var setClinicDataChangedFalse = function () { return ({
    type: ClinicInfoActionType.SET_DATA_FALSE,
}); };
