export var EhealthDeviceRAction;
(function (EhealthDeviceRAction) {
    EhealthDeviceRAction["ADD_ITEMS"] = "EHEALTH_DEVICE_REQUEST_ADD_ITEMS";
    EhealthDeviceRAction["CLEAR"] = "EHEALTH_DEVICE_REQUEST_CLEAR";
    EhealthDeviceRAction["UPDATE"] = "EHEALTH_DEVICE_REQUEST_UPDATE";
    EhealthDeviceRAction["SET_IS_LOADING"] = "EHEALTH_DEVICE_REQUEST_SET_IS_LOADING";
    EhealthDeviceRAction["SETUP_JOBS"] = "EHEALTH_DEVICE_REQUEST_SETUP_JOBS";
    EhealthDeviceRAction["REMOVE_JOB"] = "EHEALTH_DEVICE_REQUEST_REMOVE_JOB";
    EhealthDeviceRAction["SET_FILTER"] = "EHEALTH_DEVICE_REQUEST_FILTER";
})(EhealthDeviceRAction || (EhealthDeviceRAction = {}));
