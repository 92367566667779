import { PARAM_LOCALIZE_DATE_PREVIEW_FORMAT, PARAM_LOCALIZE_DATE_VALUE_FORMAT, PARAM_LOCALIZE_LOCALE, PARAM_LOCALIZE_TIME_FORMAT, } from '../../../config/di/params/keys';
import { Localize } from './localize';
export var SERVICE_LOCALIZE = 'SERVICE_LOCALIZE';
export function localizeServiceFactory(container) {
    return new Localize({
        locale: "".concat(container.get(PARAM_LOCALIZE_LOCALE)),
        datePreviewFormat: "".concat(container.get(PARAM_LOCALIZE_DATE_PREVIEW_FORMAT)),
        dateValueFormat: "".concat(container.get(PARAM_LOCALIZE_DATE_VALUE_FORMAT)),
        timeFormat: "".concat(container.get(PARAM_LOCALIZE_TIME_FORMAT)),
    });
}
