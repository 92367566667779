import { PARAM_ITEMS_IN_AUTOCOMPLETE } from '../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_SEARCH_IN_CATEGORY_SERVICE_CATALOG_URL, PARAM_EHEALTH_GET_SERVICE_CATALOG_ITEM_URL, PARAM_EHEALTH_GET_SERVICE_CATALOG_ITEMS_LIST_URL, } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
import { ServiceCatalog } from './service-catalog';
export var SERVICE_EHEALTH_SERVICE_CATALOG = 'SERVICE_EHEALTH_SERVICE_CATALOG';
export var ehealthServiceCatalogFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_IN_AUTOCOMPLETE) || 0);
    var searchInCategoryUrl = "".concat(container.get(PARAM_EHEALTH_SEARCH_IN_CATEGORY_SERVICE_CATALOG_URL));
    var getServiceCatalogItemUrl = "".concat(container.get(PARAM_EHEALTH_GET_SERVICE_CATALOG_ITEM_URL));
    var getServiceCatalogItemsListUrl = "".concat(container.get(PARAM_EHEALTH_GET_SERVICE_CATALOG_ITEMS_LIST_URL));
    return new ServiceCatalog({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        searchInCategoryUrl: searchInCategoryUrl,
        getServiceCatalogItemUrl: getServiceCatalogItemUrl,
        getServiceCatalogItemsListUrl: getServiceCatalogItemsListUrl,
    });
};
