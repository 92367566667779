export var EhealthMEConditionAction;
(function (EhealthMEConditionAction) {
    EhealthMEConditionAction["ADD_ITEMS"] = "EHEALTH_ME_CONDITION_ADD_ITEMS";
    EhealthMEConditionAction["CLEAR"] = "EHEALTH_ME_CONDITION_CLEAR";
    EhealthMEConditionAction["UPDATE"] = "EHEALTH_ME_CONDITION_UPDATE";
    EhealthMEConditionAction["SET_IS_LOADING"] = "EHEALTH_ME_CONDITION_SET_IS_LOADING";
    EhealthMEConditionAction["SETUP_JOBS"] = "EHEALTH_ME_CONDITION_SETUP_JOBS";
    EhealthMEConditionAction["REMOVE_JOB"] = "EHEALTH_ME_CONDITION_REMOVE_JOB";
    EhealthMEConditionAction["SET_FILTER"] = "EHEALTH_ME_CONDITION_SET_FILTER";
})(EhealthMEConditionAction || (EhealthMEConditionAction = {}));
