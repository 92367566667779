import React from 'react';
export default function LogoGradientIconSvg() {
    return (React.createElement("svg", { width: "40", height: "40", viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M17.448 1.51469C19.0212 0.580937 20.9788 0.580938 22.552 1.51469L28.6 5.10436L34.7328 8.54725C36.328 9.44282 37.3068 11.1381 37.2847 12.9674L37.2 20L37.2847 27.0326C37.3068 28.8619 36.328 30.5572 34.7328 31.4528L28.6 34.8956L22.552 38.4853C20.9788 39.4191 19.0212 39.4191 17.448 38.4853L11.4 34.8956L5.26725 31.4527C3.67199 30.5572 2.69321 28.8619 2.71525 27.0326L2.8 20L2.71525 12.9674C2.69321 11.1381 3.67199 9.44282 5.26725 8.54725L11.4 5.10436L17.448 1.51469Z", fill: "#2BBB97" }),
        React.createElement("path", { d: "M17.448 1.51469C19.0212 0.580937 20.9788 0.580938 22.552 1.51469L28.6 5.10436L34.7328 8.54725C36.328 9.44282 37.3068 11.1381 37.2847 12.9674L37.2 20L37.2847 27.0326C37.3068 28.8619 36.328 30.5572 34.7328 31.4528L28.6 34.8956L22.552 38.4853C20.9788 39.4191 19.0212 39.4191 17.448 38.4853L11.4 34.8956L5.26725 31.4527C3.67199 30.5572 2.69321 28.8619 2.71525 27.0326L2.8 20L2.71525 12.9674C2.69321 11.1381 3.67199 9.44282 5.26725 8.54725L11.4 5.10436L17.448 1.51469Z", fill: "url(#paint0_linear_1031_432)" }),
        React.createElement("path", { d: "M17.448 1.51469C19.0212 0.580937 20.9788 0.580938 22.552 1.51469L28.6 5.10436L34.7328 8.54725C36.328 9.44282 37.3068 11.1381 37.2847 12.9674L37.2 20L37.2847 27.0326C37.3068 28.8619 36.328 30.5572 34.7328 31.4528L28.6 34.8956L22.552 38.4853C20.9788 39.4191 19.0212 39.4191 17.448 38.4853L11.4 34.8956L5.26725 31.4527C3.67199 30.5572 2.69321 28.8619 2.71525 27.0326L2.8 20L2.71525 12.9674C2.69321 11.1381 3.67199 9.44282 5.26725 8.54725L11.4 5.10436L17.448 1.51469Z", fill: "url(#paint1_linear_1031_432)" }),
        React.createElement("path", { d: "M17.448 1.51469C19.0212 0.580937 20.9788 0.580938 22.552 1.51469L28.6 5.10436L34.7328 8.54725C36.328 9.44282 37.3068 11.1381 37.2847 12.9674L37.2 20L37.2847 27.0326C37.3068 28.8619 36.328 30.5572 34.7328 31.4528L28.6 34.8956L22.552 38.4853C20.9788 39.4191 19.0212 39.4191 17.448 38.4853L11.4 34.8956L5.26725 31.4527C3.67199 30.5572 2.69321 28.8619 2.71525 27.0326L2.8 20L2.71525 12.9674C2.69321 11.1381 3.67199 9.44282 5.26725 8.54725L11.4 5.10436L17.448 1.51469Z", fill: "url(#paint2_linear_1031_432)" }),
        React.createElement("path", { d: "M8 22.2732H12.1233L16.9334 9.33333L20.3693 30.6667L26.6667 13.3333L28 20.8743L32.7386 20.8743", stroke: "white", strokeWidth: "2.5", strokeLinecap: "square", strokeLinejoin: "round" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "paint0_linear_1031_432", x1: "36.6667", y1: "28.6667", x2: "20", y2: "18.6667", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#29F49D" }),
                React.createElement("stop", { offset: "1", stopColor: "#CC8DF3", stopOpacity: "0" })),
            React.createElement("linearGradient", { id: "paint1_linear_1031_432", x1: "20", y1: "4", x2: "20", y2: "22", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#CC8DF3" }),
                React.createElement("stop", { offset: "1", stopColor: "#CC8DF3", stopOpacity: "0" })),
            React.createElement("linearGradient", { id: "paint2_linear_1031_432", x1: "5.33333", y1: "30.6667", x2: "20.6667", y2: "20.6667", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#08DBF4" }),
                React.createElement("stop", { offset: "1", stopColor: "#CC8DF3", stopOpacity: "0" })))));
}
