import { PARAM_MOI_CLIENT_CONSULTANT_REQUEST_CONFIRM_URL, PARAM_MOI_CLIENT_CONSULTANT_REQUEST_DECLINE_URL, PARAM_MOI_CLIENT_CONSULTANT_REQUEST_LIST_URL, } from '../../config/di/params/keys';
import { ClientConsultantRequest } from './client-consultant-request';
import { PARAM_CITEMS_PER_PAGE } from '../../../../config/di/params/keys';
export var SERVICE_MOI_CLIENT_CONSULTANT_REQUEST = 'SERVICE_MOI_CLIENT_CONSULTANT_REQUEST';
export function clientConsultantRequestFactory(container) {
    var listUrl = "".concat(container.get(PARAM_MOI_CLIENT_CONSULTANT_REQUEST_LIST_URL));
    var confirmClientRequestUrl = "".concat(container.get(PARAM_MOI_CLIENT_CONSULTANT_REQUEST_CONFIRM_URL));
    var declineClientRequestUrl = "".concat(container.get(PARAM_MOI_CLIENT_CONSULTANT_REQUEST_DECLINE_URL));
    var itemsPerPage = +(container.get(PARAM_CITEMS_PER_PAGE) || 0);
    return new ClientConsultantRequest({
        urls: {
            listUrl: listUrl,
            confirmClientRequestUrl: confirmClientRequestUrl,
            declineClientRequestUrl: declineClientRequestUrl,
        },
        itemsPerPage: itemsPerPage,
    });
}
