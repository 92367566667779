import { createUseStyles } from 'react-jss';
export var useStyles = createUseStyles({
    wrapper: {
        height: '80px',
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        position: 'absolute',
        left: '20px',
        right: '20px',
        top: '20px',
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
        zIndex: '100',
    },
    container: {
        maxWidth: '1880px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
    },
    logoBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: '0px',
        left: '0px',
    },
    bottomMenu: {
        display: 'none',
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
    },
    listItem: {
        whiteSpace: 'nowrap',
        fontFamily: 'GraphikLCG',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '16px',
        letterSpacing: '-0.03em',
        color: '#173236',
        position: 'relative',
        padding: '0px',
        marginRight: '30px',
    },
    listItemActive: {
        color: '#32D29F',
    },
    listItemLine: {
        position: 'absolute',
        backgroundColor: '#32D29F',
        height: '4px',
        width: '100%',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        top: '44px',
    },
    rightBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    phoneIcon: {
        width: '40px',
        height: '40px',
        border: '1px solid #1E1E1E',
        borderRadius: '25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '10px',
    },
    phoneWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '47px',
    },
    phoneTitle: {
        fontFamily: 'GraphikLCG',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '14px',
        letterSpacing: '-0.03em',
        color: '#221F1F',
    },
    phoneNumber: {
        fontFamily: 'GraphikLCG',
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '18px',
        letterSpacing: '-0.03em',
        color: '#000000',
    },
    buttonActionIcon: {
        height: '22px',
        width: '22px',
    },
    '@media screen and (max-width: 1700px)': {
        bottomMenu: {
            display: 'flex',
            height: '80px',
            backgroundColor: '#FFFFFF',
            borderRadius: '20px',
            position: 'absolute',
            left: '0px',
            right: '0px',
            top: '90px',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px',
        },
    },
});
