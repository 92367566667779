import { combineReducers } from 'redux';
import { messageTemplateReducer } from './messageTemplate/reducer';
import { emailMessageTemplateReducer } from "./emailMessageTemplate/reducer";
import { emailMessageTaskReducer } from "./emailMessageTask/reducer";
import { messageTemplateSendReducer } from "./messageTemplateSend/reducer";
export var chatReducer = combineReducers({
    messageTemplate: messageTemplateReducer,
    messageTemplateSend: messageTemplateSendReducer,
    emailMessageTemplate: emailMessageTemplateReducer,
    emailMessageTask: emailMessageTaskReducer,
});
