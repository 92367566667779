export var ExpertScheduleInitialState = {
    hour: '',
    minute: '',
};
export var initialState = {
    expertFreeHours: [],
    expert: 0,
    date: '',
    time: '',
    service: 0,
    isLoading: false,
    error: null,
};
