var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import classNames from 'classnames';
import { Box, Typography, TextField, Checkbox, Button, FormControlLabel, FormControl, RadioGroup, Radio, } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import HidePasswordIconSvg from '../../../../assets/svg_components/HidePasswordIconSvg';
import * as Yup from 'yup';
import { useStyles } from './Register.jss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRegister } from '../../../../store/register/userRegister/register';
export default function Login(_a) {
    var handleClose = _a.handleClose;
    var classes = useStyles();
    var _b = __read(useState(false), 2), showPassword = _b[0], setShowPassword = _b[1];
    var _c = __read(useState(false), 2), specialist = _c[0], setSpecialist = _c[1];
    var dispatch = useDispatch();
    var registerError = useSelector(function (state) { return state.moi.register.userRegister.error; });
    var initialValue = {
        username: '',
        password: '',
        registerType: 'patient',
        termsOfService: false,
    };
    var phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
    var passwordRegExp = /^\S+$/;
    var requiredMessage = "\u0426\u0435 \u043F\u043E\u043B\u0435 \u043E\u0431\u043E\u0432'\u044F\u0437\u043A\u043E\u0432\u0435.";
    var phoneMessage = 'Невірний формат даних. Телефон повинен мати наступний формат: +380ХХХХХХХХХ';
    var passwordMessage = 'Пароль повинен містити від 4 до 255 символів(крім пробіла), цифр або літер латинського алфавіту';
    var SignupSchema = Yup.object().shape({
        username: Yup.string()
            .required(requiredMessage)
            .matches(phoneRegExp, phoneMessage)
            .min(13, phoneMessage)
            .max(13, phoneMessage),
        password: Yup.string()
            .required(requiredMessage)
            .matches(passwordRegExp, passwordMessage)
            .min(4, passwordMessage)
            .max(255, passwordMessage),
        termsOfService: Yup.boolean().oneOf([true], requiredMessage),
    });
    var handleRegister = function (_a, props) {
        var username = _a.username, password = _a.password, registerType = _a.registerType;
        dispatch(fetchRegister({ username: username, password: password, registerType: registerType }));
        return;
    };
    return (React.createElement(Box, { className: classNames(classes.wrapper) },
        React.createElement(Formik, { initialValues: initialValue, onSubmit: handleRegister, validationSchema: SignupSchema }, function (props) {
            var _a = props.values, username = _a.username, password = _a.password, registerType = _a.registerType;
            return (React.createElement(Form, null,
                React.createElement(Box, { sx: { width: '100%' } },
                    React.createElement(Typography, { className: classNames(classes.title) }, "\u0420\u0435\u0454\u0441\u0442\u0440\u0430\u0446\u0456\u044F"),
                    React.createElement(Box, { className: classNames(classes.specialistBox) },
                        React.createElement(Box, { className: classNames(classes.specialistBoxItem), onClick: function () {
                                setSpecialist(false);
                                props.setFieldValue(registerType, 'patient');
                            } },
                            React.createElement(Typography, { className: classNames(specialist ? classes.specialistBoxTitle : classes.specialistBoxTitleActive) }, "\u041F\u0430\u0446\u0456\u0454\u043D\u0442"),
                            specialist ? '' : React.createElement(Box, { className: classNames(classes.specialistBoxLine) })),
                        React.createElement(Box, { className: classNames(classes.specialistBoxItem), onClick: function () {
                                setSpecialist(true);
                                props.setFieldValue('registerType', 'familyDoctor');
                            } },
                            React.createElement(Typography, { className: classNames(specialist ? classes.specialistBoxTitleActive : classes.specialistBoxTitle) }, "\u041B\u0456\u043A\u0430\u0440"),
                            specialist ? React.createElement(Box, { className: classNames(classes.specialistBoxLine) }) : '')),
                    specialist && (React.createElement(FormControl, { className: classNames(classes.radioWrapper) },
                        React.createElement(RadioGroup, { "aria-labelledby": "demo-radio-buttons-group-label", defaultValue: "familyDoctor", name: "radio-buttons-group", row: true },
                            React.createElement(FormControlLabel, { value: "familyDoctor", name: "familyDoctor", control: React.createElement(Radio, null), label: "\u0421\u0456\u043C\u0435\u0439\u043D\u0438\u0439 \u043B\u0456\u043A\u0430\u0440", onClick: function () { return props.setFieldValue('registerType', 'familyDoctor'); } }),
                            React.createElement(FormControlLabel, { value: "doctor", name: "doctor", control: React.createElement(Radio, null), label: "\u041B\u0456\u043A\u0430\u0440", onClick: function () { return props.setFieldValue('registerType', 'doctor'); } })))),
                    React.createElement(Box, { className: classNames(classes.loginBox) },
                        React.createElement(Typography, { className: classNames(classes.inputTitle) }, "\u041C\u043E\u0431\u0456\u043B\u044C\u043D\u0438\u0439 \u043D\u043E\u043C\u0435\u0440 \u0430\u0431\u043E \u0435\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0430 \u043F\u043E\u0448\u0442\u0430"),
                        React.createElement(Field, { as: TextField, placeholder: "+380 ( _ _ )  _ _ _  _ _  _ _", id: "username", name: "username", value: username, className: classNames(classes.input), InputLabelProps: { shrink: false } }),
                        props.errors.username && (React.createElement(Typography, { className: classNames(classes.inputError) }, props.errors.username))),
                    React.createElement(Box, { className: classNames(classes.passwordBox) },
                        React.createElement(Typography, { className: classNames(classes.inputTitle) }, "\u041F\u0430\u0440\u043E\u043B\u044C"),
                        React.createElement(Box, { className: classNames(classes.passwordInputBox) },
                            React.createElement(Field, { as: TextField, placeholder: "\u0412\u0432\u0435\u0434\u0456\u0442\u044C \u043F\u0430\u0440\u043E\u043B\u044C", id: "password", name: "password", value: password, type: showPassword ? 'text' : 'password', className: classNames(classes.input), InputLabelProps: { shrink: false } }),
                            React.createElement(HidePasswordIconSvg, { classname: classNames(classes.passwordIcon), onclick: function () { return setShowPassword(!showPassword); } })),
                        props.errors.password && (React.createElement(Typography, { className: classNames(classes.inputError) }, props.errors.password))),
                    React.createElement(Box, { className: classNames(classes.rememberBox) },
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { onChange: function (e) { return props.setFieldValue('termsOfService', e.target.checked); }, sx: {
                                    color: '#90A4BE',
                                    '&.Mui-checked': {
                                        color: '#32D29F',
                                    },
                                } }), label: React.createElement(Box, { className: classNames(classes.rememberBoxContainer) },
                                React.createElement(Typography, { className: classNames(classes.rememberBoxText) }, "\u041E\u0437\u043D\u0430\u0439\u043E\u043C\u0438\u0432\u0441\u044F \u0442\u0430 \u043F\u0440\u0438\u0439\u043C\u0430\u044E"),
                                ' ',
                                '\u00A0',
                                React.createElement(Typography, { className: classNames(classes.rememberBoxTitle), onClick: function () { return window.location.replace('uk/page/privacy-policy'); } }, "\u041F\u043E\u043B\u0456\u0442\u0438\u043A\u0438 \u043A\u043E\u043D\u0444\u0456\u0434\u0435\u043D\u0446\u0456\u0439\u043D\u043E\u0441\u0442\u0456"),
                                ' ',
                                '\u00A0',
                                React.createElement(Typography, { className: classNames(classes.rememberBoxText) }, "\u0442\u0430"),
                                " ",
                                '\u00A0',
                                React.createElement(Typography, { className: classNames(classes.rememberBoxTitle), onClick: function () { return window.location.replace('uk/page/agreement'); } }, "\u041F\u0440\u0430\u0432\u0438\u043B\u0430 \u0441\u0435\u0440\u0432\u0456\u0441\u0443")) })),
                    props.errors.termsOfService && (React.createElement(Typography, { className: classNames(classes.inputError) }, props.errors.termsOfService)),
                    registerError && (React.createElement(Box, { className: classNames(classes.registerError) },
                        React.createElement(Typography, { className: classNames(classes.registerErrorTitle) }, "\u041F\u043E\u043C\u0438\u043B\u043A\u0430 \u0440\u0435\u0454\u0441\u0442\u0440\u0430\u0446\u0456\u0457."))),
                    React.createElement(Button, { type: "submit", className: classNames(classes.submitButton) }, "\u0417\u0430\u0440\u0435\u0454\u0441\u0442\u0440\u0443\u0432\u0430\u0442\u0438\u0441\u044F")),
                React.createElement(Typography, { className: classNames(classes.help) }, "\u0430\u0431\u043E \u0437\u0430 \u0434\u043E\u043F\u043E\u043C\u043E\u0433\u043E\u044E"),
                React.createElement(Box, { className: classNames(classes.social) },
                    React.createElement(Button, { className: classNames(classes.socialButton), sx: { backgroundColor: '#3B5898', marginRight: '10px' }, onClick: function () {
                            return window.location.replace("https://www.facebook.com/login.php?skip_api_login=1&api_key=207605933491218&kid_directed_site=0&app_id=207605933491218&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fv8.0%2Fdialog%2Foauth%3Fclient_id%3D207605933491218%26state%3D%257B%2522action%2522%253A%2522register%2522%252C%2522social%2522%253A%2522facebook%2522%252C%2522role%2522%253A%2522patient%2522%252C%2522locale%2522%253A%2522uk%2522%257D%26response_type%3Dcode%26sdk%3Dphp-sdk-5.7.0%26redirect_uri%3Dhttps%253A%252F%252Fmyheal.com.ua%252Fsocial-data-handler%26scope%3Dpublish_actions%252Cemail%26ret%3Dlogin%26fbapp_pres%3D0%26logger_id%3D7e6670f7-d675-4fa2-99eb-d944a6b34dad%26tp%3Dunspecified&cancel_url=https%3A%2F%2Fmyheal.com.ua%2Fsocial-data-handler%3Ferror%3Daccess_denied%26error_code%3D200%26error_description%3DPermissions%2Berror%26error_reason%3Duser_denied%26state%3D%257B%2522action%2522%253A%2522register%2522%252C%2522social%2522%253A%2522facebook%2522%252C%2522role%2522%253A%2522patient%2522%252C%2522locale%2522%253A%2522uk%2522%257D%23_%3D_&display=page&locale=ru_RU&pl_dbl=0");
                        } },
                        React.createElement(Box, { className: classNames(classes.socialIcon) }),
                        React.createElement(Typography, { className: classNames(classes.socialTitle) }, "Facebook")),
                    React.createElement(Button, { className: classNames(classes.socialButton), sx: { backgroundColor: '#000000', marginRight: '10px' }, onClick: function () {
                            return window.location.replace("https://appleid.apple.com/auth/authorize?client_id=com.MoniHeal&redirect_uri=https%3A%2F%2Fmyheal.com.ua%2Fsocial-data-handler&response_type=code+id_token&scope=name+email&nonce=code&response_mode=form_post&state=%7B%22action%22%3A%22register%22%2C%22social%22%3A%22apple%22%2C%22role%22%3A%22patient%22%2C%22locale%22%3A%22uk%22%7D");
                        } },
                        React.createElement(Box, { className: classNames(classes.socialIcon) }),
                        React.createElement(Typography, { className: classNames(classes.socialTitle) }, "Apple ID")),
                    React.createElement(Button, { className: classNames(classes.socialButton), sx: { backgroundColor: '#A5BDDB' }, onClick: function () {
                            return window.location.replace("https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?response_type=code&access_type=online&client_id=976144271696-7krurkdfa2ht0csegljd4p8b482rtdkb.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fmyheal.com.ua%2Fsocial-data-handler&state=%7B%22action%22%3A%22register%22%2C%22social%22%3A%22google%22%2C%22role%22%3A%22patient%22%2C%22locale%22%3A%22uk%22%7D&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&approval_prompt=auto&service=lso&o2v=1&ddm=1&flowName=GeneralOAuthFlow");
                        } },
                        React.createElement(Box, { className: classNames(classes.socialIcon) }),
                        React.createElement(Typography, { className: classNames(classes.socialTitle) }, "Google")))));
        })));
}
