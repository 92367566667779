var ClinicRegisterInitialState = {
    logo: null,
    type: null,
    name: null,
    fullName: null,
    website: null,
    description: null,
    oblast: null,
    region: null,
    settlement: null,
    street: null,
    email: null,
    mobilePhone: null,
    landlinePhone: null,
    lastName: null,
    firstName: null,
    secondName: null,
    birthday: null,
    sex: null,
    position: null,
    ownerMobilePhone: [''],
    ownerEmail: null,
    noRNOCPP: false,
    RNOCPP: null,
    documentsType: null,
    series: null,
    number: null,
    issuedDate: null,
    issuedBy: null,
    headIdentifier: '1342243l-534jk5-352lk-24k3354fbbxb',
};
export var initialState = {
    registerForm: ClinicRegisterInitialState,
    loading: false,
    error: null,
};
