var Localize = /** @class */ (function () {
    function Localize(config) {
        this.config = config;
    }
    Localize.prototype.getLocale = function () {
        return this.config.locale;
    };
    Localize.prototype.getDateValueFormat = function () {
        return this.config.dateValueFormat;
    };
    Localize.prototype.getDatePreviewFormat = function () {
        return this.config.datePreviewFormat;
    };
    Localize.prototype.getTimeFormat = function () {
        return this.config.timeFormat;
    };
    return Localize;
}());
export { Localize };
