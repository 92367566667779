var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ConclusionActionType } from './action';
import { initialState } from './state';
export var conclusionsReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ConclusionActionType.SET_DATA_FALSE:
            return __assign(__assign({}, state), { isAdded: false, isLoading: false, error: null });
        case ConclusionActionType.GET_CONCLUSIONS_PENDING:
            return __assign(__assign({}, state), { isLoading: true, error: null });
        case ConclusionActionType.GET_CONCLUSIONS_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, conclusions: action.payload });
        case ConclusionActionType.GET_CONCLUSIONS_ERROR:
            return __assign(__assign({}, state), { isLoading: false, error: action.payload });
        case ConclusionActionType.SET_CONCLUSION_PENDING:
            return __assign(__assign({}, state), { isLoading: true, isAdded: false, error: null });
        case ConclusionActionType.SET_CONCLUSION_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, isAdded: true });
        case ConclusionActionType.SET_CONCLUSION_ERROR:
            return __assign(__assign({}, state), { isLoading: false, isAdded: false, error: action.payload });
        default:
            return state;
    }
};
