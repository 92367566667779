import { combineReducers } from 'redux';
import { allergyIntolerancesReducer } from './allergy-intolerances/reducer';
import { episodesReducer } from './episodes/reducer';
import { immunizationsReducer } from './immunizations/reducer';
import { riskAssesmentReducer } from './risk-assesment/reducer';
import { observationsReducer } from './observations/reducer';
import { conditionReducer } from './conditions/reducer';
import { activeDiagnosesReducer } from './active-diagnoses/reducer';
import { deviceReducer } from './device/reducer';
import { medicalStatementsReducer } from './medical-statements/reducer';
import { medicalDiagnosticReducer } from './diagnostic-report/reducer';
import { proceduresReducer } from './procedures/reducer';
export var patientSummaryReducer = combineReducers({
    riskAssesment: riskAssesmentReducer,
    episodes: episodesReducer,
    immunizations: immunizationsReducer,
    allergyIntolerance: allergyIntolerancesReducer,
    observations: observationsReducer,
    conditions: conditionReducer,
    activeDiagnoses: activeDiagnosesReducer,
    devices: deviceReducer,
    medicalStatements: medicalStatementsReducer,
    diagnosticReport: medicalDiagnosticReducer,
    procedures: proceduresReducer
});
