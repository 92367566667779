var _a;
import { EmployeeType } from '../enum/employee.type';
export var EMPLOYEE_TYPE_TO_EDUCATION_DEGREE = (_a = {},
    _a[EmployeeType.DOCTOR] = {
        EXPERT: true,
        MASTER: true,
        BACHELOR: true,
        JUNIOR_EXPERT: true,
    },
    _a[EmployeeType.SPECIALIST] = {
        EXPERT: true,
        MASTER: true,
        BACHELOR: true,
        JUNIOR_EXPERT: true,
    },
    _a[EmployeeType.ASSISTANT] = {
        EXPERT: true,
        MASTER: true,
        BACHELOR: true,
        JUNIOR_EXPERT: true,
    },
    _a[EmployeeType.PHARMASIST] = {
        EXPERT: true,
        MASTER: true,
        BACHELOR: true,
        JUNIOR_EXPERT: true,
    },
    _a[EmployeeType.MED_COORDINATOR] = {
        EXPERT: true,
        MASTER: true,
        BACHELOR: true,
        JUNIOR_EXPERT: true,
    },
    _a[EmployeeType.LABORANT] = {
        EXPERT: true,
        MASTER: true,
        BACHELOR: true,
        JUNIOR_EXPERT: true,
    },
    _a);
