import { PARAM_MOI_CLIENT_TAGS_CREATE_URL, PARAM_MOI_CLIENT_TAGS_DELETE_URL, PARAM_MOI_CLIENT_TAGS_EDIT_URL, PARAM_MOI_CLIENT_TAGS_MASS_APPLY_URL, PARAM_MOI_CLIENT_DISEASE_LIST_URL, PARAM_MOI_CLIENT_DISEASE_ATTACH_URL, PARAM_MOI_CLIENT_DISEASE_DETACH_URL } from '../../config/di/params/keys';
import { ClientDiseases } from './client-diseases';
export var SERVICE_MOI_CLIENT_DISEASES = 'SERVICE_MOI_CLIENT_DISEASES';
export function clientDiseasesFactory(container) {
    var listUrl = "".concat(container.get(PARAM_MOI_CLIENT_DISEASE_LIST_URL));
    var attachUrl = "".concat(container.get(PARAM_MOI_CLIENT_DISEASE_ATTACH_URL));
    var detachUrl = "".concat(container.get(PARAM_MOI_CLIENT_DISEASE_DETACH_URL));
    var createUrl = "".concat(container.get(PARAM_MOI_CLIENT_TAGS_CREATE_URL));
    var deleteUrl = "".concat(container.get(PARAM_MOI_CLIENT_TAGS_DELETE_URL));
    var editUrl = "".concat(container.get(PARAM_MOI_CLIENT_TAGS_EDIT_URL));
    var massApplyUrl = "".concat(container.get(PARAM_MOI_CLIENT_TAGS_MASS_APPLY_URL));
    return new ClientDiseases({
        urls: {
            listUrl: listUrl,
            createUrl: createUrl,
            deleteUrl: deleteUrl,
            editUrl: editUrl,
            attachUrl: attachUrl,
            detachUrl: detachUrl,
            massApplyUrl: massApplyUrl
        }
    });
}
