var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ClinicGalleryActionType } from './action';
import { initialState } from './state';
export var clinicGalleryReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ClinicGalleryActionType.SET_DATA_FALSE:
            return __assign(__assign({}, state), { isAdded: false, isEdited: false, isDeleted: false, isLoading: false, error: null });
        case ClinicGalleryActionType.GET_CLINIC_GALLERY_PENDING:
            return __assign(__assign({}, state), { isLoading: true, error: null });
        case ClinicGalleryActionType.GET_CLINIC_GALLERY_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, clinicGallery: action.payload.clinicGallery, totalCount: action.payload.totalCount });
        case ClinicGalleryActionType.GET_CLINIC_GALLERY_ERROR:
            return __assign(__assign({}, state), { isLoading: false, error: action.payload });
        case ClinicGalleryActionType.GET_ONE_CLINIC_GALLERY_PENDING:
            return __assign(__assign({}, state), { isLoading: true, error: null });
        case ClinicGalleryActionType.GET_ONE_CLINIC_GALLERY_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, onePhoto: action.payload });
        case ClinicGalleryActionType.GET_ONE_CLINIC_GALLERY_ERROR:
            return __assign(__assign({}, state), { isLoading: false, error: action.payload });
        case ClinicGalleryActionType.ADD_CLINIC_GALLERY_PENDING:
            return __assign(__assign({}, state), { isLoading: true, error: null, isAdded: false });
        case ClinicGalleryActionType.ADD_CLINIC_GALLERY_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, isAdded: true });
        case ClinicGalleryActionType.ADD_CLINIC_GALLERY_ERROR:
            return __assign(__assign({}, state), { isLoading: false, error: action.payload, isAdded: false });
        case ClinicGalleryActionType.EDIT_CLINIC_GALLERY_PENDING:
            return __assign(__assign({}, state), { isLoading: true, error: null, isEdited: false });
        case ClinicGalleryActionType.EDIT_CLINIC_GALLERY_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, isEdited: true });
        case ClinicGalleryActionType.EDIT_CLINIC_GALLERY_ERROR:
            return __assign(__assign({}, state), { isLoading: false, error: action.payload, isEdited: false });
        case ClinicGalleryActionType.DELETE_CLINIC_GALLERY_PENDING:
            return __assign(__assign({}, state), { isLoading: true, error: null, isDeleted: false });
        case ClinicGalleryActionType.DELETE_CLINIC_GALLERY_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, isDeleted: true });
        case ClinicGalleryActionType.DELETE_CLINIC_GALLERY_ERROR:
            return __assign(__assign({}, state), { isLoading: false, error: action.payload, isDeleted: false });
        case ClinicGalleryActionType.SET_SELECTED_PHOTO:
            return __assign(__assign({}, state), { isLoading: false, selectedPhoto: action.payload });
        default:
            return state;
    }
};
