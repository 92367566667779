import React from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import classNames from 'classnames';
import { useStyles } from './Footer.jss';
import FacebookWhiteIconSvg from '../../assets/svg_components/FacebookWhiteIconSvg';
import InstagramWhiteIconSvg from '../../assets/svg_components/InstagramWhiteIconSvg';
import AppAppleIconSvg from '../../assets/svg_components/AppAppleIconSvg';
import AppGoogleIconSvg from '../../assets/svg_components/AppGoogleIconSvg';
import VisaIconSvg from '../../assets/svg_components/VisaIconSvg';
import { Link } from 'react-router-dom';
import LIIconSvg from '../../assets/svg_components/LIIconSvg';
import { useTranslation } from 'react-i18next';
export default function Footer() {
    var classes = useStyles();
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    return (React.createElement(Box, { className: classNames(classes.wrapper) },
        React.createElement(Box, { className: classNames(classes.container) },
            React.createElement(List, { className: classNames(classes.list) },
                React.createElement(Typography, { className: classNames(classes.listTitle) }, t('forUsers')),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/".concat(i18n.language, "/cabinet/settings#personalData") }, t('profileBreadcrumbs01'))),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/".concat(i18n.language, "/patient") }, t('menu.main.for-clients'))),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/".concat(i18n.language, "/doctor") }, t('menu.main.for-doctors'))),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/".concat(i18n.language, "/clinics") }, t('menu.main.for-clinic'))),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement(Link, { to: "/".concat(i18n.language, "/news") }, t('menu.main.news'))),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/".concat(i18n.language, "/ehealth") }, "MIC MyHeal"))),
            React.createElement(List, { className: classNames(classes.list) },
                React.createElement(Typography, { className: classNames(classes.listTitle) }, t('menu.footer.resources')),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement(Link, { to: "/".concat(i18n.language, "/page/about-platform") }, t('menu.footer.about-us'))),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement(Link, { to: "/".concat(i18n.language, "/page/policy-payments") }, t('aboutUs'))),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement(Link, { to: "/".concat(i18n.language, "/page/privacy-policy") }, t('menu.footer.privacy-policy'))),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement(Link, { to: "/".concat(i18n.language, "/page/agreement") }, t('menu.static.aboutPlatform')))),
            React.createElement(Box, { className: classNames(classes.contactsBlock) },
                React.createElement(Typography, { className: classNames(classes.listTitle) }, t('addressContacts')),
                React.createElement(Typography, { className: classNames(classes.contactItem) },
                    React.createElement("a", { href: "mailto:info@myheal.com.ua" }, "info@myheal.com.ua")),
                React.createElement(Typography, { className: classNames(classes.contactItem) },
                    React.createElement("a", { href: "tel:+380930594344" }, "+38 (093) 059 43 44")),
                React.createElement(Box, { className: classNames(classes.buttonsBlock) },
                    React.createElement("a", { href: "https://www.facebook.com/myhealua" },
                        React.createElement(Box, { className: classNames(classes.socialIcon) },
                            React.createElement(FacebookWhiteIconSvg, null))),
                    React.createElement("a", { href: "https://www.instagram.com/myheal.com.ua" },
                        React.createElement(Box, { className: classNames(classes.socialIcon) },
                            React.createElement(InstagramWhiteIconSvg, null))),
                    React.createElement("a", { href: "https://www.linkedin.com/showcase/medical-platform-myheal/" },
                        React.createElement(Box, { className: classNames(classes.socialIcon) },
                            React.createElement(LIIconSvg, null))))),
            React.createElement(Box, { className: classNames(classes.appBlock) },
                React.createElement(Typography, { className: classNames(classes.appTitle) }, t('menu.footer.downloadApp')),
                React.createElement(Box, { className: classNames(classes.buttonsBlock) },
                    React.createElement(Box, { sx: { cursor: 'pointer' } },
                        React.createElement("a", { href: "https://apps.apple.com/ua/app/myheal/id1503514770?l=".concat(i18n.language), target: "_blank" },
                            React.createElement(AppAppleIconSvg, null))),
                    React.createElement(Box, { sx: { cursor: 'pointer' } },
                        React.createElement("a", { href: "https://play.google.com/store/apps/details?id=com.moniHeal&hl=".concat(i18n.language), target: "_blank" },
                            React.createElement(AppGoogleIconSvg, null))))),
            React.createElement(Typography, { className: classNames(classes.copyright) },
                "\u00A9 2023-2025 MyHeal. ",
                t('allRightReserved')),
            React.createElement(Box, { className: classNames(classes.visa) },
                React.createElement(VisaIconSvg, null)))));
}
