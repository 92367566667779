export var EhealthMEProcedureAction;
(function (EhealthMEProcedureAction) {
    EhealthMEProcedureAction["ADD_ITEMS"] = "EHEALTH_ME_PROCEDURE_ADD_ITEMS";
    EhealthMEProcedureAction["CLEAR"] = "EHEALTH_ME_PROCEDURE_CLEAR";
    EhealthMEProcedureAction["UPDATE"] = "EHEALTH_ME_PROCEDURE_UPDATE";
    EhealthMEProcedureAction["SET_IS_LOADING"] = "EHEALTH_ME_PROCEDURE_SET_IS_LOADING";
    EhealthMEProcedureAction["SET_FILTER"] = "EHEALTH_ME_PROCEDURE_SET_FILTER";
    EhealthMEProcedureAction["SETUP_JOBS"] = "EHEALTH_ME_PROCEDURE_SETUP_JOBS";
    EhealthMEProcedureAction["REMOVE_JOB"] = "EHEALTH_ME_PROCEDURE_REMOVE_JOB";
})(EhealthMEProcedureAction || (EhealthMEProcedureAction = {}));
