export var ExpertListInitialState = {
    price: {
        min: '',
        max: '',
    },
    bestExperts: [],
    experts: [],
    formFindExpert: {},
    totalExperts: 0,
    company: null,
    companyUsers: [],
    expertsPerPage: 0,
    actions: [],
    favoriteExpertsIds: [],
    numberOfChosenCategories: 0,
    chosenCategoriesNames: null,
};
export var initialState = {
    expertList: ExpertListInitialState,
    selectedPriceFrom: 0,
    selectedPriceTo: 0,
    selectedCity: '',
    limit: 8,
    isLoading: false,
    error: null,
};
