var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import classNames from 'classnames';
import { Size } from '../../../../enum/size';
import { Icon } from '../../../misc/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { useContainer } from '../../../../service/di-container/react/useContainer';
import { SERVICE_SPA_ROUTER } from '../../../../service/router';
import { useHistory, useLocation } from 'react-router-dom';
import { NavType } from '../../../../service/nav/NavType.enum';
import { useStyles } from './DesktopMenu.jss';
export var Item = function (_a) {
    var _b, _c, _d;
    var item = _a.item;
    var _e = __read(useState(false), 2), showChildren = _e[0], setShowChildren = _e[1];
    var _f = useStyles(), item_icon_wrapper = _f.item_icon_wrapper, arrow_rewert = _f.arrow_rewert;
    var t = useTranslation().t;
    var history = useHistory();
    var container = useContainer();
    var routerService = container.require(SERVICE_SPA_ROUTER);
    var pathname = useLocation().pathname;
    var itemUrl = routerService.getUrl(item.link);
    var itemClickHandler = item.navType === NavType.SPA_LINK
        ? function (event) {
            event.preventDefault();
            history.push(itemUrl);
        }
        : ((_b = item.children) === null || _b === void 0 ? void 0 : _b.length)
            ? function (event) {
                event.preventDefault();
                event.stopPropagation();
                setShowChildren(!showChildren);
            }
            : undefined;
    return (React.createElement(React.Fragment, null,
        React.createElement("a", { className: classNames('cabinet-sidebar-nav__item', { active: pathname.endsWith(itemUrl) }, item.class), onClick: itemClickHandler, target: item.target, href: itemUrl },
            item.icon && React.createElement(Icon, { icon: item.icon, outlined: true, size: Size.EXTRA_LARGE }),
            React.createElement("div", { className: item_icon_wrapper },
                t("".concat(item.title)),
                !!((_c = item.children) === null || _c === void 0 ? void 0 : _c.length) && React.createElement(Icon, { icon: 'keyboard_arrow_down', size: Size.EXTRA_LARGE, outlined: true, className: showChildren ? arrow_rewert : '' }))),
        showChildren && (React.createElement("div", null, (_d = item.children) === null || _d === void 0 ? void 0 : _d.map(function (child) {
            return React.createElement(Item, { item: child, key: child.link });
        })))));
};
