import { SERVICE_MOI_BOOKING, bookingFactory } from '../../../service/booking';
import { messageTemplateFactory, SERVICE_MOI_CHAT_MESSAGE_TEMPLATE } from '../../../service/chat/message-template';
import { clientsFactory, SERVICE_MOI_CLIENTS } from '../../../service/clients';
import { consultantRequestFactory, SERVICE_MOI_CONSULTANT_REQUEST } from '../../../service/consultant-request';
import { clientConsultantRequestFactory, SERVICE_MOI_CLIENT_CONSULTANT_REQUEST, } from '../../../service/client-consultant-request';
import { companyClientsFactory, SERVICE_MOI_COMPANY_CLIENTS } from '../../../service/company/clients';
import { SERVICE_MOI_COMPANY_SERVICE, companyServiceFactory } from '../../../service/service';
import { companyFactory, SERVICE_MOI_COMPANY } from '../../../service/company/company';
import { clientTagsFactory, SERVICE_MOI_CLIENT_TAGS } from '../../../service/client-tags';
import { clientDiseasesFactory, SERVICE_MOI_CLIENT_DISEASES } from '../../../service/client-diseases';
import { companyExpertsFactory, SERVICE_MOI_COMPANY_EXPERTS } from '../../../service/company/experts';
import { companyExpertScheduleFactory, SERVICE_MOI_COMPANY_EXPERT_SCHEDULE, } from '../../../service/company/expert-schedule';
import { companyServicesFactory, SERVICE_MOI_COMPANY_SERVICES } from '../../../service/company/services';
import { expertsScheduleFactory, SERVICE_MOI_EXPERTS_SCHEDULE } from '../../../service/experts-schedule';
import { emailMessageTemplateFactory, SERVICE_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE, } from '../../../service/chat/email-message-template';
import { emailMessageTaskFactory, SERVICE_MOI_CHAT_EMAIL_MESSAGE_TASK } from '../../../service/chat/email-message-task';
import { messageTemplateSendFactory, SERVICE_MOI_CHAT_MESSAGE_TEMPLATE_SEND, } from '../../../service/chat/message-template-send';
import { referenceFactory, SERVICE_MOI_REFERENCE } from '../../../service/reference';
import { legalEntityFactory, SERVICE_MOI_LEGAL_ENTITY } from '../../../service/company/legal-entity';
import { SERVICE_MOI_WARDS, wardsFactory } from '../../../service/wards';
import { SERVICE_MOI_CLIENT_BOOKINGS, clientBookingsFactory } from '../../../service/client-bookings';
import { SERVICE_MOI_DATA_ARCHIVE, dataArchiveFactory } from '../../../service/data-archive';
import { SERVICE_MOI_EXPERT_SCHEDULE_OF_APPOINTMENTS, expertScheduleOfAppointmentsFactory } from '../../../service/expert-schedule-of-appointments';
export var registerMoiServices = function (container) {
    container.register(SERVICE_MOI_BOOKING, bookingFactory);
    container.register(SERVICE_MOI_WARDS, wardsFactory);
    container.register(SERVICE_MOI_DATA_ARCHIVE, dataArchiveFactory);
    container.register(SERVICE_MOI_CLIENT_BOOKINGS, clientBookingsFactory);
    container.register(SERVICE_MOI_CHAT_MESSAGE_TEMPLATE, messageTemplateFactory);
    container.register(SERVICE_MOI_CHAT_MESSAGE_TEMPLATE_SEND, messageTemplateSendFactory);
    container.register(SERVICE_MOI_CHAT_EMAIL_MESSAGE_TEMPLATE, emailMessageTemplateFactory);
    container.register(SERVICE_MOI_CHAT_EMAIL_MESSAGE_TASK, emailMessageTaskFactory);
    container.register(SERVICE_MOI_CLIENTS, clientsFactory);
    container.register(SERVICE_MOI_CLIENT_TAGS, clientTagsFactory);
    container.register(SERVICE_MOI_CLIENT_DISEASES, clientDiseasesFactory);
    container.register(SERVICE_MOI_CONSULTANT_REQUEST, consultantRequestFactory);
    container.register(SERVICE_MOI_CLIENT_CONSULTANT_REQUEST, clientConsultantRequestFactory);
    container.register(SERVICE_MOI_COMPANY_CLIENTS, companyClientsFactory);
    container.register(SERVICE_MOI_COMPANY_EXPERTS, companyExpertsFactory);
    container.register(SERVICE_MOI_COMPANY, companyFactory);
    container.register(SERVICE_MOI_LEGAL_ENTITY, legalEntityFactory);
    container.register(SERVICE_MOI_COMPANY_SERVICE, companyServiceFactory);
    container.register(SERVICE_MOI_COMPANY_EXPERT_SCHEDULE, companyExpertScheduleFactory);
    container.register(SERVICE_MOI_COMPANY_SERVICES, companyServicesFactory);
    container.register(SERVICE_MOI_EXPERTS_SCHEDULE, expertsScheduleFactory);
    container.register(SERVICE_MOI_REFERENCE, referenceFactory);
    container.register(SERVICE_MOI_EXPERT_SCHEDULE_OF_APPOINTMENTS, expertScheduleOfAppointmentsFactory);
};
