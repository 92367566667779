var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { Avatar, Button, Typography, useMediaQuery } from '@mui/material';
import { useStyles } from '../HeaderCabinetStyle';
import ArrowDownIconSvg from '../assets/ArrowDownIconSvg';
import ArrowUpIconSvg from '../assets/ArrowUpIconSvg';
import { useSelector } from 'react-redux';
import { PersonalInfoSelector } from '../../../../../app/moi/store/user/personalInfo/selector';
import { ClinicInfoSelector } from '../../../../../app/moi/store/clinic/clinicInfo/selector';
import { CabinetSelector } from '../../../../../app/moi/store/cabinet/selector';
import AvatarM1 from '../assets/Avatars/AvatarM1.svg';
import AvatarM2 from '../assets/Avatars/AvatarM2.svg';
import AvatarM3 from '../assets/Avatars/AvatarM3.svg';
import AvatarM4 from '../assets/Avatars/AvatarM4.svg';
import AvatarF1 from '../assets/Avatars/AvatarF1.svg';
import AvatarF2 from '../assets/Avatars/AvatarF2.svg';
import AvatarF3 from '../assets/Avatars/AvatarF3.svg';
import AvatarF4 from '../assets/Avatars/AvatarF4.svg';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
var maleAvatars = [AvatarM1, AvatarM2, AvatarM3, AvatarM4];
var femaleAvatars = [AvatarF1, AvatarF2, AvatarF3, AvatarF4];
export var CabinetProfile = function (_a) {
    var _b, _c;
    var isOpen = _a.isOpen, handleClick = _a.handleClick, id = _a.id;
    var classes = useStyles();
    var matches768 = useMediaQuery('(max-width:768px)');
    var personalInfo = useSelector(PersonalInfoSelector);
    var clinicInfo = useSelector(ClinicInfoSelector).clinic;
    var cabinetType = useSelector(CabinetSelector);
    var _d = __read(useState(null), 2), randomAvatar = _d[0], setRandomAvatar = _d[1];
    var t = useTranslation().t;
    function randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    function setRandomAvatarBySex(sex) {
        return sex === 'male' ? maleAvatars[randomIntFromInterval(0, 3)] : femaleAvatars[randomIntFromInterval(0, 3)];
    }
    useEffect(function () {
        function getAvatarFromCoockie() {
            var findAvatarInCoockie = Cookies.get('avatar');
            if (personalInfo.sex && !findAvatarInCoockie) {
                var newRandomAvatar = setRandomAvatarBySex(personalInfo.sex);
                Cookies.set('avatar', newRandomAvatar);
                setRandomAvatar(newRandomAvatar);
                return;
            }
            else
                setRandomAvatar(findAvatarInCoockie);
            return;
        }
        getAvatarFromCoockie();
    }, [personalInfo]);
    return (React.createElement(Button, { "aria-describedby": id, className: classes.CabinetWrapper, onClick: handleClick, classes: { endIcon: classes.buttonEndIcon }, endIcon: matches768 ? null : isOpen ? React.createElement(ArrowUpIconSvg, null) : React.createElement(ArrowDownIconSvg, null) },
        cabinetType === 'clinic' ? (React.createElement(Avatar, { sx: { marginRight: '10px', borderRadius: '50%', width: '32px', height: '32px', color: '#173236' }, src: (_b = clinicInfo.company_logo) !== null && _b !== void 0 ? _b : undefined })) : (React.createElement(Avatar, { sx: { marginRight: '10px', borderRadius: '50%', width: '32px', height: '32px', color: '#173236' }, src: (_c = personalInfo.avatar) !== null && _c !== void 0 ? _c : randomAvatar })),
        !matches768 && React.createElement(Typography, { className: classes.customTypography }, t('profileBreadcrumbs01'))));
};
