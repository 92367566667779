import { DiStrategy } from './enum/di-strategy';
var Container = /** @class */ (function () {
    function Container() {
        var _this = this;
        this.configure = function (key) {
            var _a = _this.registered[key], strategy = _a.strategy, callback = _a.callback;
            if (typeof callback !== 'function') {
                return undefined;
            }
            switch (strategy) {
                case DiStrategy.PROTOTYPE:
                    return callback(_this);
                case DiStrategy.SINGLETON:
                    _this.configured[key] = callback(_this);
                    return _this.configured[key];
            }
        };
        this.get = function (key, defaultValue) {
            if (defaultValue === void 0) { defaultValue = null; }
            if (_this.configured[key]) {
                return _this.configured[key];
            }
            else if (_this.registered[key]) {
                return _this.configure(key);
            }
            return defaultValue;
        };
        this.register = function (key, callback, strategy) {
            if (strategy === void 0) { strategy = DiStrategy.SINGLETON; }
            if (_this.configured[key] || _this.registered[key]) {
                throw new Error("Service ".concat(key, " already registered"));
            }
            else if (strategy === DiStrategy.STATIC) {
                _this.configured[key] = callback;
                return _this;
            }
            _this.registered[key] = {
                strategy: strategy,
                callback: callback,
            };
            return _this;
        };
        this.configured = {};
        this.registered = {};
    }
    Container.prototype.require = function (key) {
        var resolved = this.get(key, undefined);
        if (typeof resolved === 'undefined') {
            throw new Error("Required key ".concat(key, " was not registered"));
        }
        return resolved;
    };
    return Container;
}());
export { Container };
