import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
import { SERVICE_LOCALIZE } from '../../../../../shared/service/localize';
import { PARAM_EHEALTH_PAYLOAD_FIND_SINGLE_URL, PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_STORAGE_PROXY_URL, } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
import { SERVICE_EHEALTH_SIGN } from '../signature';
import { DeviceRequestService } from './device-request';
export var SERVICE_EHEALTH_DEVICE_REQUEST = 'SERVICE_EHEALTH_DEVICE_REQUEST';
export var ehealthDeviceRequestFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var storageUrl = "".concat(container.get(PARAM_EHEALTH_STORAGE_PROXY_URL));
    var payloadFindStoredUrl = "".concat(container.get(PARAM_EHEALTH_PAYLOAD_FIND_SINGLE_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var signService = container.require(SERVICE_EHEALTH_SIGN);
    var localizeService = container.require(SERVICE_LOCALIZE);
    return new DeviceRequestService({
        baseUrl: baseUrl,
        storageUrl: storageUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        signService: signService,
        payloadFindStoredUrl: payloadFindStoredUrl,
        localizeService: localizeService,
    });
};
