import { Box, Button, TextField, Typography } from '@mui/material';
import React from 'react';
import classNames from 'classnames';
import ConfirmPhoneIconSvg from '../../../../assets/svg_components/ConfirmPhoneIconSvg';
import { useStyles } from './RegisterComplete.jss';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { fetchRegisterComplete } from '../../../../store/register/userRegister/register';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export default function RegisterComplete() {
    var classes = useStyles();
    var registerError = useSelector(function (state) { return state.moi.register.userRegister.error; });
    var phone = useSelector(function (state) { return state.moi.register.userRegister.phone; });
    var dispatch = useDispatch();
    var history = useHistory();
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var initialValue = {
        code: '',
    };
    var codeRegex = /^[0-9]*$/;
    var requiredMessage = t('mustBeSixDigits');
    var CodeSchema = Yup.object().shape({
        code: Yup.string()
            .required(requiredMessage)
            .matches(codeRegex, requiredMessage)
            .min(6, requiredMessage)
            .max(6, requiredMessage),
    });
    var handleRegisterComplete = function (_a, props) {
        var code = _a.code;
        dispatch(fetchRegisterComplete({ code: code, phone: phone, history: history, locale: i18n.language }));
        return;
    };
    return (React.createElement(Box, { className: classNames(classes.wrapper) },
        React.createElement(ConfirmPhoneIconSvg, null),
        React.createElement(Typography, { className: classNames(classes.title) }, t('register.successMessage')),
        React.createElement(Typography, { className: classNames(classes.subtitle) }, t('register.sms.sent')),
        React.createElement(Typography, { className: classNames(classes.subtitle) }, t('register.sms.description')),
        React.createElement(Formik, { onSubmit: handleRegisterComplete, initialValues: initialValue, validationSchema: CodeSchema }, function (props) {
            var code = props.values.code;
            return (React.createElement(Form, null,
                React.createElement(Field, { as: TextField, placeholder: "______", id: "code", name: "code", value: code, inputProps: { maxLength: 6 }, className: classNames(classes.input), InputLabelProps: { shrink: false } }),
                props.errors.code && (React.createElement(Typography, { className: classNames(classes.inputError) }, t(props.errors.code))),
                registerError && (React.createElement(Box, { className: classNames(classes.registerError) },
                    React.createElement(Typography, { className: classNames(classes.registerErrorTitle) }, t(registerError)))),
                React.createElement(Button, { type: "submit", className: classNames(classes.submitButton) }, t('cabinetDocAlertConnect'))));
        })));
}
