var _a;
import { EmployeeType } from '../enum/employee.type';
export var EMPLOYEE_TYPE_TO_POSITION = (_a = {},
    _a[EmployeeType.ADMIN] = {
        P5: true,
        P6: true,
        P14: true,
        P18: true,
        P19: true,
    },
    _a[EmployeeType.ASSISTANT] = {
        P17: true,
        P66: true,
        P169: true,
        P170: true,
        P171: true,
        P173: true,
        P174: true,
        P175: true,
        P176: true,
        P177: true,
        P178: true,
        P179: true,
        P180: true,
        P181: true,
        P182: true,
        P183: true,
        P184: true,
        P185: true,
        P186: true,
        P187: true,
        P188: true,
        P189: true,
        P190: true,
        P191: true,
        P192: true,
        P193: true,
        P194: true,
        P195: true,
        P196: true,
        P197: true,
        P198: true,
        P199: true,
        P200: true,
        P201: true,
        P202: true,
        P203: true,
        P204: true,
        P205: true,
        P206: true,
        P207: true,
        P208: true,
        P209: true,
        P210: true,
        P211: true,
        P212: true,
        P213: true,
        P214: true,
        P215: true,
        P216: true,
        P250: true,
        P251: true,
        P252: true,
        P253: true,
        P256: true,
    },
    _a[EmployeeType.DOCTOR] = {
        P7: true,
        P8: true,
        P9: true,
        P10: true,
        P11: true,
    },
    _a[EmployeeType.HR] = {
        P14: true,
    },
    _a[EmployeeType.OWNER] = {
        P1: true,
        P2: true,
        P3: true,
        P4: true,
        P5: true,
        P6: true,
        P18: true,
        P19: true,
        P22: true,
        P23: true,
        P24: true,
        P25: true,
        P26: true,
        P32: true,
        P229: true,
        P230: true,
        P231: true,
        P232: true,
        P233: true,
        P234: true,
        P235: true,
        P236: true,
        P237: true,
        P238: true,
        P239: true,
        P240: true,
        P247: true,
        P249: true,
        P257: true,
    },
    _a[EmployeeType.PHARMACY_OWNER] = {
        P1: true,
        P2: true,
        P3: true,
        P4: true,
        P5: true,
        P6: true,
        P18: true,
        P19: true,
        P22: true,
        P23: true,
        P24: true,
        P25: true,
        P26: true,
        P32: true,
        P229: true,
        P230: true,
        P231: true,
        P232: true,
        P233: true,
        P234: true,
        P235: true,
        P236: true,
        P237: true,
        P238: true,
        P239: true,
        P240: true,
        P247: true,
        P249: true,
        P257: true,
    },
    _a[EmployeeType.PHARMACIST] = {
        P16: true,
        P20: true,
        P21: true,
        P217: true,
        P218: true,
        P219: true,
        P220: true,
        P221: true,
        P222: true,
        P223: true,
    },
    _a[EmployeeType.RECEPTIONIST] = {
        P15: true,
    },
    _a[EmployeeType.SPECIALIST] = {
        P5: true,
        P6: true,
        P8: true,
        P9: true,
        P10: true,
        P11: true,
        P12: true,
        P13: true,
        P33: true,
        P34: true,
        P35: true,
        P36: true,
        P37: true,
        P38: true,
        P39: true,
        P40: true,
        P41: true,
        P42: true,
        P43: true,
        P44: true,
        P45: true,
        P46: true,
        P47: true,
        P48: true,
        P49: true,
        P50: true,
        P51: true,
        P52: true,
        P53: true,
        P54: true,
        P55: true,
        P56: true,
        P57: true,
        P58: true,
        P59: true,
        P60: true,
        P61: true,
        P62: true,
        P63: true,
        P64: true,
        P65: true,
        P66: true,
        P67: true,
        P68: true,
        P69: true,
        P70: true,
        P71: true,
        P72: true,
        P73: true,
        P74: true,
        P75: true,
        P76: true,
        P77: true,
        P78: true,
        P79: true,
        P80: true,
        P81: true,
        P82: true,
        P83: true,
        P84: true,
        P85: true,
        P86: true,
        P87: true,
        P88: true,
        P89: true,
        P90: true,
        P91: true,
        P92: true,
        P93: true,
        P94: true,
        P95: true,
        P96: true,
        P97: true,
        P98: true,
        P99: true,
        P100: true,
        P101: true,
        P102: true,
        P103: true,
        P104: true,
        P105: true,
        P106: true,
        P107: true,
        P108: true,
        P109: true,
        P110: true,
        P111: true,
        P112: true,
        P113: true,
        P114: true,
        P115: true,
        P116: true,
        P117: true,
        P118: true,
        P119: true,
        P120: true,
        P121: true,
        P122: true,
        P123: true,
        P124: true,
        P125: true,
        P126: true,
        P127: true,
        P128: true,
        P129: true,
        P130: true,
        P131: true,
        P132: true,
        P133: true,
        P134: true,
        P135: true,
        P136: true,
        P137: true,
        P138: true,
        P139: true,
        P140: true,
        P141: true,
        P142: true,
        P143: true,
        P144: true,
        P145: true,
        P146: true,
        P147: true,
        P148: true,
        P149: true,
        P150: true,
        P151: true,
        P152: true,
        P153: true,
        P154: true,
        P155: true,
        P156: true,
        P157: true,
        P158: true,
        P159: true,
        P160: true,
        P161: true,
        P162: true,
        P163: true,
        P164: true,
        P165: true,
        P166: true,
        P167: true,
        P228: true,
        P248: true,
        P245: true,
        P258: true,
        P266: true,
        P267: true,
        P268: true,
        P269: true,
        P270: true,
    },
    _a[EmployeeType.MED_ADMIN] = {
        P5: true,
        P6: true,
        P7: true,
        P8: true,
        P9: true,
        P10: true,
        P11: true,
        P12: true,
        P13: true,
        P33: true,
        P34: true,
        P35: true,
        P36: true,
        P37: true,
        P38: true,
        P39: true,
        P40: true,
        P41: true,
        P42: true,
        P43: true,
        P44: true,
        P45: true,
        P46: true,
        P47: true,
        P48: true,
        P49: true,
        P50: true,
        P51: true,
        P52: true,
        P53: true,
        P54: true,
        P55: true,
        P56: true,
        P57: true,
        P58: true,
        P59: true,
        P60: true,
        P61: true,
        P62: true,
        P63: true,
        P64: true,
        P65: true,
        P66: true,
        P67: true,
        P68: true,
        P69: true,
        P70: true,
        P71: true,
        P72: true,
        P73: true,
        P74: true,
        P75: true,
        P76: true,
        P77: true,
        P78: true,
        P79: true,
        P80: true,
        P81: true,
        P82: true,
        P83: true,
        P84: true,
        P85: true,
        P86: true,
        P87: true,
        P88: true,
        P89: true,
        P90: true,
        P91: true,
        P92: true,
        P93: true,
        P94: true,
        P95: true,
        P96: true,
        P97: true,
        P98: true,
        P99: true,
        P100: true,
        P101: true,
        P102: true,
        P103: true,
        P104: true,
        P105: true,
        P106: true,
        P107: true,
        P108: true,
        P109: true,
        P110: true,
        P111: true,
        P112: true,
        P113: true,
        P114: true,
        P115: true,
        P116: true,
        P117: true,
        P118: true,
        P119: true,
        P120: true,
        P121: true,
        P122: true,
        P123: true,
        P124: true,
        P125: true,
        P126: true,
        P127: true,
        P128: true,
        P129: true,
        P130: true,
        P131: true,
        P132: true,
        P133: true,
        P134: true,
        P135: true,
        P136: true,
        P137: true,
        P138: true,
        P139: true,
        P140: true,
        P141: true,
        P142: true,
        P143: true,
        P144: true,
        P145: true,
        P146: true,
        P147: true,
        P148: true,
        P149: true,
        P150: true,
        P151: true,
        P152: true,
        P153: true,
        P154: true,
        P155: true,
        P156: true,
        P157: true,
        P158: true,
        P159: true,
        P160: true,
        P161: true,
        P162: true,
        P163: true,
        P164: true,
        P165: true,
        P166: true,
        P167: true,
        P228: true,
        P248: true,
        P245: true,
        P258: true,
        P266: true,
        P267: true,
        P268: true,
        P269: true,
        P270: true,
        P1: true,
        P2: true,
        P3: true,
        P4: true,
        P23: true,
        P24: true,
        P25: true,
        P26: true,
        P32: true,
        P229: true,
        P230: true,
        P231: true,
        P249: true,
        P257: true,
    },
    _a[EmployeeType.LABORANT] = {
        P17: true,
        P170: true,
        P173: true,
        P241: true,
        P242: true,
        P243: true,
        P244: true,
        P251: true,
        P256: true,
        P271: true,
        P272: true,
        P273: true,
        P274: true,
        P276: true,
        P277: true,
        P278: true,
        P279: true,
    },
    _a[EmployeeType.MED_COORDINATOR] = {
        P280: true,
    },
    _a);
