import { PARAM_ITEMS_PER_PAGE } from '../../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PERSON_REQUEST_SAVE, PARAM_EHEALTH_PERSON_REQUEST_SAVE_PERSON_ID, PARAM_EHEALTH_PERSON_REQUEST_SINGLE, PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_STORAGE_PROXY_URL, } from '../../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../../request';
import { SERVICE_EHEALTH_SIGN } from '../../signature';
import { PersonRequestService } from './person-request';
export var SERVICE_EHEALTH_PERSON_REQUEST = 'SERVICE_EHEALTH_PERSON_REQUEST';
export var ehealthPersonRequestFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var signService = container.require(SERVICE_EHEALTH_SIGN);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var storageUrl = "".concat(container.get(PARAM_EHEALTH_STORAGE_PROXY_URL));
    var savePersonRequestUrl = "".concat(container.get(PARAM_EHEALTH_PERSON_REQUEST_SAVE));
    var singlePersonRequestUrl = "".concat(container.get(PARAM_EHEALTH_PERSON_REQUEST_SINGLE));
    var savePersonRequestPersonIdUrl = "".concat(container.get(PARAM_EHEALTH_PERSON_REQUEST_SAVE_PERSON_ID));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    return new PersonRequestService({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        signService: signService,
        storageUrl: storageUrl,
        savePersonRequestUrl: savePersonRequestUrl,
        singlePersonRequestUrl: singlePersonRequestUrl,
        savePersonRequestPersonIdUrl: savePersonRequestPersonIdUrl,
    });
};
