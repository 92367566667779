import { PARAM_ITEMS_PER_PAGE } from '../../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PAYLOAD_STORE_CREATE_URL, PARAM_EHEALTH_PAYLOAD_STORE_DELETE_URL, PARAM_EHEALTH_PAYLOAD_STORE_LIST_URL, PARAM_EHEALTH_PAYLOAD_STORE_SINGLE_URL, PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_STORAGE_PROXY_URL, } from '../../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../../request';
import { MedicationRequestRequestService } from './medication-request-request';
import { SERVICE_EHEALTH_SIGN } from '../../signature';
export var SERVICE_EHEALTH_MEDICATION_REQUEST_REQUEST = 'SERVICE_EHEALTH_MEDICATION_REQUEST_REQUEST';
export var ehealthMedicationRequestRequestFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var storageUrl = "".concat(container.get(PARAM_EHEALTH_STORAGE_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var signService = container.require(SERVICE_EHEALTH_SIGN);
    var payloadStoreSingleUrl = "".concat(container.get(PARAM_EHEALTH_PAYLOAD_STORE_SINGLE_URL));
    var payloadStoreCreateUrl = "".concat(container.get(PARAM_EHEALTH_PAYLOAD_STORE_CREATE_URL));
    var payloadStoreDeleteUrl = "".concat(container.get(PARAM_EHEALTH_PAYLOAD_STORE_DELETE_URL));
    var payloadStoreListUrl = "".concat(container.get(PARAM_EHEALTH_PAYLOAD_STORE_LIST_URL));
    return new MedicationRequestRequestService({
        baseUrl: baseUrl,
        storageUrl: storageUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        payloadStoreCreateUrl: payloadStoreCreateUrl,
        payloadStoreDeleteUrl: payloadStoreDeleteUrl,
        payloadStoreListUrl: payloadStoreListUrl,
        payloadStoreSingleUrl: payloadStoreSingleUrl,
        signService: signService
    });
};
