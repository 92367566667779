export var PatientsTemplateAction;
(function (PatientsTemplateAction) {
    PatientsTemplateAction["ADD_ITEMS"] = "ADD_PATIENTS_ITEMS";
    PatientsTemplateAction["SET_IS_LOADING"] = "MOI_PATIENTS_TEMPLATE_SET_IS_LOADING";
    PatientsTemplateAction["SET_FILTER"] = "MOI_PATIENTS_TEMPLATE_SET_FILTER";
    PatientsTemplateAction["REMOVE"] = "MOI_PATIENTS_TEMPLATE_REMOVE";
    PatientsTemplateAction["ATTACH_TAG"] = "MOI_PATIENTS_TEMPLATE_ATTACH_TAG";
    PatientsTemplateAction["DETACH_TAG"] = "MOI_PATIENTS_TEMPLATE_DETACH_TAG";
    PatientsTemplateAction["UPDATE_TAG"] = "MOI_PATIENTS_TEMPLATE_UPDATE_TAG";
    PatientsTemplateAction["ATTACH_DISEASE"] = "MOI_PATIENTS_TEMPLATE_ATTACH_DISEASE";
    PatientsTemplateAction["DETACH_DISEASE"] = "MOI_PATIENTS_TEMPLATE_DETACH_DISEASE";
    PatientsTemplateAction["UPDATE_DISEASE"] = "MOI_PATIENTS_TEMPLATE_UPDATE_DISEASE";
})(PatientsTemplateAction || (PatientsTemplateAction = {}));
