import { connect } from 'react-redux';
import { NavPlace } from '../../../../service/nav/NavPlace.enum';
import { SideBarMenuDesktop } from '../SideBarDesktop/SideBarMenuDesktop';
function mapStateToProps(state) {
    var menu = state.nav.menus[NavPlace.DESKTOP];
    var subMenus = state.nav.subMenus[NavPlace.DESKTOP];
    var subMenusItems = [];
    var subMenusParents = Array.from((subMenus === null || subMenus === void 0 ? void 0 : subMenus.keys()) || []);
    var parentsSubMenus = Array.from((subMenus === null || subMenus === void 0 ? void 0 : subMenus.values()) || []).map(function (i) {
        // @ts-ignore
        return Array.from((i === null || i === void 0 ? void 0 : i.values()) || []);
    });
    subMenusParents.map(function (item, index) {
        return subMenusItems.push({
            parentMenuItem: item,
            subMenuItems: parentsSubMenus[index]
        });
    });
    return {
        // don`t use spread operator here
        items: Array.from((menu === null || menu === void 0 ? void 0 : menu.values()) || []),
        subMenusItems: subMenusItems,
    };
}
export var ConnectedDesktopMenu = connect(mapStateToProps)(SideBarMenuDesktop);
