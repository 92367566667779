import { PARAM_ITEMS_IN_AUTOCOMPLETE } from '../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL } from '../../../config/di/params/keys';
import { Settlement } from './settlement';
export var SERVICE_EHEALTH_SETTLEMENT = 'SERVICE_EHEALTH_SETTLEMENT';
export var ehealthSettlementServiceFactory = function (container) {
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsInAutocomplete = +(container.get(PARAM_ITEMS_IN_AUTOCOMPLETE) || 0);
    return new Settlement({
        baseUrl: baseUrl,
        itemsInAutocomplete: itemsInAutocomplete,
    });
};
