/// <reference path='main.d.ts'/>
import React, { Suspense, useLayoutEffect } from 'react';
import ReactDom from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { JssProvider } from 'react-jss';
// DI
import { DiContext } from './shared/service/di-container/react/context';
import { useContainer } from './shared/service/di-container/react/useContainer';
import { configureParams } from './config/di/params';
import { configureServices } from './config/di/services';
// setup
import { setupI18next } from './setup/i18next';
import { setupJss } from './setup/jss';
import { store } from './shared/store';
// basic screens
import { Splash } from './shared/screen/Splash';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ConnectedBasePage } from './shared/components/layout/Page/ConnectedBasePage';
import { StyledEngineProvider } from '@mui/material';
import ThemeConfig from './app/theme/ThemeConfig';
import { configureRoutes } from './app/moi/config/routes';
import { SERVICE_SPA_ROUTER } from './shared/service/router';
import { RoutesSwitch } from './RoutesSwitch';
import { configureAppRouting } from './app/ehealth/config/routes';
setupI18next("".concat(window.__moni.pageConfig.localize.loadLocaleUrl));
var jss = setupJss();
var Root = function () {
    var container = useContainer();
    useLayoutEffect(function () {
        configureParams(container);
        configureServices(container);
        var routerService = container.require(SERVICE_SPA_ROUTER);
        if (routerService) {
            // Configure Moi routes
            configureRoutes(routerService);
            // Configure E-Health routes
            configureAppRouting(routerService);
        }
    }, []);
    return (React.createElement(JssProvider, { jss: jss },
        React.createElement(DiContext.Provider, { value: container },
            React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                React.createElement(StyledEngineProvider, { injectFirst: true },
                    React.createElement(ThemeConfig, null,
                        React.createElement(BrowserRouter, null,
                            React.createElement(Suspense, { fallback: React.createElement(Splash, null) },
                                React.createElement(Provider, { store: store },
                                    React.createElement(ConnectedBasePage, null,
                                        React.createElement(RoutesSwitch, null)))))))))));
};
document.addEventListener('DOMContentLoaded', function () {
    var root = document.querySelector('#spa-app');
    ReactDom.render(React.createElement(Root, null), root);
});
