var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultState } from './state';
import { EhealthPSMedicalStatementsAction } from './enum/action';
export function medicalStatementsReducer(state, action) {
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case EhealthPSMedicalStatementsAction.ADD_ITEMS:
            return __assign(__assign({}, state), { items: __assign(__assign({}, state.items), action.payload.items), page: action.payload.page, totalCount: action.payload.totalCount, totalPages: action.payload.totalPages, isLoading: false });
        case EhealthPSMedicalStatementsAction.SET_IS_LOADING:
            return __assign(__assign({}, state), { isLoading: !!action.payload.isLoading });
        case EhealthPSMedicalStatementsAction.UPDATE:
            if (!state.items[action.payload.item.id]) {
                return state;
            }
            state.items[action.payload.item.id] = action.payload.item;
            return __assign(__assign({}, state), { items: __assign({}, state.items) });
    }
    return state;
}
