export var Resolution;
(function (Resolution) {
    Resolution[Resolution["DESKTOP"] = 1440] = "DESKTOP";
    Resolution[Resolution["LAPTOP_M"] = 1200] = "LAPTOP_M";
    Resolution[Resolution["LAPTOP"] = 1024] = "LAPTOP";
    Resolution[Resolution["TABLET"] = 992] = "TABLET";
    Resolution[Resolution["MOBILE"] = 767] = "MOBILE";
    Resolution[Resolution["MOBILE_L"] = 575] = "MOBILE_L";
    Resolution[Resolution["MOBILE_M"] = 425] = "MOBILE_M";
})(Resolution || (Resolution = {}));
;
