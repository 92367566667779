var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import React from 'react';
import { createRemoveNotificationAction } from '../../../store/notification/factory';
import { NotificationList } from '../Popup/Notification/List/List';
var mapStateToProps = function (state, ownProps) {
    return __assign(__assign({}, ownProps), { notifications: state.notification.notifications });
};
var mapDispatchToProps = function (dispatch, ownProps) {
    return __assign(__assign({}, ownProps), { removeNotification: function (uid) {
            dispatch(createRemoveNotificationAction(uid));
        } });
};
var ConnectedList = connect(mapStateToProps, mapDispatchToProps)(NotificationList);
export var ConnectedNotificationList = function () { return createPortal(React.createElement(ConnectedList, null), document.body); };
