var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChargesAction, WardsActionType } from './action';
import { defaultState } from './state';
export var ChargesReducer = function (state, action) {
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case WardsActionType.GET_WARDS_PENDING:
            return __assign({}, state);
        case WardsActionType.GET_WARDS_SUCCESS:
            return __assign(__assign({}, state), { items: action.payload });
        case WardsActionType.GET_WARDS_ERROR:
            return __assign({}, state);
        case WardsActionType.ADD_WARD_PENDING:
            return __assign(__assign({}, state), { isWardAdded: false });
        case WardsActionType.ADD_WARD_MODAL_CLOSE:
            return __assign(__assign({}, state), { isWardAdded: false });
        case WardsActionType.ADD_WARD_SUCCESS:
            return __assign(__assign({}, state), { isWardAdded: true });
        case WardsActionType.ADD_WARD_ERROR:
            return __assign(__assign({}, state), { isWardAdded: false });
        case WardsActionType.EDIT_WARD_PENDING:
            return __assign(__assign({}, state), { isWardEdited: false });
        case WardsActionType.EDIT_WARD_SUCCESS:
            return __assign(__assign({}, state), { isWardEdited: true });
        case WardsActionType.EDIT_WARD_ERROR:
            return __assign(__assign({}, state), { isWardEdited: false });
        case ChargesAction.INIT:
            return __assign(__assign({}, state), { items: __assign({}, action.payload.items) });
        case ChargesAction.ADD_ITEM:
        case ChargesAction.UPDATE_ITEM:
            return __assign(__assign({}, state), { items: __assign(__assign({}, state.items), action.payload.items) });
        case ChargesAction.REMOVE_ITEM:
            var next = __assign({}, state);
            delete next.items[action.payload.id];
            return next;
    }
    return state;
};
