export var newsListActionType;
(function (newsListActionType) {
    newsListActionType["GET_LAST_NEWS_LIST_PENDING"] = "GET_LAST_NEWS_LIST_PENDING";
    newsListActionType["GET_LAST_NEWS_LIST_SUCCESS"] = "GET_LAST_NEWS_LIST_SUCCESS";
    newsListActionType["GET_LAST_NEWS_LIST_ERROR"] = "GET_LAST_NEWS_LIST_ERROR";
    newsListActionType["GET_LAST_NEWS_LIST_LOAD_MORE_PENDING"] = "GET_LAST_NEWS_LIST_LOAD_MORE_PENDING";
    newsListActionType["GET_LAST_NEWS_LIST_LOAD_MORE_SUCCESS"] = "GET_LAST_NEWS_LIST_LOAD_MORE_SUCCESS";
    newsListActionType["GET_LAST_NEWS_LIST_LOAD_MORE_ERROR"] = "GET_LAST_NEWS_LIST_LOAD_MORE_ERROR";
    newsListActionType["GET_POPULAR_NEWS_LIST_PENDING"] = "GET_POPULAR_NEWS_LIST_PENDING";
    newsListActionType["GET_POPULAR_NEWS_LIST_SUCCESS"] = "GET_POPULAR_NEWS_LIST_SUCCESS";
    newsListActionType["GET_POPULAR_NEWS_LIST_ERROR"] = "GET_POPULAR_NEWS_LIST_ERROR";
    newsListActionType["GET_POPULAR_NEWS_LIST_LOAD_MORE_PENDING"] = "GET_POPULAR_NEWS_LIST_LOAD_MORE_PENDING";
    newsListActionType["GET_POPULAR_NEWS_LIST_LOAD_MORE_SUCCESS"] = "GET_POPULAR_NEWS_LIST_LOAD_MORE_SUCCESS";
    newsListActionType["GET_POPULAR_NEWS_LIST_LOAD_MORE_ERROR"] = "GET_POPULAR_NEWS_LIST_LOAD_MORE_ERROR";
    newsListActionType["GET_RECOMMENDED_NEWS_LIST_PENDING"] = "GET_RECOMMENDED_NEWS_LIST_PENDING";
    newsListActionType["GET_RECOMMENDED_NEWS_LIST_SUCCESS"] = "GET_RECOMMENDED_NEWS_LIST_SUCCESS";
    newsListActionType["GET_RECOMMENDED_NEWS_LIST_ERROR"] = "GET_RECOMMENDED_NEWS_LIST_ERROR";
    newsListActionType["GET_RECOMMENDED_NEWS_LIST_LOAD_MORE_PENDING"] = "GET_RECOMMENDED_NEWS_LIST_LOAD_MORE_PENDING";
    newsListActionType["GET_RECOMMENDED_NEWS_LIST_LOAD_MORE_SUCCESS"] = "GET_RECOMMENDED_NEWS_LIST_LOAD_MORE_SUCCESS";
    newsListActionType["GET_RECOMMENDED_NEWS_LIST_LOAD_MORE_ERROR"] = "GET_RECOMMENDED_NEWS_LIST_LOAD_MORE_ERROR";
})(newsListActionType || (newsListActionType = {}));
