import { PARAM_MOI_COMPANY_LEGAL_ENTITY_SAVE_URL, PARAM_MOI_COMPANY_LEGAL_ENTITY_GET_URL, } from '../../../config/di/params/keys';
import { LegalEntity } from './legal-entity';
import { SERVICE_EHEALTH_SIGN } from '../../../../ehealth/service/ehealth/signature';
export var SERVICE_MOI_LEGAL_ENTITY = 'SERVICE_MOI_LEGAL_ENTITY';
export function legalEntityFactory(container) {
    var signService = container.require(SERVICE_EHEALTH_SIGN);
    var saveLegalEntityDataUrl = "".concat(container.get(PARAM_MOI_COMPANY_LEGAL_ENTITY_SAVE_URL));
    var getLegalEntityDataUrl = "".concat(container.get(PARAM_MOI_COMPANY_LEGAL_ENTITY_GET_URL));
    return new LegalEntity({
        signService: signService,
        saveLegalEntityDataUrl: saveLegalEntityDataUrl,
        getLegalEntityDataUrl: getLegalEntityDataUrl,
    });
}
