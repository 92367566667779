export var EhealthMECarePlanActivityAction;
(function (EhealthMECarePlanActivityAction) {
    EhealthMECarePlanActivityAction["ADD_ITEMS"] = "EHEALTH_ME_CARE_PLAN_ACTIVITY_ADD_ITEMS";
    EhealthMECarePlanActivityAction["CLEAR"] = "EHEALTH_ME_CARE_PLAN_ACTIVITY_CLEAR";
    EhealthMECarePlanActivityAction["UPDATE"] = "EHEALTH_ME_CARE_PLAN_ACTIVITY_UPDATE";
    EhealthMECarePlanActivityAction["SET_IS_LOADING"] = "EHEALTH_ME_CARE_PLAN_ACTIVITY_SET_IS_LOADING";
    EhealthMECarePlanActivityAction["SETUP_JOBS"] = "EHEALTH_ME_CARE_PLAN_ACTIVITY_SETUP_JOBS";
    EhealthMECarePlanActivityAction["REMOVE_JOB"] = "EHEALTH_ME_CARE_PLAN_ACTIVITY_REMOVE_JOB";
    EhealthMECarePlanActivityAction["SET_FILTER"] = "EHEALTH_ME_CARE_PLAN_ACTIVITY_SET_FILTER";
})(EhealthMECarePlanActivityAction || (EhealthMECarePlanActivityAction = {}));
