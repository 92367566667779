var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Box, Button, ClickAwayListener, Fab, Stack, TextField, useMediaQuery } from '@mui/material';
import { useStyles } from './HeaderCabinetStyle';
import InputAdornment from '@mui/material/InputAdornment';
import { ThemeToggle } from './components/ThemeToggle';
import { CabinetProfile } from './components/CabinetProfile';
import { CabinetProfileMenu } from './components/CabinetProfileMenu';
import { NotificationMenu } from './components/NotificationMenu';
import { SearchIcon } from './assets/SearchIcon';
import { NotificationIcon } from './assets/NotificationIcon';
import { ChatIcon } from './assets/ChatIcon';
import { useOutsideClick } from '../../../../app/hooks/useOutsideClick';
import MenuIconSvg from './assets/MenuIconSvg';
import { useDispatch } from 'react-redux';
import { showCabinetMenuModal } from '../../../../app/moi/store/modal/modal/actions';
import { useTranslation } from 'react-i18next';
export var HeaderCabinet = function () {
    var classes = useStyles();
    var _a = __read(React.useState(false), 2), openNotification = _a[0], setOpenNotification = _a[1];
    //const [open, setOpen] = React.useState<boolean>(false);
    var _b = __read(React.useState(false), 2), isSearch = _b[0], setIsSearch = _b[1];
    var handleOpenNotification = function () { return setOpenNotification(true); };
    var handleCloseNotification = function () { return setOpenNotification(false); };
    //const handleOpen = () => setOpen(true);
    //const handleClose = () => setOpen(false);
    var dispatch = useDispatch();
    var matches1024 = useMediaQuery('(max-width:1024px)');
    var t = useTranslation().t;
    var _c = __read(React.useState(null), 2), anchorEl = _c[0], setAnchorEl = _c[1];
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var open = Boolean(anchorEl);
    var id = open ? 'simple-popover' : undefined;
    //const CabinetClick = () => {
    //  setOpen(!open);
    //};
    var NotificationClick = function () {
        if (open)
            handleCloseNotification();
        else
            handleOpenNotification();
    };
    var ref = useOutsideClick(function () {
        handleClose();
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: classes.MainWrapper },
            React.createElement(Button, { className: classes.menuButton, startIcon: React.createElement(MenuIconSvg, null), sx: { '& .MuiButton-startIcon': { margin: '0px' }, minWidth: 0 }, onClick: function () { return dispatch(showCabinetMenuModal()); } }),
            matches1024 && (React.createElement(Button, { startIcon: React.createElement(SearchIcon, null), sx: {
                    '& .MuiButton-startIcon': { margin: '0px' },
                    minWidth: '40px',
                    width: '40px',
                    height: '40px',
                    borderRadius: '30px',
                    backgroundColor: '#F1F6FA',
                    marginLeft: '54px',
                    marginTop: '10px',
                } })),
            React.createElement(Stack, { flexDirection: 'column', gap: '5px' },
                React.createElement(ClickAwayListener, { onClickAway: function () {
                        setIsSearch(false);
                    } },
                    React.createElement("div", null, !matches1024 && (React.createElement(TextField, { sx: {
                            paddingLeft: '0px',
                            '& fieldset': { border: 'none' },
                            '& input': {
                                paddingLeft: '0px',
                                '&::placeholder': {
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    lineHeight: '14px',
                                    letterSpacing: '-0.03em',
                                    opacity: 1,
                                    color: '#173236',
                                },
                            },
                        }, onFocus: function () {
                            setIsSearch(true);
                        }, placeholder: "".concat(t('toSearch'), "..."), className: classes.SearchField, InputProps: {
                            className: classes.inputRoot,
                            startAdornment: (React.createElement(InputAdornment, { position: "start", sx: { marginRight: '10px' } },
                                React.createElement(SearchIcon, null))),
                            // disableUnderline: true,
                        } }))))),
            React.createElement(Box, { className: classes.ContentCenter },
                React.createElement(ThemeToggle, null),
                React.createElement(Stack, { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '6px', marginRight: '10px', marginLeft: '20px' },
                    React.createElement(Fab, { className: classes.ButtonHeader },
                        React.createElement(ChatIcon, null)),
                    React.createElement(Button, { className: classes.ButtonHeader, onClick: NotificationClick, sx: [
                            openNotification && {
                                backgroundColor: '#173236 !important',
                            },
                        ] },
                        React.createElement(NotificationIcon, { isOpen: openNotification }))),
                React.createElement(CabinetProfile, { id: id, isOpen: open, handleClick: handleClick }))),
        React.createElement(CabinetProfileMenu, { handleClose: handleClose, open: open, id: id, anchorEl: anchorEl }),
        React.createElement(NotificationMenu, { open: openNotification, setOpen: setOpenNotification })));
};
