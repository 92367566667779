import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_PROGRAM_SERVICE_FOR_SERVICE_REQUEST_URL, } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
import { ProgramService } from './program-service';
export var SERVICE_EHEALTH_PROGRAM_SERVICE = 'SERVICE_EHEALTH_PROGRAM_SERVICE';
export var ehealthProgramServiceFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var programServiceForServiceRequestUrl = "".concat(container.get(PARAM_EHEALTH_PROGRAM_SERVICE_FOR_SERVICE_REQUEST_URL));
    return new ProgramService({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        programServiceForServiceRequestUrl: programServiceForServiceRequestUrl,
    });
};
