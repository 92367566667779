var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EhealthError } from '../../enum/ehealth-error';
var EhealthAuth = /** @class */ (function () {
    function EhealthAuth(loginUrl, logoutUrl, reauthUrl, apiUrl) {
        var _this = this;
        this.loginUrl = loginUrl;
        this.logoutUrl = logoutUrl;
        this.reauthUrl = reauthUrl;
        this.apiUrl = apiUrl;
        this.auth = function () { return __awaiter(_this, void 0, void 0, function () {
            var resp, _a, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, fetch(this.loginUrl, {
                                credentials: 'include',
                            })];
                    case 1:
                        resp = _b.sent();
                        _a = this.updateCreds;
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        _a.apply(this, [_b.sent()]);
                        return [2 /*return*/, resp.ok];
                    case 3:
                        e_1 = _b.sent();
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.getUser = function () { return __awaiter(_this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.creds) {
                            throw new Error(EhealthError.UNAUTHORIZED);
                        }
                        return [4 /*yield*/, fetch("".concat(this.apiUrl, "/oauth/user"), {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: "Bearer ".concat(this.creds.EHealthAccessToken),
                                },
                            })];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new Error(EhealthError.UNAUTHORIZED);
                        }
                        return [2 /*return*/, json.data];
                }
            });
        }); };
        this.reauth = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.reauthRequest) {
                            this.reauthRequest = new Promise(function (resolve) {
                                return resolve(fetch(_this.reauthUrl, {
                                    credentials: 'include',
                                }));
                            }).then(function (resp) { return resp.json(); });
                        }
                        _a = this.updateCreds;
                        return [4 /*yield*/, this.reauthRequest];
                    case 1:
                        _a.apply(this, [_b.sent()]);
                        this.reauthRequest = undefined;
                        return [2 /*return*/, true];
                }
            });
        }); };
        this.logout = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(this.logoutUrl, {
                            credentials: 'include',
                        })];
                    case 1:
                        _a.sent();
                        this.creds = undefined;
                        this.user = undefined;
                        return [2 /*return*/];
                }
            });
        }); };
        this.getCreds = function () {
            if (!_this.creds) {
                throw new Error('Can not get creds before auth');
            }
            return _this.creds;
        };
        this.checkScope = function (scope) {
            return !!_this.creds && !!_this.creds.EHealthScope[scope];
        };
        this.getAuthLink = function () {
            if (!_this.link) {
                throw new Error('Can not get Auth link before auth');
            }
            return _this.link;
        };
    }
    EhealthAuth.prototype.updateCreds = function (data) {
        var EHealthAccessToken = data.EHealthAccessToken, EHealthRefreshToken = data.EHealthRefreshToken, EHealthScope = data.EHealthScope, loginLink = data.loginLink, roles = data.roles, legalEntityId = data.legalEntityId, legalEntityStatus = data.legalEntityStatus, legalEntityType = data.legalEntityType, legalEntityEdrpou = data.legalEntityEdrpou, employeeId = data.employeeId;
        if (EHealthAccessToken) {
            this.creds = {
                EHealthAccessToken: EHealthAccessToken,
                EHealthRefreshToken: EHealthRefreshToken,
                EHealthScope: EHealthScope.split(' ').reduce(function (scope, key) {
                    scope[key] = 1;
                    return scope;
                }, {}),
                roles: roles,
                legalEntityId: legalEntityId,
                legalEntityStatus: legalEntityStatus,
                legalEntityType: legalEntityType,
                legalEntityEdrpou: legalEntityEdrpou,
                employeeId: employeeId,
            };
            this.link = loginLink;
        }
        else {
            this.creds = undefined;
            this.link = loginLink;
        }
    };
    return EhealthAuth;
}());
export { EhealthAuth };
