export var patientMenuItems = [
    {
        title: 'record.title',
        link: '/cabinet/data_archive',
        icon: 'archiveMenu',
    },
    //{
    //  title: 'menu.profile.screaning',
    //  link: '/cabinet/screaning-screaning',
    //  icon: 'screeningMenu',
    //},
    {
        title: 'healthDynamic',
        link: '/cabinet/my-health-dynamic',
        icon: 'dynamicMenu',
    },
    {
        title: 'menu.profile.reservations',
        link: '/cabinet/my-booked-services',
        icon: 'appointmentsMenu',
    },
    {
        title: 'menu.profile.my-analyses',
        link: '/cabinet/my-analyse',
        icon: 'referralsMenu',
    },
    {
        title: 'menuReferences',
        link: '/cabinet/reference',
        icon: 'referenceMenu',
    },
    {
        title: 'menu.profile.myFamily',
        link: '/cabinet/charges',
        icon: 'familyMenu',
    },
    {
        title: 'oneExpert.message',
        link: '/cabinet/messages',
        icon: 'chatMenu',
        reloadPage: true,
    },
    {
        title: 'menu.profile.notification',
        link: '/cabinet/notification/all',
        icon: 'notificationMenu',
    },
];
export var doctorMenuItems = [
    {
        title: 'patientRecordsTitle',
        link: '/cabinet/booked-services',
        icon: 'appointmentsMenu',
    },
    {
        title: 'schedule.name',
        link: '/cabinet/schedule',
        icon: 'calendarMenu',
    },
    {
        title: 'patients',
        link: '/cabinet/clients',
        icon: 'familyMenu',
    },
    {
        title: 'oneExpert.services',
        link: '/cabinet/service',
        icon: 'archiveMenu',
    },
    {
        title: 'menu.profile.my-analyses',
        link: '/cabinet/referral',
        icon: 'referralsMenu',
    },
    {
        title: 'menuReferences',
        link: '/cabinet/reference',
        icon: 'referenceMenu',
    },
    {
        title: 'EHealth',
        link: '/e-health/app',
        icon: 'briefcaseMenu',
    },
    {
        title: 'oneExpert.message',
        link: '/cabinet/messages',
        icon: 'chatMenu',
        reloadPage: true,
    },
    {
        title: 'menu.profile.notification',
        link: '/cabinet/notification/all',
        icon: 'notificationMenu',
    },
];
export var clinicMenuItems = [
    {
        title: 'patientRecordsTitle',
        link: '/cabinet/company/patient-entries',
        icon: 'appointmentsMenu',
    },
    {
        title: 'company.menu.services',
        link: '/cabinet/company/services',
        icon: 'archiveMenu',
    },
    {
        title: 'eHealth.experts',
        link: '/cabinet/company/experts-new',
        icon: 'familyMenu',
    },
    {
        title: 'schedule.name',
        link: '/cabinet/company/experts-schedule',
        icon: 'calendarMenu',
    },
    {
        title: 'news.news',
        link: '/cabinet/company/news',
        icon: 'referralsMenu',
    },
    {
        title: 'company.menu.cabinet',
        link: '/cabinet/company/profile/new',
        icon: 'building-03',
    },
    {
        title: 'menu.profile.responses',
        link: '/cabinet/company/reviews',
        icon: 'star-square',
    },
    {
        title: 'gallery',
        link: '/cabinet/company/gallery',
        icon: 'album-02',
    },
    //{
    //  title: 'company.menu.eHealth',
    //  link: '/cabinet/company/legal-entity',
    //  icon: 'first-aid-kit',
    //},
    //{
    //  title: 'EHealth',
    //  link: '/cabinet/company/patient-entries', // не зрозуміло що має тут бути (немає макету)
    //  icon: 'briefcaseMenu',
    //},
    {
        title: 'menu.profile.notification',
        link: '/cabinet/notification/all',
        icon: 'notificationMenu',
    },
];
