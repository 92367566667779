export var categoriesInitialState = [
    {
        id: 0,
        title: '',
        slug: '',
    },
];
export var initialState = {
    categories: categoriesInitialState,
    loading: false,
    error: null,
};
