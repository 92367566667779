/// <reference path='./app/moi/app.d.ts'/>
import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useLocation } from 'react-router-dom';
import { LazyNotFoundPage } from './app/moi/screen/NotFound/lazy';
import { registerMoiParams } from './app/moi/config/di/params';
import { registerMoiServices } from './app/moi/config/di/services';
import { useContainer } from './shared/service/di-container/react/useContainer';
import { registerEhealthParams } from './app/ehealth/config/di/params';
import { registerEhealthServices } from './app/ehealth/config/di/services';
import { lazyWithRetry } from '../lazyWithRetry';
import moment from 'moment';
import 'moment/locale/uk';
import 'moment/locale/ru';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
// apps
var EhealthApp = lazyWithRetry(function () { return import(/* webpackChunkName: "app-ehealth" */ './app/ehealth/App'); });
var MoiApp = lazyWithRetry(function () { return import(/* webpackChunkName: "app-moi" */ './app/moi/App'); });
var BaseApp = lazyWithRetry(function () { return import(/* webpackChunkName: "app-base" */ './app/moi/AppBase'); });
var HomeApp = lazyWithRetry(function () { return import(/* webpackChunkName: "app-home" */ './app/moi/Home'); });
export var RoutesSwitch = function () {
    //useCurrentLocaleTranslation({});
    var container = useContainer();
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var location = useLocation();
    var currentLocationLanguage = location.pathname.slice(1, 3);
    useEffect(function () {
        if (currentLocationLanguage && currentLocationLanguage !== i18n.language) {
            i18n.changeLanguage(currentLocationLanguage);
            moment.locale(currentLocationLanguage);
        }
    }, [currentLocationLanguage, i18n.language]);
    useLayoutEffect(function () {
        // Register Moi params
        registerMoiParams(container);
        registerMoiServices(container);
        // Register E-Health params
        registerEhealthParams(container);
        registerEhealthServices(container);
    }, []);
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/", component: HomeApp }),
        React.createElement(Route, { path: "/:locale/cabinet", component: MoiApp }),
        React.createElement(Route, { path: "/:locale/e-health/app", component: EhealthApp }),
        React.createElement(Route, { path: "/:locale", component: BaseApp }),
        React.createElement(Route, { path: "*", component: LazyNotFoundPage })));
};
