export var personalInitialState = {
    roles: [],
    id: 0,
    knowledge_languages: [],
    confirm_email: 0,
    avatar: '',
    full_name: '',
    last_name: '',
    first_name: '',
    second_name: '',
    sex: '',
    birthday: '',
    location: '',
    latitude: 0,
    longitude: 0,
    last_activity_at: '',
    is_notification_license_expert_end: false,
    is_notification_license_subscribe_end: false,
    blocked: 0,
    deleted: false,
    file_background: '',
    main_language: '',
    address: {
        id: 0,
        settlement: '',
        street: '',
        house: '',
        apartment: '',
        oblast: '',
        region: '',
    },
    phone: '',
    email: '',
    secondary_phone: '',
};
export var initialState = {
    personal: personalInitialState,
    loading: false,
    error: null,
};
