var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultState } from './state';
import { PersonAction } from './enum/action';
export function personReducer(state, action) {
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case PersonAction.SET_CURRENT_PERSON:
            return __assign(__assign({}, state), { currentPerson: action.payload.currentPerson });
        case PersonAction.SET_CURRENT_PERSON_VERIFICATION_DATA:
            return __assign(__assign({}, state), { verification: action.payload.verification });
    }
    return state;
}
