import { UserRoles } from '../../../service/user/enum/user-roles';
export var PersonalInfoSelector = function (state) { return state.moi.user.personal.personal; };
export var UserRoleSelector = function (state) { return state.moi.user.personal.personal.roles; };
export var RoleSelector = function (state) {
    var isClinic = state.moi.user.personal.personal.roles.includes(UserRoles.OWNER) ||
        state.moi.user.personal.personal.roles.includes(UserRoles.ROLE_COMPANY_ADMIN);
    var isExpert = state.moi.user.personal.personal.roles.includes(UserRoles.ROLE_EXPERT) ||
        state.moi.user.personal.personal.roles.includes(UserRoles.ROLE_CONSULTANT) ||
        state.moi.user.personal.personal.roles.includes(UserRoles.ROLE_MANAGER);
    var isPatient = state.moi.user.personal.personal.roles.includes(UserRoles.ROLE_USER) ||
        state.moi.user.personal.personal.roles.includes(UserRoles.ROLE_CLIENT);
    var result = [];
    if (isClinic)
        result.push('clinic');
    if (isExpert)
        result.push('expert');
    if (isPatient)
        result.push('patient');
    return result;
};
export var IsExpertSelector = function (state) {
    var isExpert = state.moi.user.personal.personal.roles.includes(UserRoles.ROLE_EXPERT) ||
        state.moi.user.personal.personal.roles.includes(UserRoles.ROLE_CONSULTANT) ||
        state.moi.user.personal.personal.roles.includes(UserRoles.ROLE_MANAGER);
    return isExpert ? true : false;
};
export var IsPatientSelector = function (state) {
    var isPatient = state.moi.user.personal.personal.roles.includes(UserRoles.ROLE_USER) ||
        state.moi.user.personal.personal.roles.includes(UserRoles.ROLE_CLIENT);
    return isPatient ? true : false;
};
