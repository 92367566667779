var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useStyles } from './SearchResultStyle';
import { SearchType } from './SearchType';
import { CountMessage } from './CountMessage';
import { FileIcon } from '../assets/FileIcon';
import { useHistory } from 'react-router-dom';
export var SearchResult = function () {
    var classes = useStyles();
    var types = ['Пацієнти', 'Файли', 'Довідки', 'Записи', 'Направлення', 'Відгуки'];
    var history = useHistory();
    var routeToResultsPage = function () {
        {
            /*@todo get locale*/
        }
        history.push('/uk/cabinet/search-results/dsa');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: classes.wrapper },
            React.createElement(Stack, { flexDirection: 'column' },
                React.createElement(Typography, { className: classes.textWhat }, "\u0429\u043E \u0431\u0443\u0434\u0435\u043C\u043E \u0448\u0443\u043A\u0430\u0442\u0438...?"),
                React.createElement(Stack, { flexDirection: 'row', flexWrap: 'wrap', gap: '4px', width: '358px', marginTop: '15px', alignItems: 'flex-start' }, types.map(function (item) {
                    return React.createElement(SearchType, { text: item });
                })),
                React.createElement(Stack, { flexDirection: 'row', alignItems: 'center', marginTop: '30px', justifyContent: 'space-between' },
                    React.createElement(Stack, { gap: '5px', flexDirection: 'row', alignItems: 'center' },
                        React.createElement(Typography, { className: classes.textWhat }, "\u041F\u0430\u0446\u0456\u0454\u043D\u0442\u0438"),
                        React.createElement(CountMessage, { count: '55' })),
                    React.createElement(Typography, { className: classes.textAll, onClick: routeToResultsPage }, "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u0438 \u0432\u0441\u0456")),
                React.createElement(Stack, { flexDirection: 'column', gap: '10px', marginTop: '10px' }, __spreadArray([], __read(Array(4)), false).map(function (x, i) { return (React.createElement(Stack, { flexDirection: 'row', alignItems: 'center', gap: '10px' },
                    React.createElement("img", { src: require("../assets/avatar.jpg"), style: { borderRadius: '50%' } }),
                    React.createElement(Typography, { className: classes.textName }, "\u0410\u043D\u0430\u0441\u0442\u0430\u0441\u044E\u043A \u0412\u0430\u043B\u0435\u0440\u0456\u0439 \u041F\u0435\u0442\u0440\u043E\u0432\u0438\u0447"))); })),
                React.createElement(Stack, { flexDirection: 'row', alignItems: 'center', marginTop: '30px', justifyContent: 'space-between' },
                    React.createElement(Stack, { gap: '5px', flexDirection: 'row', alignItems: 'center' },
                        React.createElement(Typography, { className: classes.textWhat }, "\u0424\u0430\u0439\u043B\u0438"),
                        React.createElement(CountMessage, { count: '99' })),
                    React.createElement(Typography, { className: classes.textAll, onClick: routeToResultsPage }, "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u0438 \u0432\u0441\u0456")),
                __spreadArray([], __read(Array(4)), false).map(function (x, i) { return (React.createElement(Stack, { flexDirection: 'row', alignItems: 'center', marginTop: '10px', justifyContent: 'space-between' },
                    React.createElement(Stack, { gap: '5px', flexDirection: 'row', alignItems: 'center' },
                        React.createElement(Box, { className: classes.iconWrapper },
                            React.createElement(FileIcon, null)),
                        React.createElement(Typography, { className: classes.textName }, "\u0412\u0438\u0441\u043D\u043E\u0432\u043E\u043A \u0410\u043D\u0430\u0441\u0442\u0430\u0441\u044E\u043A \u0412. \u041F..doc")),
                    React.createElement(Typography, { className: classes.textWhat }, "1.5mb"))); })))));
};
