export var RecordsFoldersInitialState = {
    records_count: 0,
    id: 0,
    name: '',
};
export var initialState = {
    folders: [RecordsFoldersInitialState],
    isFolderCreated: false,
    isFolderDeleted: false,
    isFolderEdited: false,
    loading: false,
    error: null,
};
