var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ResponseError } from '../../../../../shared/error/response-error';
import { JobStatus } from './enum/job-status';
var JobService = /** @class */ (function () {
    function JobService(params) {
        this.baseUrl = params.baseUrl;
        this.itemsPerPage = params.itemsPerPage;
        this.requestService = params.requestService;
        this.createJobUrl = params.createJobUrl;
        this.deleteJobUrl = params.deleteJobUrl;
        this.updateJobUrl = params.updateJobUrl;
        this.listJobUrl = params.listJobUrl;
        this.singleJobUrl = params.singleJobUrl;
        this.jobsEntitiesUrl = params.jobsEntitiesUrl;
    }
    JobService.prototype.parseJobIdFromUrl = function (url) {
        var match = url.href.match(/[^\/]+$/gm);
        return (match === null || match === void 0 ? void 0 : match[0]) || '';
    };
    JobService.prototype.getSingle = function (jobLink) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api").concat(jobLink.href))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    JobService.prototype.getCompositionJob = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/composition/job/").concat(id))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.clone().json()];
                    case 2:
                        json = _a.sent();
                        if (!json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    JobService.prototype.getStoredSingle = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(this.singleJobUrl, {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                            },
                            body: JSON.stringify({
                                id: id,
                            }),
                        })];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    JobService.prototype.create = function (jobLink, entity, payload, type, entityId) {
        return __awaiter(this, void 0, void 0, function () {
            var id, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        id = typeof jobLink === 'string' ? jobLink : this.parseJobIdFromUrl(jobLink);
                        if (!id.length) {
                            throw new Error('Can not parse job id from url ' + jobLink);
                        }
                        return [4 /*yield*/, fetch(this.createJobUrl, {
                                method: 'POST',
                                credentials: 'include',
                                headers: {
                                    'Content-Type': 'application/json; charset=utf-8',
                                },
                                body: JSON.stringify({
                                    id: id,
                                    type: type,
                                    entity: entity,
                                    entityId: entityId,
                                    payload: payload,
                                }),
                            })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.status];
                }
            });
        });
    };
    JobService.prototype.delete = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(this.deleteJobUrl, {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                            },
                            body: JSON.stringify({
                                id: id,
                            }),
                        })];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp.ok];
                }
            });
        });
    };
    JobService.prototype.update = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(this.updateJobUrl, {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                            },
                            body: JSON.stringify({
                                id: id,
                            }),
                        })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    JobService.prototype.getList = function (entity, page) {
        return __awaiter(this, void 0, void 0, function () {
            var params, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = new URLSearchParams({
                            entity: "".concat(entity),
                            page: "".concat(page),
                            page_size: "".concat(this.itemsPerPage),
                        });
                        return [4 /*yield*/, fetch("".concat(this.listJobUrl, "?").concat(params), {
                                credentials: 'include',
                            })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    JobService.prototype.getPendingJobsForEntities = function (ids) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(this.jobsEntitiesUrl, {
                            credentials: 'include',
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                            },
                            body: JSON.stringify({
                                status: JobStatus.PENDING,
                                ids: ids,
                            }),
                        })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    return JobService;
}());
export { JobService };
