export var ExpertBookingsActionType;
(function (ExpertBookingsActionType) {
    ExpertBookingsActionType["GET_BOOKINGS_PENDING"] = "GET_BOOKINGS_PENDING";
    ExpertBookingsActionType["GET_BOOKINGS_SUCCESS"] = "GET_BOOKINGS_SUCCESS";
    ExpertBookingsActionType["GET_BOOKINGS_ERROR"] = "GET_BOOKINGS_ERROR";
    ExpertBookingsActionType["ADD_BOOKING_PENDING"] = "ADD_BOOKING_PENDING";
    ExpertBookingsActionType["ADD_BOOKING_SUCCESS"] = "ADD_BOOKING_SUCCESS";
    ExpertBookingsActionType["ADD_BOOKING_ERROR"] = "ADD_BOOKING_ERROR";
    ExpertBookingsActionType["DELETE_BOOKING_PENDING"] = "DELETE_BOOKING_PENDING";
    ExpertBookingsActionType["DELETE_BOOKING_SUCCESS"] = "DELETE_BOOKING_SUCCESS";
    ExpertBookingsActionType["DELETE_BOOKING_ERROR"] = "DELETE_BOOKING_ERROR";
    ExpertBookingsActionType["EDIT_BOOKING_PENDING"] = "EDIT_BOOKING_PENDING";
    ExpertBookingsActionType["EDIT_BOOKING_SUCCESS"] = "EDIT_BOOKING_SUCCESS";
    ExpertBookingsActionType["EDIT_BOOKING_ERROR"] = "EDIT_BOOKING_ERROR";
    ExpertBookingsActionType["CONFIRM_BOOKING_PENDING"] = "CONFIRM_BOOKING_PENDING";
    ExpertBookingsActionType["CONFIRM_BOOKING_SUCCESS"] = "CONFIRM_BOOKING_SUCCESS";
    ExpertBookingsActionType["CONFIRM_BOOKING_ERROR"] = "CONFIRM_BOOKING_ERROR";
    ExpertBookingsActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(ExpertBookingsActionType || (ExpertBookingsActionType = {}));
