import { ehealthAuthFactory, SERVICE_EHEALTH_AUTH } from '../../../service/auth';
import { ehealthCapitationContractFactory, SERVICE_EHEALTH_CAPITATION_CONTRACT, } from '../../../service/ehealth/capitation/contract';
import { ehealthCapitationContractRequestFactory, SERVICE_EHEALTH_CAPITATION_CONTRACT_REQUEST, } from '../../../service/ehealth/capitation/contract-request';
import { ehealthCompositionFactory, SERVICE_EHEALTH_COMPOSITION } from '../../../service/ehealth/composition';
import { ehealthDeclarationFactory, SERVICE_EHEALTH_DECLARATION, } from '../../../service/ehealth/declaration/declaration';
import { ehealthDeclarationRequestFactory, SERVICE_EHEALTH_DECLARATION_REQUEST, } from '../../../service/ehealth/declaration/request';
import { ehealthDivisionsFactory, SERVICE_EHEALTH_DIVISIONS } from '../../../service/ehealth/division';
import { ehealthDeviceDefinitionsFactory, SERVICE_EHEALTH_DEVICE_DEFINITIONS, } from '../../../service/ehealth/device-definitions';
import { ehealthDrugsFactory, SERVICE_EHEALTH_DRUGS } from '../../../service/ehealth/drugs';
import { ehealthEmployeeFactory, SERVICE_EHEALTH_EMPLOYEE } from '../../../service/ehealth/employee';
import { ehealthEmployeeRequestFactory, SERVICE_EHEALTH_EMPLOYEE_REQUEST, } from '../../../service/ehealth/employee-request';
import { ehealthEmployeeRoleFactory, SERVICE_EHEALTH_EMPLOYEE_ROLE } from '../../../service/ehealth/employee-role';
import { ehealthHealthcareServiceFactory, SERVICE_EHEALTH_HEALTHCARE_SERVICE, } from '../../../service/ehealth/healthcare-service';
import { eHealthLicenseFactory, SERVICE_EHEALTH_LICENSE } from '../../../service/ehealth/license';
import { ehealthJobFactory, SERVICE_EHEALTH_JOB } from '../../../service/ehealth/job';
import { ehealthLegalEntityFactory, SERVICE_EHEALTH_LEGAL_ENTITY } from '../../../service/ehealth/legal-entity';
import { ehealthAllergyIntoleranceFactory, SERVICE_EHEALTH_MEDICAL_EVENT_ALLERGY_INTOLERANCE, } from '../../../service/ehealth/medical-events/allergy-intolerance';
import { ehealthApprovalFactory, SERVICE_EHEALTH_MEDICAL_EVENT_APPROVAL, } from '../../../service/ehealth/medical-events/approval';
import { ehealthCarePlanFactory, SERVICE_EHEALTH_MEDICAL_EVENT_CARE_PLAN, } from '../../../service/ehealth/medical-events/care-plan';
import { ehealthConditionFactory, SERVICE_EHEALTH_MEDICAL_EVENT_CONDITION, } from '../../../service/ehealth/medical-events/condition';
import { ehealthDeviceFactory, SERVICE_EHEALTH_MEDICAL_EVENT_DEVICE, } from '../../../service/ehealth/medical-events/device';
import { ehealthDiagnosticReportFactory, SERVICE_EHEALTH_MEDICAL_EVENT_DIAGNOSTIC_REPORT, } from '../../../service/ehealth/medical-events/diagnostic-report';
import { ehealthDiagnoseGroupFactory, SERVICE_EHEALTH_MEDICAL_EVENT_DIAGNOSE_GROUP, } from '../../../service/ehealth/medical-events/diagnose-group';
import { ehealthEncounterFactory, SERVICE_EHEALTH_MEDICAL_EVENT_ENCOUNTER, } from '../../../service/ehealth/medical-events/encounter';
import { ehealthEncounterPackageFactory, SERVICE_EHEALTH_MEDICAL_EVENT_ENCOUNTER_PACKAGE, } from '../../../service/ehealth/medical-events/encounter-package';
import { ehealthEpisodesFactory, SERVICE_EHEALTH_EPISODES } from '../../../service/ehealth/medical-events/episode';
import { ehealthImmunizationFactory, SERVICE_EHEALTH_MEDICAL_EVENT_IMMUNIZATION, } from '../../../service/ehealth/medical-events/immunization';
import { ehealthMedicationFactory, SERVICE_EHEALTH_MEDICAL_EVENT_MEDICATION, } from '../../../service/ehealth/medical-events/medication';
import { ehealthMedicationAdministrationFactory, SERVICE_EHEALTH_MEDICAL_EVENT_MEDICATION_ADMINISTRATION, } from '../../../service/ehealth/medical-events/medication-administration';
import { ehealthMedicationStatementFactory, SERVICE_EHEALTH_MEDICAL_EVENT_MEDICATION_STATEMENT, } from '../../../service/ehealth/medical-events/medication-statement';
import { ehealthObservationFactory, SERVICE_EHEALTH_MEDICAL_EVENT_OBSERVATION, } from '../../../service/ehealth/medical-events/observation';
import { ehealthProcedureFactory, SERVICE_EHEALTH_PROCEDURE } from '../../../service/ehealth/medical-events/procedure';
import { ehealthRiskAssessmentFactory, SERVICE_EHEALTH_MEDICAL_EVENT_RISK_ASSESSMENT, } from '../../../service/ehealth/medical-events/risk-assessment';
import { ehealthMedicalProgramFactory, SERVICE_EHEALTH_MEDICAL_PROGRAM, } from '../../../service/ehealth/medical-program';
import { ehealthMedicationRequestFactory, SERVICE_EHEALTH_MEDICATION_REQUEST, } from '../../../service/ehealth/medication/medication-request';
import { ehealthMedicationRequestRequestFactory, SERVICE_EHEALTH_MEDICATION_REQUEST_REQUEST, } from '../../../service/ehealth/medication/medication-request-request';
import { ehealthOTPVerificationFactory, SERVICE_EHEALTH_OTP_VERIFICATION, } from '../../../service/ehealth/otp-verification';
import { ehealthPersonFactory, SERVICE_EHEALTH_PERSON } from '../../../service/ehealth/person/person';
import { ehealthPersonRequestFactory, SERVICE_EHEALTH_PERSON_REQUEST } from '../../../service/ehealth/person/request';
import { ehealthPrepersonFactory, SERVICE_EHEALTH_PREPERSON } from '../../../service/ehealth/preperson';
import { ehealthReorganizeFactory, SERVICE_EHEALTH_REORGANIZE } from '../../../service/ehealth/reorganize';
import { ehealthServiceCatalogFactory, SERVICE_EHEALTH_SERVICE_CATALOG, } from '../../../service/ehealth/service-catalog';
import { ehealthServiceRequestFactory, SERVICE_EHEALTH_SERVICE_REQUEST, } from '../../../service/ehealth/service-request';
import { ehealthVaccineDiseasesFactory, SERVICE_EHEALTH_VACCINE_DISEASES, } from '../../../service/ehealth/vaccine-diseases';
import { ehealthVaccinesMedzakupivliFactory, SERVICE_EHEALTH_VACCINES_MEDZAKUPIVLI, } from '../../../service/ehealth/vaccines-medzakupivli';
import { requestFactory, SERVICE_REQUEST } from '../../../service/request';
import { SERVICE_PATIENT_SUMMARY, ehealthPatientSummaryFactory } from '../../../service/ehealth/patient-summary';
import { ehealthProgramServiceFactory, SERVICE_EHEALTH_PROGRAM_SERVICE, } from '../../../service/ehealth/program-service';
import { eHealthClientConfigurationFactory, SERVICE_EHEALTH_CLIENT_CONFIGURATION, } from '../../../service/ehealth/client-configuration';
import { ehealthMedicationDeviceRequestFactory, SERVICE_EHEALTH_MEDICATION_DEVICE_REQUEST, } from '../../../service/ehealth/medication-device/medication-device-request';
import { ehealthDeviceRequestFactory, SERVICE_EHEALTH_DEVICE_REQUEST } from '../../../service/ehealth/device-request';
import { ehealthForbiddenGroupFactory, SERVICE_EHEALTH_MEDICAL_EVENT_FORBIDDEN_GROUP, } from '../../../service/ehealth/medical-events/forbidden-group';
export var registerEhealthServices = function (container) {
    container.register(SERVICE_EHEALTH_AUTH, ehealthAuthFactory);
    container.register(SERVICE_REQUEST, requestFactory);
    container.register(SERVICE_EHEALTH_DIVISIONS, ehealthDivisionsFactory);
    container.register(SERVICE_EHEALTH_DEVICE_DEFINITIONS, ehealthDeviceDefinitionsFactory);
    container.register(SERVICE_EHEALTH_DECLARATION, ehealthDeclarationFactory);
    container.register(SERVICE_EHEALTH_DECLARATION_REQUEST, ehealthDeclarationRequestFactory);
    container.register(SERVICE_EHEALTH_EMPLOYEE, ehealthEmployeeFactory);
    container.register(SERVICE_EHEALTH_EMPLOYEE_REQUEST, ehealthEmployeeRequestFactory);
    container.register(SERVICE_EHEALTH_OTP_VERIFICATION, ehealthOTPVerificationFactory);
    container.register(SERVICE_EHEALTH_PERSON, ehealthPersonFactory);
    container.register(SERVICE_EHEALTH_PERSON_REQUEST, ehealthPersonRequestFactory);
    container.register(SERVICE_EHEALTH_PREPERSON, ehealthPrepersonFactory);
    container.register(SERVICE_EHEALTH_CAPITATION_CONTRACT_REQUEST, ehealthCapitationContractRequestFactory);
    container.register(SERVICE_EHEALTH_CAPITATION_CONTRACT, ehealthCapitationContractFactory);
    container.register(SERVICE_EHEALTH_LEGAL_ENTITY, ehealthLegalEntityFactory);
    container.register(SERVICE_EHEALTH_VACCINE_DISEASES, ehealthVaccineDiseasesFactory);
    container.register(SERVICE_EHEALTH_HEALTHCARE_SERVICE, ehealthHealthcareServiceFactory);
    container.register(SERVICE_EHEALTH_LICENSE, eHealthLicenseFactory);
    container.register(SERVICE_EHEALTH_JOB, ehealthJobFactory);
    container.register(SERVICE_EHEALTH_EPISODES, ehealthEpisodesFactory);
    container.register(SERVICE_EHEALTH_EMPLOYEE_ROLE, ehealthEmployeeRoleFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_EVENT_ENCOUNTER, ehealthEncounterFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_EVENT_ALLERGY_INTOLERANCE, ehealthAllergyIntoleranceFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_EVENT_CONDITION, ehealthConditionFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_EVENT_DEVICE, ehealthDeviceFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_EVENT_DIAGNOSTIC_REPORT, ehealthDiagnosticReportFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_EVENT_DIAGNOSE_GROUP, ehealthDiagnoseGroupFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_EVENT_FORBIDDEN_GROUP, ehealthForbiddenGroupFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_EVENT_ENCOUNTER_PACKAGE, ehealthEncounterPackageFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_EVENT_IMMUNIZATION, ehealthImmunizationFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_EVENT_MEDICATION_ADMINISTRATION, ehealthMedicationAdministrationFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_EVENT_MEDICATION_STATEMENT, ehealthMedicationStatementFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_EVENT_OBSERVATION, ehealthObservationFactory);
    container.register(SERVICE_EHEALTH_PROCEDURE, ehealthProcedureFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_EVENT_RISK_ASSESSMENT, ehealthRiskAssessmentFactory);
    container.register(SERVICE_EHEALTH_SERVICE_REQUEST, ehealthServiceRequestFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_EVENT_APPROVAL, ehealthApprovalFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_EVENT_MEDICATION, ehealthMedicationFactory);
    container.register(SERVICE_EHEALTH_MEDICATION_REQUEST, ehealthMedicationRequestFactory);
    container.register(SERVICE_EHEALTH_MEDICATION_DEVICE_REQUEST, ehealthMedicationDeviceRequestFactory);
    container.register(SERVICE_EHEALTH_MEDICATION_REQUEST_REQUEST, ehealthMedicationRequestRequestFactory);
    container.register(SERVICE_EHEALTH_DEVICE_REQUEST, ehealthDeviceRequestFactory);
    container.register(SERVICE_EHEALTH_DRUGS, ehealthDrugsFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_PROGRAM, ehealthMedicalProgramFactory);
    container.register(SERVICE_EHEALTH_MEDICAL_EVENT_CARE_PLAN, ehealthCarePlanFactory);
    container.register(SERVICE_EHEALTH_SERVICE_CATALOG, ehealthServiceCatalogFactory);
    container.register(SERVICE_EHEALTH_COMPOSITION, ehealthCompositionFactory);
    container.register(SERVICE_EHEALTH_VACCINES_MEDZAKUPIVLI, ehealthVaccinesMedzakupivliFactory);
    container.register(SERVICE_PATIENT_SUMMARY, ehealthPatientSummaryFactory);
    container.register(SERVICE_EHEALTH_PROGRAM_SERVICE, ehealthProgramServiceFactory);
    container.register(SERVICE_EHEALTH_CLIENT_CONFIGURATION, eHealthClientConfigurationFactory);
    container.register(SERVICE_EHEALTH_REORGANIZE, ehealthReorganizeFactory);
};
