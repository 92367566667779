import { combineReducers } from 'redux';
import { expertListReducer } from './expert-list';
import { expertRecommendedReducer } from './expert-recommended';
import { expertSingleReducer } from './expert-single';
import { expertReviewsReducer } from './expert-reviews';
import { expertScheduleReducer } from './expert-schedule';
import { expertFreeHoursReducer } from './expert-free-hours';
export var expertsReducer = combineReducers({
    expertList: expertListReducer,
    expertRecommended: expertRecommendedReducer,
    expertSingle: expertSingleReducer,
    expertReviews: expertReviewsReducer,
    expertSchedule: expertScheduleReducer,
    expertFreeHours: expertFreeHoursReducer,
});
