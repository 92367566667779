var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ResponseError } from '../../../../shared/error/response-error';
var Reference = /** @class */ (function () {
    function Reference(params) {
        this.getReferencesUrl = params.getReferencesUrl;
        this.getReferenceDeleteUrl = params.getReferenceDeleteUrl;
        this.getUserDataForReferenceUrl = params.getUserDataForReferenceUrl;
        this.referenceCreateUrl = params.referenceCreateUrl;
        this.itemsPerPage = params.itemsPerPage;
    }
    Reference.prototype.getReferences = function (type, page, page_size) {
        return __awaiter(this, void 0, void 0, function () {
            var params, resp, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = new URLSearchParams({
                            type: "".concat(type),
                            page: "".concat(page),
                            page_size: "".concat(page_size),
                        });
                        return [4 /*yield*/, fetch("".concat(this.getReferencesUrl, "?").concat(params), {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json; charset=utf-8',
                                },
                                credentials: 'include',
                            })];
                    case 1:
                        resp = _b.sent();
                        if (!!resp.ok) return [3 /*break*/, 3];
                        _a = Error.bind;
                        return [4 /*yield*/, resp.text()];
                    case 2: throw new (_a.apply(Error, [void 0, _b.sent()]))();
                    case 3: return [4 /*yield*/, resp.json()];
                    case 4: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    Reference.prototype.deleteReference = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("".concat(this.getReferenceDeleteUrl, "?id=").concat(id), {
                            method: 'DELETE',
                        })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    Reference.prototype.createReference = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("".concat(this.referenceCreateUrl), {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                            },
                            body: JSON.stringify(__assign({}, data)),
                        })];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    Reference.prototype.getUserDataForReference = function (clientId) {
        return __awaiter(this, void 0, void 0, function () {
            var params, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = new URLSearchParams({
                            clientId: clientId,
                        });
                        return [4 /*yield*/, fetch("".concat(this.getUserDataForReferenceUrl, "?").concat(params), {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json; charset=utf-8',
                                },
                                credentials: 'include',
                            })];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.json()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return Reference;
}());
export { Reference };
