import { PARAM_MOI_EXPERTS_SCHEDULE_LIST_URL } from '../../config/di/params/keys';
import { ExpertsSchedule } from './experts-schedule';
export var SERVICE_MOI_EXPERTS_SCHEDULE = 'SERVICE_MOI_EXPERTS_SCHEDULE';
export function expertsScheduleFactory(container) {
    return new ExpertsSchedule({
        urls: {
            listUrl: "".concat(container.get(PARAM_MOI_EXPERTS_SCHEDULE_LIST_URL))
        }
    });
}
