import { PARAM_MOI_CLIENTS_LIST_URL, PARAM_MOI_CONSULTANT_REQUEST_URL, PARAM_MOI_DECLINE_CLIENT_URL, PARAM_MOI_CLIENTS_CONSULTANTS_LIST_URL, PARAM_MOI_CLIENTS_MEDICAL_INFORMATION_URL, PARAM_MOI_CLIENTS_ALL_LIST_URL } from '../../config/di/params/keys';
import { Clients } from './clients';
import { PARAM_ITEMS_PER_PAGE } from '../../../../config/di/params/keys';
export var SERVICE_MOI_CLIENTS = 'SERVICE_MOI_CLIENTS';
export function clientsFactory(container) {
    var listUrl = "".concat(container.get(PARAM_MOI_CLIENTS_LIST_URL));
    var allListUrl = "".concat(container.get(PARAM_MOI_CLIENTS_ALL_LIST_URL));
    var declineClientUrl = "".concat(container.get(PARAM_MOI_DECLINE_CLIENT_URL));
    var consultantRequestUrl = "".concat(container.get(PARAM_MOI_CONSULTANT_REQUEST_URL));
    var clientConsultantListUrl = "".concat(container.get(PARAM_MOI_CLIENTS_CONSULTANTS_LIST_URL));
    var medicalInformationUrl = "".concat(container.get(PARAM_MOI_CLIENTS_MEDICAL_INFORMATION_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    return new Clients({
        urls: {
            listUrl: listUrl,
            allListUrl: allListUrl,
            consultantRequestUrl: consultantRequestUrl,
            declineClientUrl: declineClientUrl,
            clientConsultantListUrl: clientConsultantListUrl,
            medicalInformationUrl: medicalInformationUrl,
        },
        itemsPerPage: itemsPerPage,
    });
}
