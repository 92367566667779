import { combineReducers } from 'redux';
import { expertsModalReducer } from './experts/reducers';
import { serviceModalReducer } from './service/reducers';
import { galleryModalReducer } from './gallery/reducers';
import { clinicScheduleModalReducer } from './clinicSchedule/reducers';
import { cabinetMenuModalReducer } from './cabinetMenu/reducers';
export var modalReducer = combineReducers({
    experts: expertsModalReducer,
    service: serviceModalReducer,
    gallery: galleryModalReducer,
    clinicSchedule: clinicScheduleModalReducer,
    cabinetMenu: cabinetMenuModalReducer,
});
