var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EhealthError } from '../../enum/ehealth-error';
export var MAX_RETRIES = 2;
var Request = /** @class */ (function () {
    function Request(params) {
        this.authService = params.authService;
        this.retries = 0;
    }
    Object.defineProperty(Request.prototype, "aborter", {
        get: function () {
            if (!this.abortController) {
                this.abortController = new AbortController();
            }
            return this.abortController;
        },
        enumerable: false,
        configurable: true
    });
    Request.prototype.genericRequest = function (url, method, params, body) {
        return __awaiter(this, void 0, void 0, function () {
            var requestsDebounce, makeRequest, resp;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        requestsDebounce = 0;
                        makeRequest = function () {
                            var creds = _this.authService.getCreds();
                            var headers = {
                                Authorization: "Bearer ".concat(creds.EHealthAccessToken),
                            };
                            if (body) {
                                headers['Content-Type'] = 'application/json';
                            }
                            var urlWithParams = params ? "".concat(url, "?").concat(new URLSearchParams(params)) : url;
                            return new Promise(function (resolve, reject) {
                                clearTimeout(requestsDebounce);
                                if (_this.retries > MAX_RETRIES) {
                                    return reject(new Error(EhealthError.MAX_RETRIES));
                                }
                                requestsDebounce = window.setTimeout(function () {
                                    return fetch(urlWithParams, {
                                        method: method,
                                        body: body,
                                        headers: headers,
                                        signal: _this.aborter.signal,
                                    })
                                        .then(resolve)
                                        .catch(reject);
                                }, _this.calculateJitter());
                            });
                        };
                        _a.label = 1;
                    case 1:
                        if (!true) return [3 /*break*/, 5];
                        return [4 /*yield*/, makeRequest()];
                    case 2:
                        resp = _a.sent();
                        if (!(resp.status === 401)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.authService.reauth()];
                    case 3:
                        _a.sent();
                        this.retries--;
                        return [3 /*break*/, 1];
                    case 4:
                        if (resp.ok) {
                            this.retries = MAX_RETRIES;
                        }
                        return [2 /*return*/, resp];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * this method calculate timeout for requests retries timeout (if no retries - zero latency)
     */
    Request.prototype.calculateJitter = function () {
        return Math.round(this.retries * (Math.random() * (this.retries * 1000)));
    };
    Request.prototype.getWithAuth = function (url, params) {
        return this.genericRequest(url, 'GET', params);
    };
    Request.prototype.postWithAuth = function (url, params, body) {
        return this.genericRequest(url, 'POST', params, body);
    };
    Request.prototype.patchWithAuth = function (url, params, body) {
        return this.genericRequest(url, 'PATCH', params, body);
    };
    Request.prototype.putWithAuth = function (url, params, body) {
        return this.genericRequest(url, 'PUT', params, body);
    };
    Request.prototype.cancel = function () {
        if (!this.abortController) {
            return;
        }
        this.abortController.abort();
    };
    return Request;
}());
export { Request };
