import { Composition } from './composition';
import { SERVICE_EHEALTH_AUTH } from '../../auth';
import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_REMOVE_STORED_COMPOSITION_URL, PARAM_EHEALTH_CREATE_STORED_COMPOSITION_URL, PARAM_EHEALTH_SINGLE_STORED_COMPOSITION_URL, } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
import { SERVICE_EHEALTH_SIGN } from '../signature';
export var SERVICE_EHEALTH_COMPOSITION = 'SERVICE_EHEALTH_COMPOSITION';
export var ehealthCompositionFactory = function (container) {
    var authService = container.require(SERVICE_EHEALTH_AUTH);
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var compositionSingleUrl = "".concat(container.get(PARAM_EHEALTH_SINGLE_STORED_COMPOSITION_URL));
    var compositionRemoveUrl = "".concat(container.get(PARAM_EHEALTH_REMOVE_STORED_COMPOSITION_URL));
    var compositionCreateUrl = "".concat(container.get(PARAM_EHEALTH_CREATE_STORED_COMPOSITION_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var signService = container.require(SERVICE_EHEALTH_SIGN);
    return new Composition({
        authService: authService,
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        signService: signService,
        compositionSingleUrl: compositionSingleUrl,
        compositionRemoveUrl: compositionRemoveUrl,
        compositionCreateUrl: compositionCreateUrl,
    });
};
