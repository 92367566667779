import { useTranslation } from 'react-i18next';
import { getYears } from '../../../shared/util/date';
export function formatFullName(data) {
    return "".concat(data.last_name, " ").concat(data.first_name, " ").concat((data === null || data === void 0 ? void 0 : data.second_name) || '');
}
export function formatEmployeeFullName(employee) {
    return formatFullName(employee.party);
}
export function formatEducation(education) {
    return "".concat(education.city, ", ").concat(education.institution_name, ", ").concat(education.speciality);
}
export function usePersonFormatter() {
    var t = useTranslation().t;
    return function (person) { return "".concat(formatFullName(person), " (").concat(getYears(person.birth_date), " ").concat(t('years'), ")"); };
}
export function formatToTranslate(data) {
    var wordsArr = data.split(' ');
    var wordsArrChangeCase = wordsArr.map(function (word, index) {
        return index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return wordsArrChangeCase.join('');
}
