var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import pick from 'lodash/pick';
import { clearObject } from '../../../../../../../../assets/js/utils/etc';
import { ResponseError } from '../../../../../../shared/error/response-error';
import { getYears } from '../../../../../../shared/util/date';
import { uploadFile } from '../../../../../../shared/util/file';
import { getRelativeUrl } from '../../../../../../shared/util/url';
import { AuthenticationMethhod } from '../request/enum/AuthenticationMethhod';
import { AuthMethodAction } from './enum/auth-method-action';
import isEmpty from 'lodash/isEmpty';
var PersonService = /** @class */ (function () {
    function PersonService(params) {
        this.baseUrl = params.baseUrl;
        this.itemsPerPage = params.itemsPerPage;
        this.requestService = params.requestService;
        this.storageUrl = "".concat(params.storageUrl);
        this.getSingleStoredPersonUrl = params.getSingleStoredPersonUrl;
        this.getMoniHealPerson = params.getMoniHealPerson;
        this.assignMoniHealPersonUrl = params.assignMoniHealPersonUrl;
    }
    PersonService.prototype.search = function (page, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var preparedFilter, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        preparedFilter = clearObject(filter);
                        return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/persons"), __assign({}, preparedFilter))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.json()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PersonService.prototype.searchMergedPersons = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/persons/").concat(id, "/merged_persons"))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    PersonService.prototype.getDeclaration = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/persons/").concat(id, "/declaration"))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) return [3 /*break*/, 3];
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        json = _a.sent();
                        return [2 /*return*/, json.data];
                    case 3:
                        if (resp.status === 404 || resp.status === 403) {
                            return [2 /*return*/, null];
                        }
                        _a.label = 4;
                    case 4: throw new ResponseError(resp);
                }
            });
        });
    };
    PersonService.prototype.getAuthMethods = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/persons/").concat(id, "/authentication_methods"))];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        json = _a.sent();
                        if (!resp.ok || !json.data) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, json.data];
                }
            });
        });
    };
    PersonService.prototype.deleteAuthMethod = function (id, authMethodId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/persons/").concat(id, "/authentication_method_requests"), undefined, JSON.stringify({
                            action: AuthMethodAction.DEACTIVATE,
                            authentication_method: {
                                id: authMethodId,
                            },
                        }))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        json = _a.sent();
                        return [2 /*return*/, __assign(__assign({}, json.data), json.urgent)];
                }
            });
        });
    };
    PersonService.prototype.createAuthMethod = function (id, authMethod) {
        return __awaiter(this, void 0, void 0, function () {
            var payload, resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        payload = clearObject(pick(authMethod, ['type', 'phone_number', 'value', 'alias']));
                        switch (payload.type) {
                            case AuthenticationMethhod.OTP:
                                payload === null || payload === void 0 ? true : delete payload.value;
                                break;
                            case AuthenticationMethhod.OFFLINE:
                                payload === null || payload === void 0 ? true : delete payload.value;
                                payload === null || payload === void 0 ? true : delete payload.phone_number;
                                break;
                            case AuthenticationMethhod.THIRD_PERSON:
                                break;
                        }
                        return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/persons/").concat(id, "/authentication_method_requests"), undefined, JSON.stringify({
                                action: AuthMethodAction.INSERT,
                                authentication_method: payload,
                            }))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        json = _a.sent();
                        return [2 /*return*/, __assign(__assign({}, json.data), json.urgent)];
                }
            });
        });
    };
    PersonService.prototype.updateAuthMethod = function (id, authMethodId, alias) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/persons/").concat(id, "/authentication_method_requests"), undefined, JSON.stringify({
                            action: AuthMethodAction.UPDATE,
                            authentication_method: {
                                id: authMethodId,
                                alias: alias,
                            },
                        }))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        json = _a.sent();
                        return [2 /*return*/, __assign(__assign({}, json.data), json.urgent)];
                }
            });
        });
    };
    PersonService.prototype.approveAuthMethod = function (id, authMethodId, code) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.patchWithAuth("".concat(this.baseUrl, "/api/persons/").concat(id, "/authentication_method_requests/").concat(authMethodId, "/actions/approve"), undefined, code
                            ? JSON.stringify({
                                verification_code: +code.trim(),
                            })
                            : undefined)];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    PersonService.prototype.resendOTP = function (id, requestId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.postWithAuth("".concat(this.baseUrl, "/api/persons/").concat(id, "/authentication_method_requests/").concat(requestId, "/actions/resend_otp"))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [2 /*return*/, resp.json()];
                }
            });
        });
    };
    PersonService.prototype.uploadFile = function (url, file) {
        return uploadFile(file, "".concat(this.storageUrl).concat(getRelativeUrl(url)));
    };
    PersonService.prototype.getStoredPerson = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var params, _a, resp, verificationResp, personJson;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = new URLSearchParams({
                            id: id,
                        });
                        return [4 /*yield*/, Promise.all([
                                fetch("".concat(this.getSingleStoredPersonUrl, "?").concat(params), {
                                    credentials: 'include',
                                }),
                                this.getVerificationData(id),
                            ])];
                    case 1:
                        _a = __read.apply(void 0, [_b.sent(), 2]), resp = _a[0], verificationResp = _a[1];
                        if (!resp.ok) {
                            return [2 /*return*/, null];
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        personJson = _b.sent();
                        personJson.age = getYears(personJson.birth_date);
                        personJson.verification = verificationResp;
                        return [2 /*return*/, personJson];
                }
            });
        });
    };
    PersonService.prototype.searchMoniHealUser = function (filter) {
        return __awaiter(this, void 0, void 0, function () {
            var filterParams, params, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filterParams = clearObject(filter);
                        if (isEmpty(filterParams)) {
                            return [2 /*return*/, []];
                        }
                        params = new URLSearchParams(filterParams);
                        return [4 /*yield*/, fetch("".concat(this.getMoniHealPerson, "?").concat(params), {
                                credentials: 'include',
                            })];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.json()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PersonService.prototype.assignMoniHealUser = function (id, eHealthId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("".concat(this.assignMoniHealPersonUrl, "?id=").concat(id, "&eHealthId=").concat(eHealthId), {
                            credentials: 'include',
                        })];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.json()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PersonService.prototype.getVerificationData = function (personId) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/persons/").concat(personId, "/verification"))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    };
    PersonService.prototype.getEmergencyContact = function (personId, medical_event_type, medical_event_id) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestService.getWithAuth("".concat(this.baseUrl, "/api/patients/").concat(personId, "/").concat(medical_event_type, "/").concat(medical_event_id, "/emergency_contact"))];
                    case 1:
                        resp = _a.sent();
                        if (!resp.ok) {
                            throw new ResponseError(resp);
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return PersonService;
}());
export { PersonService };
