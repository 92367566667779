import { PARAM_COMPANY_CREATE_PAGE } from '../../../../../config/di/params/keys';
import { DiStrategy } from '../../../../../shared/service/di-container/enum/di-strategy';
import { normalizeOrigin } from '../../../../../shared/util/normalizer';
import { PARAM_EHEALTH_ALL_MEDICAL_PROGRAM_URL, PARAM_EHEALTH_ASSIGN_EMPLOYEE_COMPLETE_URL, PARAM_EHEALTH_ASSIGN_EMPLOYEE_REQUEST_URL, PARAM_EHEALTH_ASSIGN_MONI_HEAL_PERSON_URL, PARAM_EHEALTH_CREATE_JOB_URL, PARAM_EHEALTH_CREATE_STORED_COMPOSITION_URL, PARAM_EHEALTH_DELETE_JOB_URL, PARAM_EHEALTH_ENTITIES_JOB_URL, PARAM_EHEALTH_GET_MONI_HEAL_PERSON, PARAM_EHEALTH_GET_SERVICE_CATALOG_ITEMS_LIST_URL, PARAM_EHEALTH_GET_SERVICE_CATALOG_ITEM_URL, PARAM_EHEALTH_GET_STORED_PREPERSON_LIST_URL, PARAM_EHEALTH_GET_VACCINE_DISEASES_URL, PARAM_EHEALTH_LEGAL_ENTITY_GET_LIST_URL, PARAM_EHEALTH_LIST_JOB_URL, PARAM_EHEALTH_LOGIN_URL, PARAM_EHEALTH_LOGOUT_URL, PARAM_EHEALTH_MEDICAL_PROGRAM_FOR_MEDICATION_REQUEST_URL, PARAM_EHEALTH_PROGRAM_SERVICE_FOR_SERVICE_REQUEST_URL, PARAM_EHEALTH_MEDZAKUPIVLI_GET_VACCINES_BY_LOTS_URL, PARAM_EHEALTH_MEDZAKUPIVLI_GET_VACCINES_LIST_URL, PARAM_EHEALTH_PAYLOAD_FIND_SINGLE_URL, PARAM_EHEALTH_PAYLOAD_STORE_CREATE_URL, PARAM_EHEALTH_PAYLOAD_STORE_DELETE_URL, PARAM_EHEALTH_PAYLOAD_STORE_LIST_URL, PARAM_EHEALTH_PAYLOAD_STORE_SINGLE_URL, PARAM_EHEALTH_PERSON_REQUEST_SAVE, PARAM_EHEALTH_PERSON_REQUEST_SAVE_PERSON_ID, PARAM_EHEALTH_PERSON_REQUEST_SINGLE, PARAM_EHEALTH_REAUTH_URL, PARAM_EHEALTH_REGISTER_MOI_USER_URL, PARAM_EHEALTH_REMOVE_STORED_COMPOSITION_URL, PARAM_EHEALTH_SEARCH_IN_CATEGORY_SERVICE_CATALOG_URL, PARAM_EHEALTH_SINGLE_JOB_URL, PARAM_EHEALTH_SINGLE_MEDICAL_EVENT_URL, PARAM_EHEALTH_SINGLE_STORED_COMPOSITION_URL, PARAM_EHEALTH_SINGLE_STORED_PERSON_URL, PARAM_EHEALTH_STORAGE_PROXY_URL, PARAM_EHEALTH_UPDATE_JOB_URL, PARAM_MEDZAKUPIVLI_EHEALTH_PROXY_URL, PARAM_VERIFY_EMPLOYEE_ERROR, PARAM_EHEALTH_GET_MOI_LEGAL_ENTITIES, PARAM_EHEALTH_SAVE_REORGANIZE_EMPLOYEE_DECLARATION_URL, PARAM_EHEALTH_GET_REORGANIZE_DECLARATION_LIST_URL, PARAM_EHEALTH_CHANGE_REORGANIZE_DECLARATION_STATUS_URL, PARAM_SAVE_RELATED_LEGAL_ENTITIES_IDS_URL, PARAM_EHEALTH_EMPLOYEE_UPDATE_STATUS, } from './keys';
export var registerEhealthParams = function (container) {
    container.register(PARAM_EHEALTH_PERSON_REQUEST_SAVE, window.__moni.pageConfig.ehealth.urls.savePersonRequest, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_PERSON_REQUEST_SINGLE, window.__moni.pageConfig.ehealth.urls.singlePersonRequest, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_PERSON_REQUEST_SAVE_PERSON_ID, window.__moni.pageConfig.ehealth.urls.savePersonRequestPersonId, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_LOGIN_URL, window.__moni.pageConfig.ehealth.urls.auth, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_LOGOUT_URL, window.__moni.pageConfig.ehealth.urls.logout, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_REAUTH_URL, window.__moni.pageConfig.ehealth.urls.reauth, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_ASSIGN_EMPLOYEE_COMPLETE_URL, window.__moni.pageConfig.ehealth.urls.assignEmployeeComplete, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_ASSIGN_EMPLOYEE_REQUEST_URL, window.__moni.pageConfig.ehealth.urls.assignEmployeeRequest, DiStrategy.STATIC);
    container.register(PARAM_COMPANY_CREATE_PAGE, window.__moni.pageConfig.ehealth.urls.companyProfile, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_PAYLOAD_STORE_CREATE_URL, window.__moni.pageConfig.ehealth.urls.payloadStoreCreateUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_PAYLOAD_STORE_DELETE_URL, window.__moni.pageConfig.ehealth.urls.payloadStoreDeleteUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_PAYLOAD_STORE_LIST_URL, window.__moni.pageConfig.ehealth.urls.payloadStoreListUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_PAYLOAD_STORE_SINGLE_URL, window.__moni.pageConfig.ehealth.urls.payloadStoreSingleUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_PAYLOAD_FIND_SINGLE_URL, window.__moni.pageConfig.ehealth.urls.payloadFindSingleUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_LEGAL_ENTITY_GET_LIST_URL, window.__moni.pageConfig.ehealth.urls.getLegalEntityUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_REGISTER_MOI_USER_URL, window.__moni.pageConfig.ehealth.urls.registerMoiUser, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_CREATE_JOB_URL, window.__moni.pageConfig.ehealth.urls.jobCreateUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_DELETE_JOB_URL, window.__moni.pageConfig.ehealth.urls.jobDeleteUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_UPDATE_JOB_URL, window.__moni.pageConfig.ehealth.urls.jobUpdateUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_LIST_JOB_URL, window.__moni.pageConfig.ehealth.urls.jobListUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_SINGLE_JOB_URL, window.__moni.pageConfig.ehealth.urls.jobSingleUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_ENTITIES_JOB_URL, window.__moni.pageConfig.ehealth.urls.jobEntitiesUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_SINGLE_MEDICAL_EVENT_URL, window.__moni.pageConfig.ehealth.urls.medicalEventSingleUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_ALL_MEDICAL_PROGRAM_URL, window.__moni.pageConfig.ehealth.urls.allMedicalProgramsUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_MEDICAL_PROGRAM_FOR_MEDICATION_REQUEST_URL, window.__moni.pageConfig.ehealth.urls.medicalProgramsForMedicationRequestUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_PROGRAM_SERVICE_FOR_SERVICE_REQUEST_URL, window.__moni.pageConfig.ehealth.urls.programServiceForServiceRequestUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_GET_VACCINE_DISEASES_URL, window.__moni.pageConfig.ehealth.urls.getVaccineDiseasesUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_SEARCH_IN_CATEGORY_SERVICE_CATALOG_URL, window.__moni.pageConfig.ehealth.urls.searchInCategoryUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_GET_SERVICE_CATALOG_ITEM_URL, window.__moni.pageConfig.ehealth.urls.getServiceCatalogItemUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_GET_SERVICE_CATALOG_ITEMS_LIST_URL, window.__moni.pageConfig.ehealth.urls.getServiceCatalogItemsListUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_SINGLE_STORED_PERSON_URL, window.__moni.pageConfig.ehealth.urls.getSingleStoredPerson, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_GET_MONI_HEAL_PERSON, window.__moni.pageConfig.ehealth.urls.getMoniHealPerson, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_ASSIGN_MONI_HEAL_PERSON_URL, window.__moni.pageConfig.ehealth.urls.assignMoniHealPersonUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_SINGLE_STORED_COMPOSITION_URL, window.__moni.pageConfig.ehealth.urls.compositionSingleUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_CREATE_STORED_COMPOSITION_URL, window.__moni.pageConfig.ehealth.urls.compositionCreateUrl, DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_REMOVE_STORED_COMPOSITION_URL, window.__moni.pageConfig.ehealth.urls.compositionRemoveUrl, DiStrategy.STATIC);
    container.register(PARAM_MEDZAKUPIVLI_EHEALTH_PROXY_URL, normalizeOrigin(window.__moni.pageConfig.ehealth.urls.vaccinesMedzakupivliProxyUrl), DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_STORAGE_PROXY_URL, normalizeOrigin(window.__moni.pageConfig.ehealth.urls.storeProxyUrl), DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_MEDZAKUPIVLI_GET_VACCINES_LIST_URL, normalizeOrigin(window.__moni.pageConfig.ehealth.urls.medzakupivliVaccinesListUrl), DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_MEDZAKUPIVLI_GET_VACCINES_BY_LOTS_URL, normalizeOrigin(window.__moni.pageConfig.ehealth.urls.medzakupivliVaccinesListByLotsUrl), DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_GET_STORED_PREPERSON_LIST_URL, normalizeOrigin(window.__moni.pageConfig.ehealth.urls.getStoredPrepersonList), DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_GET_MOI_LEGAL_ENTITIES, normalizeOrigin(window.__moni.pageConfig.ehealth.urls.getMoiLegalEntitiesUrl), DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_SAVE_REORGANIZE_EMPLOYEE_DECLARATION_URL, normalizeOrigin(window.__moni.pageConfig.ehealth.urls.saveReorganizeEmployeeDeclarationUrl), DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_GET_REORGANIZE_DECLARATION_LIST_URL, normalizeOrigin(window.__moni.pageConfig.ehealth.urls.getReorganizeDeclarationListUrl), DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_CHANGE_REORGANIZE_DECLARATION_STATUS_URL, normalizeOrigin(window.__moni.pageConfig.ehealth.urls.changeReorganizeDeclarationStatusUrl), DiStrategy.STATIC);
    container.register(PARAM_SAVE_RELATED_LEGAL_ENTITIES_IDS_URL, normalizeOrigin(window.__moni.pageConfig.ehealth.urls.saveRelatedLegalEntitiesIdsUrl), DiStrategy.STATIC);
    container.register(PARAM_VERIFY_EMPLOYEE_ERROR, window.__moni.pageConfig.ehealth.verification.error || '', DiStrategy.STATIC);
    container.register(PARAM_EHEALTH_EMPLOYEE_UPDATE_STATUS, window.__moni.pageConfig.ehealth.urls.updateEmployeeStatus, DiStrategy.STATIC);
};
