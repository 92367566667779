import { createUseStyles } from 'react-jss';
export var useStyles = createUseStyles({
    activePopup: {},
    menuWrapper: {
        '&.cabinet-mobile-menu': {
            overflowY: 'auto',
        },
        '&$activePopup': {
            display: 'block',
        },
    },
});
