var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ErrorTypes } from '../enum/error/error-types';
import set from 'lodash/set';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { hashString } from './file';
import { clearObject } from '../../../../assets/js/utils/etc';
export function normalizeOrigin(url) {
    return url.replace(/\/+$/is, '').trim();
}
export function createHashTable(items, field, keyFilter) {
    return items.reduce(function (table, item) {
        var key = get(item, field);
        table[keyFilter ? keyFilter(key) : key] = item;
        return table;
    }, {});
}
export function toMap(items, keyResolver) {
    return items.reduce(function (acc, curr) {
        acc.set(keyResolver(curr), curr);
        return acc;
    }, new Map());
}
export function getFirstLeaf(tree) {
    while (true) {
        var subtree = Object.values(tree)[0];
        if (typeof subtree !== 'object') {
            return subtree;
        }
        tree = subtree;
    }
}
export function parseEHealthErrors(t, arrayOfErrors) {
    var e_1, _a, e_2, _b;
    arrayOfErrors = getErrorsArray(arrayOfErrors);
    var errors = {};
    try {
        for (var arrayOfErrors_1 = __values(arrayOfErrors), arrayOfErrors_1_1 = arrayOfErrors_1.next(); !arrayOfErrors_1_1.done; arrayOfErrors_1_1 = arrayOfErrors_1.next()) {
            var error = arrayOfErrors_1_1.value;
            try {
                for (var _c = (e_2 = void 0, __values(error.rules)), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var rule = _d.value;
                    if (!error.entry) {
                        continue;
                    }
                    set(errors, "".concat(error.entry.replace('$.', '')), [getErrorTranslations(t, rule)]);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (arrayOfErrors_1_1 && !arrayOfErrors_1_1.done && (_a = arrayOfErrors_1.return)) _a.call(arrayOfErrors_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return errors;
}
export function parseEhealthErrorForFormik(t, err) {
    var e_3, _a;
    var _b;
    var parsedErrors = {};
    try {
        for (var _c = __values(((_b = err === null || err === void 0 ? void 0 : err.error) === null || _b === void 0 ? void 0 : _b.invalid) || []), _d = _c.next(); !_d.done; _d = _c.next()) {
            var invalid = _d.value;
            var key = invalid.entry.replace('$.', '');
            set(parsedErrors, key, getErrorTranslations(t, invalid.rules[0]));
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
        }
        finally { if (e_3) throw e_3.error; }
    }
    return parsedErrors;
}
function getErrorsArray(arrayOfErrors) {
    if (arrayOfErrors.error && arrayOfErrors.error.invalid) {
        return arrayOfErrors.error.invalid;
    }
    if (arrayOfErrors.error && arrayOfErrors.error.message) {
        var arrayOfMessages = [
            {
                entry: arrayOfErrors.error.type,
                rules: [
                    {
                        description: arrayOfErrors.error.message,
                    },
                ],
            },
        ];
        return arrayOfMessages;
    }
    return arrayOfErrors;
}
export function clearAndSerialize(obj, paths) {
    if (paths) {
        obj = pick(obj, paths);
    }
    return JSON.stringify(clearObject(obj));
}
export function getResponseErrorData(err) {
    if (!err.response) {
        throw err;
    }
    return err.response.json();
}
function getErrorTranslations(t, rule) {
    var errorText;
    switch (rule.rule) {
        case ErrorTypes.length: {
            var transKey = 'eHealth.error.length.is';
            if (rule.params.min) {
                transKey = 'eHealth.error.length.min';
            }
            else if (rule.params.max) {
                transKey = 'eHealth.error.length.max';
            }
            else if (rule.params.actual && rule.params.min) {
                transKey = 'eHealth.error.length.actual_min';
            }
            errorText = t(transKey, rule.params);
            break;
        }
        case ErrorTypes.format: {
            errorText = t('eHealth.error.format', rule.params);
            break;
        }
        case ErrorTypes.cast: {
            errorText = t('eHealth.error.cast', rule.params);
            break;
        }
        case ErrorTypes.required: {
            errorText = t('requiredField');
            break;
        }
        case ErrorTypes.inclusion: {
            var values = rule.params.values && rule.params.values instanceof Array
                ? rule.params.values
                : rule.params instanceof Array
                    ? rule.params
                    : [];
            errorText = t('eHealth.error.inclusion', {
                list: values.map(function (value) { return t("errorParam.".concat(value)); }).join(', '),
            });
            break;
        }
        case ErrorTypes.exclusion: {
            errorText = t('eHealth.error.exclusion', {
                enum: rule.params.enum.join(', '),
            });
            break;
        }
        case ErrorTypes.subset: {
            errorText = t('eHealth.error.subset', {
                set: rule.params.set.join(', '),
            });
            break;
        }
        case ErrorTypes.number: {
            if (rule.params.greater_than) {
                errorText = t('eHealth.error.number.greaterThan', rule.params);
                break;
            }
            errorText = rule.description;
            break;
        }
        case ErrorTypes.confirmation: {
            errorText = t('eHealth.error.confirmation');
            break;
        }
        case ErrorTypes.acceptance: {
            errorText = t('eHealth.error.acceptance');
            break;
        }
        case ErrorTypes.email: {
            errorText = t('eHealth.error.email');
            break;
        }
        case ErrorTypes.phone_number: {
            errorText = t('eHealth.error.phone_number');
            break;
        }
        case ErrorTypes.card_number: {
            errorText = t('eHealth.error.card_number');
            break;
        }
        case ErrorTypes.metadata: {
            errorText = t('eHealth.error.metadata');
            break;
        }
        case ErrorTypes.unique: {
            errorText = t('eHealth.error.unique');
            break;
        }
        case ErrorTypes.schemata: {
            errorText = t('eHealth.error.schemata');
            break;
        }
        case ErrorTypes.schema: {
            errorText = t('eHealth.error.schema');
            if (rule.params.properties) {
                errorText = t('eHealth.error.schema.properties', {
                    props: Object.keys(rule.params.properties)
                        .map(function (key) { return t("errorParam.".concat(key)); })
                        .join(', '),
                });
            }
            break;
        }
        case ErrorTypes.dependency: {
            errorText = t('eHealth.error.dependency');
            break;
        }
        case ErrorTypes.json: {
            errorText = t('eHealth.error.json');
            break;
        }
        case ErrorTypes.authentification_code: {
            errorText = t('eHealth.error.authentification_code');
            break;
        }
        case ErrorTypes.date: {
            errorText = rule.description;
            if (rule.params.pattern && rule.params.actual) {
                errorText = t('eHealth.error.date.invalid', rule.params);
            }
            break;
        }
        case ErrorTypes.datetime: {
            errorText = rule.description;
            if (rule.params.pattern && rule.params.actual) {
                errorText = t('eHealth.error.date.invalid', rule.params);
            }
            break;
        }
        case ErrorTypes.timestamp: {
            errorText = rule.description;
            if (rule.params.pattern && rule.params.actual) {
                errorText = t('eHealth.error.date.invalid', rule.params);
            }
            break;
        }
        case ErrorTypes.url: {
            errorText = t('eHealth.error.url');
            break;
        }
        default: {
            errorText = translateError(t, rule.description);
            break;
        }
    }
    return errorText;
}
export function translateError(t, str, params) {
    var stringHash = hashString(str);
    console.log(stringHash, str);
    return t("eHealth.backErrors.".concat(stringHash), str, params);
}
export function pluck(arr, key) {
    // @ts-ignore
    return arr.map(function (item) { return get(item, key); });
}
export function getMedicalEventFromUrlResponseError(url) {
    var basePathPattern = 'http://[^/]+';
    var urlPattern = '/api/:patients/:patientid/:typeME/:typeMEId';
    var fullPattern = basePathPattern + urlPattern.replace(/:\w+/g, '([^/]+)');
    var regexp = new RegExp(fullPattern);
    var match = url.match(regexp);
    if (!match) {
        return null;
    }
    var params = {};
    var keys = __spreadArray([], __read(urlPattern.matchAll(/:([^/]+)/g)), false).map(function (result) {
        return result[1];
    });
    keys.forEach(function (key, index) {
        params[key] = match[index + 1];
    });
    return params.typeME;
}
