import { PARAM_ITEMS_IN_AUTOCOMPLETE } from '../../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_STORAGE_PROXY_URL } from '../../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../../request';
import { SERVICE_EHEALTH_SIGN } from '../../signature';
import { DeclarationRequestService } from './declaration-request';
export var SERVICE_EHEALTH_DECLARATION_REQUEST = 'SERVICE_EHEALTH_DECLARATION_REQUEST';
export var ehealthDeclarationRequestFactory = function (container) {
    var requestService = container.require(SERVICE_REQUEST);
    var signService = container.require(SERVICE_EHEALTH_SIGN);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var storageUrl = "".concat(container.get(PARAM_EHEALTH_STORAGE_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_IN_AUTOCOMPLETE) || 0);
    return new DeclarationRequestService({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        signService: signService,
        storageUrl: storageUrl,
    });
};
