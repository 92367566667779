import { ehealthDictionaryFactory, SERVICE_EHEALTH_DICT } from '../../../app/ehealth/service/ehealth/dictionary';
import { SERVICE_EHEALTH_SETTLEMENT, ehealthSettlementServiceFactory, } from '../../../app/ehealth/service/ehealth/settlement';
import { SERVICE_EHEALTH_SIGN, ehealthSignatureFactory } from '../../../app/ehealth/service/ehealth/signature';
import { queueFactory, SERVICE_QUEUE_FACTORY } from '../../../app/ehealth/service/queue';
import { clipboardServiceFactory, SERVICE_CLIPBOARD } from '../../../shared/service/clipboard';
import { deviceServiceFactory, SERVICE_DEVICE } from '../../../shared/service/device';
import { geocoderServiceFactory, SERVICE_GEOCODER } from '../../../shared/service/geocoder';
import { localizeServiceFactory, SERVICE_LOCALIZE } from '../../../shared/service/localize';
import { navConfiguratorFactory, SERVICE_NAV_CONFIGURATOR } from '../../../shared/service/nav';
import { SERVICE_SPA_ROUTER, spaRouterFactory } from '../../../shared/service/router';
export var configureServices = function (container) {
    container.register(SERVICE_QUEUE_FACTORY, queueFactory);
    container.register(SERVICE_SPA_ROUTER, spaRouterFactory);
    container.register(SERVICE_NAV_CONFIGURATOR, navConfiguratorFactory);
    container.register(SERVICE_DEVICE, deviceServiceFactory);
    container.register(SERVICE_LOCALIZE, localizeServiceFactory);
    container.register(SERVICE_GEOCODER, geocoderServiceFactory);
    container.register(SERVICE_CLIPBOARD, clipboardServiceFactory);
    container.register(SERVICE_EHEALTH_SETTLEMENT, ehealthSettlementServiceFactory);
    container.register(SERVICE_EHEALTH_SIGN, ehealthSignatureFactory);
    container.register(SERVICE_EHEALTH_DICT, ehealthDictionaryFactory);
};
