import { PARAM_MOI_EXPERT_BOOKINGS_CREATE_BOOKED_SERVICE_URL, PARAM_MOI_EXPERT_BOOKINGS_CREATE_UPDATE_SERVICE_URL, PARAM_MOI_EXPERT_BOOKINGS_DELETE_SERVICE_URL, PARAM_MOI_EXPERT_BOOKINGS_GET_ALL_SERVICES_URL, PARAM_MOI_EXPERT_BOOKINGS_GET_BOOKED_SERVICES_URL, PARAM_MOI_EXPERT_BOOKINGS_GET_FREE_HOURS_URL, } from '../../config/di/params/keys';
import { ExpertScheduleOfAppointments } from './expert-schedule-appointments';
export var SERVICE_MOI_EXPERT_SCHEDULE_OF_APPOINTMENTS = 'SERVICE_MOI_EXPERT_SCHEDULE_OF_APPOINTMENTS';
export function expertScheduleOfAppointmentsFactory(container) {
    var getExpertBookedServicesListUrl = "".concat(container.get(PARAM_MOI_EXPERT_BOOKINGS_GET_BOOKED_SERVICES_URL));
    var createExpertBookedServiceUrl = "".concat(container.get(PARAM_MOI_EXPERT_BOOKINGS_CREATE_BOOKED_SERVICE_URL));
    var getExpertServicesListUrl = "".concat(container.get(PARAM_MOI_EXPERT_BOOKINGS_GET_ALL_SERVICES_URL));
    var createUpdateExpertServiceUrl = "".concat(container.get(PARAM_MOI_EXPERT_BOOKINGS_CREATE_UPDATE_SERVICE_URL));
    var deleteExpertServiceUrl = "".concat(container.get(PARAM_MOI_EXPERT_BOOKINGS_DELETE_SERVICE_URL));
    var getExpertFreeHoursUrl = "".concat(container.get(PARAM_MOI_EXPERT_BOOKINGS_GET_FREE_HOURS_URL));
    return new ExpertScheduleOfAppointments({
        getExpertBookedServicesListUrl: getExpertBookedServicesListUrl,
        createExpertBookedServiceUrl: createExpertBookedServiceUrl,
        getExpertServicesListUrl: getExpertServicesListUrl,
        createUpdateExpertServiceUrl: createUpdateExpertServiceUrl,
        deleteExpertServiceUrl: deleteExpertServiceUrl,
        getExpertFreeHoursUrl: getExpertFreeHoursUrl,
    });
}
