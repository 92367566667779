var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BookingServicesActionType } from './action';
import { initialState } from './state';
export var servicesAsyncReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case BookingServicesActionType.SET_DATA_FALSE:
            return __assign(__assign({}, state), { isServiceAdded: false, loading: false, error: null });
        case BookingServicesActionType.GET_SERVICES_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null });
        case BookingServicesActionType.GET_SERVICES_SUCCESS:
            return __assign(__assign({}, state), { loading: false, services: action.payload });
        case BookingServicesActionType.GET_SERVICES_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload });
        case BookingServicesActionType.ADD_SERVICE_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null });
        case BookingServicesActionType.ADD_SERVICE_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isServiceAdded: true, selectedService: {
                    serviceId: null,
                    clientId: null,
                    description: null,
                    date: null,
                    startTime: null,
                    type: 'offline',
                    isNotifyClient: false,
                    notifyClientType: 'email',
                    clientEmail: null,
                    clientPhone: null,
                } });
        case BookingServicesActionType.ADD_SERVICE_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, selectedService: {
                    serviceId: null,
                    clientId: null,
                    description: null,
                    date: null,
                    startTime: null,
                    type: 'offline',
                    isNotifyClient: false,
                    notifyClientType: 'email',
                    clientEmail: null,
                    clientPhone: null,
                } });
        case BookingServicesActionType.SET_SELECTED_DATE:
            return __assign(__assign({}, state), { loading: false, selectedService: __assign(__assign({}, state.selectedService), { date: action.payload }) });
        case BookingServicesActionType.SET_SELECTED_TIME:
            return __assign(__assign({}, state), { loading: false, selectedService: __assign(__assign({}, state.selectedService), { startTime: action.payload }) });
        case BookingServicesActionType.SET_SELECTED_SERVICE:
            return __assign(__assign({}, state), { loading: false, selectedService: __assign(__assign({}, state.selectedService), { serviceId: action.payload }) });
        case BookingServicesActionType.SET_SELECTED_CLIENT:
            return __assign(__assign({}, state), { loading: false, selectedService: __assign(__assign({}, state.selectedService), { clientId: action.payload }) });
        case BookingServicesActionType.SET_SELECTED_IS_NOTIFY:
            return __assign(__assign({}, state), { loading: false, selectedService: __assign(__assign({}, state.selectedService), { isNotifyClient: action.payload }) });
        case BookingServicesActionType.SET_SELECTED_NOTIFY_TYPE:
            return __assign(__assign({}, state), { loading: false, selectedService: __assign(__assign({}, state.selectedService), { notifyClientType: action.payload }) });
        case BookingServicesActionType.SET_SELECTED_EMAIL:
            return __assign(__assign({}, state), { loading: false, selectedService: __assign(__assign({}, state.selectedService), { clientEmail: action.payload }) });
        case BookingServicesActionType.SET_SELECTED_PHONE:
            return __assign(__assign({}, state), { loading: false, selectedService: __assign(__assign({}, state.selectedService), { clientPhone: action.payload }) });
        case BookingServicesActionType.SET_SELECTED_DESCRIPTION:
            return __assign(__assign({}, state), { loading: false, selectedService: __assign(__assign({}, state.selectedService), { description: action.payload }) });
        default:
            return state;
    }
};
