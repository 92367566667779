var _a;
import { EmployeeType } from '../enum/employee.type';
export var EMPLOYEE_TYPE_TO_QUALIFICATION_TYPE = (_a = {},
    _a[EmployeeType.DOCTOR] = {
        CLINICAL_RESIDENCY: true,
        INTERNSHIP: true,
        REATTESTATION: true,
        SPECIALIZATION: true,
        STAZHUVANNYA: true,
        POSTGRADUATE: true,
        TOPIC_IMPROVEMENT: true,
    },
    _a[EmployeeType.SPECIALIST] = {
        CLINICAL_RESIDENCY: true,
        INTERNSHIP: true,
        REATTESTATION: true,
        SPECIALIZATION: true,
        STAZHUVANNYA: true,
        POSTGRADUATE: true,
        TOPIC_IMPROVEMENT: true,
    },
    _a[EmployeeType.ASSISTANT] = {
        CLINICAL_RESIDENCY: true,
        INTERNSHIP: true,
        REATTESTATION: true,
        SPECIALIZATION: true,
        STAZHUVANNYA: true,
        POSTGRADUATE: true,
        TOPIC_IMPROVEMENT: true,
    },
    _a[EmployeeType.PHARMASIST] = {
        REATTESTATION: true,
        SPECIALIZATION: true,
        STAZHUVANNYA: true,
        POSTGRADUATE: true,
    },
    _a[EmployeeType.MED_COORDINATOR] = {
        INFORMATION_COURSES: true,
        STAZHUVANNYA: true,
    },
    _a[EmployeeType.LABORANT] = {
        REATTESTATION: true,
        SPECIALIZATION: true,
        CLINICAL_RESIDENCY: true,
        INTERNSHIP: true,
        STAZHUVANNYA: true,
        POSTGRADUATE: true,
        TOPIC_IMPROVEMENT: true,
    },
    _a);
