export var EmployeeType;
(function (EmployeeType) {
    EmployeeType["DOCTOR"] = "DOCTOR";
    EmployeeType["SPECIALIST"] = "SPECIALIST";
    EmployeeType["PHARMACIST"] = "PHARMACIST";
    EmployeeType["ASSISTANT"] = "ASSISTANT";
    EmployeeType["ADMIN"] = "ADMIN";
    EmployeeType["HR"] = "HR";
    EmployeeType["OWNER"] = "OWNER";
    EmployeeType["PHARMACY_OWNER"] = "PHARMACY_OWNER";
    EmployeeType["RECEPTIONIST"] = "RECEPTIONIST";
    EmployeeType["MED_ADMIN"] = "MED_ADMIN";
    EmployeeType["PHARMASIST"] = "PHARMASIST";
    EmployeeType["LABORANT"] = "LABORANT";
    EmployeeType["MED_COORDINATOR"] = "MED_COORDINATOR";
})(EmployeeType || (EmployeeType = {}));
