var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fetchGetClinicAddressBegin, fetchGetClinicAddressFailure, fetchGetClinicAddressSuccess, fetchGetClinicBegin, fetchGetClinicFailure, fetchGetClinicSuccess, fetchGetClinicTypesBegin, fetchGetClinicTypesFailure, fetchGetClinicTypesSuccess, fetchSetClinicAdditionalBegin, fetchSetClinicAdditionalFailure, fetchSetClinicAdditionalSuccess, fetchSetClinicAddressBegin, fetchSetClinicAddressFailure, fetchSetClinicAddressSuccess, fetchSetClinicBegin, fetchSetClinicFailure, fetchSetClinicSuccess, } from './factory';
export var getClinicInformation = function () {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(fetchGetClinicBegin());
            try {
                fetch('/api/v1/company/profile')
                    .then(function (resp) {
                    if (resp.status === 200) {
                        return resp.json();
                    }
                    dispatch(fetchGetClinicFailure(resp.statusText));
                })
                    .then(function (data) {
                    dispatch(fetchGetClinicSuccess(data.company));
                });
            }
            catch (err) {
                dispatch(fetchGetClinicFailure(err));
            }
            return [2 /*return*/];
        });
    }); };
};
export var setClinicInformation = function (data) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var formData;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __generator(this, function (_l) {
            dispatch(fetchSetClinicBegin());
            formData = new FormData();
            if (data.license)
                formData.append('license', data.license);
            if (data.logo)
                formData.append('logo', data.logo);
            if ((_a = data.company) === null || _a === void 0 ? void 0 : _a.full_name)
                formData.append('company[full_name]', data.company.full_name);
            if ((_b = data.company) === null || _b === void 0 ? void 0 : _b.short_name)
                formData.append('company[short_name]', data.company.short_name);
            if (data.type)
                formData.append('company[type]', data.type);
            if ((_c = data.company) === null || _c === void 0 ? void 0 : _c.city)
                formData.append('company[city]', data.company.city);
            if ((_d = data.company) === null || _d === void 0 ? void 0 : _d.email)
                formData.append('company[email]', data.company.email);
            if (data.phone)
                formData.append('company[phone]', data.phone);
            if ((_e = data.company) === null || _e === void 0 ? void 0 : _e.work_phone)
                formData.append('company[work_phone]', data.company.work_phone);
            if ((_f = data.company) === null || _f === void 0 ? void 0 : _f.stationary_phone)
                formData.append('company[stationary_phone]', data.company.stationary_phone);
            if ((_g = data.company) === null || _g === void 0 ? void 0 : _g.webAddress)
                formData.append('company[webAddress]', data.company.webAddress);
            if ((_h = data.company) === null || _h === void 0 ? void 0 : _h.certificates)
                formData.append('company[certificates]', data.company.certificates);
            if ((_j = data.company) === null || _j === void 0 ? void 0 : _j.accreditation)
                formData.append('company[accreditation]', data.company.accreditation);
            if ((_k = data.company) === null || _k === void 0 ? void 0 : _k.institutionDocument)
                formData.append('company[institutionDocument]', data.company.institutionDocument);
            try {
                fetch('/api/v1/company/save-data', {
                    method: 'POST',
                    body: formData,
                }).then(function (resp) {
                    if (resp.status == 200) {
                        dispatch(fetchSetClinicSuccess());
                    }
                    return resp.json();
                });
            }
            catch (err) {
                dispatch(fetchSetClinicFailure(err));
            }
            return [2 /*return*/];
        });
    }); };
};
export var setClinicAdditionalInformation = function (data) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(fetchSetClinicAdditionalBegin());
            try {
                fetch('/api/v1/company/save-description', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ description: data.description }),
                }).then(function (resp) {
                    if (resp.status == 200) {
                        dispatch(fetchSetClinicAdditionalSuccess());
                    }
                    else {
                        dispatch(fetchSetClinicAdditionalFailure(resp.statusText));
                    }
                    return resp.json();
                });
            }
            catch (err) {
                dispatch(fetchSetClinicAdditionalFailure(err));
            }
            return [2 /*return*/];
        });
    }); };
};
export var getClinicTypes = function () {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(fetchGetClinicTypesBegin());
            try {
                fetch('/api/v1/company/types')
                    .then(function (resp) {
                    if (resp.status === 200) {
                        return resp.json();
                    }
                    dispatch(fetchGetClinicTypesFailure(resp.statusText));
                })
                    .then(function (data) {
                    dispatch(fetchGetClinicTypesSuccess(data.types));
                });
            }
            catch (err) {
                dispatch(fetchGetClinicTypesFailure(err));
            }
            return [2 /*return*/];
        });
    }); };
};
export var getClinicAddress = function () {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(fetchGetClinicAddressBegin());
            try {
                fetch('/api/v1/company/fetch-address-information')
                    .then(function (resp) {
                    if (resp.status === 200) {
                        return resp.json();
                    }
                    dispatch(fetchGetClinicAddressFailure(resp.statusText));
                })
                    .then(function (data) {
                    dispatch(fetchGetClinicAddressSuccess(data));
                });
            }
            catch (err) {
                dispatch(fetchGetClinicAddressFailure(err));
            }
            return [2 /*return*/];
        });
    }); };
};
export var setClinicAddress = function (data) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(fetchSetClinicAddressBegin());
            console.log(data);
            try {
                fetch('/api/v1/company/save-address-information', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                }).then(function (resp) {
                    console.log(resp);
                    if (resp.status == 200) {
                        dispatch(fetchSetClinicAddressSuccess());
                    }
                    else {
                        dispatch(fetchSetClinicAddressFailure(resp.statusText));
                    }
                    return resp.json();
                });
            }
            catch (err) {
                dispatch(fetchSetClinicAddressFailure(err));
            }
            return [2 /*return*/];
        });
    }); };
};
