import { EmployeeRequestService } from './employee-request';
import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL, PARAM_EHEALTH_REGISTER_MOI_USER_URL } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
import { SERVICE_EHEALTH_SIGN } from '../signature';
export var SERVICE_EHEALTH_EMPLOYEE_REQUEST = 'SERVICE_EHEALTH_EMPLOYEE_REQUEST';
export var ehealthEmployeeRequestFactory = function (container) {
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    var requestService = container.require(SERVICE_REQUEST);
    var registerMoiUserUrl = "".concat(container.get(PARAM_EHEALTH_REGISTER_MOI_USER_URL));
    var signService = container.require(SERVICE_EHEALTH_SIGN);
    return new EmployeeRequestService({
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
        registerMoiUserUrl: registerMoiUserUrl,
        signService: signService,
    });
};
