export var ClinicNewsActionType;
(function (ClinicNewsActionType) {
    ClinicNewsActionType["GET_CLINIC_NEWS_PENDING"] = "GET_CLINIC_NEWS_PENDING";
    ClinicNewsActionType["GET_CLINIC_NEWS_SUCCESS"] = "GET_CLINIC_NEWS_SUCCESS";
    ClinicNewsActionType["GET_CLINIC_NEWS_ERROR"] = "GET_CLINIC_NEWS_ERROR";
    ClinicNewsActionType["GET_CLINIC_NEWS_TAGS_PENDING"] = "GET_CLINIC_NEWS_TAGS_PENDING";
    ClinicNewsActionType["GET_CLINIC_NEWS_TAGS_SUCCESS"] = "GET_CLINIC_NEWS_TAGS_SUCCESS";
    ClinicNewsActionType["GET_CLINIC_NEWS_TAGS_ERROR"] = "GET_CLINIC_NEWS_TAGS_ERROR";
    ClinicNewsActionType["ADD_CLINIC_NEWS_PENDING"] = "ADD_CLINIC_NEWS_PENDING";
    ClinicNewsActionType["ADD_CLINIC_NEWS_SUCCESS"] = "ADD_CLINIC_NEWS_SUCCESS";
    ClinicNewsActionType["ADD_CLINIC_NEWS_ERROR"] = "ADD_CLINIC_NEWS_ERROR";
    ClinicNewsActionType["EDIT_CLINIC_NEWS_PENDING"] = "EDIT_CLINIC_NEWS_PENDING";
    ClinicNewsActionType["EDIT_CLINIC_NEWS_SUCCESS"] = "EDIT_CLINIC_NEWS_SUCCESS";
    ClinicNewsActionType["EDIT_CLINIC_NEWS_ERROR"] = "EDIT_CLINIC_NEWS_ERROR";
    ClinicNewsActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
    ClinicNewsActionType["SET_SINGLE_CLINIC_NEWS"] = "SET_SINGLE_CLINIC_NEWS";
})(ClinicNewsActionType || (ClinicNewsActionType = {}));
