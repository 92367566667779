export var ChatMessageTemplateAction;
(function (ChatMessageTemplateAction) {
    ChatMessageTemplateAction["ADD"] = "MOI_CHAT_MESSAGE_TEMPLATE_ADD";
    ChatMessageTemplateAction["ADD_ITEMS"] = "MOI_CHAT_MESSAGE_TEMPLATE_ADD_ITEMS";
    ChatMessageTemplateAction["UPDATE"] = "MOI_CHAT_MESSAGE_TEMPLATE_UPDATE";
    ChatMessageTemplateAction["REMOVE"] = "MOI_CHAT_MESSAGE_TEMPLATE_REMOVE";
    ChatMessageTemplateAction["SET_IS_LOADING"] = "MOI_CHAT_MESSAGE_TEMPLATE_SET_IS_LOADING";
    ChatMessageTemplateAction["SET_CAN_SEND"] = "MOI_CHAT_MESSAGE_TEMPLATE_SET_CAN_SEND";
    ChatMessageTemplateAction["SET_EDITABLE"] = "MOI_CHAT_MESSAGE_TEMPLATE_SET_EDITABLE";
    ChatMessageTemplateAction["SET_LAST_MAILING"] = "MOI_CHAT_MESSAGE_TEMPLATE_SET_LAST_MAILING";
    ChatMessageTemplateAction["SET_NEXT_AVAILABLE_DATE"] = "MOI_CHAT_MESSAGE_TEMPLATE_SET_NEXT_AVAILABLE_DATE";
})(ChatMessageTemplateAction || (ChatMessageTemplateAction = {}));
