import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from '../HeaderCabinetStyle';
import { ArrowDownWhite } from '../assets/ArrowDownWhite';
import { ArrowUpGreen } from '../assets/ArrowUpGreen';
export var CabinetProfile = function (_a) {
    var isOpen = _a.isOpen;
    var classes = useStyles();
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: classes.CabinetWrapper },
            React.createElement(Box, { sx: { margin: '4px', marginRight: '10px' } },
                React.createElement("img", { src: require("../assets/img.jpg"), style: { borderRadius: '50%' } })),
            React.createElement(Typography, { className: classes.customTypography }, "\u041A\u0430\u0431\u0456\u043D\u0435\u0442"),
            React.createElement(Box, { sx: { margin: '10px 12px' } },
                React.createElement(Box, { sx: { display: 'flex', justifyContent: 'center', alignItems: 'center' } }, isOpen ? React.createElement(ArrowUpGreen, null) : React.createElement(ArrowDownWhite, null))))));
};
