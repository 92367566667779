export var Dictionary;
(function (Dictionary) {
    Dictionary["MEDICATION_REQUEST_INTENT"] = "MEDICATION_REQUEST_INTENT";
    Dictionary["EHEALTH_REFERENCE_RANGE_APPLICATIONS"] = "eHealth/reference_range_applications";
    Dictionary["EHEALTH_HAIR_COLOR"] = "eHealth/hair_color";
    Dictionary["EHEALTH_RANKIN_SCALE"] = "eHealth/rankin_scale";
    Dictionary["LEGAL_ENTITY_TYPE"] = "LEGAL_ENTITY_TYPE";
    Dictionary["LEGAL_ENTITY_TYPE_V2"] = "LEGAL_ENTITY_TYPE_V2";
    Dictionary["MEDICATION_REQUEST_CATEGORY"] = "MEDICATION_REQUEST_CATEGORY";
    Dictionary["EHEALTH_REFERENCE_RANGE_TYPES"] = "eHealth/reference_range_types";
    Dictionary["EHEALTH_EYE_COLOUR"] = "eHealth/eye_colour";
    Dictionary["EHEALTH_SNOMED_ADDITIONAL_DOSAGE_INSTRUCTIONS"] = "eHealth/SNOMED/additional_dosage_instructions";
    Dictionary["LEGAL_FORM"] = "LEGAL_FORM";
    Dictionary["EHEALTH_MEDICATION_STATEMENT_MEDICATIONS"] = "eHealth/medication_statement_medications";
    Dictionary["EHEALTH_STATURE"] = "eHealth/stature";
    Dictionary["DIVISION_STATUS"] = "DIVISION_STATUS";
    Dictionary["EHEALTH_SNOMED_MEDICATION_AS_NEEDED_REASON_CODES"] = "eHealth/SNOMED/medication_as_needed_reason_codes";
    Dictionary["EHEALTH_IMMUNIZATION_STATUSES"] = "eHealth/immunization_statuses";
    Dictionary["EHEALTH_ENCOUNTER_PRIORITY"] = "eHealth/encounter_priority";
    Dictionary["PHONE_TYPE"] = "PHONE_TYPE";
    Dictionary["EHEALTH_SNOMED_ANATOMICAL_STRUCTURE_ADMINISTRATION_SITE_CODES"] = "eHealth/SNOMED/anatomical_structure_administration_site_codes";
    Dictionary["EHEALTH_VACCINE_CODES"] = "eHealth/vaccine_codes";
    Dictionary["SETTLEMENT_TYPE"] = "SETTLEMENT_TYPE";
    Dictionary["EHEALTH_SNOMED_ROUTE_CODES"] = "eHealth/SNOMED/route_codes";
    Dictionary["EHEALTH_SNOMED_MEDICATION_AS_NEEDED_REASON"] = "eHealth/SNOMED/medication_as_needed_reason";
    Dictionary["TERM_UNIT"] = "TERM_UNIT";
    Dictionary["EHEALTH_SNOMED_ADMINISTRATION_METHODS"] = "eHealth/SNOMED/administration_methods";
    Dictionary["EHEALTH_DOSE_AND_RATE"] = "eHealth/dose_and_rate";
    Dictionary["MEDICATION_UNIT"] = "MEDICATION_UNIT";
    Dictionary["EVENT_TIMING"] = "EVENT_TIMING";
    Dictionary["COMPOSITION_TREATMENT_VIOLATION"] = "COMPOSITION_TREATMENT_VIOLATION";
    Dictionary["TIMING_ABBREVIATION"] = "TIMING_ABBREVIATION";
    Dictionary["EHEALTH_IMMUNIZATION_DOSAGE_UNITS"] = "eHealth/immunization_dosage_units";
    Dictionary["DICTIONARY_LABELS"] = "DICTIONARY_LABELS";
    Dictionary["UNITS_OF_TIME"] = "UNITS_OF_TIME";
    Dictionary["LEGAL_ENTITY_STATUS"] = "LEGAL_ENTITY_STATUS";
    Dictionary["ADMIN_TRANSLATIONS"] = "ADMIN_TRANSLATIONS";
    Dictionary["DAYS_OF_WEEK"] = "DAYS_OF_WEEK";
    Dictionary["EHEALTH_RESOURCES"] = "eHealth/resources";
    Dictionary["OWNER_PROPERTY_TYPE"] = "OWNER_PROPERTY_TYPE";
    Dictionary["EHEALTH_RISK_ASSESSMENT_STATUSES"] = "eHealth/risk_assessment_statuses";
    Dictionary["EHEALTH_UCUM_UNITS"] = "eHealth/ucum/units";
    Dictionary["ADDRESS_TYPE"] = "ADDRESS_TYPE";
    Dictionary["EHEALTH_RISK_ASSESSMENT_METHODS"] = "eHealth/risk_assessment_methods";
    Dictionary["EHEALTH_EPISODE_CLOSING_REASONS"] = "eHealth/episode_closing_reasons";
    Dictionary["EDUCATION_DEGREE"] = "EDUCATION_DEGREE";
    Dictionary["EHEALTH_DEVICE_TYPES"] = "eHealth/device_types";
    Dictionary["GENDER"] = "GENDER";
    Dictionary["EHEALTH_ENCOUNTER_STATUSES"] = "eHealth/encounter_statuses";
    Dictionary["EQUIPMENT_TYPE"] = "EQUIPMENT_TYPE";
    Dictionary["COUNTRY"] = "COUNTRY";
    Dictionary["EHEALTH_ENCOUNTER_CLASSES"] = "eHealth/encounter_classes";
    Dictionary["EQUIPMENT_UDI_TYPE"] = "EQUIPMENT_UDI_TYPE";
    Dictionary["STREET_TYPE"] = "STREET_TYPE";
    Dictionary["EHEALTH_CANCELLATION_REASONS"] = "eHealth/cancellation_reasons";
    Dictionary["EHEALTH_RISK_ASSESSMENT_CODES"] = "eHealth/risk_assessment_codes";
    Dictionary["EHEALTH_ICD10_AM_CONDITION_CODES"] = "eHealth/ICD10_AM/condition_codes";
    Dictionary["ACCREDITATION_CATEGORY"] = "ACCREDITATION_CATEGORY";
    Dictionary["EHEALTH_DIAGNOSIS_ROLES"] = "eHealth/diagnosis_roles";
    Dictionary["EHEALTH_RISK_ASSESSMENT_REASONS"] = "eHealth/risk_assessment_reasons";
    Dictionary["EHEALTH_CONDITION_CLINICAL_STATUSES"] = "eHealth/condition_clinical_statuses";
    Dictionary["KVEDS"] = "KVEDS";
    Dictionary["EHEALTH_RISK_ASSESSMENT_OUTCOMES"] = "eHealth/risk_assessment_outcomes";
    Dictionary["COMPOSITION_TYPES"] = "COMPOSITION_TYPES";
    Dictionary["KVEDS_ALLOWED"] = "KVEDS_ALLOWED";
    Dictionary["EHEALTH_CONDITION_VERIFICATION_STATUSES"] = "eHealth/condition_verification_statuses";
    Dictionary["EHEALTH_RISK_ASSESSMENT_QUALITATIVE_RISKS"] = "eHealth/risk_assessment_qualitative_risks";
    Dictionary["COMPOSITION_EVENTS"] = "COMPOSITION_EVENTS";
    Dictionary["COMPOSITION_PROCESSING_STATUS"] = "COMPOSITION_PROCESSING_STATUS";
    Dictionary["COMPOSITION_CANCELLATION_REASONS"] = "COMPOSITION_CANCELLATION_REASONS";
    Dictionary["COMPOSITION_CANCELLATION_REASONS_TEMP_DISABILITY"] = "COMPOSITION_CANCELLATION_REASONS_TEMP_DISABILITY";
    Dictionary["COMPOSITION_STATUS"] = "COMPOSITION_STATUS";
    Dictionary["COMPOSITION_CATEGORIES"] = "COMPOSITION_CATEGORIES";
    Dictionary["COMPOSITION_RELATION_CODE"] = "COMPOSITION_RELATION_CODE";
    Dictionary["COMPOSITION_EXTENSIONS"] = "COMPOSITION_EXTENSIONS";
    Dictionary["COMPOSITION_PROCESSING_TASKS"] = "COMPOSITION_PROCESSING_TASKS";
    Dictionary["COMPOSITION_PROCESSING_TASK_STATUS"] = "COMPOSITION_PROCESSING_TASK_STATUS";
    Dictionary["COMPOSITION_PROCESSING_SYSTEMS"] = "COMPOSITION_PROCESSING_SYSTEMS";
    Dictionary["EHEALTH_ICD10_AM_FULL_CONDITION_CODES"] = "eHealth/ICD10_AM_FULL/condition_codes";
    Dictionary["SERVICE_REQUEST_INTENT"] = "SERVICE_REQUEST_INTENT";
    Dictionary["EHEALTH_REASON_EXPLANATIONS"] = "eHealth/reason_explanations";
    Dictionary["KVEDS_ALLOWED_PHARMACY"] = "KVEDS_ALLOWED_PHARMACY";
    Dictionary["EHEALTH_CONDITION_SEVERITIES"] = "eHealth/condition_severities";
    Dictionary["EHEALTH_SNOMED_SERVICE_REQUEST_CATEGORIES"] = "eHealth/SNOMED/service_request_categories";
    Dictionary["KVEDS_ALLOWED_MSP"] = "KVEDS_ALLOWED_MSP";
    Dictionary["EHEALTH_CONDITION_STAGES"] = "eHealth/condition_stages";
    Dictionary["EHEALTH_ENCOUNTER_TYPES"] = "eHealth/encounter_types";
    Dictionary["EHEALTH_LOINC_OBSERVATION_CODES"] = "eHealth/LOINC/observation_codes";
    Dictionary["AUTHENTICATION_METHOD"] = "AUTHENTICATION_METHOD";
    Dictionary["EHEALTH_SNOMED_SERVICE_REQUEST_PERFORMER_ROLES"] = "eHealth/SNOMED/service_request_performer_roles";
    Dictionary["SPECIALITY_LEVEL"] = "SPECIALITY_LEVEL";
    Dictionary["EHEALTH_OBSERVATION_INTERPRETATIONS"] = "eHealth/observation_interpretations";
    Dictionary["EHEALTH_SERVICE_REQUEST_RECALL_REASONS"] = "eHealth/service_request_recall_reasons";
    Dictionary["EMPLOYEE_STATUS"] = "EMPLOYEE_STATUS";
    Dictionary["EHEALTH_BODY_SITES"] = "eHealth/body_sites";
    Dictionary["EHEALTH_SERVICE_REQUEST_CANCEL_REASONS"] = "eHealth/service_request_cancel_reasons";
    Dictionary["QUALIFICATION_TYPE"] = "QUALIFICATION_TYPE";
    Dictionary["EHEALTH_IMMUNIZATION_BODY_SITES"] = "eHealth/immunization_body_sites";
    Dictionary["CONFIDANT_PERSON_TYPE"] = "CONFIDANT_PERSON_TYPE";
    Dictionary["DOCUMENT_TYPE"] = "DOCUMENT_TYPE";
    Dictionary["EHEALTH_OBSERVATION_METHODS"] = "eHealth/observation_methods";
    Dictionary["TAX_ID"] = "TAX_ID";
    Dictionary["SERVICE_REQUEST_PRIORITY"] = "SERVICE_REQUEST_PRIORITY";
    Dictionary["EMPLOYEE_REQUEST_STATUS"] = "EMPLOYEE_REQUEST_STATUS";
    Dictionary["EHEALTH_VACCINATION_ROUTES"] = "eHealth/vaccination_routes";
    Dictionary["REGISTER_TYPE"] = "REGISTER_TYPE";
    Dictionary["LICENSE_TYPE"] = "LICENSE_TYPE";
    Dictionary["EHEALTH_REASON_NOT_GIVEN_EXPLANATIONS"] = "eHealth/reason_not_given_explanations";
    Dictionary["SPECIALITY_TYPE"] = "SPECIALITY_TYPE";
    Dictionary["CONTRACT_STATUS_REASON"] = "CONTRACT_STATUS_REASON";
    Dictionary["DIVISION_TYPE"] = "DIVISION_TYPE";
    Dictionary["EHEALTH_VACCINATION_TARGET_DISEASES"] = "eHealth/vaccination_target_diseases";
    Dictionary["MEDICATION_REQUEST_REJECT_REASON"] = "MEDICATION_REQUEST_REJECT_REASON";
    Dictionary["EMPLOYEE_TYPE"] = "EMPLOYEE_TYPE";
    Dictionary["EHEALTH_VACCINATION_DOSE_STATUSES"] = "eHealth/vaccination_dose_statuses";
    Dictionary["EHEALTH_DIAGNOSTIC_REPORT_CATEGORIES"] = "eHealth/diagnostic_report_categories";
    Dictionary["SCIENCE_DEGREE"] = "SCIENCE_DEGREE";
    Dictionary["EHEALTH_VACCINATION_DOSE_STATUS_REASONS"] = "eHealth/vaccination_dose_status_reasons";
    Dictionary["EHEALTH_PROCEDURE_OUTCOMES"] = "eHealth/procedure_outcomes";
    Dictionary["SPEC_QUALIFICATION_TYPE"] = "SPEC_QUALIFICATION_TYPE";
    Dictionary["EHEALTH_ALLERGY_INTOLERANCE_CLINICAL_STATUSES"] = "eHealth/allergy_intolerance_clinical_statuses";
    Dictionary["EHEALTH_PROCEDURE_STATUS_REASONS"] = "eHealth/procedure_status_reasons";
    Dictionary["SCOPES"] = "SCOPES";
    Dictionary["EHEALTH_ALLERGY_INTOLERANCE_VERIFICATION_STATUSES"] = "eHealth/allergy_intolerance_verification_statuses";
    Dictionary["MEDICAL_PROGRAM_TYPE"] = "MEDICAL_PROGRAM_TYPE";
    Dictionary["SERVICE_CATEGORY"] = "SERVICE_CATEGORY";
    Dictionary["DOCUMENT_RELATIONSHIP_TYPE"] = "DOCUMENT_RELATIONSHIP_TYPE";
    Dictionary["EHEALTH_ALLERGY_INTOLERANCE_CODES"] = "eHealth/allergy_intolerance_codes";
    Dictionary["EHEALTH_ALLERGY_INTOLERANCE_TYPES"] = "eHealth/allergy_intolerance_types";
    Dictionary["EDR_STATE"] = "EDR_STATE";
    Dictionary["POSITION"] = "POSITION";
    Dictionary["MEDICATION_FORM"] = "MEDICATION_FORM";
    Dictionary["EHEALTH_ALLERGY_INTOLERANCE_CATEGORIES"] = "eHealth/allergy_intolerance_categories";
    Dictionary["EHEALTH_DIAGNOSTIC_REPORT_STATUSES"] = "eHealth/diagnostic_report_statuses";
    Dictionary["CONTRACT_TYPE"] = "CONTRACT_TYPE";
    Dictionary["EHEALTH_ALLERGY_INTOLERANCE_CRITICALITIES"] = "eHealth/allergy_intolerance_criticalities";
    Dictionary["EHEALTH_PROCEDURE_STATUSES"] = "eHealth/procedure_statuses";
    Dictionary["CONTRACT_PAYMENT_METHOD"] = "CONTRACT_PAYMENT_METHOD";
    Dictionary["EHEALTH_REPORT_ORIGINS"] = "eHealth/report_origins";
    Dictionary["EHEALTH_IMMUNIZATION_REPORT_ORIGINS"] = "eHealth/immunization_report_origins";
    Dictionary["EHEALTH_MEDICATION_ADMINISTRATION_STATUSES"] = "eHealth/medication_administration_statuses";
    Dictionary["MEDICAL_SERVICE"] = "MEDICAL_SERVICE";
    Dictionary["EHEALTH_AMBULATORY_REASONS"] = "eHealth/ambulatory/reasons";
    Dictionary["EHEALTH_SNOMED_MEDICATION_ADMINISTRATION_CATEGORIES"] = "eHealth/SNOMED/medication_administration_categories";
    Dictionary["CONTRACT_DOCUMENT"] = "CONTRACT_DOCUMENT";
    Dictionary["EHEALTH_VACCINATION_AUTHORITIE"] = "eHealth/vaccination_authoritie";
    Dictionary["EHEALTH_MEDICATION_ADMINISTRATION_STATUS_REASONS"] = "eHealth/medication_administration_status_reasons";
    Dictionary["EHEALTH_VACCINATION_AUTHORITIES"] = "eHealth/vaccination_authorities";
    Dictionary["PERSONS_DEACTIVATION_IDENTITIES"] = "PERSONS_DEACTIVATION_IDENTITIES";
    Dictionary["EHEALTH_AMBULATORY_ACTIONS"] = "eHealth/ambulatory/actions";
    Dictionary["EHEALTH_OBSERVATION_CATEGORIES"] = "eHealth/observation_categories";
    Dictionary["PROVIDING_CONDITION"] = "PROVIDING_CONDITION";
    Dictionary["EHEALTH_ICPC2_CONDITION_CODES"] = "eHealth/ICPC2/condition_codes";
    Dictionary["EHEALTH_ICD10_CONDITION_CODES"] = "eHealth/ICD10/condition_codes";
    Dictionary["EHEALTH_DEVICE_STATUSES"] = "eHealth/device_statuses";
    Dictionary["DEVICE_NAME_TYPE"] = "device_name_type";
    Dictionary["EHEALTH_REASONS_NOT_GIVEN"] = "eHealth/reasons_not_given";
    Dictionary["EHEALTH_ENCOUNTER_ADMIT_SOURCE"] = "eHealth/encounter_admit_source";
    Dictionary["EHEALTH_MEDICATION_STATEMENT_STATUSES"] = "eHealth/medication_statement_statuses";
    Dictionary["EHEALTH_ICPC2_ACTIONS"] = "eHealth/ICPC2/actions";
    Dictionary["EHEALTH_ENCOUNTER_RE_ADMISSION"] = "eHealth/encounter_re_admission";
    Dictionary["CAPITATION_CONTRACT_REQUEST_REVIEW_TEXT"] = "CAPITATION_CONTRACT_REQUEST_REVIEW_TEXT";
    Dictionary["EHEALTH_ICPC2_REASONS"] = "eHealth/ICPC2/reasons";
    Dictionary["EHEALTH_ENCOUNTER_DISCHARGE_DISPOSITION"] = "eHealth/encounter_discharge_disposition";
    Dictionary["REIMBURSEMENT_CONTRACT_REQUEST_REVIEW_TEXT"] = "REIMBURSEMENT_CONTRACT_REQUEST_REVIEW_TEXT";
    Dictionary["EHEALTH_EPISODE_TYPES"] = "eHealth/episode_types";
    Dictionary["EHEALTH_ENCOUNTER_DISCHARGE_DEPARTMENT"] = "eHealth/encounter_discharge_department";
    Dictionary["CAPITATION_CONTRACT_CONSENT_TEXT"] = "CAPITATION_CONTRACT_CONSENT_TEXT";
    Dictionary["EHEALTH_EPISODE_STATUSES"] = "eHealth/episode_statuses";
    Dictionary["EHEALTH_PROCEDURE_CATEGORIES"] = "eHealth/procedure_categories";
    Dictionary["REIMBURSEMENT_CONTRACT_CONSENT_TEXT"] = "REIMBURSEMENT_CONTRACT_CONSENT_TEXT";
    Dictionary["EHEALTH_OBSERVATION_STATUSES"] = "eHealth/observation_statuses";
    Dictionary["EHEALTH_HAIR_LENGTH"] = "eHealth/hair_length";
    Dictionary["EHEALTH_CARE_PLAN_CATEGORIES"] = "eHealth/care_plan_categories";
    Dictionary["EHEALTH_CARE_PLAN_CANCEL_REASONS"] = "eHealth/care_plan_cancel_reasons";
    Dictionary["EHEALTH_CARE_PLAN_COMPLETE_REASONS"] = "eHealth/care_plan_complete_reasons";
    Dictionary["EHEALTH_CARE_PLAN_ACTIVITY_GOALS"] = "eHealth/care_plan_activity_goals";
    Dictionary["EHEALTH_CARE_PLAN_ACTIVITY_CANCEL_REASONS"] = "eHealth/care_plan_activity_cancel_reasons";
    Dictionary["EHEALTH_CARE_PLAN_ACTIVITY_COMPLETE_REASONS"] = "eHealth/care_plan_activity_complete_reasons";
    Dictionary["EHEALTH_CARE_PLAN_ACTIVITY_OUTCOMES"] = "eHealth/care_plan_activity_outcomes";
    Dictionary["HEALTHCARE_SERVICE_CATEGORIES"] = "HEALTHCARE_SERVICE_CATEGORIES";
    Dictionary["HEALTHCARE_SERVICE_PHARMACY_DRUGS_TYPES"] = "HEALTHCARE_SERVICE_PHARMACY_DRUGS_TYPES";
    Dictionary["SERVICE_UNIT"] = "SERVICE_UNIT";
    Dictionary["FUNDING_SOURCE"] = "FUNDING_SOURCE";
    Dictionary["EHEALTH_CLINICAL_IMPRESSION_PATIENT_CATEGORIES"] = "eHealth/clinical_impression_patient_categories";
    Dictionary["MR_BLANK_TYPES"] = "MR_BLANK_TYPES";
    Dictionary["DEVICE_REQUEST_STATUSES"] = "device_request_statuses";
    Dictionary["DEVICE_UNIT"] = "device_unit";
    Dictionary["DEVICE_DEFINITION_CLASSIFICATION_TYPE"] = "device_definition_classification_type";
    Dictionary["DEVICE_DEFINITION_PACKAGING_TYPE"] = "device_definition_packaging_type";
    Dictionary["EHEALTH_DEVICE_PROPERTIES"] = "eHealth/device_properties";
    Dictionary["PARTY_VERIFICATION_STATUSES"] = "PARTY_VERIFICATION_STATUSES";
    Dictionary["PARTY_VERIFICATION_STATUS_REASONS"] = "PARTY_VERIFICATION_STATUS_REASONS";
    Dictionary["DECLARATION_REQUEST_CHANNELS"] = "declaration_request_channels";
    Dictionary["DECLARATION_REQUEST_STATUSES"] = "declaration_request_statuses";
    Dictionary["PERSON_VERIFICATION_STATUSES"] = "PERSON_VERIFICATION_STATUSES";
    Dictionary["PERSON_VERIFICATION_STATUS_REASONS"] = "PERSON_VERIFICATION_STATUS_REASONS";
    Dictionary["MEDICATION_REQUEST_BLOCK_REASON"] = "MEDICATION_REQUEST_BLOCK_REASON";
    Dictionary["MEDICATION_DISPENSE_STATUSES"] = "eHealth/medication_dispense_statuses";
})(Dictionary || (Dictionary = {}));
