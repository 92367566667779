export var EhealthServiceRequestAction;
(function (EhealthServiceRequestAction) {
    EhealthServiceRequestAction["ADD_ITEMS"] = "EHEALTH_SERVICE_REQUEST_ADD_ITEMS";
    EhealthServiceRequestAction["CLEAR"] = "EHEALTH_SERVICE_REQUEST_CLEAR";
    EhealthServiceRequestAction["UPDATE"] = "EHEALTH_SERVICE_REQUEST_UPDATE";
    EhealthServiceRequestAction["SET_IS_LOADING"] = "EHEALTH_SERVICE_REQUEST_SET_IS_LOADING";
    EhealthServiceRequestAction["SETUP_JOBS"] = "EHEALTH_SERVICE_REQUEST_SETUP_JOBS";
    EhealthServiceRequestAction["REMOVE_JOB"] = "EHEALTH_SERVICE_REQUEST_REMOVE_JOB";
    EhealthServiceRequestAction["SET_FILTER"] = "EHEALTH_SERVICE_REQUEST_SET_FILTER";
})(EhealthServiceRequestAction || (EhealthServiceRequestAction = {}));
