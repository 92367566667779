import { ClientConfiguration } from './client-configuration';
import { SERVICE_EHEALTH_AUTH } from '../../auth';
import { PARAM_ITEMS_PER_PAGE } from '../../../../../config/di/params/keys';
import { PARAM_EHEALTH_PROXY_URL } from '../../../config/di/params/keys';
import { SERVICE_REQUEST } from '../../request';
export var SERVICE_EHEALTH_CLIENT_CONFIGURATION = 'SERVICE_EHEALTH_CLIENT_CONFIGURATION';
export var eHealthClientConfigurationFactory = function (container) {
    var authService = container.require(SERVICE_EHEALTH_AUTH);
    var requestService = container.require(SERVICE_REQUEST);
    var baseUrl = "".concat(container.get(PARAM_EHEALTH_PROXY_URL));
    var itemsPerPage = +(container.get(PARAM_ITEMS_PER_PAGE) || 0);
    return new ClientConfiguration({
        authService: authService,
        baseUrl: baseUrl,
        itemsPerPage: itemsPerPage,
        requestService: requestService,
    });
};
