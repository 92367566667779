import { Button, Typography } from '@mui/material';
import React from 'react';
import classNames from 'classnames';
import { useStyles } from './ButtonAction.jss';
import { useHistory } from 'react-router-dom';
export default function ButtonAction(props) {
    var classes = useStyles();
    var history = useHistory();
    return (React.createElement(Button, { sx: {
            width: "".concat(props.width, "px"),
            height: "".concat(props.height, "px"),
            borderRadius: "".concat(props.width, "px"),
            backgroundColor: props.backgroundColor,
            marginRight: '10px',
            minWidth: "".concat(props.width, "px"),
        }, onClick: function () { return (props.reactLink ? history.push(props.buttonLink) : (window.location.href = props.buttonLink)); } },
        props.icon,
        !props.hideText && (React.createElement(Typography, { sx: { color: props.textColor }, className: classNames(classes.buttonActionTitle) }, props.buttonTitle))));
}
