var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ClinicNewsActionType } from './action';
import { initialState } from './state';
export var clinicNewsReducer = function (state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ClinicNewsActionType.SET_DATA_FALSE:
            return __assign(__assign({}, state), { isNewsAdded: false, isNewsDeleted: false, isNewsEdited: false, isLoading: false, error: null });
        case ClinicNewsActionType.GET_CLINIC_NEWS_PENDING:
            return __assign(__assign({}, state), { isLoading: true, error: null });
        case ClinicNewsActionType.GET_CLINIC_NEWS_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, clinicNews: action.payload.clinicNews, totalCount: action.payload.totalCount });
        case ClinicNewsActionType.GET_CLINIC_NEWS_ERROR:
            return __assign(__assign({}, state), { isLoading: false, error: action.payload });
        case ClinicNewsActionType.GET_CLINIC_NEWS_TAGS_PENDING:
            return __assign(__assign({}, state), { isLoading: true, error: null });
        case ClinicNewsActionType.GET_CLINIC_NEWS_TAGS_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, tags: action.payload });
        case ClinicNewsActionType.GET_CLINIC_NEWS_TAGS_ERROR:
            return __assign(__assign({}, state), { isLoading: false, error: action.payload });
        case ClinicNewsActionType.ADD_CLINIC_NEWS_PENDING:
            return __assign(__assign({}, state), { isLoading: true, error: null, isNewsAdded: false });
        case ClinicNewsActionType.ADD_CLINIC_NEWS_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, isNewsAdded: true });
        case ClinicNewsActionType.ADD_CLINIC_NEWS_ERROR:
            return __assign(__assign({}, state), { isLoading: false, error: action.payload, isNewsAdded: false });
        case ClinicNewsActionType.SET_SINGLE_CLINIC_NEWS:
            return __assign(__assign({}, state), { isLoading: false, singleNews: (_a = state.clinicNews.find(function (item) { return item.id === action.payload; })) !== null && _a !== void 0 ? _a : null });
        default:
            return state;
    }
};
