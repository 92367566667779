export var defaultState = {
    experts: {
        data: [],
        isLoading: false,
    },
    schedules: [],
    services: [],
    bookings: {
        data: [],
        isLoading: false,
    },
    selectedExperts: {},
};
